import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
const apiUrl = environment.baseUrl+'/api/PaymentType/';
@Injectable({
  providedIn: 'root'
})
export class PaymentTypeService {

  constructor(private http: HttpClient) { }
  
  getPaymentTypeList(): Observable<any> {
    return this.http.get(apiUrl + 'GetAllPaymentType');
   }
}
