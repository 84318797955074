import { Component, OnInit } from '@angular/core';
import { PdfService } from '../pdf.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-ledger-book',
  templateUrl: './ledger-book.component.html',
  styleUrls: ['./ledger-book.component.scss']
})
export class LedgerBookComponent implements OnInit {
  fromDate:string;
  toDate:string;

  constructor(private pdfService: PdfService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.formatDates();
  }

  formatDates(): void {
    const formattedFromDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.fromDate = formattedFromDate;

    const formattedToDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.toDate = formattedToDate;
  }

  OpenPDF(){
    this.pdfService.OpenLedgerBookPdf(this.fromDate, this.toDate).subscribe(res => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    })
  }
}
