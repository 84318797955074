import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavbarsService } from '../navbars.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { Navbars } from '../navbars';

@Component({
  selector: 'app-navbars',
  templateUrl: './navbars.component.html',
  styleUrls: ['./navbars.component.scss']
})
export class NavbarsComponent implements OnInit {
 
  list: any[] = [];
  userTypelist: any[] = [];
  isEdit: boolean = false;
  showEntry = false;
  menuDiv = false;
  frmGroup: FormGroup;
  model= new Navbars ();
  constructor(
    private _service:NavbarsService,
    private formBuilder: FormBuilder,
     private router: Router, 
     private activeRoute: ActivatedRoute
  ) 
  {
    this.loadParentListInfo();
  }

  ngOnInit(): void {
    this.loadParentListInfo();
    this. getAllUserType();
    this.populateForm();
    this.getNavbarList();
    this.activeRoute.paramMap.subscribe(params => {
      const navbarId = +params.get('id');
      if (navbarId > 0) {
        this.getnavbarId(navbarId);
      }
    });
  }

  ParentList: Observable<any[]>;
  loadParentListInfo(){
    this._service.getParentListInfo().subscribe(res => {
      this.ParentList=res;
    })
  }
  getnavbarId(id: number) {
    this.isEdit = true;
  }

  populateForm() {
    this.frmGroup = this.formBuilder.group({
     id: [null],
     name: [''],
     nameBn: [''],
     title: [''],
     route: [''],
     icon: [''],
    // bootClass: [''],
     //badge: [''],
     //badgeClass: [''],
     isActive: [''],
     parentId: [''],
     isParent: [''],
     displayOrder: [''],
     remarks: [''],
     userTypeId: [''],
    })
  }

  getNavbarList() { 
    this._service.getList().subscribe(res => {    
      this.list = res;
    })
  }

  getAllUserType() { 
    this._service.getAllUserType().subscribe(res => {    
      this.userTypelist = res;
    })
  }

  showDiv = 1;
  showView(div): void {
    this.showDiv = div;
  }

  onsubmit() {
    if (this.isEdit) {
      this.model.id = this.frmGroup.value.id;
    }  
    this.model.name = this.frmGroup.value.name;
    this.model.nameBn = this.frmGroup.value.nameBn;
    this.model.title = this.frmGroup.value.title;
    this.model.route = this.frmGroup.value.route;
    this.model.icon = this.frmGroup.value.icon;
    //this.model.bootClass = this.frmGroup.value.bootClass;
    //this.model.badge = this.frmGroup.value.badge;
   // this.model.badgeClass = this.frmGroup.value.badgeClass;
    this.model.isActive = (this.frmGroup.value.isActive ==="1") ?true : false;  
    //this.model.parentId = this.frmGroup.value.parentId;
    this.model.parentId = (this.frmGroup.value.parentId === "")? 0:this.frmGroup.value.parentId ;
    //this.model.isParent = this.frmGroup.value.isParent;
    this.model.isParent = (this.frmGroup.value.isParent ==="1") ?true : false;
    this.model.displayOrder = this.frmGroup.value.displayOrder;
    this.model.remarks = this.frmGroup.value.remarks;
    this.model.userTypeId = this.frmGroup.value.userTypeId;
   
    if (this.model.id) {
      this._service.save(this.model).subscribe(res => {
        this.getNavbarList();
        this.showDiv = 1;
      })
    } 
    else {
      this._service.save(this.model).subscribe(res => {
        this.getNavbarList();
        this.showDiv = 1;
      })
    }
  }

  edit(item: any) {   
    this.isEdit = true;
    this.showDiv = 2;   
    const activeStatus = item.isActive === false ? '0' : '1';
    const parentStatus = item.isParent === false ? '0' : '1';
    if(parentStatus==='1')
    {
      this.menuDiv = true;
    }
    else
    {
      this.menuDiv = false;
    }

    this.frmGroup.patchValue({
      id: item.id,
      name: item.name,
      nameBn: item.nameBn,
      title: item.title,
      route: item.route,
      icon: item.icon,
      //bootClass: item.bootClass,
      //badge: item.badge,
      //badgeClass: item.badgeClass,
      parentId: item.parentId,
      displayOrder: item.displayOrder,
      remarks: item.remarks,
      userTypeId: item.userTypeId,
      //isParent: item.isParent,
      //isActive: item.isActive,
      isParent: parentStatus ,
      isActive:activeStatus
      
    });
  }

  onTypeChange(){
    if(this.frmGroup.value.isParent==1){
      this.menuDiv=true;
    }
    else{
      this.menuDiv=false;
    }
  }

  delete(Id:any){  
    this._service.delete(Id).subscribe(res=>{
      if(res=='ok'){
        alert('Data deleted successfully');
      }
      this.getNavbarList();
    })
  }
}
