
<br>
<h4 class="card-title" style="color: black">Process Account Balance</h4>
<hr>
<div class="card">
    <div class="card-body">
        <div class="form-group row">
           <!-- <label for="type" class="col-md-1">Type:</label>
           <div class="col-2">
               <select type="text" class="form-control"  [(ngModel)]="types">
                    <option value="">Select</option>   
                    <option value="Deposit">Deposit</option>
                    <option value="FDR">FDR</option>
                    <option value="DPS">DPS</option>
                    <option value="Loan">Loan</option>
               </select>
           </div> -->

            <label for="dateInput" class="col-md-2">From Date:</label>
            <div class="col-md-2 input-group">
                <input type="date" [(ngModel)]="fromDate" class="form-control" > 
            </div>

            <label for="dateInput" class="col-md-2">To Date:</label>
            <div class="col-md-2 input-group">
                <input type="date" [(ngModel)]="toDate" class="form-control">
            </div>

            <div class="col-2">
                <!-- <button type="button" class="btn btn-primary" id="processButton">Process</button> -->
            </div>
        </div>
        <hr>
        <div class="row">
            <table style="width: 100%;">
                <thead>
                    <tr style="background-color: rgb(180, 179, 179);">
                        <th>
                            <input (click)="onChangeCheckbox('All', 'All')" type="checkbox" id="allCheck" [(ngModel)]="isAllProcessed" class="form-control checkbox">
                        </th>
                        <th>
                            <label for="allCheck" style="font-weight: bold; margin: 0;">Check All</label>
                        </th>
                        <th>
                            <div style="display: flex;">
                                <a href="javascript.void(0)" (click)="ProcessAll()" class="btn btn-outline-info btn-sm">Process</a>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody style="background-color: white;">
                    <tr>
                        <td style="width: 5%;">
                            <input id="BalanceFDR" (click)="onChangeCheckbox('Balance', 'FDR')" type="checkbox" [(ngModel)]="isBalanceFDRChecked" class="form-control checkbox">
                        </td>
                        <td style="width: 800px;">
                            <label for="BalanceFDR" style="font-weight: bold; margin: 0;">Process Daily Balance (FDR)</label>
                        </td>
                        <td style="width: 100px;">
                            <button type="button" (click)="ProcessByNameAndType('Balance', 'FDR')" class="btn btn-info btn-sm">Process</button>
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 5%;">
                            <input id="BalanceDPS" (click)="onChangeCheckbox('Balance', 'DPS')" type="checkbox" [(ngModel)]="isBalanceDPSChecked" class="form-control checkbox">
                        </td>
                        <td style="width: 800px;">
                            <label for="BalanceDPS" style="font-weight: bold; margin: 0;">Process Daily Balance (DPS)</label>
                        </td>
                        <td style="width: 100px;">
                            <button type="button" (click)="ProcessByNameAndType('Balance', 'DPS')" class="btn btn-info btn-sm">Process</button>
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 5%;">
                            <input id="BalanceDeposit" (click)="onChangeCheckbox('Balance', 'Deposit')" type="checkbox" [(ngModel)]="isBalanceDepositChecked" class="form-control checkbox">
                        </td>
                        <td style="width: 800px;">
                            <label for="BalanceDeposit" style="font-weight: bold; margin: 0;">Process Daily Balance (Deposit)</label>
                        </td>
                        <td style="width: 100px;">
                            <button type="button" (click)="ProcessByNameAndType('Balance', 'Deposit')" class="btn btn-info btn-sm">Process</button>
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 5%;">
                            <input id="BalanceLoan" (click)="onChangeCheckbox('Balance', 'Loan')" type="checkbox" [(ngModel)]="isBalanceLoanChecked" class="form-control checkbox">
                        </td>
                        <td style="width: 800px;">
                            <label for="BalanceLoan" style="font-weight: bold; margin: 0;">Process Daily Balance (Loan)</label>
                        </td>
                        <td style="width: 100px;">
                            <button type="button" (click)="ProcessByNameAndType('Balance', 'Loan')" class="btn btn-info btn-sm">Process</button>
                        </td>
                    </tr>


                    
                    <tr>
                        <td style="width: 5%;">
                            <input id="ProvisionFDR" (click)="onChangeCheckbox('Provision', 'FDR')" type="checkbox" [(ngModel)]="isProvisionFDRChecked" class="form-control checkbox">
                        </td>
                        <td style="width: 800px;">
                            <label for="ProvisionFDR" style="font-weight: bold; margin: 0;">Process Provision (FDR)</label>
                        </td>
                        <td style="width: 100px;">
                            <button type="button" (click)="ProcessByNameAndType('Provision', 'FDR')" class="btn btn-info btn-sm">Process</button>
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 5%;">
                            <input id="ProvisionDPS" (click)="onChangeCheckbox('Provision', 'DPS')" type="checkbox" [(ngModel)]="isProvisionDPSChecked" class="form-control checkbox">
                        </td>
                        <td style="width: 800px;">
                            <label for="ProvisionDPS" style="font-weight: bold; margin: 0;">Process Provision (DPS)</label>
                        </td>
                        <td style="width: 100px;">
                            <button type="button" (click)="ProcessByNameAndType('Provision', 'DPS')" class="btn btn-info btn-sm">Process</button>
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 5%;">
                            <input id="ProvisionDeposit" (click)="onChangeCheckbox('Provision', 'Deposit')" type="checkbox" [(ngModel)]="isProvisionDepositChecked" class="form-control checkbox">
                        </td>
                        <td style="width: 800px;">
                            <label for="ProvisionDeposit" style="font-weight: bold; margin: 0;">Process Provision (Deposit)</label>
                        </td>
                        <td style="width: 100px;">
                            <button type="button" (click)="ProcessByNameAndType('Provision', 'Deposit')" class="btn btn-info btn-sm">Process</button>
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 5%;">
                            <input id="ProvisionLoan" (click)="onChangeCheckbox('Provision', 'Loan')" type="checkbox" [(ngModel)]="isProvisionLoanChecked" class="form-control checkbox">
                        </td>
                        <td style="width: 800px;">
                            <label for="ProvisionLoan" style="font-weight: bold; margin: 0;">Process Provision (Loan)</label>
                        </td>
                        <td style="width: 100px;">
                            <button type="button" (click)="ProcessByNameAndType('Provision', 'Loan')" class="btn btn-info btn-sm">Process</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>