import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageServiceService {
  private languageSubject = new BehaviorSubject<string>('en');
  language$ = this.languageSubject.asObservable();
  supportedLanguage = ['en', 'bn'];

  constructor(private translateService: TranslateService) {
    const storedLang = localStorage.getItem('lang');
    if (storedLang) {
      this.languageSubject.next(storedLang);
    }
  }

  setLanguage() {
    const language = localStorage.getItem('lang');

    this.translateService.addLangs(this.supportedLanguage);
    this.translateService.setDefaultLang('en');
    const browserLang = this.translateService.getBrowserLang();
    this.translateService.use(language);
  }
}
