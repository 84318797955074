import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

const apiUrl=environment.baseUrl + '/api/DaySize/'

@Injectable({
  providedIn: 'root'
})
export class DaySizeService {

  constructor(private http:HttpClient) { }

  getDaySizeList():Observable<any>{
    return this.http.get(apiUrl+ 'GetAllDaySizeList')
  }
  getDaySizeListByProductId(id:number):Observable<any>{
    return this.http.get(apiUrl+ 'GetAllDaySizeListByProductType?id='+id)
  }
  getDaySizeById(id:number){
    return this.http.get(apiUrl + 'GetDaySizeById')
  }

  saveDaySize(model:any){
    return this.http.post(apiUrl + 'SaveDaySize', model)
  }

  deleteDaySize(id:any){
    return this.http.delete(apiUrl + 'DeleteDaySize/' + id)
  }
  getListWithSerchAndPagination(currentPage: any, itemsPerPage: any, searchValue: string) {
    return this.http.get(apiUrl + 'GetDaysizeWithSerchAndPagination?page='+ currentPage + '&pageSize=' +itemsPerPage + '&search=' + searchValue);
  }
}
