<form [formGroup]="frmGroup">
  <!-- ... -->
  <div class="col-md-12">
    <div class="card" style="width: 100%;">
     
      <div class="row card-header  d-flex flex-row align-items-center">
        <div class="col-6">
        <h5 class="card-title" style="color: black">Payment voucher</h5>
        </div>
        <div class="col-6">
          <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
            <!-- <i class="ft-chevrons-left"> Back</i> -->
            <i class="icon-arrow-left" style="font-size: 17px;"> </i>
          </button>
        </div>
      </div>
      <hr>
      <div class="card-body">
        <div>
          <div class="row" style="margin-top: 10px;">
            <div class="col-md-6">

              <div class="form-group row">
                <label for="" class="col-sm-5 col-form-label">Payment Account</label>
                <div class="col-sm-7">
                  <select class="form-control" formControlName="crLrId" [(ngModel)]="crLrId">
                    <option value="0">Select</option>
                    <ng-container *ngFor="let item of AccountNoList1 | async">
                      <option [value]="item.id" [attr.CrparticularName]="item.ledger?.accountName">
                        {{item.ledger?.accountName}} - {{item.ledger?.accountCode}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>


              <div class="form-group row">
                <label for="" class="col-sm-5 col-form-label">Particular Account</label>
                <div class="col-sm-7">
                  <select class="form-control" (change)="onLedgerChange($event.target.value)" formControlName="drLrId"
                    [(ngModel)]="drLrId">
                    <option value="0">Select</option>
                    <ng-container *ngFor="let item of AccountNoList | async">
                      <option [value]="item.id" [attr.ledgerId]="item.ledger?.id"
                        [attr.haveSubledger]="item.ledger?.haveSubledger"
                        [attr.particularName]="item.ledger?.accountName">{{item.ledger?.accountName}} -
                        {{item.ledger?.accountCode}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>

              <div class="form-group row" *ngIf="subledgerStatus === 'Show'" >
                <label for="ledgerId" class="col-sm-5 col-form-label">Select Sub Ledger</label>
                <div class="col-sm-7">
                  <select type="text" (change)="onSubLedgerChange($event.target.value)" class="form-control" formControlName="SubledgerId" [(ngModel)]="SubledgerId"
                    id="SubledgerId">
                    <option value="0" selected>Select</option>
                    <option *ngFor="let item of AccountSubLedgerList| async" [value]="item.id">{{item.subLedger?.accountName}}
                      - {{item.subLedger?.accountCode}}</option>
                  </select>
                </div>
              </div>




            </div>
            <div class="col-md-6">

              <div class="form-group row">
                <label for="voucherAmount" class="col-sm-5 col-form-label">Current Balance</label>
                <div class="col-sm-7">
                  <input type="text" class="form-control" formControlName="currentBalance" value="0"
                    style="text-align: right" readonly>
                </div>
              </div>
              <div class="form-group row">
                <label for="voucherAmount" class="col-sm-5 col-form-label">Payment Amount</label>
                <div class="col-sm-4">
                  <input type="number" class="form-control" [(ngModel)]="amount" formControlName="amount"
                    placeholder="0" style="text-align: right">
                </div>
                <div class="col-md-1" *ngIf="addButtonStatus === 'Show'">
                  <button (click)="AddParticular()" class="btn btn-success">ADD</button>
                </div>
              </div>
              <!-- <div class="form-group row">
                  <label for="" class="col-sm-5 ">Currency</label>
                  <div class="col-sm-7">
                    <select class="form-control" name="currencyId" formControlName="currencyId">
                      <option value="">Select</option>
                      <ng-container *ngFor="let item of countryList | async">
                        <option [value]="item.id">{{item.currency}}</option>
                      </ng-container>
                    </select>
                  </div>
                </div> -->


            </div>
          </div>
        </div>
        <div class="form-group row" *ngIf="TblStatus === 'Show'">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <table class="table table-bordered" id="myTable">
              <thead>
                <th>Particular Name</th>
                <th>Dr Amount</th>
                <th>Cr Amount</th>
                <th>Action</th>
              </thead>
              <tbody>
                <tr *ngFor="let item of particularList ;let i = index;">

                  <td class="">{{item.particularName}}-{{item.SubparticularName}}</td>
                  <td class="text-right">{{item.drAmount}}</td>
                  <td class="text-right">{{item.crAmount}}</td>
                  <td style="width: 200px;">
                    <a class="btn btn-sm btn-info" (click)="editAccount(i)"><i class="fa fa-edit"></i></a> &nbsp;&nbsp;
                    <a class="btn btn-sm btn-danger" (click)="deleteAccount(i)"><i class="fa fa-trash"></i></a>
                  </td>
                </tr>
                <tr>
                <tr>
                  <td class="">{{creditInfo.particularName}}</td>
                  <td class="text-right">0</td>
                  <td class="text-right">{{creditInfo.amount}}</td>
                  <td></td>
                </tr>
                <tr>
                  <td class="text-right">Total</td>
                  <td class="text-right">{{creditInfo.amount}}</td>
                  <td class="text-right">{{creditInfo.amount}}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-1"></div>
        </div>
        <div class="form-group row">
          <label for="remarks" class="col-sm-2 col-form-label">Remarks</label>
          <div class="col-sm-9">
            <textarea class="form-control" formControlName="remarks" id="" cols="30" rows="3"></textarea>
          </div>
        </div>

        <button data-toggle="tooltip" *ngIf="SaveStatus === 'Show'" (click)="onsubmit()" title="Save" type="button" class="btn btn-success"
          style="float:right; margin-top:5px;margin-right: 80px;">Save </button>

        <button type="button" title="Refresh" class="btn btn-secondary"
          style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;" (click)="ResetForm()">Refresh</button>
      </div>
    </div>
  </div>
</form>