import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardCustomService } from '../dashboard-custom.service';
import { SharedService } from 'app/services/shared.service';
import Swal from 'sweetalert2';
import { DatePipe, Location } from "@angular/common";
import { LayoutService } from 'app/shared/services/layout.service';
@Component({
  selector: "app-clcalculation-info",
  templateUrl: "./clcalculation-info.component.html",
  styleUrls: ["./clcalculation-info.component.scss"],
})
export class ClcalculationInfoComponent implements OnInit {
  List: any[] = [];

  startDate: Date | null = null;
  EndDate: Date | null = null;
  businessDate: Date;
  formattedStartDate: string| null = null;
  formattedEndDate: string| null = null;

  constructor(
    private router: Router,
    private _serviceDashboard: DashboardCustomService,
    private changeDetectorRef: ChangeDetectorRef,
   private location: Location,
    private shared: SharedService,
    private datePipe: DatePipe,
    private layoutService: LayoutService,
  ) {}

  ngOnInit(): void {
    this.GetClCalculationListByDate();
    //this.getClList();
  }

  // getCurrentBusinessDate() {
  //   this.layoutService.getCurrentBusinessDate().subscribe((res: any) => {
  //     this.businessDate = res.business_Date;
  //     this.startDate = this.businessDate;
      
  //     this.GetClCalculationListByDate();
  //   })
  // }


  GetClCalculationListByDate() {
    const defaultDate = "0001-01-01";
    this.List = [];
    const formattedStartDate = this.startDate ? this.datePipe.transform(this.startDate,"yyyy-MM-dd") : "";
    const formattedEndDate = this.EndDate ? this.datePipe.transform(this.EndDate, "yyyy-MM-dd") : "";

    this._serviceDashboard.getClProcessListByClTypeWiseByDate(this.startDate ?? defaultDate, this.EndDate ?? defaultDate).subscribe((res) => {
      this.List = res;
      this.changeDetectorRef.detectChanges();
    });
  }



  formatDates() {
    this.formattedStartDate = this.datePipe.transform(this.startDate, "dd-MM-yyyy");
    this.formattedEndDate = this.datePipe.transform(this.EndDate, "dd-MM-yyyy");
  }
  onDateChange() {
    this.formatDates();
  }

  // getClList() {
  //   this._serviceDashboard.getClProcessListByClTypeWise().subscribe((res) => {
  //     this.List = res;
  //     this.changeDetectorRef.detectChanges();
  //   });
  // }
  goBack() {
    this.location.back();
  }
}
