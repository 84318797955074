import { Component, OnInit } from '@angular/core';
import { LoanProductService } from 'app/product/service/loan-product.service';

@Component({
  selector: 'app-loan-product-details',
  templateUrl: './loan-product-details.component.html',
  styleUrls: ['./loan-product-details.component.scss']
})
export class LoanProductDetailsComponent implements OnInit {
  productDetails: any;
  constructor(private _loanProductService:LoanProductService) { }

  ngOnInit(): void {
    this.getProductDetails(5)
  }

  getProductDetails(id : any) {
    this._loanProductService.getLoanProductBuilderDetailsById(id).subscribe(
      (data : any) => {
        this.productDetails = data;
        console.log(data);
      },
      (error) => {
        console.error('Error fetching product details:', error);
      }
    );
  }

}
