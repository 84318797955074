<!-- <div class="" *ngFor="let item of customerAccountlist;">
    <div class="card" style="border: 1px solid gray; width: 50%; margin: 0 auto; margin-bottom: 15px;  background-image: linear-gradient(to right, rgb(12, 151, 194) , rgb(133, 190, 207) ); color: white;">
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group row">
                        <label for="name" class="col-sm-6 col-form-label" style="text-transform: capitalize; font-size: 1rem;">Customer Name</label>
                        <div class="col-sm-6">
                            <p class="form-control-static"> {{ item.customers.customerNameEng }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group row">
                        <label for="name" class="col-sm-6 col-form-label" style="text-transform: capitalize; font-size: 1rem;">Account Type</label>
                        <div class="col-sm-6">
                            <p class="form-control-static"> {{ item.productBuilder.name }}</p>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col-md-12">
                    <div class="form-group row">
                        <label for="name" class="col-sm-6 col-form-label" style="text-transform: capitalize; font-size: 1rem;">Account Title</label>
                        <div class="col-sm-6">
                            <p class="form-control-static"> {{ item.accountTilte }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group row">
                        <label for="name" class="col-sm-6 col-form-label" style="text-transform: capitalize; font-size: 1rem;">Account No</label>
                        <div class="col-sm-6">
                            <p class="form-control-static"> {{ item.customerAccountNo }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group row">
                        <label for="name" class="col-sm-6 col-form-label" style="text-transform: capitalize; font-size: 1rem;">Opening Balance</label>
                        <div class="col-sm-6">
                            <p class="form-control-static"> {{ item.openingBalance }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group row">
                        <label for="name" class="col-sm-6 col-form-label" style="text-transform: capitalize; font-size: 1rem;">Action</label>
                        <div class="col-sm-6">
                            <button class="btn btn-sm btn-secondary ml-2" title="Account Details" (click)="PrintKyc(item.id)"><i class="fa fa-file 3x"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->


<link href="https://fonts.googleapis.com/css?family=Roboto:300,400" rel="stylesheet">

<div *ngFor="let item of customerAccountlist; let i = index;">
   
    <br>
        <div *ngIf="item.accountType === 'deposit'" class="row">
        <div class="col-2"></div>
            <div class="col-md-8">
                <div class="Base">
                    <div class="Inner-wrap">
        
        
                        <div style="font-size: 17px;">{{item.productType}}</div>
        
                        <svg class="Chip" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 387.8 380.3"
                            style="enable-background:new 0 0 387.8 380.3;" xml:space="preserve">
                            <style type="text/css">
                                .st0 {
                                    fill: url(#gold-gradient);
                                    stroke: #000000;
                                    stroke-width: 10;
                                    stroke-miterlimit: 10;
                                }
                            </style>
                            <defs>
                                <linearGradient id="gold-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                    <stop offset="0%" stop-color="#c79750"></stop>
                                    <stop offset="20%" stop-color="#e6b964"></stop>
                                    <stop offset="50%" stop-color=" #f8e889"></stop>
                                    <stop offset="80%" stop-color=" #deb15f"></stop>
                                    <stop offset="100%" stop-color=" #dfb461"></stop>
                                </linearGradient>
                            </defs>
                            <g id="XMLID_4_">
                                <path id="XMLID_1_" class="st0" d="M308.8,375.3H79.1C38.2,375.3,5,342.1,5,301.2V79.1C5,38.2,38.2,5,79.1,5h229.7
                                        c40.9,0,74.1,33.2,74.1,74.1v222.2C382.8,342.1,349.7,375.3,308.8,375.3z" />
                                <line id="XMLID_2_" class="st0" x1="109.9" y1="5.1" x2="109.9" y2="375.1" />
                                <line id="XMLID_3_" class="st0" x1="4.9" y1="95.1" x2="109.9" y2="95.1" />
                                <line id="XMLID_7_" class="st0" x1="4.9" y1="185.1" x2="109.9" y2="185.1" />
                                <line id="XMLID_8_" class="st0" x1="1.9" y1="275.1" x2="106.9" y2="275.1" />
                                <line id="XMLID_9_" class="st0" x1="276.9" y1="275.1" x2="381.9" y2="275.1" />
                                <line id="XMLID_10_" class="st0" x1="274.9" y1="185.1" x2="379.9" y2="185.1" />
                                <line id="XMLID_11_" class="st0" x1="277.9" y1="95.1" x2="382.9" y2="95.1" />
                                <g id="XMLID_6_">
                                    <g id="XMLID_14_">
                                        <g id="XMLID_32_">
                                            <path id="XMLID_33_" d="M277.4,90.1h-1c-2.5,0-4.5,2-4.5,4.5v272c0,2.5,2,4.5,4.5,4.5h1c2.5,0,4.5-2,4.5-4.5v-272
                                                     C281.9,92.1,279.9,90.1,277.4,90.1z" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
        
                        <div class="Card-number">
                            <ul>
                                <li id="first-li">{{item.accNo}}</li>
                            </ul>
                        </div>
        
                        <div class='Expire'>
                            <h4 style="text-transform: capitalize;">Account Name &nbsp; &nbsp; {{item.title}}</h4>
        
                        </div>
                        <div class='Expire' style="margin-top: 10px;">
        
                            <p>Present Balance &nbsp; &nbsp; {{item.balance}}</p>
                        </div>
        
                        <!-- <div class='Name'>
                                    <h3>{{item.customers.customerNameEng}}</h3>
                                </div>
            
                                <div class="btn">
                                    <button class="btn btn-sm btn-secondary d-block m-auto" title="Details" (click)="PrintKyc(item.id)"><i class="fa fa-file fa-2x"></i></button>
                               </div> -->
                    </div>
                </div>
        
        
            </div>
            <div class="col-md-2 col-sm-2" style="margin-bottom: 31%; margin-left: -14%;">
                <button class="btn btn-sm btn-secondary"
                    [routerLink]="'/customeraccount/saving-account-details/' + item.accountId">
                    <a style="color:black;font-weight: 700;">
                        Account Details
                    </a>
                </button>
            </div>
        
        
        
       
    </div>
  
</div>