<div class="card" style="width: 100%;">
    <div class="card-body">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h5 class="card-title" style="color: black">DPS Info</h5>
                </div>
                <!-- <div class="col" style="text-align: right;">
                    <button class="btn btn-primary" (click)="openingCreate()">Create</button>
                </div> -->

                <div class="col-1">
                    <button class="btn btn-secondary" title="Go Back" (click)="goBack()">
        
                        <i class="icon-arrow-left" style="font-size: 17px;"> </i>
                    </button>
                </div>
        
            </div>
            <table id="example" class="table table-striped table-bordered mt-3" style="width:100%">
                <thead style="background-color: #f8f9fb;">
                    <tr>
                        <th>Action</th>
                        <th>Account Type</th>
                        <th>Account Name</th>
                        <th>Account No</th>
                        <th>Account Create Date</th>
                        <th>Amount</th>
                
                    </tr>
                </thead>
                <tbody>
                    <ng-container ></ng-container>
                    <tr *ngFor="let item of customerList">
                        <td>
                            <button class="btn btn-primary" style="font-size: 14px; width: 120px !important; height: 35px;"
                                (click)="PrintKyc(item.accountId)" title="Click here for download pdf">View PDF</button>
                            <button class="btn btn-secondary mt-2" style="font-size: 14px; width: 120px !important; height: 35px;"
                                (click)="DPSSchedulePDF(item.accountId)" title="Click here for download pdf">Schedule</button>
                                <button class="btn btn-sm btn-primary mt-1" style="margin-top:5px; width: 120px;background-color:#45e2ff;color:white;"
                                (click)="DPSAccountBreakdownPDF(item.accountId)" title="Click here for download pdf">Account Breakdown</button>
                       
                       
                       
                            </td>
                        <td>{{item.accountType}}</td>
                        <td>{{item.title}}</td>
                        <td>{{item.accNo}}</td>
                        <td>{{item.createdDate | date: 'yyyy-MM-dd'}}</td>
                        <td>{{item.balance}}</td>
                
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>