<div class="row">
    <hr>
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-10">
                        <h5 class="card-title" style="color: #888888; font-family: Arial; font-size: 20px !important;">
                           Processing Fee Report</h5>

                    </div>
                    <div class="col-2">

                        <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
                            <i class="icon-arrow-left" style="font-size: 17px;"> </i>
                        </button>
                    </div>
                </div>
                <hr>
            </div>

            <div class="card-body">
                <div class="col-md-12">
                    <div class="form-group row" id="DateRangeSelect">
                        <label for="fromDate" class="col-sm-2 col-form-label text-left">From Date <span
                                class="redStar">*</span></label>
                        <div class="col-sm-4 input-group">
                            <input type="date" class="form-control" [(ngModel)]="fromDate"
                                placeholder="Select From Date">
                        </div>
                        <label for="toDate" class="col-sm-2 col-form-label text-left">To Date <span
                                class="redStar">*</span></label>
                        <div class="col-sm-4 input-group">
                            <input type="date" class="form-control" [(ngModel)]="toDate" placeholder="Select To Date">
                        </div>
                    </div>

                    <div class="form-group row" style="margin-top: 10px;">
                        <label class="col-sm-8"></label>
                        <div class="col-sm-4">
                            <button class="btn btn-success" style="margin-left: 5px; float: right;"
                                (click)="OpenPdf()">Print</button>
                            <button class="btn btn-info" style="margin-left: 5px; float: right;"
                                (click)="OpenExcel()">Excel</button>
                            <button class="btn btn-warning" style="margin-left: 5px; float: right;"
                                (click)="GetLoanCollection()">Load</button>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-sm-12" *ngIf="collectionDataList">
                    <div class="card col-12" style="overflow: auto;max-height: 600px;">

                        <table id="loanCollectionTable" class="table table-striped table-bordered scrollable-table"
                            style="width: 100%;">
                            <thead>
                                <tr>
                                    <th>SL.</th>
                                    <th>Account No</th>
                                    <th>Account Title</th>
                                    <th>Disbursement Date</th>
                                    <!-- <th>Loan Amount</th> -->
                                    <th>Processing Fee</th>
                                    <!-- <th>Other Fees</th>
                                    <th>Collection Status</th>
                                    <th>Collection Date</th>
                                    <th>Collection Amount</th>
                                    <th>Interest Amount</th> -->
                                    <th>Principal Amount</th>
                                    <th>Interest Amount</th>
                                    <th>Total Amount</th>



                                    <th>Outstanding</th>
                                    <!-- <th>Outstanding Amount</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of collectionDataList; let i=index">
                                    <td>{{i+1}}.</td>
                                    <td>{{item.accounT_NO}}</td>
                                    <td>{{item.accounT_TITLE}}</td>
                                    <td>{{formatDateString(item.disbursementDate)}}</td>
                                    <!-- <td style="text-align: right !important;">{{item.loanAmount}}</td> -->
                                    <td style="text-align: right !important;">{{item.processingFee}}</td>
                                    <!-- <td>{{item.otherFees}}</td>
                                    <td>{{item.collectionBehaviour}}</td>
                                    <td>{{formatDateString(item.collectionDate)}}</td>
                                    <td style="text-align: right !important;">{{item.collectionAmount}}</td>
                                    <td style="text-align: right !important;">{{item.interestAmount}}</td> -->
                                    <td style="text-align: right !important;">{{item.loanAmount}}
                                    </td>
                                    <td style="text-align: right !important;">{{item.interestAmount}}</td>
                                    <td style="text-align: right !important;">{{item.loanAmount + item.interestAmount}}</td>
                                    <td style="text-align: right !important;">{{item.outstanding}}</td>
                                    <!-- <td style="text-align: right !important;">{{item.outstanding}}</td> -->
                                </tr>
                            </tbody>

                            <tfoot>
                                <tr>
                                    <td colspan="4" style="text-align: right;"> Total</td>
                                    <td style="text-align: right !important;">{{ totalProcessingFee | number:'1.0-2' }}
                                    </td>

                                    <td style="text-align: right !important;">{{ totalPrincipal | number:'1.2-2' }}
                                    </td>
                                    <td style="text-align: right !important;">{{ totalInterest | number:'1.2-2' }}
                                    </td>
                                    <td style="text-align: right !important;">{{ (totalPrincipal + totalInterest) | number:'1.2-2' }}
                                    </td>
                                    <td style="text-align: right !important;">{{ totalOutstanding | number:'1.2-2' }}
                                    </td>
                                </tr>
                            </tfoot>

                        </table>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<br />
