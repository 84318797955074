import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CashApproveService } from '../service/cash-approve.service';
import { SharedService } from 'app/services/shared.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VoucherInfoService } from '../service/voucher-info.service';

@Component({
  selector: 'app-cash-approve',
  templateUrl: './cash-approve.component.html',
  styleUrls: ['./cash-approve.component.scss']
})
export class CashApproveComponent implements OnInit {
  voucherDetailsInfo: any[]=[];
  voucherMaster:any[]=[];
  closeResult: string;
  constructor(
    private _service:CashApproveService,
    private _voucherServie:VoucherInfoService,
    private shared:SharedService,
    private router: Router,
    private modalService: NgbModal,
    private changeDetectorRef:ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this. getVoucherList();
  }
    getVoucherList(){
    this._service.getAlllist().subscribe(res =>{
      this.voucherMaster = res.filter(item=>item.voucherMaster.isPosted===1);
      this.changeDetectorRef.detectChanges();
    })
  }


   getVoucherDetails(id : any) {
 
    this._voucherServie.getVoucherDetailsByMasterId(id).subscribe(data  => {
        this.voucherDetailsInfo = data;
        this.changeDetectorRef.detectChanges();
      })
  }

   openVoucherDetailsModal(content) {
    let options: any = {
      size: 'xl',
      centered: false
    }
      this.modalService.open(content, options).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
          this.closeResult = `Dismissed`;
      });
  }

  ApproveVoucher(id : any) {

    this._voucherServie.approveVoucherMasterById(id).subscribe(res  => {
        if(res.status==="Approved"){
          this.shared.ShowAlert("Success","Approved Successfully","success");
          this.getVoucherList();
          this.changeDetectorRef.detectChanges();
        }
        else if(res.status==="fail"){
          this.shared.ShowAlert("Warnibg","Unable to submit","warning");
        }
        else{
          this.shared.ShowAlert("Warning","Unable to submit","warning");
             
        }
      })
  }

  

}
