import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { RelationServiceService } from '../../basic-service/relation-service.service';
import { SharedService } from 'app/services/shared.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Relation } from '../../basic-model/relation';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-relation',
  templateUrl: './relation.component.html',
  styleUrls: ['./relation.component.scss']
})
export class RelationComponent implements OnInit {

  relationList : Observable<any[]>;
  model = new Relation();
  relationForm : FormGroup;
  isEdit : boolean = false;
  showSaveForm:string="saveList";
  List : Observable<any[]>;

  currentPage = 1;
  itemsPerPage = 10;
  totalItems = 0;
  currentItems = 0;
  searchControl = "";

  constructor(
    private _relationService : RelationServiceService,
    private shared: SharedService,
    private formBuilder : FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.buildForm();

    // this.relationForm = new FormGroup({
    //   id: new FormControl(),
    //   name: new FormControl(),
    //   nameBn: new FormControl(),
    //   sortOrder: new FormControl(),
      

    // });
   }

  ngOnInit(): void {
    this.getAllRelationList();
    this.buildForm();
  }

  getAllRelation(){
    this._relationService.getRelationList().subscribe(res=>{
      this.relationList = res;
    })
  }

  getAllRelationList(){
    this._relationService.getListWithSearchAndPagination(this.currentPage, this.itemsPerPage, this.searchControl).subscribe((res:any)=>{
      this.List = res.data;
      this.totalItems = res.totalItems;
      this.currentItems = res.currentItems;
    })
  }

  onClickShowSaveForm(value : string){
    this.showSaveForm = value;
  }


  buildForm(){
    
    this.relationForm = this.formBuilder.group({
      id: [0],
      name: ['', Validators.required],
      nameBn: ['', Validators.required],
      sortOrder: ['', Validators.required],
    })
  }

  onsubmit(){
    //alert(33)
    if(this.isEdit = true){
      this.model.id = this.relationForm.value.id;
    }
    this.model.name = this.relationForm.value.name;
    this.model.nameBn = this.relationForm.value.nameBn;
   this.model.sortOrder = this.relationForm.value.sortOrder;

    if(this.model.id){
      
      this._relationService.save(this.model).subscribe(data=>{
        this.shared.ShowAlert("Success", "Updated Successfully", "success");
        this.getAllRelationList();
        this.showSaveForm = 'saveList';
      })
    }
    else{
      this._relationService.save(this.model).subscribe(data=>{
        console.log(data);
        this.shared.ShowAlert("Success", "Saved Successfully", "success");
        this.isEdit = false;
        this.reset();
        this.getAllRelationList();
        this.showSaveForm = 'saveList';
      })
    }
    this.changeDetectorRef.detectChanges();
  }

  reset(){
    this.isEdit = false;
   this.model.name = '',
   this.model.nameBn = '',
   this.model.sortOrder = 0
  }

  edit(item:any){
    this.isEdit = true;
    this.showSaveForm = 'saveForm';
    this.relationForm.patchValue({
      id: item.id,
      name: item.name,
      nameBn: item.nameBn,
      sortOrder: item.sortOrder
    })
    
  }

  delete(id:any){
    this._relationService.delete(id).subscribe(data=>{
      this.shared.ShowAlert("Alert", "Data Deleted Successfully", "warning");
      this.getAllRelation();
      this.showSaveForm='saveList';
    })
  }
 
  onChangeForShow(value:any){
    this.currentPage = 1;
    this.itemsPerPage = value;
    this.getAllRelationList();
  }

  onPageChange(pageNumber : number){
    this.currentPage = pageNumber;
    this.getAllRelationList();
  }

  onSearch(){
    this.currentPage = 1;
    this.getAllRelationList();
  }
}
