<h5>Accounting Reports</h5>
<div class="row">
    <div class="col-md-2">
        <div class="list-group">
            <a href="#" class="list-group-item list-group-item-action" onclick="return false" *ngFor="let masterMenuItem of masterMenuItems" [ngClass]="{ 'active': activeItem == masterMenuItem.itemName}" (click)="menuItemClick(masterMenuItem)">
                {{masterMenuItem.displayName}}
            </a>
        </div>
    </div>

    <div class="col-md-8">
        <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item" *ngFor="let tab of tabs">
                <a class="nav-link" data-toggle="tab" role="tab" [href]=" '#' + tab.itemName" [ngClass]="{ 'active': activeItem == tab.itemName}" (click)="activeItem = tab.itemName">
                    {{tab.displayName}}
                    <span class="fa fa-window-close" (click)="onCloseClick(tab)">
                    </span>
                </a>
            </li>
        </ul>

        <div class="tab-content">
            <div class="tab-pane fade show" role="tabpanel" *ngFor="let tab of tabs" [id]="tab.itemName" [ngClass]="{ 'active': activeItem == tab.itemName}">
                <ng-template appComponentLoader>
                </ng-template>
            </div>
        </div>
    </div>

</div>