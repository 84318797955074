<div class="row">
    <form [formGroup]="frmGroup">
    <div class="card col-md-12 row">
    <div class="col-sm-12 col-md-8 row mt-4">
        <div class="col-12 row">
            <div class="form-group co-sm-12 col-md-6 col-lg-6 row">
                <label class="col-4 col-form-label">Name</label>
                <input type="text" class="col-8 form-control" formControlName="name" (keyup)="getUserName(1)" />
            </div>
            <div class="form-group co-sm-12 col-md-6 col-lg-6 row">
                <label class="col-4 col-form-label">Org Name</label>
                <input type="text" class="col-8 form-control"/>
            </div>
        </div>
        <div class="col-12 row">
            <div class="form-group co-sm-12 col-md-6 col-lg-6 row">
                <label class="col-4 col-form-label">Email</label>
                <input type="text" class="col-8 form-control"/>
            </div>
            <div class="form-group col-sm-12 col-md-6 col-lg-6 row">
                <label class="col-4 col-form-label">Website (opt)</label>
                <input type="text" class="col-8 form-control"/>
            </div>
        </div>
        <div class="col-12 row">
            <div class="form-group col-sm-12 col-md-6 col-lg-6 row">
                <label class="col-4 col-form-label">Suggested User Name</label>
                <input type="text" class="col-6 form-control" formControlName="username" value="{{uName}}" (keyup)="getUserName(2)"/>
                <button class="btn-sm col btn-outline-success" *ngIf="valid" style="height: 37px">Valid</button>
                <button class="btn-sm col btn-outline-danger" *ngIf="invalid" style="height: 37px">Invalid</button>
            </div>
            <div class="form-group col-sm-12 col-md-6 col-lg-6  row">
                <label class="col-4 col-form-label">Suggested Password</label>
                <input type="text" class="col-8 form-control" formControlName="password" value="{{pass}}"/>
            </div>
        </div>
        <div class="col-12 row">
            <div class="form-group col-sm-12 col-md-6 col-lg-6 row">
                
                
            </div>
            <div class="form-group col-sm-12 col-md-6 col-lg-6 row">
                
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-4">Test</div>
</div>
</form>
</div>