import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { SharedService } from 'app/services/shared.service';
import { CountryInfoService } from 'app/master-data/basic/basic-service/country-info.service';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { CustomerService } from 'app/customer/customer.service';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { CashPosting } from '../model/cash-posting';
import { CashPostingService } from '../service/cash-posting.service';
import { OtherAccountService } from '../service/other-account.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-npsb',
  templateUrl: './npsb.component.html',
  styleUrls: ['./npsb.component.scss']
})
export class NpsbComponent implements OnInit {

  frmGroup : FormGroup;
  form: FormGroup;
  searchType : string = '';
  formControl = new FormControl();
  suggestions: any[] = [];
  hideShow: string = 'Show';
  accountNoFund1: string = '';
  accountNoFund2: string = '';
  currentBalance: number;
  currentBalance2: number;
  customerInfo_cashFundTransfer1: any = '';
  customerInfo_cashFundTransfer2: any = ''; 

  customerAccountId: number;
  customerAccountId2: number;
  isvalid: boolean = true;
  isBtnValid: boolean = false;
  customerIdPayment: number;
  countryList: Observable<any[]>;
  AccountNoList: Observable<any[]>;
  AccountNoList2: Observable<any[]>;
  isEdit: boolean = false;
  list: CashPosting[] = [];
  model = new CashPosting();
  accountNo: string = '';
  customerInfo_cashPayment: any = '';
  customerInfo_cashReceive: any = '';
  glAccountName:any;
  accountShow : number;
  customersId: number;
  gLGenerateId: number = 0;
  totalCurrentBalance: number;

  constructor(
    private _service : OtherAccountService,
    private _CustomerService: CustomerService,
    private shared: SharedService,
    private _CountryInfoService: CountryInfoService,
    private _cashService: CashPostingService,
    private router: Router,
    private formBuilder: FormBuilder,
  ) {
    {
      this.frmGroup = new FormGroup({
        id: new FormControl(),
        customerCode: new FormControl(),
        transactionType: new FormControl(),
        customerId: new FormControl(),
        voucherNo: new FormControl(),
        postingType: new FormControl(),
        remarks: new FormControl(),
        currencyId: new FormControl(),
        customersId: new FormControl(),
        voucherAmount: new FormControl(),
        gLGenerateId:new FormControl(),
      })
    }
    this.frmGroup = this.formBuilder.group({
      
      voucherAmount : ['', [Validators.required, Validators.pattern('^[0-9,.]*$')]],
      customerCode: ['', Validators.required],
      customerAccountId: [0, Validators.required],
      voucherNo: ['', Validators.required],
      remarks: ['', Validators.required],
      currencyId: [0, Validators.required]
    });

  }

  ngOnInit(): void {
    this.loadCountryList();
     this. GetCustomerAccountNo();
     this. GetCustomerAccountNo2();
    this.formControl.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(value=> this._service.getAutocompleteSuggestions(value))
    ).subscribe(data=>{
      this.suggestions = data;
    });
    this.hideShow = 'Show';
  }

  selectSearchType(type : string){
    this.searchType = type;
  }

  selectedSuggestion: any = null;

  selectSuggestion(item:any){
    this.formControl.setValue(item.bdGenerateCode);
    this.selectedSuggestion = item;
    this.hideShow = 'Hide';
  }

  hideAfterSelectValue(value : string){
    this.hideShow = value;
  }

 

  loadUserDataByAccountNoFundFirst(accountId:number) {
   
    this._CustomerService.GetCustomerInfoByAccountNoById(accountId).subscribe(res => {       
      if (res != null) {
         
        if(res.glAccountName==null){           
          this.currentBalance = res.currentBalance.toLocaleString();
          this.totalCurrentBalance = res.currentBalance.toLocaleString();
        this.customerInfo_cashFundTransfer1 = res.customerNameEng + '; ' + res.mobileNo + '; ' + res.nidNo;
        this.customerIdPayment = res.customersId;
       
        this.customerAccountId = res.id;
        this.isBtnValid = true;
        this.glAccountName='';
        }
        else{
          this.glAccountName=res.glAccountName;
          this.gLGenerateId = res.glAccountId;
          this.customerInfo_cashFundTransfer1 ='';
          this.isBtnValid = true;
      
        }
      }
      else {
        this.refreshAc();
        swal('Warning', 'Invalid Account No', 'warning');
      }
    })
  }

  GetCustomerAccountNo() {
    this._CustomerService.GetCustomerAccountNo().subscribe(res => {
    
      this.AccountNoList = res;
    });
  }


   
  // loadUserDataByAccountNoFundLast(accountId:number) { 
  //   this._CustomerService.GetBankBeneficiaryAccountNoById(accountId).subscribe(res => {
  //      console.log("res2");
  //      console.log(res);
  //     if (res != null) {
       
  //       if(res.glAccountName==null){           
        
  //       this.customerInfo_cashFundTransfer2 = res.accountName + '; ' + res.bankName + '; ' + res.address;
  //       this.customerIdPayment = res.customersId;       
  //       this.customerAccountId2 = res.bankBeneficiaryId;
  //       this.isBtnValid = true;
  //       this.glAccountName='';
  //       }
  //       else{
  //         this.glAccountName=res.glAccountName;
  //         this.gLGenerateId = res.glAccountId;
  //         this.customerInfo_cashFundTransfer2 ='';
  //         this.isBtnValid = true;
        
  //       }
  //     }
  //     else {
  //       this.refreshAc();
  //       swal('Warning', 'Invalid Account No', 'warning');
  //     }
  //   })
  // }

  formatNumber(value: string): string {

    const formattedValue = value.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return formattedValue;
  }


  GetCustomerAccountNo2() {
    this._CustomerService.GetBankBeneficiaryAccountByUser().subscribe(res => {

  
      this.AccountNoList2 = res;
    });
  }

  loadUserDataByAccountNoFundLast(accountId: number) {
    this._CustomerService.GetBankBeneficiaryAccountNoById(accountId).subscribe(res => {

      if (res != null) {

        if (res.glAccountName == null) {

          this.customerInfo_cashFundTransfer2 = res.accountName + '; ' + res.bankName + '; ' + res.address;
          this.customerIdPayment = res.customersId;
          this.customerAccountId2 = res.bankBeneficiaryId;
          this.isBtnValid = true;
          this.glAccountName = '';
        }
        else {
          this.glAccountName = res.glAccountName;
          this.gLGenerateId = res.glAccountId;
          this.customerInfo_cashFundTransfer2 = '';
          this.isBtnValid = true;

        }
      }
      else {
        this.refreshAc();
        swal('Warning', 'Invalid Account No', 'warning');
      }
    })
  }

  AddBenificiary() {
    this.router.navigate(['/score/bank-beneficiar-create']);
  }
 
  loadUserDataByAccountNoCashPayment() {
    this._CustomerService.getCustomerByAccountNo(this.accountNo).subscribe(res => {
      
      if (res != null) {

        if(res.glAccountName==null){
           
        this.currentBalance = res.currentBalance;
        this.customerInfo_cashPayment = res.customerNameEng + '; ' + res.mobileNo + '; ' + res.nidNo;
        this.customerIdPayment = res.customersId;
        this.customerAccountId = res.id;
        this.isBtnValid = true;
        this.glAccountName='';
        }
        else{
          this.glAccountName=res.glAccountName;
          this.customerInfo_cashPayment ='';
          this.isBtnValid = true;
        }
      }
      
      else {
        this.refreshAc();
        swal('Warning', 'Invalid Account No', 'warning');
      }
    })
  }

  refreshAc() {
    this.currentBalance = 0;
    this.currentBalance2 = 0;
    this.customerInfo_cashFundTransfer1 = '';
    this.customerInfo_cashFundTransfer2 = '';
    this.customerIdPayment = 0;
    this.customerAccountId = 0;
    this.isBtnValid = false;
  }

  loadCountryList() {
    this._CountryInfoService.getAllCurrency().subscribe(res => {
       
      this.countryList = res; 
    });
  }
  
  CheckBalance(){
    var balanceAmount = this.currentBalance.toString().replace(/,/g, '');
    var inputAmount = parseInt(this.frmGroup.value.voucherAmount.replace(/,/g, ''), 10);

    if (inputAmount > parseInt(balanceAmount, 10))
    {
      this.isvalid = false;
    }
     else
    {
      this.isvalid = true;
    }
  }
  voucherAmountValue(value: any) {
    var balanceAmount = this.totalCurrentBalance.toString().replace(/,/g, '');
    var inputAmount = parseInt(value.replace(/,/g, ''), 10);

    if(inputAmount>0){
     this.currentBalance = parseInt(balanceAmount) - inputAmount;
    }
    else{
     this.currentBalance = this.totalCurrentBalance;
    }
 }

  onsubmit() { 

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to Transfer this Amount!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, send it!"
    }).then((res) => {
      if (res.isConfirmed) {
     
    if (this.isEdit) {
      this.model.id = this.frmGroup.value.id;
      //alert(this.frmGroup.value.id)
    }
    else {
      this.model.id = 0;
    }

   // this.model.customerCode = this.frmGroup.value.customerCode;
    this.model.transactionType = 3;
    this.model.customerAccountId = this.customerAccountId;
    this.model.customerAccountId2 = this.customerAccountId2;
    this.model.remarks = this.frmGroup.value.remarks;
    this.model.currencyId = this.frmGroup.value.currencyId; 

    this.model.voucherAmount = this.frmGroup.value.voucherAmount.replace(/,/g, '');

   // this.model.gLGenerateId =  this.gLGenerateId;
    if (this.model.voucherAmount > 0 && this.model.currencyId > 0) {
      if (this.model.id == 0) {
    
        this._cashService.SaveOtherBankTransaction(this.model).subscribe((res: number) => {
          if (res > 0) {
            this.ResetForm();

            this.shared.ShowAlert("Success", "Save Successfully", "success");
            this.isEdit = false;
          }
          else {
            this.shared.ShowAlert("Warning", "Failed to Save", "warning");
            this.isEdit = false;
          }

        })
      }
      else {
        this._cashService.SaveOtherBankTransaction(this.model).subscribe(res => {
          this.ResetForm();

          this.shared.ShowAlert("Success", "Save Successfully", "success");
          this.isEdit = false;
        })
      }
    }
  }
  else if (res.isDismissed) {
    this.router.navigate(['/accounting/npsb']);
  }
});

} 

  ResetForm() {
    this.frmGroup.reset();
    this.customerInfo_cashPayment = '';
    this.customerInfo_cashFundTransfer1 = '';
    this.customerInfo_cashFundTransfer2 = '';
    this.currentBalance = 0;
  }

  
}
