import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
const apiUrl = environment.baseUrl + '/Dashboard/';
@Injectable({
  providedIn: 'root'
})
export class LeadInfoService {

  constructor(private http: HttpClient) { }

  getLeadList(): Observable<any> {
    return this.http.get(apiUrl + 'GetLeadGenerationListForCustomerDash');
  }
}
