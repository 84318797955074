<section>
    <!-- <div class="row d-flex flex-row align-items-center">
        <div class="col-6">
            <h4 class="card-title">Transaction Log</h4>
        </div>
    </div> -->



<diV class="card">
    <div class="row  ">
           
        <div class="col-6">
            <h4 class="mt-3 ml-3 font-weight-bold text-primary" >Transaction Log</h4>
        </div>
        <div class="col-6">
            <button class="mt-3 mr-3 btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
        
                <i class="icon-arrow-left" style="font-size: 17px;"> </i>
            </button>
        </div>
    </div>

<hr>
    <div class="row" style="margin-left: 2%;" >

        <div class="col-4">
            <label>From Date</label>
            <div class="col-12" style="margin-left: -16px;">

                <div class="input-group">
                    <input type="date" class="form-control" [(ngModel)]="startDate" (ngModelChange)="onDateChange()" placeholder="Select From Date">

                </div>
            </div>
        </div>

        <div class="col-4">
            <label>To Date</label>
            <div class="col-12" style="margin-left: -16px;">

                <div class="input-group">
                    <input type="date" class="form-control" [(ngModel)]="EndDate" (ngModelChange)="onDateChange()" placeholder="Select To Date">
                </div>

            </div>
        </div>

        <div class="col-4">
            <label></label>

            <div class="col-12 mt-1">
                <button class="btn btn-primary" style="background-color: #1fca74;color: white;" (click)="GetClCalculationListByDate()">Search</button>
            </div>
        </div>

    </div>
    <hr>
    <div class="card-body">
        <div class="table-container">
            <table id="example" class="table table-bordered">
                <thead class="sticky-header">
                    <tr>
                        <th style="font-size: smaller;">Action</th>
                        <th style="font-size: smaller;">Customer Name</th>
                        <th style="font-size: smaller;">TRAN ID</th>
                        <th style="font-size: smaller;">TRAN DATE</th>
                        <th style="font-size: smaller;">STATUS</th>
                        <th style="font-size: smaller;">AMOUNT</th>
                        <th style="font-size: smaller;">CARD TYPE</th>
                        <th style="font-size: smaller;">CARD NO</th>
                        <th style="font-size: smaller;">Recharge Id</th>
                        <th style="font-size: smaller;">Issuer Id</th>
                        <th style="font-size: smaller;">Issuer Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let log of transactionLogs">
                        <td>  <button class="btn btn-sm btn-primary" style="background-color: #1fca74;color: white;" (click)="TransactionDetails(tranDetails)"  (click)="getTransactionById(log.id)">Details</button></td>
                        <td>{{ log.customerAccount?.customers?.customerNameEng }}</td>
                        <td>{{ log?.tran_id }}</td>
                        <td>{{ log?.tran_date | date:'dd-MMM-yyyy' }}</td>
                        <td>{{ log?.status }}</td>
                        <td style="text-align: right;">{{ log.amount }}</td>
                        <td>{{ log?.card_type }}</td>
                        <td>{{ log?.customerMobileNo}}</td>
                        <!-- <td>{{ log.value_a }}</td> -->
                        <td>{{ log?.rechargeId}}</td>
                        <td>{{ log?.issuerTxnId}}</td>
                        <td>{{ log?.issuerReqDateTime | date:'dd-MMM-yyyy' }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</diV>
    
</section>


<ng-template #tranDetails let-c="close" let-d="dismiss">
    <div class="">

        <div class="" style=" width: 100%;">
            <div class="modal-header">
                <h4 class="modal-title" style="text-align: center;">Payment Details</h4>
                <button type="button" class="close" style="size: 100%;" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="col-12 row">
                <div class="col-2"></div>
                <div class="modal-body col-9">
                    
                    <p><span style="font-weight: 800;color: black;">Customer Name:</span>   {{ transactionDetails?.customerAccount?.customers?.customerNameEng }}</p>
                    <p><span style="font-weight: 800;color: black;">Customer MFS No:</span>   {{ transactionDetails?.customerMobileNo }}</p>
                    <p><span style="font-weight: 800;color: black;">Amount:</span>   {{ transactionDetails?.amount }}</p>
                    <p><span style="font-weight: 800;color: black;">Trxn-Id:</span>  {{ transactionDetails?.tran_id }}</p>
                    <p><span style="font-weight: 800;color: black;">Trxn-Date :</span>  {{ transactionDetails?.tran_date | date }}</p>
                    <p><span style="font-weight: 800;color: black;">MFS Type:</span>  {{ transactionDetails?.card_type}}</p>
                    <p><span style="font-weight: 800;color: black;">Recharge ID:</span>  {{ transactionDetails?.rechargeId}}</p>
                    
                    <p><span style="font-weight: 800;color: black;">Issuer Trxn Id :</span>  {{ transactionDetails?.issuerTxnId}}</p>
                    <p><span style="font-weight: 800;color: black;">Issuer Requ Id :</span>  {{ transactionDetails?.issuerReqId}}</p>
                    <p><span style="font-weight: 800;color: black;">Issuer Date:</span>
                    
                        {{ (transactionDetails?.issuerTxnDateTime?.slice(0, 4) + '-' + transactionDetails?.requestDateTime?.slice(4, 6) + '-'
                        + transactionDetails?.requestDateTime?.slice(6, 8)) | date:'dd-MM-yyyy' }}
                    
                    
                    </p>
                    <p><span style="font-weight: 800;color: black;">Request Date:</span> {{ (transactionDetails?.requestDateTime?.slice(0, 4) + '-' + transactionDetails?.requestDateTime?.slice(4, 6) + '-' +
                    transactionDetails?.requestDateTime?.slice(6, 8)) | date:'dd-MM-yyyy' }}
                    </p>
                    <p><span style="font-weight: 800;color: black;">Reference No:</span>  {{ transactionDetails?.referenceNo }}</p>
                    <p><span style="font-weight: 800;color: black;">Merchant Id:</span>  {{ transactionDetails?.merchantId }}</p>
                    <p><span style="font-weight: 800;color: black;"> Merchant Mobile No:</span>  {{ transactionDetails?.merchantMobileNo }}</p>
                    <p><span style="font-weight: 800;color: black;">Merchant Balance: </span>  {{ transactionDetails?.merchantBalance }}</p>
                    <p><span style="font-weight: 800;color: black;">Merchant Aggregator Id:</span>   {{ transactionDetails?.merchantAggregatorId }}</p>
                   
                  </div>
    
                  <div class="col-1"></div>
            </div>
           

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
            </div>
        </div>
    </div>
</ng-template>
