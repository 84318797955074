import { Component, OnInit } from '@angular/core';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { Location } from "@angular/common";
@Component({
  selector: "app-balannce-sheet-report",
  templateUrl: "./balannce-sheet-report.component.html",
  styleUrls: ["./balannce-sheet-report.component.scss"],
})
export class BalannceSheetReportComponent implements OnInit {
  fromDate: Date = new Date();
  toDate: Date = new Date();
  asDate: Date = new Date();
  reportBy: number;
  reportByDate: 1;
  constructor(private pdfService: PdfService, private location: Location) {}

  ngOnInit(): void {}

  OpenPDF() {
    this.pdfService
      .BalanceSheetReport(this.asDate, this.asDate, 1, 2, this.reportBy)
      .subscribe((res) => {
        let blob: Blob = res.body as Blob;
        let url = window.URL.createObjectURL(blob);

        window.open(url);
      });
    // if(this.reportByDate ==1)
    // {

    //   this.pdfService.BalanceSheetReport(this.asDate,this.asDate,1,2,this.reportBy).subscribe(res => {
    //     let blob: Blob = res.body as Blob;
    //     let url = window.URL.createObjectURL(blob);

    //     window.open(url);
    //   })
    // }
    // else
    // {
    //   this.pdfService.BalanceSheetReport(this.fromDate,this.toDate,1,2,this.reportBy).subscribe(res => {
    //     let blob: Blob = res.body as Blob;
    //     let url = window.URL.createObjectURL(blob);

    //     window.open(url);
    //   })
    // }
  }

  goBack() {
    this.location.back();
  }
}
