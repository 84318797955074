<div class="card-header">
    <h5 class="card-title" style="color: black;font-family: Arial;font-size: 20px !important;">Account Details</h5>

</div>


<div class=" row mt-4">
    <div class="col-10"></div>
    <div class="col-2"><button class="btn btn-success" (click)="PrintKyc()" title="Click here for download pdf">View PDF
        </button></div>

</div>


<div *ngIf="accountDatails$ | async as accountDatails">
    <div class="card" style="border: 1px solid #888888!important;">
        <h4 class="mt-2 ml-2">Account Information - ({{accountDatails?.kycData[0]?.productName}})</h4>
        <hr>
        <div class="row col-12" style="padding: 0px 30px 30px 30px;">
            <div class="col-md-6">
                <div class="row">
                    <label for="NameEnglish" class="col-sm-4 col-form-label"
                        style="font-weight: 700;font-size:16px">Account
                        No</label>
                    <div class="col-sm-8">
                        <label for="" class="">: {{accountDatails?.kycData[0]?.accounT_NO}}</label>
                    </div>
                </div>

                <div class="row">
                    <label for="NameBangla" class="col-sm-4 col-form-label"
                        style="font-weight: 700;font-size:16px">Account
                        Title</label>
                    <div class="col-sm-8">
                        <label for="" class="">: {{accountDatails?.kycData[0]?.accounT_TITLE}}</label>
                    </div>
                </div>

                <div class="row">
                    <label for="" class="col-sm-4 col-form-label" style="font-weight: 700;font-size:16px">Father Name
                    </label>
                    <div class="col-sm-8">
                        <label for="" class="">:{{accountDatails?.kycData[0]?.fatherName}}</label>
                    </div>
                </div>

                <div class="row">
                    <label for="" class="col-sm-4 col-form-label" style="font-weight: 700;font-size:16px">Mother Name
                    </label>
                    <div class="col-sm-8">
                        <label for="" class="">: {{accountDatails?.kycData[0]?.motherName}}</label>
                    </div>
                </div>



            </div>
            <div class="col-md-6">
                <div class="row">
                    <label for="NameEnglish" class="col-sm-4 col-form-label"
                        style="font-weight: 700;font-size:16px">Customer Name</label>
                    <div class="col-sm-8">
                        <label for="" class="">: {{accountDatails?.kycData[0]?.customeR_NAME_ENG}}</label>
                    </div>
                </div>

                <div class="row">
                    <label for="NameBangla" class="col-sm-4 col-form-label"
                        style="font-weight: 700;font-size:16px">Opening
                        Balane</label>
                    <div class="col-sm-8">
                        <label for="" class="">: {{accountDatails?.kycData[0]?.openinG_BALANCE | number}}
                            {{accountDatails?.kycData[0]?.currency}}</label>
                    </div>
                </div>

                <div class="row">
                    <label for="dateOfBirth" class="col-sm-4 col-form-label"
                        style="font-weight: 700;font-size:16px">Date Of
                        Birth</label>
                    <div class="col-sm-8">
                        <label for="" class="">: {{accountDatails?.kycData[0]?.dateOfBirth | date:
                            'dd/MM/yyyy'}}</label>
                    </div>
                </div>



            </div>
        </div>
    </div>


    <div class="card row mt-3" style="border: 1px solid #888888!important;"
        *ngIf="accountDatails.kYCFormNominees && accountDatails.kYCFormNominees.length > 0">
        <div class="col-12">
            <h4 class="mt-2">Nominee Information</h4>
            <hr>
            <ul>
                <li *ngFor="let nominee of accountDatails.kYCFormNominees">


                    <div class="row col-12">
                        <div class="col-6">
                            <div class="row">
                                <label class="col-form-label col-4">Name :</label>
                                <input type="text" class="form-control col-8" value="{{nominee?.name}}" readonly>

                            </div>

                            <br>
                            <div class="row">
                                <label class="col-form-label col-4">NID :</label>
                                <input type="text" class="form-control col-8" value="{{nominee.nid}}" readonly>
                            </div>

                            <br>

                            <div class="row">
                                <label class="col-form-label col-4">Nominee's Share:</label>
                                <input type="text" class="form-control col-8" value="{{nominee.sharinG_RATE}}" readonly>
                            </div>
                        </div>
                        <div class="col-6">

                            <div class="row">
                                <label class="col-form-label col-4">Present Address :</label>
                                <input type="text" class="form-control col-8" value="{{nominee.presenT_ADDRESS}}"
                                    readonly>
                            </div>

                            <br>
                            <div class="row">
                                <label class="col-form-label col-4">Date of Birth :</label>
                                <input type="text" class="form-control col-8" value="{{nominee.datE_OF_BIRTH}}"
                                    readonly>
                            </div>
                        </div>
                    </div>







                </li>
            </ul>
        </div>
    </div>
</div>