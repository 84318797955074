export class ProductMappingGL{
    chargeId:number;
    accountCode:string;
    accountName:string;
    chargeCode:string;
    chargeName:string;
    ledgerRelationId:number;
    constructor(chargeId:number=0,accountCode:string='',accountName:string='',chargeCode:string='',chargeName:string='',ledgerRelationId:number=0){
        this.chargeId=chargeId;
        this.accountCode=accountCode;
        this.accountName=accountName;
        this.chargeCode=chargeCode;
        this.chargeName=chargeName;
        this.ledgerRelationId=ledgerRelationId;
    }
}