import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DailyCloseComponent } from './daily-close/daily-close.component';
import { MonthlyCloseComponent } from './monthly-close/monthly-close.component';
import { QuarterlyCloseComponent } from './quarterly-close/quarterly-close.component';
import { HalfYearlyCloseComponent } from './half-yearly-close/half-yearly-close.component';
import { ProcessAccountBalanceComponent } from './process-account-balance/process-account-balance.component';
import { BatchProcessComponent } from './batch-process/batch-process.component';
import { BatchProcessMonitorComponent } from './batch-process-monitor/batch-process-monitor.component';
import { PaymentReceiveListComponent } from './payment-receive-list/payment-receive-list.component';
import { CobBatchSettingComponent } from 'app/accounting-reports/cob-batch-setting/cob-batch-setting.component';
import { SearchCobFileComponent } from 'app/accounting-reports/search-cob-file/search-cob-file.component';
import { DailyInterestComponent } from './daily-interest/daily-interest.component';
import { MonthlyProvisionComponent } from './monthly-provision/monthly-provision.component';

const routes: Routes = [

  {
    path: '',
    children: [
      {
        path: 'daily-close',
        component: DailyCloseComponent,
        data: {
          title: 'daily-close'
        }
      },
      {
        path: 'batch-process',
        component: BatchProcessComponent,
        data: {
          title: 'batch-process'
        }
      },
      {
        path: 'batch-process-monitor',
        component: BatchProcessMonitorComponent,
        data: {
          title: 'batch-process-monitor'
        }
      },
      {
        path: 'process-balance',
        component: ProcessAccountBalanceComponent,
        data: {
          title: 'process-balance'
        }
      },
      {
        path: 'monthly-close',
        component: MonthlyCloseComponent,
        data: {
          title: 'monthly-close'
        }
      },

      {
        path: 'quarterly-close',
        component: QuarterlyCloseComponent,
        data: {
          title: 'quarterly-close'
        }
      },

      {
        path: 'half-yearly-close',
        component: HalfYearlyCloseComponent,
        data: {
          title: 'half-yearly-close'
        }
      },
      {
        path: 'payment-receive-list',
        component: PaymentReceiveListComponent,
        data: {
          title: 'payment-receive-list'
        }
      },
      {
        path: 'cob-batch-setting',
        component: CobBatchSettingComponent,
        data: {
          title: 'cob-batch-setting'
        }
      },
      {
        path: 'search-cob-file',
        component: SearchCobFileComponent,
        data: {
          title: 'search-cob-file'
        }
      },
      {
        path: 'daily-interest',
        component: DailyInterestComponent,
        data: {
          title: 'daily-interest'
        }
      },
      {
        path: 'monthly-provision',
        component: MonthlyProvisionComponent,
        data: {
          title: 'monthly-provision'
        }
      },
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DaycloseProcessRoutingModule { }
