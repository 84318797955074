import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { OccupationService } from 'app/master-data/basic/basic-service/occupation.service';
import { Occupation } from 'app/master-data/basic/basic-model/occupation';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SharedService } from 'app/services/shared.service';

@Component({
  selector: 'app-occupation',
  templateUrl: './occupation.component.html',
  styleUrls: ['./occupation.component.scss']
})
export class OccupationComponent implements OnInit {
  isEdit: boolean = false;
  list: Occupation[] = [];
  frmGroup: FormGroup;
  model = new Occupation();
  currentPage = 1;
  itemsPerPage = 5;
  totalItems = 0;
  currentItems = 0;
  searchControl = "";
  showSaveForm: string = '';

  constructor
    (
      private _service: OccupationService,
      private changeDetectorRef: ChangeDetectorRef,
      private router: Router,
      private shared: SharedService,
    ) {
    this.getOccupationist();
    this.frmGroup = new FormGroup({
      id: new FormControl(),
      OccupationName: new FormControl(),
      ShortName: new FormControl(),
    });
  }

  ngOnInit(): void {
    this.showSaveForm = 'saveList';
  }

  //#region For pagination
  onChangeForShow(value: any) {
    this.currentPage = 1;
    this.itemsPerPage = value;
    this.getOccupationist();
  }

  onPageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.getOccupationist();
  }

  onSearch() {
    this.currentPage = 1; // Reset the current page to 1 when a new search is performed
    this.getOccupationist();
  }

  onClickShowSaveForm(value: string) {
    this.showSaveForm = value;
    this.refresh();
  }

  getOccupationist() {
    this._service.getListWithSerchAndPagination(this.currentPage, this.itemsPerPage, this.searchControl).subscribe((res: any) => {
      this.list = res.data;
      this.totalItems = res.totalItems;
      this.currentItems = res.currentItems;
    })
    this.reset();
  }

  //#endregion

  onsubmit() {
    if (this.isEdit) {
      this.model.id = this.frmGroup.value.id;
    }

    this.model.occupationName = this.frmGroup.value.OccupationName;
    this.model.shortName = this.frmGroup.value.ShortName;

    if (this.model.id > 0) {
      this._service.save(this.model).subscribe(res => {
        this.shared.ShowAlert("Success", "Updated Successfully", "success");
        this.isEdit = false;
        this.showSaveForm = 'saveList';
        this.getOccupationist();

      })
    } else {
      this._service.save(this.model).subscribe(res => {
        this.shared.ShowAlert("Success", "Save Successfully", "success");
        this.isEdit = false;
        this.showSaveForm = 'saveList';
        this.getOccupationist();

      })
    }
  }
  refresh() {
    this.isEdit = false;
    this.frmGroup.patchValue({
      id: null,
      OccupationName: null,
      ShortName: null,

    })
  }
  reset() {
    this.model.occupationName = ''
    this.model.shortName = '';
  }

  delete(Id: any) {
    this._service.delete(Id).subscribe(res => {
      this.shared.ShowAlert("Success", "Data deleted successfully", "success");
      this.getOccupationist();
    })
  }

  edit(item: any) {
    this.isEdit = true;
    this.showSaveForm = 'saveForm';
    this.frmGroup.patchValue({
      id: item.id,
      OccupationName: item.occupationName,
      ShortName: item.shortName
    })
  }
}
