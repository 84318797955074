<div class="card" style="width: 100%;">
    <div class="card-body">
      <div class="container">
        <div style="display: flex; justify-content: space-between; align-items: center;">
          <h3 class="card-title" style="color: black; text-align: center;">{{'emp_reg.User Registration' | translate}}</h3>
          <div>
           
            <!-- <button class="btn btn-primary mr-2" (click)="showRegistrationForm()">{{ 'global.CREATE' }}</button>
          
            <button class="btn btn-secondary" (click)="showUserList()">{{ 'global.LIST' }}</button> -->
            <!-- <button class="btn" style="background-color: #975AFF;"> <a routerLink="/employeeregister/employee-list" style="color: white;font-weight: 700;">{{ 'global.LIST' }}</a></button> -->
            <button class="btn" style="background-color: #8888;"> <a routerLink="/user" style="color: white;font-weight: 700;">USER LIST</a></button>
           
          
          </div>
        </div>
  
        <hr style="height: 10%;">
  
        <div class="card">
  
          <div class="card-body">
            <form #registerForm="ngForm">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group row">
                    <label for="" class="col-sm-4">{{ 'emp_reg.FULL NAME' | translate }}</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" name="Name" placeholder="{{ 'emp_reg.FULL NAME' | translate }}"  [(ngModel)]="model.Name">
                    </div>
                  </div>
                </div>
  
                <div class="col-md-6">
                  <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">{{ 'emp_reg.MOBILE' | translate }}</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" name="Mobile" placeholder="{{ 'emp_reg.MOBILE' | translate }}"  [(ngModel)]="model.Mobile">
                    </div>
                  </div>
                </div>
              </div>


              <div class="row">
                
  
                <div class="col-md-6">
                  <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">{{ 'emp_reg.NID' | translate }}</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" name="NID" placeholder="{{ 'emp_reg.NID' | translate }}"  [(ngModel)]="model.NID">
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group row">
                      <label for="" class="col-sm-4 col-form-label">{{ 'emp_reg.GENDER' | translate }}</label>
                      <div class="col-sm-8">
  
                        <select class="form-control" name="genderId" placeholder="" [(ngModel)]="model.genderId">
                          <option value="">Select</option>
                          <option *ngFor="let item of genderList  | async" [value]="item.id">{{item.name}}</option>
                        </select>
  
                      </div>
                    </div>
                  </div>
              </div>
              <div class="row">
                
  
                <div class="col-md-6">
                  <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">{{ 'emp_reg.EMAIL ADDRESS' | translate}}</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" name="EmailAddress" placeholder="{{ 'emp_reg.EMAIL ADDRESS' | translate}}"  [(ngModel)]="model.EmailAddress">
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group row">
                    <label for="" class="col-sm-2 col-form-label">CHOOSE ROLE</label>
                    <div class="col-sm-10">
                      <div *ngFor="let item of roleList" class="pb-1 custom-switch custom-switch-info custom-control-inline mb-1 mb-xl-0">
                        <input (change)="ChangeRole($event.target)" value="{{item.name}}" type="checkbox" class="custom-control-input" id="color-switch-{{item.id}}">
                        <label class="custom-control-label mr-1" for="color-switch-{{item.id}}">
                          <span>{{item.name}}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
              <button data-toggle="tooltip" title="Save" type="button" (click)="onsubmit(registerForm)"
                class="btn btn-success" style="float:right; margin-top:5px;">{{ 'global.SAVE' | translate }} </button>
  
            </form>
          </div>
        </div>
      



      </div>
    </div>
  </div>

  