import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardCustomService } from '../dashboard-custom.service';
import { SharedService } from 'app/services/shared.service';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { PaymentService } from 'app/payment/payment.service';
import { PaymentRequestVm } from 'app/payment/models/payment-request-vm';
//import swal from 'sweetalert';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-app-approve-disburse',
  templateUrl: './app-approve-disburse.component.html',
  styleUrls: ['./app-approve-disburse.component.scss']
})
export class AppApproveDisburseComponent implements OnInit {
  LeadList: any[] = [];
  status: number;
  dueAmount: number;
  paymentName: string = '';
  constructor(
    private router: Router,
    private _serviceDashboard: DashboardCustomService,
    private changeDetectorRef: ChangeDetectorRef,
    private shared: SharedService,
    private pdfService: PdfService,
    private paymentService: PaymentService
  ) { }

  ngOnInit(): void {
    this.getLeadList()
  }


  onResized(event: any) {
    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }
  fireRefreshEventOnWindow() {
    throw new Error('Method not implemented.');
  }

  getLeadList() {
    this.status = 4
    this._serviceDashboard.getApprovedDisbursLoan(this.status).subscribe((res: any[]) => {
      this.LeadList = res.sort((a, b) => {
        return b.id - a.id;
      })
      this.changeDetectorRef.detectChanges();
    })
  }

  // loanDisburse(id){
  //   swal('Confirm First', 'Do you want to disburse this loan', 'warning').then(x=>{
  //     this._serviceDashboard.disbursLoan(id).subscribe((res: any[]) =>{
  //       this.LeadList =res;
  //       if(res != null)
  //       {
  //         this.shared.ShowAlert('Thank you...', 'Saved succesfully!', 'success');
  //         this.getLeadList();
  //         this.changeDetectorRef.detectChanges();
  //       }
  //       else
  //       {
  //         this.shared.ShowAlert('Error', 'Failed to save.', 'error');
  //       }

  //     })
  //   });
  // }

  OpenPDF(leadId) {
    this.pdfService.OpenSchedulePdf(leadId).subscribe(res => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    })
  }



  checkPayment(leadId, customerAccountNo, loanAmount) {
    this.dueAmount = loanAmount;
    this.paymentName = customerAccountNo;
    if (this.dueAmount > 0) {
      const paymentRequest: PaymentRequestVm = {
        name: [this.paymentName],
        price: [this.dueAmount],
        url: ['url1'],
        qty: [1],
        pname: 'Loan Disbursement',
        purl: 'Loan Disbursement',
        pprice: this.dueAmount,
        customer_account: this.paymentName,
        card_no:'',
        valueType:'approve',
      };

      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to disburse this loan!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, disburse it!"
      }).then((res) => {
        if (res.isConfirmed) {
          this._serviceDashboard.FinalDisbursLoan(leadId).subscribe((res: any[]) => {

            if (res != null) {

              this.paymentService.checkoutPayment(paymentRequest).subscribe(
                (response) => {


                  if (response.gatewayPageURL) {
                    // Use the navigateByUrl method to navigate to the specified URL
                    window.location.href = response.gatewayPageURL;
                  }
                  else {
                    console.error('No gatewayPageURL found in the response');
                  }
                },
                (error) => {
                  console.error('Payment request failed', error);
                }
              );

            }
            else {
              this.shared.ShowAlert('Error', 'Failed to save.', 'error');
            }

          })


        }
        else if (res.isDismissed) {
          this.router.navigate(['/dashboard/app-approve-disburse']);
        }
      });

      // this._serviceDashboard.FinalDisbursLoan(leadId).subscribe((res: any[]) =>{

      //   if(res != null)
      //   {

      // this.paymentService.checkoutPayment(paymentRequest).subscribe(
      //   (response) => {


      //     if (response.gatewayPageURL) {
      //       // Use the navigateByUrl method to navigate to the specified URL
      //       window.location.href = response.gatewayPageURL;
      //     } 
      //     else {
      //       console.error('No gatewayPageURL found in the response');
      //     }
      //   },
      //   (error) => {
      //     console.error('Payment request failed', error);
      //   }
      // );

      //   }
      //   else
      //   {
      //     this.shared.ShowAlert('Error', 'Failed to save.', 'error');
      //   }

      // })

    }
  }


}
