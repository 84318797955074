import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mis-report',
  templateUrl: './mis-report.component.html',
  styleUrls: ['./mis-report.component.scss']
})
export class MisReportComponent implements OnInit {

  constructor(
    private router:Router,
    private changeDetectorRef:ChangeDetectorRef,
    private location:Location

  ) { }

  ngOnInit(): void {
  }

  goBack(){
    this.location.back();
  }

  changeReport(reportType:number){
    console.log(reportType)
    if(reportType==1){
      this.router.navigate(["/accounting-reports/loan-disbursement"]);
    }
    else if(reportType==2){
      this.router.navigate(["/accounting-reports/loan-outstanding"]);
    }
    else if(reportType==3){
      this.router.navigate(["/accounting-reports/loan-overdue"]);
    }
    else if(reportType==4){
      this.router.navigate(["/accounting-reports/loan-collection"]);
    }
    else if(reportType==5){
      this.router.navigate(["/accounting-reports/loan-settlement-report"])
    }
    else if(reportType==6){
      this.router.navigate(["/accounting-reports/loan-interest-income"])
    }
    else if(reportType==7){
      this.router.navigate(["/accounting-reports/processingfee-report"]);
    }
  }

}
