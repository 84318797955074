export class Navbars {
  id: number;
  name: string;
  nameBn: string;
  title: string;
  route: string;
  icon: string;
  bootClass: string;
  badge: string;
  badgeClass: string;
  isActive:boolean;
  parentId: number;
  isParent: boolean;
  displayOrder: number;
  remarks: string;
  userTypeId: number;
}
