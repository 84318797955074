import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PasswordPolicy } from 'app/models/Security/password-policy';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  private apiUrl: string = environment.baseUrl;

  constructor(private http: HttpClient) { }

  savePasswordPolicy(passwordPolicy: PasswordPolicy): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/api/SavePasswordPolicy', passwordPolicy);
  }

  getPasswordPolicy(): Observable<PasswordPolicy> {
    return this.http.get<PasswordPolicy>(this.apiUrl + '/api/GetPasswordPolicy');
  }


  validatePassword(password: string): Observable<string[]> {
    return this.http.get<string[]>(this.apiUrl + '/api/ValidatePassword?password=' + password);
  }
  
}
