import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
//import { Applicationuser } from 'app/employee/employee-model/applicationuser';
import { IdleTimeServiceService } from 'app/services/idle-time-service.service';
//import { LanguageServiceService } from 'app/services/language-service.service';
import { SharedService } from 'app/services/shared.service';
import { TokenManagerService } from 'app/services/token-manager.service';
import { UserService } from 'app/services/user.service';
import { Observable } from 'rxjs';
import { ApplicationUser } from '../user-management/user-model/application-user';
import { LoginService } from 'app/pages/content-pages/login/login.service';
import { RoleService } from '../user-management/user-service/role.service';
import { RoleUserVm } from '../role-user-vm';
import { SecurityService } from 'app/services/security.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  users: any[] = [];
  userlist: Observable<any[]>;
  formGroupReset: FormGroup;
  modalRef: NgbModalRef | undefined;
  model = new ApplicationUser();
  //model = {};
  errorMessage: string = '';
  closeResult: string;
  isExistingPasswordValid: boolean = true;
  isActiveUser: any;
  userId: any;
  proxyFormList : FormGroup;
  proxyModel : any = {};
  roleList: any;
  assignedRoles: RoleUserVm[] = [];
  accessibleDays: any;
  
  passwordValidate: string[];

  showSaveForm:string='';

  currentPage = 1;
  itemsPerPage = 5;
  totalItems = 0;
  currentItems = 0;
  searchControl = "";

  roleSelected: string = "";

  constructor(
    private userService: UserService,
    private tokenManager: TokenManagerService,
    private idleTimeService: IdleTimeServiceService,
    // private language:LanguageServiceService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private shared: SharedService,
    private changeDetectorRef: ChangeDetectorRef,
    private loginService: LoginService,  
    private _roleService:RoleService
  ) {
   
  }

  ngOnInit(): void {
    this.proxyForm();
    this.populateForm();
    //this.getCustomerList();
    //this.getCustomerList();
    this.getRoleList();
  }

  // getCustomerList() {
  //   this.userService.GetCustomerList().subscribe(res => {
     
  //     this.userlist = res;
       
     
  //   });
  // }

  onRoleChange(event: any){
    const selectedRole = event.target.value;
    
    this.roleSelected = selectedRole;

    this.getUserList(this.roleSelected);
  }

  getCustomerList(){
    this.userService.GetUserListWithSearchAndPagination(this.currentPage, this.itemsPerPage, this.searchControl).subscribe((res:any)=>{
      this.users = res.data;
      this.totalItems = res.totalItems;
      this.currentItems = res.currentItems;
      this.changeDetectorRef.detectChanges();
    })
  }

  getUserList(roleId:string){
    this.userService.GetUserListRoleWiseWithPagination(this.currentPage, this.itemsPerPage, this.searchControl, roleId).subscribe((res:any)=>{
      this.users = res.data;
      this.totalItems = res.totalItems;
      this.currentItems = res.currentItems;
      this.changeDetectorRef.detectChanges();
    })
  }

  populateForm() {
    this.formGroupReset = this.formBuilder.group({
      userName: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });
  }

  proxyForm(){
    this.proxyFormList = this.formBuilder.group({
      userName: ['', Validators.required],
      securityCode: ['', Validators.required]
    })
  }

  openReset(resetcontent, item: any) {
    this.formGroupReset.patchValue({
      userName: item.userName,
      password: item.password,
      confirmPassword: item.confirmPassword
    });
    this.modalRef = this.modalService.open(resetcontent, {
      backdrop: 'static',
      keyboard: false,
    });
  }


  OpenRoleModal(assignRoleModal, item: any) {
    this.userService.GetRoleListByUserName(item.userName).subscribe((res:any)=>{
      this.assignedRoles = res;
      console.log(res);
      this.changeDetectorRef.detectChanges();
    })

    this.formGroupReset.patchValue({
      userName: item.userName,
      password: item.password,
      confirmPassword: item.confirmPassword
    });
    this.modalRef = this.modalService.open(assignRoleModal, {
      backdrop: 'static',
      keyboard: false,
    });
  }

  onCheckboxChange(item:any){
    item.status = item.status == true ? false : true;
    // console.log(item.rolename);
    // console.log(item.status);
    // console.log(this.assignedRoles);



  }
  changeRoleOfUser(){
 
    this.userService.UpdateUserRoles(this.assignedRoles).subscribe(
      (res) => {
        this.shared.ShowAlert('Thank You', 'Role Assigned successfully!', 'success');
        this.closeModal();
        this.router.navigate(['/user']);
      },
      (err) => {
        console.log(err);
        this.shared.ShowAlert('Alert', 'Something Went Wrong!', 'warning');
      }
    );
  }

  ValidatePassword(event) {
    console.log(event);
    this.userService.validatePassword(event).subscribe(
      (response: string[]) => {
        this.passwordValidate = response;
        console.log(this.passwordValidate);
      },
      (error: any) => {
        console.error('Error occurred:', error);
      }
    );
  }
  // proxyUser(){
    
  //    this.proxyModel.userName = this.proxyFormList.value.userName,
  //     this.proxyModel.securityCode = this.proxyFormList.value.securityCode
     
  //   this.userService.postProxyUser(this.proxyModel.userName, this.proxyModel.securityCode).subscribe(res =>{
  //    // console.log("res")
  //    // console.log(res)

  //     sessionStorage.removeItem('token');
  //     localStorage.removeItem('token');
  //   if (res['status'] === 'ok')
  //   {
  //     const data = res['user'];

  //     localStorage.setItem('token', res['jwt']);
  //     sessionStorage.setItem('token', res['jwt']);
    
  //     if (data.userTypeId === 1) {
  //       this.router.navigate(['/dashboard/customer-dashboard']);

  //     }
  //     else {
  //       this.router.navigate(['/dashboard/admin-dashboard']);
  //     }

      
  //     this.modalRef.close();
  //     this.changeDetectorRef.detectChanges();
  //   }
  //   else{

  //   }
      
  //   })
  // }


  proxyUser() {

    this.proxyModel.userName = this.proxyFormList.value.userName,
    this.proxyModel.securityCode = this.proxyFormList.value.securityCode

    this.userService.postProxyUser(this.proxyModel.userName, this.proxyModel.securityCode).subscribe((res) => {
      // Check response and navigate to the appropriate dashboard
      // if (res['status'] === 'ok') {
      //   const data = res['user'];
      //   if (data.userTypeId === 1) {
      //     this.router.navigate(['/dashboard/customer-dashboard']);
      //   } else {
      //     this.router.navigate(['/dashboard/admin-dashboard']);
      //   }

      //   this.modalRef.close();
      //   this.changeDetectorRef.detectChanges();
      // }

      if (res['status'] === 'ok') {
        // Remove and set the token in the service
        this.userService.postProxyUser(this.proxyModel.userName, this.proxyModel.securityCode).subscribe(() => {
          // After the token is set, navigate to the appropriate dashboard
          const data = res['user'];
          if (data.userTypeId === 1) {
            this.router.navigate(['/dashboard/customer-dashboard']);
          }
           else {
            this.router.navigate(['/dashboard/admin-dashboard']);
          }
        });

        this.modalRef.close();
        this.changeDetectorRef.detectChanges();
      }


    });
  }


  






  open(userDetails, item: any){
    this.proxyFormList.patchValue({
      userName: item.userName,
    });
    this.modalRef = this.modalService.open(userDetails, {
      backdrop: 'static',
      keyboard: false,
    });
  }


  ResetSubmit() {
    if (this.formGroupReset.invalid) {
      this.shared.ShowAlert('Alert', 'Please fill in all required fields.', 'warning');
      return;
    }

    const password = this.formGroupReset.value.password;
    const confirmPassword = this.formGroupReset.value.confirmPassword;
    if (password !== confirmPassword) {
      this.errorMessage = "Password didn't match.";
      return;
    }

    const model = {
      userName: this.formGroupReset.value.userName,
      password: password,
      confirmPassword: confirmPassword,

    };

    this.userService.ResetPassword(model).subscribe(
      (res) => {
        console.log(res);
        this.shared.ShowAlert('Thank You', 'Password Updated successfully!', 'success');
        this.closeModal();
        this.router.navigate(['/user']);
      },
      (err) => {
        this.shared.ShowAlert('Alert', 'password must be at least 6 characters long, contain at least one number and have a mixture of uppercase and lowercase letters.!', 'warning');
      }
    );
  }
  closeModal() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }






  openInactive(inactivecontent, item: any) {

    this.userId = item.id
      this.modalRef = this.modalService.open(inactivecontent, {
      backdrop: 'static',
      keyboard: false,
    });

  }
  
  openactive(activecontent, item: any) {

    this.userId = item.id
      this.modalRef = this.modalService.open(activecontent, {
      backdrop: 'static',
      keyboard: false,
      
    });
     
  }
  


  InActiveSubmit() {
    //alert(0)
    
    if (this.userId!='') {
      this.userService.CustomerInactive(this.userId).subscribe(data => {
        this.modalRef.close();
        //this.getCustomerList();
        this.getCustomerList();
        this.changeDetectorRef.detectChanges();
      })
      
    }
    else{
      this.userService.CustomerInactive(this.userId).subscribe(data=>{
        this.getCustomerList();
      })
    }
    
    this.changeDetectorRef.detectChanges();
  }

  ActiveSubmit() {

    
    if (this.userId!='') {
      this.userService.CustomerInactive(this.userId).subscribe(data => {
         this.modalRef.close();
        this.getCustomerList();
        this.changeDetectorRef.detectChanges();
      })
      
    }
    else{
      this.userService.CustomerInactive(this.userId).subscribe(data=>{
        this.getCustomerList();
        this.changeDetectorRef.detectChanges();
      })
    }
    
    this.changeDetectorRef.detectChanges();
  }

  getRoleList(){
    this._roleService.getlist().subscribe(res=>
    {
     this.roleList=res;
     console.log(this.roleList);
    })
  }

  onChangeForShow(value : any){
    this.currentPage = 1;
    this.itemsPerPage = value;
    this.getCustomerList();
    this.changeDetectorRef.detectChanges();
  }

  onSearch(){
    this.currentPage = 1;
    this.getCustomerList();
    this.changeDetectorRef.detectChanges();
  }

  onPageChange(pageNumber : number){
    this.currentPage = pageNumber;
    this.getCustomerList();
    this.changeDetectorRef.detectChanges();
  }


  OpenAccessibleModal(accessibleModal, item: any) {
    this.userService.GetAccessibleDaysByUsername(item.userName).subscribe((res:any)=>{
      this.accessibleDays = res;

      this.changeDetectorRef.detectChanges();
    })

    // this.formGroupReset.patchValue({
    //   userName: item.userName,
    //   password: item.password,
    //   confirmPassword: item.confirmPassword
    // });
    this.modalRef = this.modalService.open(accessibleModal, {
      backdrop: 'static',
      keyboard: false,
    });
  }



  ChangeAccessibleDays(){
 
    this.userService.UpdateAccessibleDays(this.accessibleDays).subscribe(
      (res) => {
        this.shared.ShowAlert('Thank You', 'Access Changed successfully!', 'success');
        this.closeModal();
        this.router.navigate(['/user']);
      },
      (err) => {
        console.log(err);
        this.shared.ShowAlert('Alert', 'Something Went Wrong!', 'warning');
      }
    );
  }




}
