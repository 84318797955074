
<div class="row">
    <!-- <div class="col-xl-3 col-lg-6 col-12" (click)="onDisburse(5)" style="cursor: pointer;height: 50%;">
        <div class="TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="">
                    <div class="media">
                        <div class="media-body text-left" style="margin-left: 11%;">
                            <h3 class="mb-1 " style="font-size: 19px;">{{waitingForDisburse}}</h3>
                            <span style="color:black;font-size: 13px;">Waiting loan</span>
                        </div>
                        <div class="media-right align-self-center">

                            <i class="ft-rotate-cw Secondary font-large-1 float-right" style="margin-right: 39%;"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-lg-6 col-12" (click)="FinalDisburse(7)" style="cursor: pointer;">
        <div class="TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="">
                    <div class="media">
                        <div class="media-body text-left" style="margin-left: 11%;">
                            <h3 class="mb-1 " style="font-size: 19px;">{{finalDisbursList}}</h3>
                            <span style="color:black;font-size: 13px;">Disbursed loan</span>
                        </div>
                        <div class="media-right align-self-center">
                            <i class="ft-check-circle  Secondary font-large-1 float-right"
                                style="margin-right: 39%;"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>-->
   <!--  
    <div class="col-xl-3 col-lg-6 col-12" [routerLink]="'/dps-account/dps-list'" style="cursor: pointer;">


       <div class="TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="">
                    <div class="media">
                        <div class="media-body text-left" style="margin-left: 11%;">
                            <h3 class="mb-1 " style="font-size: 19px;">{{ dpsAccount || 0 }}</h3>
                            <span style="color:black;font-size: 13px;">DPS List</span>
                        </div>
                        <div class="media-right align-self-center">
                            <i class="ft-slack Secondary font-large-1 float-right" style="margin-right: 39%;"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div> -->

    <div class="col-xl-3 col-lg-6 col-12" [routerLink]="'/dashboard/today-collection-list'"  style="cursor: pointer;">
        <div class="TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="">
                    <div class="media">
                        <div class="media-body text-left" style="margin-left: 11%;">
                            <h3 class="mb-1 " style="font-size: 19px;">{{todayReceivableAmount}}</h3>
                            <span style="color:black;font-size: 13px;">Today Receivable</span>
                        </div>
                        <div class="media-right align-self-center">
                            <i class="ft-layers Secondary font-large-1 float-right" style="margin-right: 39%;"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

<!-- <div class="col-xl-3 col-lg-6 col-12" [routerLink]="'/dashboard/app-payable-loan-list'" style="cursor: pointer;">
    <div class=" TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
        <div class="card-content">
            <div class="">
                <div class="media">
                    <div class="media-body text-left" style="margin-left: 11%;">
                        <h3 class="mb-1" style="font-size: 19px;">{{totalPayable}}</h3>
                        <span style="color:black;font-size: 13px;" style="margin-right: 39%;">Total Payable</span>
                    </div>
                    <div class="media-right align-self-center">
                        <i class="ft-credit-card Secondary font-large-1 float-right" style="margin-right: 39%;"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- <div class="col-xl-3 col-lg-6 col-12"  [routerLink]="'/dps-account/today-encashment-list'" style="cursor: pointer;">
    <div class=" TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
        <div class="card-content">
            <div class="">
                <div class="media">
                    <div class="media-body text-left" style="margin-left: 11%;">
                        <h3 class="mb-1" style="font-size: 19px;">{{todayEncashement || 0}}</h3>
                        <span style="color:black;font-size: 13px;" style="margin-right: 39%;">Encashement</span>
                    </div>
                    <div class="media-right align-self-center">
                        <i class="ft-credit-card Secondary font-large-1 float-right" style="margin-right: 39%;"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- -----------------------------------------------------mim----------------- -->
<div class="col-xl-3 col-lg-6 col-12" [routerLink]="'/dps-account/encashment-alert'"  style="cursor: pointer;">
    <div class=" TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
        <div class="card-content">
            <div class="">
                <div class="media">
                    <div class="media-body text-left" style="margin-left: 11%;">
                        <h3 class="mb-1" style="font-size: 19px;">{{ encashmentalert || 0}}</h3>
                        <span style="color:black;font-size: 13px;" style="margin-right: 39%;">Encashment Alert </span>
                    </div>
                    <div class="media-right align-self-center">
                        <i class="ft-credit-card Secondary font-large-1 float-right" style="margin-right: 39%;"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="col-xl-3 col-lg-6 col-12" [routerLink]="'/dps-account/today-dps-list'"  style="cursor: pointer;">
    <div class=" TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
        <div class="card-content">
            <div class="">
                <div class="media">
                    <div class="media-body text-left" style="margin-left: 11%;">
                        <h3 class="mb-1" style="font-size: 19px;">{{todaydpsnewaccount || 0}}</h3>
                        <span style="color:black;font-size: 13px;" style="margin-right: 39%;">New Accounts </span>
                    </div>
                    <div class="media-right align-self-center">
                        <i class="ft-credit-card Secondary font-large-1 float-right" style="margin-right: 39%;"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="col-xl-3 col-lg-6 col-12" [routerLink]="'/dps-account/close-account-list'"  style="cursor: pointer;">
    <div class=" TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
        <div class="card-content">
            <div class="">
                <div class="media">
                    <div class="media-body text-left" style="margin-left: 11%;">
                        <h3 class="mb-1" style="font-size: 19px;">{{dpsclosedaccount || 0}}</h3>
                        <span style="color:black;font-size: 13px;" style="margin-right: 39%;">Close Accounts  </span>
                    </div>
                    <div class="media-right align-self-center">
                        <i class="ft-credit-card Secondary font-large-1 float-right" style="margin-right: 39%;"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-xl-3 col-lg-6 col-12" [routerLink]="'/dps-account/missed-payment-list'"  style="cursor: pointer;">
    <div class=" TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
        <div class="card-content">
            <div class="">
                <div class="media">
                    <div class="media-body text-left" style="margin-left: 11%;">
                        <h3 class="mb-1" style="font-size: 19px;">{{dpsmissedpayment || 0}}</h3>
                        <span style="color:black;font-size: 13px;" style="margin-right: 39%;">Missed Payment</span>
                    </div>
                    <div class="media-right align-self-center">
                        <i class="ft-credit-card Secondary font-large-1 float-right" style="margin-right: 39%;"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


</div>


<div class="col-md-12 row">
    <div class="col-sm-6" style="background-color: #FFFFFF!important;">
        <h5 class="text-center mt-3">Designation Wise DPS Account</h5>
        <div class="">
    
    
            <div class="card-body">
                <h3 class=" text-center" style="font-size: 25px;"></h3>
                <div id="bar-doughnut" class="height-250 mt-4">
                    <canvas baseChart class="chart" [data]="occupationList" [labels]="designationNames"
                        [options]="designationChartOptions" [chartType]="designationChartType" [colors]="designationChartColors"
                        chartHover="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
                </div>
            </div>
    
        </div>
    </div>
    
    
    
    
    <div class="col-sm-6" style="background-color: #FFFFFF!important;">
        <h5 class="text-center mt-3">AVB - 500/1000/2000 VS Tenure</h5>
        <div class="">
    
    
            <div class="card-body">
                <h3 class=" text-center" style="font-size: 25px;"></h3>
                <div id="bar-doughnut" class="height-250 mt-4">
                    <canvas baseChart class="chart" [data]="countList" [labels]="tenureNames"
                        [options]="tenureChartOptions" [chartType]="tenureChartType" [colors]="tenureChartColors"
                        chartHover="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
                </div>
            </div>
    
        </div>
    </div>
</div>











<div class="row">

    <!-- <div class="col-xl-3 col-lg-6 col-12" (click)="onReceivable()" style="cursor: pointer;">
        <div class="TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="">
                    <div class="media">
                        <div class="media-body text-left" style="margin-left: 11%;">
                            <h3 class="mb-1" style="font-size: 19px;">{{ totalReceivable?.toString() || '0' }}</h3>
                            <span style="color:black;font-size: 13px;">Total Receivable</span>
                        </div>
                        <div class="media-right align-self-center">
                            <i class="ft-layers Secondary font-large-1 float-right" style="margin-right: 39%;"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-3 col-lg-6 col-12" (click)="TotalOverdue()" style="cursor: pointer;">
        <div class="TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="">
                    <div class="media">
                        <div class="media-body text-left" style="margin-left: 11%;">
                            <h3 class="mb-1" style="font-size: 19px;">{{todayOverdue}}</h3>
                            <span style="color:black;font-size: 14px;">Total Overdue</span>
                        </div>
                        <div class="media-right align-self-center">
                            <i class="ft-credit-card Secondary font-large-1 float-right" style="margin-right: 39%;"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    

</div>


<!-- ------------------------------------------------------------------------------- -->
<!-- <div class="row">
    <div class="col-xl-3 col-lg-6 col-12" (click)="onDisburse(5)" style="cursor: pointer;height: 50%;">
        <div class="TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="">
                    <div class="media">
                        <div class="media-body text-left" style="margin-left: 11%;">
                            <h3 class="mb-1" style="font-size: 19px;">{{waitingForDisburse}}</h3>
                            <span style="color:black;font-size: 13px;">Today Received</span>
                        </div>
                        <div class="media-right align-self-center">

                            <i class="ft-rotate-cw Secondary font-large-1 float-right" style="margin-right: 39%;"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-lg-6 col-12" (click)="FinalDisburse(7)" style="cursor: pointer;">
        <div class="TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="">
                    <div class="media">
                        <div class="media-body text-left" style="margin-left: 11%;">
                            <h3 class="mb-1" style="font-size: 19px;">{{finalDisbursList}}</h3>
                            <span style="color:black;font-size: 13px;">Advance Received</span>
                        </div>
                        <div class="media-right align-self-center">
                            <i class="ft-check-circle  Secondary font-large-1 float-right" style="margin-right: 39%;"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div> -->

<!-- ---------------------------------------------------------------------------------------- -->

<!-- <div class="row match-height mt-2" style="margin-left:-1px;">
    <div class="col-md-3 col-12 mr-1" (click)="TodayReceived()"
        style="background-color:#FFFFFF!important;cursor: pointer;">

        <div class=" text-center pb-0 mt-1">
            <span class="font-medium-2 warning">Today Received</span>
            <h4 class="font-large-0 mt-2">{{todayReceived}}</h4>
        </div>


    </div>
    <div class="col-md-3 col-12 mr-1" (click)="AdvaceReceived()"
        style="background-color:#FFFFFF!important;cursor: pointer;">

        <div class=" text-center pb-0 mt-1">
            <span class="font-medium-2 info ">Advance Received</span>
            <h4 class="font-large-0 mt-2">{{advanceAmount}}
            </h4>
        </div>


    </div>
    <div class="col-md-3 col-12" (click)="TodaySettlement()"
        style="background-color:#FFFFFF!important;cursor: pointer;">

        <div class="text-center pb-0 mt-1">
            <span class="font-medium-1 danger">Today Settlement</span>
            <h3 class="font-large-0 mt-2">{{todaySettlement}}
            </h3>
        </div>


    </div>
</div> -->





<!-- Cash Flow Chart Starts -->

<!-- <div class="row mt-2" style="margin-left: -1px; margin-right: -1px;">
    <div class="col-sm-6" style="background-color: #FFFFFF;margin: 15px 0;">
        <div class="">
            <div class="mt-3">
                <h5 class="text-center">Loan & Deposit Statistics</h5>
            </div>
            <div class="card-content">
                <div class="card-body">
                    <div class="chart-info mb-2">
                        <span class="text-uppercase mr-3"><i style="color:#191970;"
                                class="fa fa-circle  font-small-2 mr-1"></i> Loan</span>
                        <span class="text-uppercase"><i style="color:#C71585;"
                                class="fa fa-circle font-small-2 mr-1"></i> Deposit</span>
                    </div>
                    <div id="line-area2" class="lineArea2">
                        <x-chartist class="height-200" [data]="lineArea2.data" [type]="lineArea2.type"
                            [options]="lineArea2.options" [responsiveOptions]="lineArea2.responsiveOptions"
                            [events]="lineArea2.events">
                        </x-chartist>
                    </div>
                </div>
            </div>
        </div>


    </div>
    <div class="col-sm-6" style="background-color: #FFFFFF;margin: 15px 0;">
        <div class="">
            <div class="mt-3">
                <h5 class="text-center">Account Info</h5>
            </div>
            <div class="card-content">
                <div class="card-body">
                    <ul class="list-group" style="color: black">
                        <li class="list-group-item" [routerLink]="'/loan-application/loan-list'">
                            <span class="badge bg-success float-right"
                                style="width: 80px!important;">{{LoanApplication}}</span>Loan Account
                        </li>
                        <li class="list-group-item" [routerLink]="'/fdr-select/fdr-list'">
                            <span class="badge bg-success float-right"
                                style="width: 80px!important;">{{FdrAccount}}</span>FDR Account
                        </li>
                       
                    </ul>
                </div>
            </div>
        </div>


    </div>



</div> -->













<!-- <div class="row" style="margin-left: -1px; margin-right: -1px;">
    <div class="col-sm-6" style="background-color: #FFFFFF;margin: 15px 0;">
        <div class="">
            <div class="mt-3">
                <h5 class="text-center">Accounts Payable</h5>
            </div>
            <div class="card-content">
                <div class="card-body">
                    <h3 class="mb-1 text-center" style="font-size: 25px;">{{totalPayable}}</h3>
                    <div id="bar-doughnut" class="height-250">
                        <canvas baseChart class="chart" [data]="APdoughnutChartData" [labels]="APdoughnutChartLabels"
                            [options]="APdoughnutChartOptions" [chartType]="APdoughnutChartType"
                            [colors]="APdoughnutChartColors"></canvas>
                    </div>
                </div>
            </div>
        </div>


    </div>


    <div class="col-sm-6" style="background-color: #FFFFFF!important; margin: 15px 0;">
        <h5 class="text-center mt-3">Total Amount Receivable</h5>
        <div class="">


            <div class="card-body">
                <h3 class=" text-center" style="font-size: 25px;"></h3>
                <div id="bar-doughnut" class="height-250 mt-4">
                    <canvas baseChart class="chart" [data]="doughnutChartData" [labels]="doughnutChartLabels"
                        [options]="doughnutChartOptions" [chartType]="doughnutChartType" [colors]="doughnutChartColors"
                        chartHover="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
                </div>
            </div>

        </div>
    </div>












</div> -->

<div class="row" style="margin-left: -1px; margin-right: -1px;">
   <!--  <div class="col-sm-12" style="background-color: #FFFFFF;margin: 15px 0;">
        <div class="">
            <div class="mt-3">
                <h5 class="text-center">DPS Collection Information</h5>
            </div>
            <div class="card-content">
                <div class="card-body">
                    <div id="bar-bar1" class="height-400">
                        <canvas baseChart class="chart" [datasets]="barChartData" [labels]="barChartLabels"
                            [options]="barChartOptions" [colors]="barChartColors" [legend]="barChartLegend"
                            [chartType]="barChartType" chartHover="chartHovered($event)"
                            (chartClick)="chartClicked($event)"></canvas>
                    </div>
                </div>
            </div>
        </div>


    </div> -->


    <!-- <div class="col-sm-6" style="background-color: #FFFFFF!important; margin: 15px 0;">
        <h5 class="text-center mt-3">Loan Payment Status</h5>
        <div class="">
            
            <div id="donut-dashboard-chart" class="height-250 mt-5">
                <canvas baseChart class="chart" [data]=" LoanPaymentdoughnutChartData"
                    [labels]=" LoanPaymentdoughnutChartLabels" [options]="LoanPaymentdoughnutChartOptions"
                    [chartType]="LoanPaymentdoughnutChartType" [colors]="LoanPaymentdoughnutChartColors"
                    chartHover="LoanPaymentchartHovered($event)"></canvas>
            </div>

        </div>
    </div> -->












</div>













