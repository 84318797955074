import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardCustomService } from 'app/dashboard/dashboard-custom.service';

@Component({
  selector: 'app-waiting-loan-app',
  templateUrl: './waiting-loan-app.component.html',
  styleUrls: ['./waiting-loan-app.component.scss']
})
export class WaitingLoanAppComponent implements OnInit {
  dataList:any []=[];
  constructor(private router: Router,
    private _serviceDashboard: DashboardCustomService,
    private changeDetectorRef: ChangeDetectorRef) 
    {  
    }

  ngOnInit(): void {
    this.getWaitingApplication();
  }


  getWaitingApplication(){
    this._serviceDashboard.getLoanLeadInfoForAdmin().subscribe((res) =>{
      this.dataList =res.filter(item => item.statusInfoId===1);
      this.dataList.sort((a,b) => b.id - a.id);
      this.changeDetectorRef.detectChanges();
    })
  }

  // ApplicationApproval(leadCode:string,type:number){
  //   this.router.navigate(['/loanapplication-approval/loanapplication-approval/'+ leadCode +'/'+ type]);
  // }
}
