<div class="row">
    <hr>
    <div class="col-md-12">
        <div class="card">
            <!-- <div class="card-header" style="background-color:#657b9c;">
                <div class="row">
                    <div class="col-8">
                        <h5 class="card-title" style="color: black;font-family: Arial;font-size: 20px !important;">Statement-Report</h5>
                    </div>
                </div>
            </div> -->
            <div class="card-header" style="background-color:rgb(231, 238, 238);">
                <h5 class="card-title" style="color: black;font-family: Arial;font-size: 20px !important;">Statement Report</h5>         
            </div>
          
          
            <div class="card-body">
                <div class="col-md-12">
                    <div class="form-group row" [(ngModel)]="customerId">
                        <label for="customerId" class="col-sm-3 col-form-label">Select Customer</label>
                        <div class="col-sm-9">
                            <select type="text" class="form-control" (change)="onCustomerChange($event.target.value)" [(ngModel)]="customerId">
                                <option value="">Select</option>
                                <option *ngFor="let item of customerlist" [value]="item.id">   {{ item.customerNameEng }} - {{ item.mobileNo }}</option>
                              
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="ledgerId" class="col-sm-3 col-form-label">Select  Account</label>
                        <div class="col-sm-9">
                            <select type="text" class="form-control" [(ngModel)]="loanAccountId">
                                <option value="">Select</option>
                                <option *ngFor="let item of loanAccountList" [value]="item.id">{{ item.accountTilte }} - {{ item.customerAccountNo }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row" id="DateRangeSelect">
                        <label for="fromDate" class="col-sm-3 col-form-label text-left">From Date <span class="redStar">*</span></label>
                        <div class="col-sm-3 input-group">
                           
                                <input type="date" class="form-control" [(ngModel)]="fromDate" placeholder="Select From Date">
                               
                
                           
                            
                        </div>
                        <label for="toDate" class="col-sm-2 col-form-label text-left">To Date <span class="redStar">*</span></label>
                        <div class="col-sm-4 input-group">
                            <input type="date" class="form-control" [(ngModel)]="toDate" placeholder="Select From Date">
                        </div>
                    </div>
                   

                    <div class="form-group row" style="margin-top:10px;">
                        <label class="col-sm-8"></label>
                        <div class="col-sm-4">
                            <button  class="btn btn-success" style="margin-left: 5px; float: right;" (click)="OpenPDF()">Print</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<br />