import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ScoretagService } from '../scoretag.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { SharedService } from 'app/services/shared.service';
import { Score } from '../score-model/score';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { id } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-score-list',
  templateUrl: './score-list.component.html',
  styleUrls: ['./score-list.component.scss']
})
export class ScoreListComponent implements OnInit {

  scoreTagList: Observable<any[]>;
  list : Score[]=[];
  model = new Score();
  isEdit: boolean = false;
  scoreTagForm: FormGroup;
  loadData:any;

  constructor(
    private _scoreService : ScoretagService,
    private router: Router,
    private shared: SharedService,
    private formBuilder : FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private activeRoute: ActivatedRoute,
  ) {
   
   }

  ngOnInit(): void {
   
    this.getScoreTagList();
    this.buildForm();

  }

  getScoreTagList(){
    this._scoreService.GetScoreTagList().subscribe(data=>{
      //console.log(data);
      this.list = data;
    })
  }

  buildForm(){
    this.scoreTagForm = this.formBuilder.group({
      id: [0],
      tagName: ['', Validators.required],
      tagPattern: ['', Validators.required]
    })
  }

  edit(id: number) {
    this.router.navigate(['/score/score-create', id]);
    this.isEdit =true;
    
  }


  delete(id:any){
    this._scoreService.delete(id).subscribe(data=>{
      this.shared.ShowAlert('Alert','Data deleted successfully.','warning');
      this.getScoreTagList();
    })
  }
}
