import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { PdfService } from "app/pdf-reporting/pdf.service";
import { DatePipe, Location } from "@angular/common";

@Component({
  selector: "app-processingfee-report",
  templateUrl: "./processingfee-report.component.html",
  styleUrls: ["./processingfee-report.component.scss"],
})
export class ProcessingfeeReportComponent implements OnInit {
  totalProcessingFee: number = 0;
  totalCollectionAmount: number = 0;
  totalInterest: number = 0;
  totalPrincipal: number = 0;
  totalDisburseAmount: number = 0;
  totalOutstanding: number = 0;

  fromDate: Date = new Date();
  toDate: Date = new Date();
  collectionDataList: any[];
  constructor(
    private pdfService: PdfService,
    private location: Location,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {}
  OpenPdf() {
    this.pdfService
      .ProcessingFeeReportViewPDF(this.fromDate, this.toDate)
      .subscribe((x) => {
        let blob: Blob = x.body as Blob;
        let url = window.URL.createObjectURL(blob);

        window.open(url);
      });
  }

  OpenExcel() {
    this.pdfService
      .ProcessingFeeReportExcel(this.fromDate, this.toDate)
      .subscribe((x) => {
        let blob: Blob = x.body as Blob;
        let url = window.URL.createObjectURL(blob);

        window.open(url);
      });
  }

  goBack() {
    this.location.back();
  }

  GetLoanCollection() {
    this.pdfService
      .ProcessingFeeReportData(this.fromDate, this.toDate)
      .subscribe((res) => {
        this.collectionDataList = res.body;
        this.updateTotals();
      });
  }

  formatDateString(dateString: string): string {
    const formattedDate = this.datePipe.transform(dateString, "dd-MM-yyyy");
    return formattedDate || dateString;
  }

  updateTotals(): void {
    this.totalProcessingFee = 0;
    this.totalCollectionAmount = 0;
    this.totalInterest = 0;
    this.totalPrincipal = 0;
    this.totalDisburseAmount = 0;
    this.totalOutstanding = 0;

    for (let i = 0; i < this.collectionDataList.length; i++) {
      const item = this.collectionDataList[i];
      this.totalProcessingFee += item.processingFee || 0;
      this.totalPrincipal += item.loanAmount || 0;
      this.totalInterest += item.interestAmount || 0;
      this.totalOutstanding += item.outstanding || 0;
    }
  }

}
