

    <div class="card">
        
            <div class="row">
                <div class="col-10 mt-4">
                    <h5 class="card-title" style="color: #888888; font-family: Arial; font-size: 20px !important;">Loan
                        Settlement Report</h5>

                </div>
                <div class="col-2">

                    <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
                        <i class="icon-arrow-left" style="font-size: 17px;"> </i>
                    </button>
                </div>
            </div>
            <hr>
       

        <div class="card-body">
            <div class="col-md-12">
                <div class="form-group row" id="DateRangeSelect">
                    <label for="fromDate" class="col-sm-2 col-form-label text-left">From Date <span
                            class="redStar">*</span></label>
                    <div class="col-sm-4 input-group">
                        <input type="date" class="form-control" [(ngModel)]="fromDate" placeholder="Select From Date">
                    </div>
                    <label for="toDate" class="col-sm-2 col-form-label text-left">To Date <span
                            class="redStar">*</span></label>
                    <div class="col-sm-4 input-group">
                        <input type="date" class="form-control" [(ngModel)]="toDate" placeholder="Select To Date">
                    </div>
                </div>

                <div class="form-group row" style="margin-top: 10px;">
                    <label class="col-sm-8"></label>
                    <div class="col-sm-4">
                        <button class="btn btn-success" style="margin-left: 5px; float: right;"
                            (click)="OpenPdf()">Print</button>
                        <button class="btn btn-info" style="margin-left: 5px; float: right;"
                            (click)="OpenExcel()">Excel</button>
                        <button class="btn btn-warning" style="margin-left: 5px; float: right;"
                            (click)="GetLoanCollection()">Load</button>
                    </div>
                </div>
            </div>
            

        </div>
     <br>
        <diV class="card-body" *ngIf="settlementDataList" style="overflow: auto;max-height: 600px;">
          
                <table  *ngIf="settlementDataList && settlementDataList.length > 0"  class="table table-striped table-bordered scrollable-table"
                    style="width:100%;margin-top: 2%;">
                    <thead style="position: sticky;top:0;z-index: 1;background-color: #f8f9fa;">
                        <tr>
                            <th style="width:5%;">SL.</th>
                            <th>Account Info</th>
                            <th>Disbursement Date</th>
                            <th>Principal Amount</th>
                            <th>Interest Amount</th>
                            <th>Monthly EMI</th>
                            <th>Total Disburse Amount</th>
                            <th>Settlement Date</th>
                            <th>Settlement Amount</th>
                            <th>Total Collection Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of settlementDataList;let i=index">
                            <td>{{i+1}}.</td>
                            <td>
                                <span>Account No: {{item.accounT_NO}} </span> <br>
                                <span>Account title: {{item.accounT_TITLE}}</span>
                            </td>
                            <td>{{formatDateString(item.disbursementDate)}}</td>
                            <td style="text-align: right !important;">{{item.loanAmount}}</td>
                            <td style="text-align: right !important;">{{item.interestAmount}}</td>
                            <td style="text-align: right !important;">{{item.monthlyEMI}}</td>
                            <td style="text-align: right !important;">{{item.loanAmount+item.interestAmount}}</td>
                            <td>{{formatDateString(item.settlementDate)}}</td>
                            <td style="text-align: right !important;">{{item.settlementAmount}}</td>
                            <td style="text-align: right !important;">{{item.totalCollection}}</td>
                        </tr>
                    </tbody>
                </table>
          
        </diV>

        <div class="col-md-12" *ngIf="!settlementDataList || settlementDataList.length === 0">
        
            <p style="text-align: center;"></p>
        </div>
    </div>
