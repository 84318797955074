<form [formGroup]="frmGroup">
  <div class="col-md-12">
    <div class="card" style="width: 100%;">
      <div class="card-header" >
        <h5 class="card-title" style="color: black;font-family: Arial;font-size: 20px !important;">Mobile Recharge</h5>

      </div>
      <div class="card-body" style="margin-top: 30px;">
        <div class="row">
          <div class="col-md-6">

            <div class="form-group row">
              <label for="" class="col-sm-4 col-form-label">Debit Account No <span style="color: red;"> *</span></label>
              <div class="col-sm-8">
                <select class="form-control" formControlName="customerCode" [(ngModel)]="accountNoFund1"
                  (change)="loadUserDataByAccountNoFundFirst($event.target.value)">
                  <option value="">Select</option>
                  <ng-container *ngFor="let item of AccountNoList">
                    <option [value]="item.id">{{item.customerAccountNo}}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label for="" class="col-sm-4 col-form-label">Current Balance</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" value="{{currentBalance}}" readonly style="text-align: right">
              </div>
            </div>


            <!-- <div class="form-group row">
                      <label for="" class="col-sm-4 ">Debit Customer</label>
                      <div class="col-sm-8">
                        <input type="text" class="form-control" [value]="accountName" name="accountName" readonly>
                      </div>
                    </div> -->


            <div class="form-group row">
              <label for="" class="col-sm-4 col-form-label">Type <span style="color: red;"> *</span></label>
              <div class="col-sm-8">
                <div *ngFor="let option of options">
                  <label>
                    <input type="radio" name="options" [value]="option" formControlName="paidType"
                      [(ngModel)]="paidType">
                    {{option}}
                  </label>
                </div>

                <div *ngIf="paidType">
                  <span>{{paidType}}</span>
                </div>

              </div>
            </div>

          </div>


          <div class="col-md-6">

            <div class="form-group row">
              <label for="" class="col-sm-4 col-form-label">Operators<span style="color: red;"> *</span></label>
              <div class="col-sm-8">
                <select class="form-control" formControlName="operatorCode" [(ngModel)]="accountNoFund2"
                  (change)="loadUserDataByAccountNoFundLast($event.target.value)">
                  <option value="">Select</option>
                  <ng-container *ngFor="let item of OperatorsList">
                    <option [value]="item.id">{{item.name}}</option>
                  </ng-container>
                </select>
              </div>
            </div>


            <div class="form-group row">
              <label for="name" class="col-sm-4 col-form-label">Receipiant No <span style="color: red;">
                  *</span></label>
              <div class="col-sm-8">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">88</span>
                  </div>
                  <!-- <input type="text" class="form-control"   name="MobileNo" formControlName="MobileNo"   [(ngModel)]="MobileNumber"
                           placeholder="Type mobile no..." maxlength="11"
                          (keyup.enter)="loadMobileBankingInfoById(MobileNumber)" > -->
                  <input type="text" class="form-control" name="MobileNo" formControlName="MobileNo"
                    [(ngModel)]="MobileNumber" placeholder="Type mobile no..." maxlength="11">

                </div>
              </div>
            </div>




            <div class="form-group row">
              <label for="" class="col-sm-4 col-form-label">Amount <span style="color: red;"> *</span></label>
              <div class="col-sm-8">

                <input type="text" style="text-align: right;" class="form-control" formControlName="voucherAmount"
                  placeholder="Enter Amount" style="text-align: right"
                  (input)="frmGroup.get('voucherAmount').setValue(formatNumber($event.target.value))"
                  (keyup)="CheckBalance()" (keyup)="voucherAmountValue($event.target.value)">

                <span class="text-danger" style="font-size: 10px; font-weight: bold;" *ngIf="!isvalid">Invalid Amount.
                  Amount cannot be more than the Balance.</span>
                <span *ngIf="frmGroup.get('voucherAmount').hasError('pattern')" class="text-danger">
                  Only numeric values are allowed.
                </span>

              </div>

            </div>

          </div>

        </div>


        <button data-toggle="tooltip" (click)="onsubmit()" title="Save" type="button" class="btn btn-primary"
          style="float:right; margin-top:5px;">Send </button>


      </div>
    </div>
  </div>

</form>