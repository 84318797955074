import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScoreRoutingModule } from './score-routing.module';
import { ScoreListComponent } from './score-list/score-list.component';
import { ScoreCreateComponent } from './score-create/score-create.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createTranslateLoader } from 'app/app.module';
import { HttpClient } from '@microsoft/signalr';
import { ScoreQuestionComponent } from './score-question/score-question.component';
import { ScoreQueAnswerComponent } from './score-que-answer/score-que-answer.component';
import { BankComponent } from './bank/bank.component';
import { ProvideInfoComponent } from './provide-info/provide-info.component';
import { BankBeneficiarCreateComponent } from './bank-beneficiar-create/bank-beneficiar-create.component';
import { BankBeneficiarListComponent } from './bank-beneficiar-list/bank-beneficiar-list.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    ScoreListComponent,
    ScoreCreateComponent,
    BankComponent,
    ScoreCreateComponent,
    ScoreQuestionComponent,
    ScoreQueAnswerComponent,
    ProvideInfoComponent,
    BankBeneficiarCreateComponent,
    BankBeneficiarListComponent
  ],
  imports: [
    CommonModule,
    ScoreRoutingModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
  ]
})
export class ScoreModule { }
