import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PdfService } from "app/pdf-reporting/pdf.service";
import { AccountingreportService } from "../accountingreport.service";
import { LedgerService } from "app/accounting/service/ledger.service";
import { Location } from "@angular/common";
import { LayoutService } from "app/shared/services/layout.service";
import * as moment from "moment";

@Component({
  selector: "app-ledger-book-report",
  templateUrl: "./ledger-book-report.component.html",
  styleUrls: ["./ledger-book-report.component.scss"],
})
export class LedgerBookReportComponent implements OnInit {
  reportForm: FormGroup; 
  reportBy: number;
  Sbulist: any[] = [];
  projectlist: any[] = [];
  subledgerId: number | null = null;
  ledgerId: number | null = null;
  subledgerStatus: string = "hide";

  businessDate: string;
  fromDate: any;
  toDate: any;

  AccountNoList: any[] = [];
  AccountSubLedgerList: any[] = [];

  constructor(
    private fb: FormBuilder,
    private pdfService: PdfService,
    private accountingreportService: AccountingreportService,
    private _LedgerService: LedgerService,
    private location: Location,
    private layoutService: LayoutService
  ) {

    this.reportForm = this.fb.group({
      ledgerId: [null, Validators.required],
      subledgerId: [null],
      fromDate: [null, Validators.required],
      toDate: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.getSbuList();
    this.GetProjectist();
    this.Getledgerlist();
    this.getCurrentBusinessDate();
  }

  getCurrentBusinessDate() {
    this.layoutService.getCurrentBusinessDate().subscribe((res: any) => {
      const bdate = moment.utc(res.business_Date).format("YYYY-MM-DD");
      this.fromDate = bdate;
      this.toDate = bdate;
      this.reportForm.patchValue({
        fromDate: bdate,
        toDate: bdate,
      });
    });
  }

  OpenPDF() {
    this.pdfService
      .LedgerBookReportDataViewpdf(
        this.ledgerId,
        this.subledgerId,
        this.fromDate,
        this.toDate,
        1,
        2
      )
      .subscribe((res) => {
        let blob: Blob = res.body as Blob;
        let url = window.URL.createObjectURL(blob);

        window.open(url);
      });
  }

  getSbuList() {
    this.accountingreportService.GetSbuList().subscribe((res) => {
      this.Sbulist = res;
    });
  }

  GetProjectist() {
    this.accountingreportService.GetProjectist().subscribe((res) => {
      this.projectlist = res;
    });
  }

  Getledgerlist() {
    this._LedgerService.GetAllLedgerList().subscribe((res) => {
      this.AccountNoList = res;
    });
  }

  onLedgerChange(selectedItem: any): void {
    if (selectedItem && selectedItem.id) {
      const Id = selectedItem.id;
      const selectedAccount = this.AccountNoList.find((item) => item.id === Id);

      if (selectedAccount?.ledger?.haveSubledger === 1) {

        this.subledgerStatus = "Show";
        this.ledgerId = Id;
        this.subledgerId = 0;
        this.getSubLedgerList(Id);
      } 
      else {

        this.ledgerId = Id;
        this.subledgerId = 0;
        this.subledgerStatus = "hide";
      }
    }
  }

  onSubLedgerChange(event: any) {
    this.subledgerId = event ? event.id : null;
  }

  getSubLedgerList(Id: number) {
    this._LedgerService.GetAllSubLedgerbyLrId(Id).subscribe((res) => {
      this.AccountSubLedgerList = res;
      this.AccountSubLedgerList.forEach((item) => {
        item.formattedSubLedgerName = `${item.subLedger.accountName} - ${item.subLedger.accountCode}`;
      });
    });
  }

  goBack() {
    this.location.back();
  }
}
