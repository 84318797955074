import { NgModule } from '@angular/core';


import { NavbarsRoutingModule } from './navbars-routing.module';
import { NavbarsComponent } from './navbars/navbars.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { UserroleassignComponent } from './userroleassign/userroleassign.component';
import { UserExceptionLogComponent } from './user-exception-log/user-exception-log.component';
import { LoanMenuSetupComponent } from './loan-menu-setup/loan-menu-setup.component';


@NgModule({
  declarations: [
    NavbarsComponent,
    UserroleassignComponent,
    UserExceptionLogComponent,
    LoanMenuSetupComponent
  ],
  imports: [
    CommonModule,
    NavbarsRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class NavbarsModule { }
