import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { PaymentFailedComponent } from './payment-failed/payment-failed.component';
import { PaymentCancelledComponent } from './payment-cancelled/payment-cancelled.component';
import { TransactionLogListComponent } from './transaction-log-list/transaction-log-list.component';
import { AuthGuard } from 'app/shared/auth/auth-guard.service';
import { BKashPaymentSuccessComponent } from './b-kash-payment-success/b-kash-payment-success.component';
import { BKashPaymentFailedComponent } from './b-kash-payment-failed/b-kash-payment-failed.component';

const routes: Routes = [
  {
    path: "payment-success/:trans_id",
    component: PaymentSuccessComponent,
    canActivate:[AuthGuard]
  },
  {
    path: "payment-failed/:trans_id",
    component: PaymentFailedComponent,
    canActivate:[AuthGuard]
  },
  {
    path: "payment-cancelled/:trans_id",
    component: PaymentCancelledComponent,
    canActivate:[AuthGuard]
  },
  {
    path: "transaction-logs",
    component: TransactionLogListComponent,
    canActivate:[AuthGuard]
  },
  {
    path: "bkash-payment-success",
    component: BKashPaymentSuccessComponent,
    canActivate:[AuthGuard]
  },
  {
    path: "bkash-payment-failed/:paymentID",
    component: BKashPaymentFailedComponent,
    canActivate:[AuthGuard]
  }




];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PaymentRoutingModule { }
