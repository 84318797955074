import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { AccountingreportService } from '../accountingreport.service';
import { Observable, of } from 'rxjs';
import { LedgerService } from 'app/accounting/service/ledger.service';
import { DatePipe, Location } from "@angular/common";
import { SharedService } from 'app/services/shared.service';
import { NGB_DATEPICKER_18N_FACTORY } from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker-i18n';

@Component({
  selector: 'app-payment-receive-report',
  templateUrl: './payment-receive-report.component.html',
  styleUrls: ['./payment-receive-report.component.scss']
})
export class PaymentReceiveReportComponent implements OnInit {

  fromDate: any = new Date();
  toDate: any = new Date();
  
  businessDate:Date;
  businessYear: number;
  businessMonth: number;
  businessDay: number;

  reportBy: number;
  Sbulist: any = [];
  projectlist: any = [];
  subledgerId: any = 0;
  ledgerId: any = [];
  AccountGroupList: Observable<any[]>;
  AccountNoList: Observable<any[]>;
  AccountSubLedgerList: any[] = [];
  subledgerStatus: string = "hide";

  constructor(
    private pdfService: PdfService,
    private accountingreportService: AccountingreportService,
    private _LedgerService: LedgerService,
    private accountReportService: AccountingreportService,
    private changeDetectorRef: ChangeDetectorRef,
    private location: Location,
    private shared: SharedService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.getSbuList();
    this.GetProjectist();
    this.Getledgerlist();
    //this.GetAccountGroupList();
    this._LedgerService.GetLastAciteBusinessDate().subscribe(res => {
      this.fromDate = { day: parseInt(res.business_Date.split('-')[2].split('T')[0]), month: parseInt(res.business_Date.split('-')[1]), year: parseInt(res.business_Date.split('-')[0])};// this.datePipe.transform(res.business_Date, 'yyyy-MM-dd');
      
      this.businessDate = res.business_Date;

      this.businessYear = 2024; //parseInt(this.businessDate.toString().split('-')[0]);
      this.businessMonth = 3;//parseInt(this.businessDate.toString().split('-')[1]);
      this.businessDay = 1;//parseInt(this.businessDate.toString().split('-')[2].split('T')[0]);

      this.changeDetectorRef.detectChanges();
    })
  }
  OpenPDF() {
    let date = new Date(this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day);

    this.pdfService
      .PaymentReceiveReportPdf(
        this.datePipe.transform(date, 'yyyy-MM-dd')
      )
      .subscribe((res) => {
        let blob: Blob = res.body as Blob;
        let url = window.URL.createObjectURL(blob);

        window.open(url);
      });
  }

  getSbuList() {
    this.accountingreportService.GetSbuList().subscribe((res) => {
      this.Sbulist = res;
    });
  }
  GetProjectist() {
    this.accountingreportService.GetProjectist().subscribe((res) => {
      this.projectlist = res;
    });
  }
  Getledgerlist() {
    this._LedgerService.GetAllLedgerList().subscribe((res) => {
      this.AccountNoList = of(res);
    });
  }

  onLedgerChange(Id: any): void {
    // if (Id > 0 || Id != null || Id != "") {
    //   this.ledgerId=Id;
    //   this.getSubLedgerList(Id);
    // }

    this.AccountNoList.subscribe((accountList) => {
      const selectedAccount = accountList.find((item) => item.id == Id);

      if (selectedAccount?.ledger?.haveSubledger == 1) {
        this.subledgerStatus = "Show";
        this.ledgerId = Id;
        this.subledgerId = 0;
        this.getSubLedgerList(Id);
      } else {
        this.ledgerId = Id;
        this.subledgerId = 0;
        this.subledgerStatus = "hide";
      }
    });
  }

  onSubLedgerChange(Id: any): void {
    this.subledgerId = Id;
  }

  getSubLedgerList(Id: number) {
    this._LedgerService.GetAllSubLedgerbyLrId(Id).subscribe((res) => {
      console.log(res);
      this.AccountSubLedgerList = res;
    });
  }
  goBack() {
    this.location.back();
  }

}
