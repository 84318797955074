import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomerService } from 'app/customer/customer.service';
import { SharedService } from 'app/services/shared.service';
import { Observable } from 'rxjs';
import { CashPosting } from '../model/cash-posting';
import { CashPostingService } from '../service/cash-posting.service';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-breb',
  templateUrl: './breb.component.html',
  styleUrls: ['./breb.component.scss']
})
export class BrebComponent implements OnInit {

  frmGroup: FormGroup;
  AccountNoList: Observable<any[]>;
  billAcoountList: Observable<any[]>;
  BillMonthList: Observable<any[]>;
  accountNoFund1: string = '';
  currentBalance: number;
  totalCurrentBalance: number;
  isvalid: boolean = true;
  isBtnValid: boolean = true;

  isEdit: boolean = false;
  model = new CashPosting();
  customerIdPayment: number;
  customerAccountId: number;
  glAccountName: any;
  customerInfo1: any = '';
  billInfo: any = '';
  customerAccountId2: number;
  BillNumber: string = '';
  accType: string = '';
  accountNoFund2: string = '';
  BillPayDate: Date = new Date();
  //monthId:Date = new Date();
  monthId: string = '';
  value: string;


  constructor(
    private shared: SharedService,
    private _CustomerService: CustomerService,
    private formBuilder: FormBuilder,
    private _cashService: CashPostingService,
    private datePipe: DatePipe,
    private router: Router,
  ) {
    this.frmGroup = new FormGroup({
      customerCode: new FormControl(),

      voucherAmount: new FormControl,
      BillNumber: new FormControl(),
      accType: new FormControl(),

    })
    this.frmGroup = this.formBuilder.group({

      customerCode: new FormControl(),
      transactionType: new FormControl(),
      customerId: new FormControl(),
      voucherNo: new FormControl(),
      postingType: new FormControl(),
      remarks: new FormControl(),
      currencyId: new FormControl(),
      customerAccountId2: new FormControl(),
      transactionTypesId: new FormControl(),
      //BillPayDate: new FormControl(),
      // voucherAmount: new FormControl(),

      monthId: new FormControl(),
      BillPayDate: [this.datePipe.transform(new Date(), 'yyyy-MM-dd')],
      //customerCode: '',
      voucherAmount: ['', [Validators.required, Validators.pattern('^[0-9,.]*$')]],
      //BillNumber: [null, [Validators.required, Validators.min(11), Validators.max(13)]],
      BillNumber: [''],


    });


  }

  ngOnInit(): void {
    this.GetCustomerAccountNo();
    //this.GetBillAccountInformationByType();
  }

  populateForm() {
    BillNumber: ['']
  }

  billPayMonth(id: any) {
    this.frmGroup.value.monthId = id;
    this.model.monthId = id;
  }



  CheckBalance() {
    var balanceAmount = this.currentBalance.toString().replace(/,/g, '');
    var inputAmount = parseInt(this.frmGroup.value.voucherAmount.replace(/,/g, ''), 10);

    if (inputAmount > parseInt(balanceAmount, 10)) {
      this.isvalid = false;
    }
    else {
      this.isvalid = true;
    }
  }

  //For Debit
  GetCustomerAccountNo() {
    this._CustomerService.GetCustomerAccountNo().subscribe(res => {
      this.AccountNoList = res;
    });
  }
  loadUserDataByAccountNoFundFirst(accountId: number) {

    this._CustomerService.GetCustomerInfoByAccountNoById(accountId).subscribe(res => {
      if (res != null) {

        if (res.glAccountName == null) {
          this.currentBalance = res.currentBalance.toLocaleString();
          this.totalCurrentBalance = res.currentBalance.toLocaleString();
          //this.customerIdPayment = res.customersId;
          this.customerInfo1 = res.customerNameEng + '; ' + res.mobileNo + '; ' + res.nidNo;
          this.customerAccountId = res.id;
          // this.isBtnValid = true;
          // this.glAccountName = '';
        }

      }
      else {
        // this.refreshAc();
        swal('Warning', 'Invalid Account No', 'warning');
      }
    })
  }
  //End

  //For bill


  loadBillAccountInfo() {
    if (this.frmGroup.value.BillNumber.length === 13) {
      this.billInfo = "BREB"
    }
    else {
      this.shared.ShowAlert("Warning", "Bill Number should be 13 digit", "warning");
    }
  }

  //End


  formatNumber(value: string): string {
    // Remove existing commas and format the value
    const formattedValue = value.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return formattedValue;
  }

  onsubmit() {

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to paid BREB bill!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, send it!"
    }).then((res) => {
      if (res.isConfirmed) {

    if (this.isEdit) {
      this.model.id = this.frmGroup.value.id;
    }
    else {
      this.model.id = 0;
    }

    this.model.customerCode = this.frmGroup.value.customerCode;
    this.model.transactionType = 3;
    this.model.customerAccountId = this.customerAccountId;
    //this.model.customerAccountId2 = this.customerAccountId2;
    this.model.customerAccountId2 = this.frmGroup.value.BillNumber;
    this.model.AccountName = this.frmGroup.value.accountName;
    this.model.BillPayDate = this.frmGroup.value.BillPayDate;
    this.model.monthId = this.frmGroup.value.monthId;

    this.model.ledgerRelationId = 473;
    this.model.transactionTypeId = 6;

    this.model.remarks = this.frmGroup.value.remarks;
    this.model.currencyId = this.frmGroup.value.currencyId;
    // this.model.voucherAmount = this.frmGroup.value.voucherAmount;
    this.model.voucherAmount = this.frmGroup.value.voucherAmount.replace(/,/g, '');

    // this.model.gLGenerateId =  this.gLGenerateId;
    if (this.model.voucherAmount > 0) {
      if (this.model.id == 0) {

        this._cashService.SaveWasa(this.model).subscribe((res: number) => {

          if (res > 0) {
            //this.ResetForm();            

            this.shared.ShowAlert("Success", "Save Successfully", "success");
            this.refreshAc();
            this.isEdit = false;



          }
          else {
            this.shared.ShowAlert("Warning", "Failed to Save", "warning");
            this.isEdit = false;
          }

        })
      }
      else {
        this._cashService.SaveWasa(this.model).subscribe(res => {
          //this.ResetForm();
          this.refreshAc();

          this.shared.ShowAlert("Success", "Save Successfully", "success");
          this.isEdit = false;
        })
      }
    }
    else {
      this.shared.ShowAlert("Warning", "Requireed Field is Invalied", "warning");
      this.isEdit = false;
    }
  }
  else if (res.isDismissed) {
    this.router.navigate(['/accounting/breb']);
  }
});

} 

  voucherAmountValue(value: any) {
    var balanceAmount = this.totalCurrentBalance.toString().replace(/,/g, '');
    var inputAmount = parseInt(value.replace(/,/g, ''), 10);

    if(inputAmount>0){
     this.currentBalance = parseInt(balanceAmount) - inputAmount;
    }
    else{
     this.currentBalance = this.totalCurrentBalance;
    }
 }
  formatDate(transactionDate: any): string {
    const dateObject = new Date(transactionDate);
    if (!transactionDate || isNaN(dateObject.getTime())) {
      return '';
    } else {
      const formattedDate = `${dateObject.getDate()}-${dateObject.getMonth() + 1}-${dateObject.getFullYear()}`;
      return formattedDate;
    }
  }
  refreshAc() {

    this.isBtnValid = false;
    this.customerAccountId = 0;
    this.customerAccountId2 = 0;
    this.BillPayDate = new Date();
    this.monthId = '';
    this.model.BillNo = '';
    this.frmGroup.value.voucherAmount = '';
    // this.frmGroup.value.remarks='';
    this.model.remarks = '';


  }

  ResetForm() { }
}
