import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from 'app/customer/customer.service';
import { Applicationuser } from 'app/employee/employee-model/applicationuser';
import { EmployeeserviceService } from 'app/employee/employee-service/employeeservice.service';
import { LanguageServiceService } from 'app/services/language-service.service';
import { SharedService } from 'app/services/shared.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.scss']
})
export class EmployeeListComponent implements OnInit {
  modalRef: NgbModalRef | undefined;
   model = new Applicationuser();
   userlist: Observable<any[]>;
   list: Observable<any[]>;
   errorMessage: string = '';
   formGroupReset: FormGroup;
  closeResult: string;
  isExistingPasswordValid: boolean = true;

  currentPage = 1;
  itemsPerPage = 10;
  totalItems = 0;
  currentItems = 0;
  searchControl = "";

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private _service: EmployeeserviceService,
    private _customerservice: CustomerService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private shared: SharedService,
    private language: LanguageServiceService
  ) 
  { 
    this.populateForm();
    language.setLanguage();

  }

  ngOnInit(): void {
    this.getAllUserList();
    this.getACtiveUserWithSearchAndPagination();
  }


  getAllUserList() {
    this._service.getUserlist().subscribe(res => {
      this.userlist = res;
    });
  }

  getACtiveUserWithSearchAndPagination(){
    this._service.getActiveUserWithSearchAndPagination(this.currentPage, this.itemsPerPage, this.searchControl).subscribe((res:any)=>{
      this.list = res.data;
      this.currentItems = res.currentItems;
      this.totalItems = res.totalItems;
    })
  }

  populateForm(){
    this.formGroupReset = this.formBuilder.group({
      userName: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });
  }

  openReset(resetcontent, item: any) {
    this.formGroupReset.patchValue({
      userName: item.userName,
      password: item.password,
      confirmPassword: item.confirmPassword
    });
    this.modalRef = this.modalService.open(resetcontent, {
      backdrop: 'static',
      keyboard: false,
    });
  }


  ResetSubmit() {
    if (this.formGroupReset.invalid) {
      this.shared.ShowAlert('Alert', 'Please fill in all required fields.', 'warning');
      return;
    }

    const password = this.formGroupReset.value.password;
    const confirmPassword = this.formGroupReset.value.confirmPassword;
    if (password !== confirmPassword) {
      this.errorMessage = "Password didn't match.";
      return;
    }

    const model = {
      userName: this.formGroupReset.value.userName,
      password: password,
      confirmPassword: confirmPassword,
    };

    this._service.ResetPassword(model).subscribe(
      (res) => {

        this.shared.ShowAlert('Thank You', 'Password Updated successfully!', 'success');
        this.closeModal();
        this.router.navigate(['/employeeregister/employee-list']);
      },
      (err) => {
        this.shared.ShowAlert('Alert', 'Something Went Wrong!', 'warning');
      }
    );
  }
  closeModal() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  onChangeForShow(value : any){
    this.currentPage = 1;
    this.itemsPerPage = value;
    this.getACtiveUserWithSearchAndPagination();
  }

  onSearch(){
    this.currentPage = 1;
    this.getACtiveUserWithSearchAndPagination();
  }

  onPageChange(pageNumber : number){
    this.currentPage = pageNumber;
    this.getACtiveUserWithSearchAndPagination();
  }
}
