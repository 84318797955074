<!--Statistics cards Starts-->
<div class="row">
    <div class="col-xl-3 col-lg-6 col-12" (click)="onDisburse(5)" style="cursor: pointer;height: 50%;">
        <div class="TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="">
                    <div class="media">
                        <div class="media-body text-left" style="margin-left: 11%;">
                            <h3 class="mb-1 " style="font-size: 19px;">{{waitingForDisburse}}</h3>
                            <span style="color:black;font-size: 13px;">Waiting loan</span>
                        </div>
                        <div class="media-right align-self-center">
                            
                            <i class="ft-rotate-cw Secondary font-large-1 float-right" style="margin-right: 39%;"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-lg-6 col-12" (click)="FinalDisburse(7)" style="cursor: pointer;">
        <div class="TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="">
                    <div class="media">
                        <div class="media-body text-left" style="margin-left: 11%;">
                            <h3 class="mb-1 " style="font-size: 19px;">{{finalDisbursList}}</h3>
                            <span style="color:black;font-size: 13px;">Disbursed loan</span>
                        </div>
                        <div class="media-right align-self-center">
                            <i class="ft-check-circle  Secondary font-large-1 float-right" style="margin-right: 39%;"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="col-xl-3 col-lg-6 col-12" (click)="onRejected(4)" style="cursor: pointer;">
        <div class="TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="">
                    <div class="media">
                        <div class="media-body text-left" style="margin-left: 11%;">
                            <h3 class="mb-1" style="font-size: 19px;">{{rejectedLoan}}</h3>
                            <span style="color:black;font-size: 13px;">Rejected Loan</span>
                        </div>
                        <div class="media-right align-self-center">
                            <i class="ft-x-circle Secondary font-large-1 float-right" style="margin-right: 39%;"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="col-xl-3 col-lg-6 col-12" (click)="ClProcess()" style="cursor: pointer;">


        <div class="TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="">
                    <div class="media">
                        <div class="media-body text-left" style="margin-left: 11%;">
                            <h3 class="mb-1 " style="font-size: 19px;">{{totalRunningLoan}}</h3>
                            <span style="color:black;font-size: 13px;">CL Classification</span>
                        </div>
                        <div class="media-right align-self-center">
                            <i class="ft-slack Secondary font-large-1 float-right" style="margin-right: 39%;"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-3 col-lg-6 col-12" (click)="TodayCollection()" style="cursor: pointer;">
        <div class="TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="">
                    <div class="media">
                        <div class="media-body text-left" style="margin-left: 11%;">
                            <h3 class="mb-1 " style="font-size: 19px;">{{todayReceivableAmount}}</h3>
                            <span style="color:black;font-size: 13px;">Today Receivable</span>
                        </div>
                        <div class="media-right align-self-center">
                            <i class="ft-layers Secondary font-large-1 float-right" style="margin-right: 39%;"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    
    
    

</div>

<div class="row">
    
    <div class="col-xl-3 col-lg-6 col-12" (click)="onReceivable()" style="cursor: pointer;">
        <div class="TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="">
                    <div class="media">
                        <div class="media-body text-left" style="margin-left: 11%;">
                            <h3 class="mb-1" style="font-size: 19px;">{{ totalReceivable?.toString() || '0' }}</h3>
                            <span style="color:black;font-size: 13px;">Total Receivable</span>
                        </div>
                        <div class="media-right align-self-center">
                            <i class="ft-layers Secondary font-large-1 float-right" style="margin-right: 39%;"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <div class="col-xl-3 col-lg-6 col-12" (click)="TotalOverdue()" style="cursor: pointer;">
        <div class="TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="">
                    <div class="media">
                        <div class="media-body text-left" style="margin-left: 11%;">
                            <h3 class="mb-1" style="font-size: 19px;">{{todayOverdue}}</h3>
                            <span style="color:black;font-size: 14px;">Total Overdue</span>
                        </div>
                        <div class="media-right align-self-center">
                            <i class="ft-credit-card Secondary font-large-1 float-right" style="margin-right: 39%;"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-lg-6 col-12" (click)="TotalPayable()" style="cursor: pointer;">
        <div class=" TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="">
                    <div class="media">
                        <div class="media-body text-left" style="margin-left: 11%;">
                            <h3 class="mb-1" style="font-size: 19px;">{{totalPayable}}</h3>
                            <span style="color:black;font-size: 13px;" style="margin-right: 39%;">Total Payable</span>
                        </div>
                        <div class="media-right align-self-center">
                            <i class="ft-credit-card Secondary font-large-1 float-right" style="margin-right: 39%;"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>


<!-- ------------------------------------------------------------------------------- -->
<!-- <div class="row">
    <div class="col-xl-3 col-lg-6 col-12" (click)="onDisburse(5)" style="cursor: pointer;height: 50%;">
        <div class="TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="">
                    <div class="media">
                        <div class="media-body text-left" style="margin-left: 11%;">
                            <h3 class="mb-1" style="font-size: 19px;">{{waitingForDisburse}}</h3>
                            <span style="color:black;font-size: 13px;">Today Received</span>
                        </div>
                        <div class="media-right align-self-center">

                            <i class="ft-rotate-cw Secondary font-large-1 float-right" style="margin-right: 39%;"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-lg-6 col-12" (click)="FinalDisburse(7)" style="cursor: pointer;">
        <div class="TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="">
                    <div class="media">
                        <div class="media-body text-left" style="margin-left: 11%;">
                            <h3 class="mb-1" style="font-size: 19px;">{{finalDisbursList}}</h3>
                            <span style="color:black;font-size: 13px;">Advance Received</span>
                        </div>
                        <div class="media-right align-self-center">
                            <i class="ft-check-circle  Secondary font-large-1 float-right" style="margin-right: 39%;"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div> -->

<!-- ---------------------------------------------------------------------------------------- -->

<div class="row match-height mt-2" style="margin-left:-1px;">
    <div class="col-md-3 col-12 mr-1" (click)="TodayReceived()" style="background-color:#FFFFFF!important;cursor: pointer;">

        <div class=" text-center pb-0 mt-1">
            <span class="font-medium-2 warning">Today Received</span>
            <h4 class="font-large-0 mt-2">{{todayReceived}}</h4>
        </div>


    </div>
    <div class="col-md-3 col-12 mr-1" (click)="AdvaceReceived()" style="background-color:#FFFFFF!important;cursor: pointer;">

        <div class=" text-center pb-0 mt-1">
            <span class="font-medium-2 info ">Advance Received</span>
            <h4 class="font-large-0 mt-2">{{advanceAmount}}
            </h4>
        </div>


    </div>
    <div class="col-md-3 col-12" (click)="TodaySettlement()"  style="background-color:#FFFFFF!important;cursor: pointer;">

        <div class="text-center pb-0 mt-1">
            <span class="font-medium-1 danger">Today Settlement</span>
            <h3 class="font-large-0 mt-2">{{todaySettlement}}
            </h3>
        </div>


    </div>
</div>





<!-- Cash Flow Chart Starts -->

<div class="row mt-2" style="margin-left: -1px; margin-right: -1px;">
    <div class="col-sm-6" style="background-color: #FFFFFF;margin: 15px 0;">
        <div class="">
            <div class="mt-3">
                <h5 class="text-center">Loan & Deposit Statistics</h5>
            </div>
            <div class="card-content">
                <div class="card-body">
                    <div class="chart-info mb-2">
                        <span class="text-uppercase mr-3"><i style="color:#191970;" class="fa fa-circle  font-small-2 mr-1"></i> Loan</span>
                        <span class="text-uppercase"><i style="color:#C71585;" class="fa fa-circle font-small-2 mr-1"></i> Deposit</span>
                    </div>
                    <div id="line-area2" class="lineArea2">
                        <x-chartist class="height-200" [data]="lineArea2.data" [type]="lineArea2.type"
                            [options]="lineArea2.options" [responsiveOptions]="lineArea2.responsiveOptions"
                            [events]="lineArea2.events">
                        </x-chartist>
                    </div>
                </div>
            </div>
        </div>


    </div>
    <div class="col-sm-6" style="background-color: #FFFFFF;margin: 15px 0;">
        <div class="">
            <div class="mt-3">
                <h5 class="text-center">Account Info</h5>
            </div>
            <div class="card-content">
                <div class="card-body">
                    <ul class="list-group" style="color: black">
                        <li class="list-group-item" [routerLink]="'/loan-application/loan-list'">
                            <span class="badge bg-success float-right" style="width: 80px!important;">{{LoanApplication}}</span>Loan Account
                        </li>
                        <li class="list-group-item" [routerLink]="'/fdr-select/fdr-list'">
                            <span class="badge bg-success float-right" style="width: 80px!important;">{{FdrAccount}}</span>FDR Account
                        </li>
                        <li class="list-group-item" [routerLink]="'/dps-account/dps-list'">
                            <span class="badge bg-success float-right" style="width: 80px!important;">{{dpsAccount}}</span>DPS Account
                        </li>
                    </ul>
                </div>
            </div>
        </div>


    </div>



</div>













<div class="row" style="margin-left: -1px; margin-right: -1px;">
    <div class="col-sm-6" style="background-color: #FFFFFF; margin: 15px 0;">
        <div class="">
            <div class="mt-3">
                <h5 class="text-center">Cash Flow</h5>
            </div>

                <div class="card-body">

 <!--    <div class="row">

                <div class="col-xl-3 col-lg-6 col-12" style="cursor: pointer;height: 50%;">
                    <div class="" style="background-color: #FFFFFF;margin: 15px 0;">
                        <div class="card-content">
                            <div>
                                <div class="media">
                                    <div class="media-body" style="margin-left: auto; margin-right: auto; text-align: center;">
                                        <span style="color:black;font-size: 13px;">Beginning Cash on Hand</span>
                                        <h3 class="mb-1" style="font-size: 19px;">{{beginningcashonhand}}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-2 col-lg-6 col-12" style="cursor: pointer;">
                    <div class="" style="background-color: #FFFFFF;margin: 15px 0;">
                        <div class="card-content">
                            <div class="">
                                <div class="media">
                                    <div class="media-body text-center">
                                        <span style="color:black;font-size: 13px;">Cash going on</span>
                                        <h3 class="mb-1" style="font-size: 19px;">{{cashgoingon}}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-2 col-lg-6 col-12" style="cursor: pointer;">
                    <div class="" style="background-color: #FFFFFF;margin: 15px 0;">
                        <div class="card-content">
                            <div class="">
                                <div class="media">
                                    <div class="media-body text-center">
                                        <span style="color:black;font-size: 13px;">Cash going out</span>
                                        <h3 class="mb-1" style="font-size: 19px;">{{cashgoingout}}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-2 col-lg-6 col-12" style="cursor: pointer;">
                    <div class="" style="background-color: #FFFFFF;margin: 15px 0;">
                        <div class="card-content">
                            <div class="">
                                <div class="media">
                                    <div class="media-body text-center">
                                        <span style="color:black;font-size: 13px;">Profit/Loss</span>
                                        <h3 class="mb-1" style="font-size: 19px;">{{profitloss}}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div class="col-xl-3 col-lg-6 col-12"  style="cursor: pointer;">
                    <div class="" style="background-color: #FFFFFF;margin: 15px 0;">
                        <div class="card-content">
                            <div class="">
                                <div class="media">
                                    <div class="media-body text-center">
                                        <span style="color:black;font-size: 13px;">Ending Cash on Hand</span>
                                        <h3 class="mb-1" style="font-size: 19px;">{{endingcashonhand}}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div> -->












                    <div id="bar-bar1" class="height-400">
                        <canvas baseChart class="chart"
                                [datasets]="CashflowbarChartData"
                                [labels]="CashflowbarChartLabels"
                                [options]="CashflowbarChartOptions"
                                [colors]="CashflowbarChartColors"
                                [legend]="CashflowbarChartLegend"
                                [chartType]="CashflowbarChartType"
                                style="width: 100%; max-width: 100%;">
                        </canvas>
                    </div>

            </div>
        </div>
    </div>
    <div class="col-sm-6" style="background-color: #FFFFFF;margin: 15px 0;">
        <div class="">
            <div class="mt-3">
                <h5 class="text-center">Profit/Loss</h5>
            </div>
            <div class="card-content">
                <div class="card-body">
                    <div id="bar-bar1" class="height-400">
                        <canvas baseChart class="chart" [datasets]="PLbarChartData" [labels]="PLbarChartLabels"
                            [options]="PLbarChartOptions" [colors]="PLbarChartColors" [legend]="PLbarChartLegend"
                            [chartType]="PLbarChartType"></canvas>
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>

<div  class="row" style="margin-left: -1px; margin-right: -1px;">
    <div class="col-sm-6" style="background-color: #FFFFFF;margin: 15px 0;">
        <div class="">
            <div class="mt-3">
                <h5 class="text-center">Monthly Loan & DPS Collection Information</h5>
            </div>
            <div class="card-content">
                <div class="card-body">
                    <div id="bar-bar1" class="height-400">
                        <canvas baseChart class="chart" [datasets]="barChartData" [labels]="barChartLabels"
                            [options]="barChartOptions" [colors]="barChartColors" [legend]="barChartLegend"
                            [chartType]="barChartType" chartHover="chartHovered($event)"
                            (chartClick)="chartClicked($event)"></canvas>
                    </div>
                </div>
            </div>
        </div>


    </div>


    <div class="col-sm-6" style="background-color: #FFFFFF!important; margin: 15px 0;">
      <h5 class="text-center mt-3">Loan Payment Status</h5>
      <div class="">
         <!--  <div class="card-body">
              <div class="row mb-3">
                  <div class="col">
                      <span class="mb-1 text-muted d-block" style="color: black !important;"> {{allLoanStatus[0]?.regularLoan}}% - Regular</span>
                      <div class="progress" style="height: 5px;"></div>
                  </div>
                  <div class="col">
                      <span class="mb-1 text-muted d-block" style="color: black !important;">{{allLoanStatus[0]?.advanceLoan}}% - Advance</span>
                      <div class="progress" style="height: 5px;"></div>
                  </div>
              </div>
              <div class="row mb-2">
                  <div class="col">
                      <span class="mb-1 text-muted d-block" style="color: black !important;">{{allLoanStatus[0]?.osLoan}}% - OS</span>
                      <div class="progress" style="height: 5px;"></div>
                  </div>
                  <div class="col">
                      <span class="mb-1 text-muted d-block" style="color: black !important;">{{allLoanStatus[0]?.odLoan}}% - Overdue</span>
                      <div class="progress" style="height: 5px;"></div>
                  </div>
              </div>
          </div> -->
         <!--  <div id="donut-dashboard-chart" class="donut">
              <x-chartist class="height-250" [data]="DonutChart.data" [type]="DonutChart.type"
                  [options]="DonutChart.options" [responsiveOptions]="DonutChart.responsiveOptions"
                  [events]="DonutChart.events">
              </x-chartist>
          </div> -->


          <div id="donut-dashboard-chart" class="height-250 mt-5">
              <canvas baseChart class="chart" [data]=" LoanPaymentdoughnutChartData"
                  [labels]=" LoanPaymentdoughnutChartLabels" [options]="LoanPaymentdoughnutChartOptions"
                  [chartType]="LoanPaymentdoughnutChartType" [colors]="LoanPaymentdoughnutChartColors"
                  chartHover="LoanPaymentchartHovered($event)"></canvas>
          </div>

      </div>
  </div>












</div>

<div  class="row" style="margin-left: -1px; margin-right: -1px;">
  <div class="col-sm-6" style="background-color: #FFFFFF;margin: 15px 0;">
      <div class="">
          <div class="mt-3">
              <h5 class="text-center">Accounts Payable</h5>
          </div>
          <div class="card-content">
            <div class="card-body">
              <h3 class="mb-1 text-center" style="font-size: 25px;">{{totalPayable}}</h3>
              <div id="bar-doughnut" class="height-250">
                  <canvas baseChart class="chart" [data]="APdoughnutChartData"
                      [labels]="APdoughnutChartLabels" [options]="APdoughnutChartOptions"
                      [chartType]="APdoughnutChartType" [colors]="APdoughnutChartColors"
                      ></canvas>
              </div>
          </div>
          </div>
      </div>


  </div>


  <div class="col-sm-6" style="background-color: #FFFFFF!important; margin: 15px 0;">
    <h5 class="text-center mt-3">Total Amount Receivable</h5>
    <div class="">


      <div class="card-body"><h3 class=" text-center" style="font-size: 25px;"></h3>
        <div id="bar-doughnut" class="height-250 mt-4">
            <canvas baseChart class="chart" [data]="doughnutChartData"
                [labels]="doughnutChartLabels" [options]="doughnutChartOptions"
                [chartType]="doughnutChartType" [colors]="doughnutChartColors"
                chartHover="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
        </div>
    </div>

    </div>
</div>












</div>





<div class="row match-height">
    <div class="col-xl-4 col-lg-12 col-12 d-none">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title mt-3">Statistics</h4>
            </div>
            <div class="card-content">
                <div class="card-body">
                    <p class="font-medium-2 text-center my-2">Loan Information</p>
                    <!-- <div id="Stack-bar-chart" class="Stackbarchart mb-2">
                        <x-chartist class="height-300" [data]="Stackbarchart.data" [type]="Stackbarchart.type"
                            [options]="Stackbarchart.options" [responsiveOptions]="Stackbarchart.responsiveOptions"
                            [events]="Stackbarchart.events">
                        </x-chartist>
                    </div>  -->
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="col-xl-12 col-lg-12 col-12">
        <div class="card shopping-cart">
            <div class="card-header pb-2">
                <h4 class="card-title mb-1">Account Details</h4>
            </div>
            <div class="card-content">
                <div class="card-body p-0">
                    <div class="table-responsive">
                        <table class="table text-center m-0 table-borderless">
                            <thead>
                                <tr>
                                    <th>Account Type</th>
                                    <th>Account Name</th>
                                    <th>Account No</th>
                                    <th>Amount</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of customerList">
                                    <td>{{item.accountType}}</td>
                                    <td>{{item.accountTilte}}</td>
                                    <td>{{item.customerAccountNo}}</td>
                                    <td>{{item.openingBalance}}</td>
                                    <td>
                                        <button class="btn" (click)="PrintKyc()"
                                            title="Click here for download pdf">View PDF</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>

<!--Line with Area Chart 1 Starts-->
<div class="row">
    <!-- <div class="col-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Loan Details</h4>
            </div>
            <div class="card-content">
                <div class="card-body">
                    <div class="chart-info mb-3 ml-3">
                        <span class="gradient-purple-bliss d-inline-block rounded-circle mr-1"
                            style="width:15px; height:15px;"></span>
                        <span></span>
                        <span class="gradient-mint d-inline-block rounded-circle mr-1 ml-2"
                            style="width:15px; height:15px;"></span>
                        <span></span>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- <div class="col-xl-12 col-lg-12 col-12">
        <div class="card shopping-cart">
            <div class="card-header pb-2">
                <h4 class="card-title mb-1">Loan Details</h4>
            </div>
            <div class="card-content">
                <div class="card-body p-0">
                    <div class="table-responsive">
                        <table class="table text-center m-0 table-borderless">
                            <thead>
                                <tr>
                                    <th>Account Type</th>
                                    <th>Account Name</th>
                                    <th>Account No</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of customerLoan">
                                    <td>{{item.accountType}}</td>
                                    <td>{{item.accountTilte}}</td>
                                    <td>{{item.customerAccountNo}}</td>
                                    <td>{{item.openingBalance}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>




<!--
<div class="row text-left">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Line Chart</h4>
            </div>
            <div class="card-content">
                <div class="card-body">
                    <div id="line-chart3" class="">
                        <x-chartist class="height-400" [data]="lineChart3.data" [type]="lineChart3.type" [options]="lineChart3.options" [responsiveOptions]="lineChart3.responsiveOptions"
                            [events]="lineChart3.events">
                        </x-chartist>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

