import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EKycRoutingModule } from './e-kyc-routing.module';
import { NidVerifyComponent } from './nid-verify/nid-verify.component';
import { OtpVerifyComponent } from './otp-verify/otp-verify.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutoRegistrationComponent } from './auto-registration/auto-registration.component';
import { LoanEkycComponent } from './loan-ekyc/loan-ekyc.component';


@NgModule({
  declarations: [
    NidVerifyComponent,
    OtpVerifyComponent,
    AutoRegistrationComponent,
    LoanEkycComponent
  ],
  imports: [

    FormsModule,ReactiveFormsModule,
    CommonModule,
    EKycRoutingModule
  ],
  exports: [NidVerifyComponent,LoanEkycComponent]
})
export class EKycModule { }
