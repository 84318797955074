import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NidverificationRoutingModule } from './nidverification-routing.module';
import { NidListComponent } from './nid-list/nid-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';


@NgModule({
  declarations: [
    NidListComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NidverificationRoutingModule,
    HttpClientModule
  ]
})
export class NidverificationModule { }
