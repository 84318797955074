
<br>
<h4 class="card-title" style="color: black">Process Account Balance</h4>
<hr>
<div class="card">
    <div class="card-body">
        <div class="form-group row">
           <!-- <label for="type" class="col-md-1">Type:</label>
           <div class="col-2">
               <select type="text" class="form-control"  [(ngModel)]="types">
                    <option value="">Select</option>   
                    <option value="Deposit">Deposit</option>
                    <option value="FDR">FDR</option>
                    <option value="DPS">DPS</option>
                    <option value="Loan">Loan</option>
               </select>
           </div> -->

            <label for="dateInput" class="col-md-2">From Date:</label>
            <div class="col-md-2 input-group">
                <input type="date" [(ngModel)]="fromDate" class="form-control" > 
            </div>

            <label for="dateInput" class="col-md-2">To Date:</label>
            <div class="col-md-2 input-group">
                <input type="date" [(ngModel)]="toDate" class="form-control">
            </div>

            <div class="col-2">
                <button type="button" class="btn btn-primary" (click)="GetBatchProcessMonitorSummary()" id="processButton">Find</button>
            </div>
        </div>
        <hr>
        <div class="row">
            <table class="table table-bordered table-hover" style="width: 100%;">
                <thead>
                    <tr>
                        <th style="text-align: center; color: black;">Effective Date</th>
                        <th style="text-align: center; color: black;">Name</th>
                        <th style="text-align: center; color: black;">Success Data</th>
                        <th style="text-align: center; color: black;">Failed Data</th>
                      </tr>
                </thead>
                <tbody style="background-color: white;">
                    <tr *ngFor="let item of processSummray">
                        <td style="text-align: center; color: black;">{{ item.effectiveDate | date:'yyyy-MM-dd' }}</td>
                        <td style="text-align: center; color: black;">{{ item.name }}</td>
                        <td style="text-align: center; color: black;">{{ item.successData }}</td>
                        <td style="text-align: center; color: black;">{{ item.failedData }}</td>
                      </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>