import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { DashboardCustomService } from "app/dashboard/dashboard-custom.service";
import { FdrdetailsService } from "app/fdr-account/fdrdetails.service";
import { PdfService } from "app/pdf-reporting/pdf.service";
import { SharedService } from "app/services/shared.service";

@Component({
  selector: 'app-today-missed-payment',
  templateUrl: './today-missed-payment.component.html',
  styleUrls: ['./today-missed-payment.component.scss']
})
export class TodayMissedPaymentComponent implements OnInit {

  dpsmissedpaymnetList: any[] = [];

  constructor(
    private pdfService: PdfService,
    private _fdrService: FdrdetailsService,
    private _serviceDashboard: DashboardCustomService,
    private changeDetect: ChangeDetectorRef,
    private shared: SharedService
  ) {}

  ngOnInit(): void {
    this.GetAllDPSEncashment();
  }

  GetAllDPSEncashment() {
    this._serviceDashboard
      .GetAllDPSMissedPaymentByBusinessDate()
      .subscribe((res) => {
        if (res.length > 0) {
          this.dpsmissedpaymnetList = res;

          this.changeDetect.detectChanges();
        }
      });
  }

  PrintKyc(id: number) {
    console.log(id);
    this.pdfService.OpenKycPdf(id).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    });
  }

  DPSSchedulePDF(accountId: number) {
    this.pdfService.DPSOpenSchedulePdf(accountId).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }

  Approve(id: number) {
    this._serviceDashboard.ApproveDPSEncashment(id).subscribe((res) => {
      if (res != null) {
        this.shared.ShowAlert("Success", "Approved Successful", "success");
        this.GetAllDPSEncashment();
        this.changeDetect.detectChanges();
      }
    });
  }


}
