import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-half-yearly-close',
  templateUrl: './half-yearly-close.component.html',
  styleUrls: ['./half-yearly-close.component.scss']
})
export class HalfYearlyCloseComponent implements OnInit {

  businessDate: any = Date;
  interval: any;

  constructor() { }

  ngOnInit(): void {
  }

 
  InterestProcess(){
    const year = this.businessDate.year;
    const month = ('0' + (this.businessDate.month + 1)).slice(-2);
    const day = ('0' + this.businessDate.day).slice(-2);

    this.businessDate = `${year}-${month}-${day}`;
    
    
    // this.cobService.processInterest(this.businessDate).subscribe(res => {
     
    // }, err => {
      
    // })
  }

}
