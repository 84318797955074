import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
const apiUrl=environment.baseUrl + '/api/Charge/'
@Injectable({
  providedIn: 'root'
})
export class ChargeService {

  constructor(private http:HttpClient) { }

  
  //#region Commission
  GetAllChargeList():Observable<any>{
    return this.http.get(apiUrl+ 'GetAllChargeList')
  }
  //#region Commission
  GetAllChargeListByProBuilderType(id:number):Observable<any>{
    return this.http.get(apiUrl+ 'GetAllChargeListByProBuilderType?id='+id)
  }
  getListWithSerchAndPagination(currentPage: any, itemsPerPage: any, searchValue: string) {
    return this.http.get(apiUrl + 'GetChargeListWithSerchAndPagination?page='+ currentPage + '&pageSize=' +itemsPerPage + '&search=' + searchValue);
  }
  GetChargeById(id:number){
    return this.http.get(apiUrl + 'GetChargeById')
  }
  
  SaveCharge(model:any){
    return this.http.post(apiUrl + 'SaveCharge',model)
  }
  
  DeleteCharge(id:any):Observable<any>{
    return this.http.delete(apiUrl + 'DeleteCharge/' + id)
  }
  //#endregion
  
}
