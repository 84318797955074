import { RouteInfo } from "./vertical-menu.metadata";


export const ROUTES: RouteInfo[] = [
  // {
  //   path: "/user",title: "User",icon: "ft-user",class: "",badge: "",badgeClass: "",isExternalLink: false,submenu: [],
  // },
  
  // {
  //   path: "/employeeregister/employeeregister",title: "Employee-Register",icon: "ft-user",class: "",badge: "",badgeClass: "",isExternalLink: false,submenu: [],
  // },

  // {
  //   path: 'customer',title: 'Customer', icon: 'ft-align-left',class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',isExternalLink: false,
  //   submenu: [
  //     { path: '/customer/list',title: 'Customer-list',icon: 'ft-arrow-right submenu-icon', class: '', badge: '',badgeClass: '',isExternalLink: false, 
  //         submenu: [] },
          
  //     // { path: '/customer/create',title: 'Customer-create',icon: 'ft-arrow-right submenu-icon',class: '', badge: '', badgeClass: '',isExternalLink: false,
  //     //     submenu: []   }
  //   ]
  // },
  // {
  //   path: '', title: 'Product', icon: 'ft-edit', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-primary float-right mr-1 mt-1', isExternalLink: false,
  //   submenu: [
  //     {path: '/product/product-builder', title: 'Product Builder', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //     {
  //       path: '', title: 'Product Builder', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
  //       submenu: [
  //         { path: '/products/taxkey', title: 'tax-key', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //         { path: '/products/exciseduty', title: 'Excise Duty', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
         
  //         { path: '/products/interest-calculation-frequency', title: 'Frequency Calculation', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //         { path: '/products/interest-calculation-type', title: 'Interest calculation Type', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
             
  //          ]
  //     },
  //     { path: '/balancetype/balancetype',title: 'Balance-Type',icon: 'ft-arrow-right submenu-icon', class: '', badge: '',badgeClass: '',isExternalLink: false,  submenu: [] }, 
  //   ]
  // },

  // {
  //   path: 'occupation',title: 'MasterData',icon: 'ft-align-left',class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',isExternalLink: false,
  //   submenu: [
  //     {
  //       path: '', title: 'Prduct', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
  //       submenu: [
  //         { path: '/forms/inputs', title: 'Prduct Builder', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
           
  //       ]
  //     },
  //       { path: '/occupation/occupation',title: 'Occupation',icon: 'ft-arrow-right submenu-icon', class: '', badge: '',badgeClass: '',isExternalLink: false, 
  //       submenu: [] },         
  //       { path: '/incomesource/incomesource',title: 'Income-Source',icon: 'ft-arrow-right submenu-icon', class: '', badge: '',badgeClass: '',isExternalLink: false, submenu: [] }, 
  //       { path: '/country/country',title: 'Country',icon: 'ft-arrow-right submenu-icon', class: '', badge: '',badgeClass: '',isExternalLink: false, 
  //       submenu: [] }, 
  //       { path: '/division/division',title: 'Division',icon: 'ft-arrow-right submenu-icon', class: '', badge: '',badgeClass: '',isExternalLink: false, submenu: [] }, 
  //       { path: '/district/district',title: 'District',icon: 'ft-arrow-right submenu-icon', class: '', badge: '',badgeClass: '',isExternalLink: false, submenu: [] }, 
  //       { path: '/upazila/upazila',title: 'Upazila',icon: 'ft-arrow-right submenu-icon', class: '', badge: '',badgeClass: '',isExternalLink: false, submenu: [] },  
  //       { path: '/fiscalyear/fiscalyear',title: 'FiscalYear',icon: 'ft-arrow-right submenu-icon', class: '', badge: '',badgeClass: '',isExternalLink: false, submenu: [] },
  //       { path: '/role/role',title: 'Role',icon: 'ft-arrow-right submenu-icon', class: '', badge: '',badgeClass: '',isExternalLink: false, submenu: [] },
  //       { path: '/userrole/userrole',title: 'User Role',icon: 'ft-arrow-right submenu-icon', class: '', badge: '',badgeClass: '',isExternalLink: false, submenu: [] },



  //   ]

    
  // },
 

  

];
