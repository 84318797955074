import { Pipe, PipeTransform } from '@angular/core';
import { LanguageServiceService } from 'app/services/language-service.service';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {
  constructor(private languageService: LanguageServiceService) {}

  transform(value: string, args?: any): string {
    let languageFile;
    this.languageService.language$.subscribe(language => {
      languageFile = language === 'bn' ? '/assets/i18n/bn.json' : '/assets/i18n/en.json';
    });

    if (languageFile && languageFile[value]) {
      return languageFile[value];
    }

    return value;
  }

}
