import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ScoretagService } from '../scoretag.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'app/services/shared.service';
import Swal from 'sweetalert2';
import { Location } from "@angular/common";

@Component({
  selector: "app-bank-beneficiar-list",
  templateUrl: "./bank-beneficiar-list.component.html",
  styleUrls: ["./bank-beneficiar-list.component.scss"],
})
export class BankBeneficiarListComponent implements OnInit {
  list: any = [];
  beneficiaryList: any = [];
  model: any = {};
  isEdit: boolean = false;
  bankForm: FormGroup;
  loadData: any;
  currentPage = 1;
  itemsPerPage = 10;
  totalItems = 0;
  currentItems = 0;
  searchValue = "";

  constructor(
    private _scoreService: ScoretagService,
    private router: Router,
    private shared: SharedService,
    private formBuilder: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private activeRoute: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.getBeneficiaryList();
    this.buildForm();
    this.getBeneficiaryAccountList();
  }
  getBeneficiaryList() {
    this._scoreService.GetBankBeneficiaryAccountNo().subscribe((data) => {
      this.list = data;
      this.changeDetectorRef.detectChanges;
    });
    this.changeDetectorRef.detectChanges;
  }

  getBeneficiaryAccountList() {
    this._scoreService
      .GetBankBeneficiaryAccountListWithSearchAndPagination(
        this.currentPage,
        this.itemsPerPage,
        this.searchValue
      )
      .subscribe((res: any) => {
        this.beneficiaryList = res.data;
      });
  }

  buildForm() {
    this.bankForm = this.formBuilder.group({
      id: [0],
      bankInfoId: [""],
      branchId: [""],
      AccountName: [""],
      AccountNumber: [""],
      status: [""],
    });
  }
  edit(id: number) {
    this.router.navigate(["/score/bank-beneficiar-create", id]);
    this.isEdit = true;
  }

  delete(id: any) {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to Delete this info!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete!",
    }).then((res) => {
      if (res.isConfirmed) {
        this._scoreService.DeleteBankBeneficiar(id).subscribe((data) => {
          this.shared.ShowAlert(
            "Success",
            "Data deleted successfully.",
            "warning"
          );
          this.getBeneficiaryList();
        });
        this.changeDetectorRef.detectChanges;
      } else if (res.isDismissed) {
        this.router.navigate(["/accounting/own-account-fund-transfer"]);
      }
    });
  }
  goBack() {
    this.location.back();
  }
}
