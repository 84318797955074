import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NavbarsService } from '../navbars.service';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'app/services/shared.service';

@Component({
  selector: 'app-user-exception-log',
  templateUrl: './user-exception-log.component.html',
  styleUrls: ['./user-exception-log.component.scss']
})

export class UserExceptionLogComponent implements OnInit {
  checkedUserMenu: any[] = [];
  checkedUserMenuInActive: any[] = [];
  userTypeId: string= '';
  userId: string = '';
  userRoles: any[];
  userMenu: any[];
  userList: any[];
  isToggled: boolean = false;

  constructor(
    private http: HttpClient,
    private _service:NavbarsService,
    private formBuilder: FormBuilder,
     private router: Router,
     private activeRoute: ActivatedRoute,
     private shared: SharedService
    ) { }

  ngOnInit(): void {
   // this.getUserRole();
    this.getUserMenuAccess();
    this.getUserListInfo();

  }
  toggle() {
    
    const checkbox = document.getElementById('toggleSwitch') as HTMLInputElement;
     if (this.userTypeId =='') {
      alert('Please select User and Role.');
      checkbox.checked = !checkbox.checked;
      return;
   }

    this.isToggled = !this.isToggled;
    this.userMenu = [];
    if( this.isToggled == true){
      //alert(this.isToggled);
     this.getUserInActiveNavbarList();
    }else{
     // alert(this.isToggled);
      this.getUserMenuAccess();
    }
   

    
  }




  getUserListInfo() {
    this._service.getUserListInfo().subscribe((res) => {
        this.userList = res;
    });
  }
  getUserWiseRole() {
    if (!this.userId) {
      this.userRoles = [];
      return;
    }

    this.userRoles = [];
    this._service.getUserRoalByUserId(this.userId)
       .subscribe((data) => {
        this.userTypeId = '';
        this.userMenu = [];
        if (data.length > 0) {
          this.userRoles = data;
          this.userTypeId = data[0].id;
          this.getUserMenuAccess();
        }else{
          
          this.userMenu = [];
          return;
        }
        
      

    });


  }

  getUserInActiveNavbarList() {
    if (!this.userTypeId) {
      this.userMenu = [];
      return;
    }
    this.checkedUserMenu = [];
    this._service.GetUserInActiveNavbarList(this.userTypeId,this.userId)
      .subscribe((data) => {
        this.userMenu = data;
        console.log(data)

        data.forEach(menu => {
          if(menu.isActive ==1){
            this.checkedUserMenu.push(menu);
          }
            menu.submenu.forEach(submenu => {
              if(submenu.isActive ==1){
                this.checkedUserMenu.push(submenu);
              }
                submenu.submenu.forEach(child => {
                  if(child.isActive ==1){
                    this.checkedUserMenu.push(child);
                  }
                });
            });
        });




       
        
    });

  }


  getUserMenuAccess() {
    if (!this.userTypeId) {
      this.userMenu = [];
      return;
    }
    this.checkedUserMenuInActive = [];
    this._service.GetUserActiveNavbarList(this.userTypeId,this.userId)
      .subscribe((data) => {
        this.userMenu = data;

       // this.checkedUserMenuInActive = [];
        data.forEach(menu => {
          if(menu.isActive==1){
            this.checkedUserMenuInActive.push(menu);
          }
            menu.submenu.forEach(submenu => {
              if(submenu.isActive==1){
                this.checkedUserMenuInActive.push(submenu);
              }
                submenu.submenu.forEach(child => {
                  if(child.isActive==1){
                    this.checkedUserMenuInActive.push(child);
                  }
                });
            });
        });



        
    });

  }


  saveUserMenuAccess() {
    if (!this.userTypeId) {
      return;
    }
    // if (this.checkedUserMenu.length === 0) {
    //   alert('Please select at least one menu item.');
    //   return;
    // }

    const selectedMenuIds = this.checkedUserMenu.map((menu) => menu.id);

    this._service.saveUserExceptionLog(this.userId,this.userTypeId, selectedMenuIds).subscribe(
      () => {
        this.shared.ShowAlert('Success', 'Save Successfully', 'success');
      },
      (error) => {
        this.shared.ShowAlert('Warning', 'Unable to Save. Please Try Again', 'warning');
      }
    );
  }


  saveInactiveUserMenuAccess() {
    if (!this.userTypeId) {
      return;
    }
    

    const selectedMenuIds = this.checkedUserMenuInActive.map((menu) => menu.id);

    this._service.saveInactiveUserExceptionLog(this.userId,this.userTypeId, selectedMenuIds).subscribe(
      () => {
        this.shared.ShowAlert('Success', 'Save Successfully', 'success');
      },
      (error) => {
        this.shared.ShowAlert('Warning', 'Unable to Save. Please Try Again', 'warning');
      }
    );
  }




 


  onCheckboxChange(event: Event, id) {
    const checkedMenu = event.target as HTMLInputElement;
    const menuItem = this.findMenuItemById(this.userMenu, id);
   
  
    if (checkedMenu.checked) {

      if (menuItem && !this.checkedUserMenu.some((checkedItem) => checkedItem.id === id)) {
        this.checkedUserMenu.push(menuItem);
      }
    
    } 
    else {
     
      const index = this.checkedUserMenu.findIndex((checkedItem) => checkedItem.id === id);
      if (index !== -1) {
        this.checkedUserMenu.splice(index, 1);
      }
  
    }


    if (checkedMenu.checked) {
      if (menuItem && !this.checkedUserMenuInActive.some((checkedItem) => checkedItem.id === id)) {
        this.checkedUserMenuInActive.push(menuItem);
      }
      
       
    } 
    else {
     
      const index = this.checkedUserMenuInActive.findIndex((checkedItem) => checkedItem.id === id);
      if (index !== -1) {
        this.checkedUserMenuInActive.splice(index, 1);
      }
    }

  }


  findMenuItemById(menuItems, itemId ) {
    for (const menuItem of menuItems) {
      if (menuItem.id === itemId) {
        return menuItem;
      }
      if (menuItem.submenu && menuItem.submenu.length > 0) {
        const foundItem = this.findMenuItemById(menuItem.submenu, itemId);
        if (foundItem) {
          return foundItem;
        }
      }
    }
    return null;
  }







}
