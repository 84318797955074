import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import Swal from "sweetalert2";
import { ActivatedRoute, Router } from "@angular/router";
import { LedgerService } from "../service/ledger.service";
import { SharedService } from "app/services/shared.service";

@Component({
  selector: "app-sub-ledger-entry",
  templateUrl: "./sub-ledger-entry.component.html",
  styleUrls: ["./sub-ledger-entry.component.scss"],
})
export class SubLedgerEntryComponent implements OnInit {
  isEdit: boolean = false;
  frmGroup: FormGroup;
  ledgerlist: any[] = [];
  accountCodes: any;

  MainLedgerList: any[] = [];
  model: any = {};
  subLedger: FormGroup;
  subLedgerlist: any[] = [];

  showSaveForm: string = "";

  itemId: any = 0;
  ledgerRelLit: any[] = [];
  ledgId: any;
  constructor(
    private _Ledgerservice: LedgerService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private formBuilder: FormBuilder,
    private shared: SharedService,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getSubLedgerandSubLedgerCode();
    this.populateForm();
    this.populateSubLedgerForm();
    this.showSaveForm = "saveList";
    this.getAllSubLedger();
  }

  onClickShowSaveForm(value: string) {
    this.showSaveForm = value;
    this.getSubLedgerandSubLedgerCode();
    this.reset();
  }
  populateForm() {
    this.frmGroup = this.formBuilder.group({
      id: [0],
      accountCodes: [""],
      accountNames: [""],
    });
  }

  populateSubLedgerForm() {
    this.subLedger = this.formBuilder.group({
      ledgerId: [0],
      accountCode: [""],
      accountName: [""],
    });
  }

  getAllSubLedger() {
    this._Ledgerservice.GetAllSubLedger().subscribe((res) => {
      this.subLedgerlist = res;
      this.changeDetectorRef.detectChanges();
    });
  }

  getSubLedgerandSubLedgerCode() {
    this._Ledgerservice.GetSubLedgerCodeAndSubLedgerOf().subscribe((res) => {
      this.ledgerlist = res.ledgers;
      this.frmGroup.patchValue({
        accountCodes: res["accountCode"],
      });
      this.changeDetectorRef.detectChanges();
    });
  }

  handleButtonClick(item: any, index: number): void {
    this._Ledgerservice.GetAllSubLedgerbyLId(this.itemId).subscribe((res) => {
      const matchedItem = res.find(
        (ledger: any) => ledger.ledgerId === item.ledgerId
      );

      if (matchedItem) {
        this.deleteParticular(matchedItem.id);
      } else {
        this.removeParticular(index);
      }
    });
  }

  AddParticular() {
    const selectedLedger = this.ledgerlist.find(
      (ledger) => ledger.id === parseInt(this.subLedger.value.ledgerId)
    );
    if (selectedLedger) {
      const alreadyExists = this.MainLedgerList.some(
        (ledger) => ledger.ledgerId === selectedLedger.id
      );

      if (alreadyExists) {
        Swal.fire({
          title: "Warning",
          text: "This sub-ledger is already added.",
          icon: "warning",
          confirmButtonColor: "#3085d6",
        });
      } else {
        this.MainLedgerList.push({
          ledgerId: selectedLedger.id,
          accountName: selectedLedger.accountName,
          accountCode: selectedLedger.accountCode,
        });

        this.populateSubLedgerForm();
      }
    }
  }

  removeParticular(index: number) {
    this.MainLedgerList.splice(index, 1);
  }

  onsubmit() {
    if (this.isEdit == true) {
      this.model.id = this.frmGroup.value.id;
    } else {
      this.model.id = 0;
    }
    this.model.accountNames = this.frmGroup.value.accountNames;
    this.model.accountCodes = this.frmGroup.value.accountCodes;

    this.model.subLedgerSource = this.MainLedgerList.map((item) => ({
      ledgerId: item.ledgerId,
      accountName: item.accountName,
      accountCode: item.accountCode,
    }));

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to save this sub-ledger Information!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Save sub-ledger!",
    }).then((res) => {
      if (res.isConfirmed) {
        this._Ledgerservice
          .saveSubLedger(this.model)
          .subscribe((res: number) => {
            if (res > 0) {
              this.shared.ShowAlert("Success", "Save Successfully", "success");
              //this.router.navigate(['/accounting/payment-voucher']);
            } else {
              this.shared.ShowAlert("Warning", "Failed to Save", "warning");
            }
            this.getAllSubLedger();
            this.reset();
            this.showSaveForm = "saveList";
            this.changeDetectorRef.detectChanges();
          });
      }
    });
  }

  edit(item: any) {
    this.isEdit = true;
    this.itemId = item.id;
    this.ledgId = item.id;
    this.showSaveForm = "saveForm";
    this.frmGroup.patchValue({
      id: item.id,
      accountCodes: item.accountCode,
      accountNames: item.accountName,
    });
    this.Updatedetails(this.itemId);
    this.changeDetectorRef.detectChanges();
  }

  Updatedetails(id: any) {
    this.isEdit = true;
    this._Ledgerservice.GetAllSubLedgerbyLId(id).subscribe((res) => {
      this.ledgerRelLit = res;

      this.MainLedgerList = [];
      this.ledgerRelLit.forEach((item: any) => {
        this.MainLedgerList.push({
          ledgerId: item.ledgerId,
          accountName: item.ledger.accountName,
          accountCode: item.ledger.accountCode,
        });
      });
    });
    this.changeDetectorRef.detectChanges();
  }

  deleteSubLedger(Id: number) {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this sub-ledger Information!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete sub-ledger!",
    }).then((res) => {
      if (res.isConfirmed) {
        this._Ledgerservice.DeleteSubLedgerRelationById(Id).subscribe((res) => {
          if (res === 1) {
            this.shared.ShowAlert("Deleted", "Delete Successfully", "success");
          } else {
            this.shared.ShowAlert("Warning", "Failed to delete", "warning");
          }

          this.getAllSubLedger();
          this.showSaveForm = "saveList";
          this.changeDetectorRef.detectChanges();
        });
      }
    });
  }

  deleteParticular(relId: number) {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this sub-ledger Information!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete sub-ledger!",
    }).then((res) => {
      if (res.isConfirmed) {
        this._Ledgerservice
          .DeleteLedgerRelationByRelId(relId)
          .subscribe((res) => {
            if (res === 1) {
              this.shared.ShowAlert(
                "Deleted",
                "Delete Successfully",
                "success"
              );
            } else {
              this.shared.ShowAlert("Warning", "Failed to delete", "warning");
            }

            // this.getAllSubLedger();
            this.showSaveForm = "saveForm";
            this.Updatedetails(this.itemId);
            this.changeDetectorRef.detectChanges();
          });
      }
    });
  }

  reset() {
    this.isEdit = false;
    this.populateForm();
    this.populateSubLedgerForm();
    this.MainLedgerList = [];
    this.model = {};
  }
}
