<div class="card" style="width: 100%;">
    <div class="card-body">
        <div class="container">
            <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveForm'">
                <div class="" style="margin-top: 3%;">
                    <h3 class="card-title" style="color: black; align-items: center;">Relation Info</h3>
                    <button type="button" data-toggle="tooltip" title="Add new"  class="btn btn-secondary" (click)="onClickShowSaveForm('saveList')" style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">List View</button>
                </div>
                <div class="card-body">
                    <form [formGroup]="relationForm">
                        <div class="row">
                            <div class="col-md-12">
                               <div class="form-group row">
                                <label for="name" class="col-sm-4">Name</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control"  formControlName="name" placeholder="Name">
                                </div>
                               </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                               <div class="form-group row">
                                <label for="nameBn" class="col-sm-4">Name Bangla</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control"  formControlName="nameBn" placeholder="Name Bangla">
                                </div>
                               </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                               <div class="form-group row">
                                <label for="sortOrder" class="col-sm-4">Sort Order</label>
                                <div class="col-sm-8">
                                    <input type="number" min="0" class="form-control" formControlName="sortOrder" placeholder="Sort Order">
                                </div>
                               </div>
                            </div>
                        </div>
                        <button data-toggle="tooltip" [disabled]="!relationForm.valid" (click)="onsubmit()" title="Save" type="button" class="btn btn-success" style="float:right; margin-top:5px;">Save </button>
                    </form>
                </div>
            </div>
            <div class="card" style="width: 100%;"  *ngIf="showSaveForm==='saveList'" >
                <div class="card-body">
                    <div class="container">
                        <h5 class="card-title" style="color: black">Relation List</h5>
                        <button type="button" data-toggle="tooltip" title="Add new"  class="btn btn-secondary" (click)="onClickShowSaveForm('saveForm')"style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">Add New</button>
                        <hr>
                        <div class="row">
                            <div class="col-md-6" style="text-align: left;">
                                <select type="number" class="form-control" style="width: 16%;" (change)="onChangeForShow($event.target.value)">
                                    <option value="5">5</option>
                                    <option value="10" selected>10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            <div class="form-group col-md-6" style="text-align: right;">
                                <input type="text" class="form-control"  [(ngModel)]="searchControl" placeholder="Search" (keyup)="onSearch()">
                            </div>
                        </div>
                        <table id="" class="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th>Sl. No</th>
                                    <th>Name</th>
                                    <th>Name Bangla</th>
                                    <th>Sort Order</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let item of List | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }; let i = index">
                                <tr>
                                    <td>{{ (currentPage - 1) * itemsPerPage + i + 1 }}</td>
                                    <td>{{item.name}}</td>
                                    <td>{{item.nameBn}}</td>
                                    <td>{{item.sortOrder}}</td> 
                                    <td>
                                        <button class="btn btn-primary" (click)="edit(item)" ><i class="fa fa-edit 3x"></i></button>
                                        <button class="btn btn-danger" style="margin-left: 5px;"  (click)="delete(item.id)"><i class="fa fa-trash-o 3x"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="row">
                            <div class="col-md-6" style="text-align: left">
                              <span>Showing {{currentItems}} of {{totalItems}} entries</span>
                            </div>
                            <div class="col-md-6" style="text-align: right">
                              <pagination-controls (pageChange)="onPageChange($event)"></pagination-controls>
                            </div>
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
