import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployeeRegisterComponent } from '../employee-component/employee-register/employee-register.component';
import { EmployeeRoutingModule } from '../employee-routing/employee-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createTranslateLoader } from 'app/app.module';
import { HttpClient } from '@microsoft/signalr';
import { NgxPaginationModule } from 'ngx-pagination';
import { EmployeeListComponent } from '../employee-component/employee-list/employee-list.component';



@NgModule({
  declarations: [EmployeeRegisterComponent, EmployeeListComponent],
  imports: [
    CommonModule,FormsModule,EmployeeRoutingModule,ReactiveFormsModule,NgxPaginationModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),

  ]
})
export class EmployeeModuleModule { }
