<form [formGroup]="frmGroup">
  <div class="col-md-12">
    <div class="card" style="width: 100%">
      <div class="card-header">
        <h5 class="card-title" style="color: black">DPS Immature Encashment</h5>
      </div>

      <div class="card-body" style="margin-top: 30px">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group row">
              <label for="customerCode" class="col-sm-4 col-form-label"
                >Account No</label
              >
              <div class="col-sm-8">
                <select
                  class="form-control"
                  name="customerCode"
                  formControlName="customerAccountId"
                  [(ngModel)]="accountNotFound1"
                  (change)="
                    GetCustomerDPSInfoForEncashment($event.target.value)
                  "
                >
                  <option value="">Select</option>
                  <ng-container *ngFor="let item of AccountNoList | async">
                    <option [value]="item.id">
                      {{ item.customerAccountNo }}
                    </option>
                  </ng-container>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label for="" class="col-sm-4 col-form-label"
                >Maturity Date</label
              >
              <div class="col-sm-8">
                <input
                  type="text"
                  style="text-align: right"
                  class="form-control"
                  formControlName="maturityDate"
                  [value]="maturityDate | date"
                  readonly
                />
              </div>
            </div>

            <div class="form-group row">
              <label for="" class="col-sm-4 col-form-label"
                >Total Payment</label
              >
              <div class="col-sm-8">
                <input
                  type="text"
                  style="text-align: right"
                  class="form-control"
                  formControlName="totalPayment"
                  [value]="totalPayment | number"
                  readonly
                />
              </div>
            </div>

            <div class="form-group row d-none">
              <label for="" class="col-sm-4 col-form-label"
                >Total Receivable</label
              >
              <div class="col-sm-8">
                <input
                  type="text"
                  style="text-align: right"
                  class="form-control"
                  formControlName="totalReceivableAmount"
                  [value]="totalReceivableAmount | number"
                  readonly
                />
              </div>
            </div>

            <div class="form-group row">
              <label for="" class="col-sm-4 col-form-label"
                >Cr Account Type</label
              >
              <div class="col-sm-8">
                <select
                  class="form-control"
                  name="accountType"
                  formControlName="accountType"
                  [(ngModel)]="accountType"
                  (change)="
                    GetCustomerDPSInfoForEncashment($event.target.value)
                  "
                >
                  <option value="">Select</option>
                  <ng-container *ngFor="let item of accountTypes | async">
                    <option [value]="item.accType">{{ item.accType }}</option>
                  </ng-container>
                </select>
              </div>
            </div>

            <!-- <div class="form-group row">
                            <label for="" class="col-sm-4 col-form-label">Total Due</label>
                            <div class="col-sm-8">
                                <input type="text" style="text-align: right;" class="form-control" formControlName="currentBalance"  [value]="currentBalance | number:'1.0-2'" readonly>
                            </div>
                        </div> -->
          </div>
          <div class="col-md-6">
            <!-- <div class="form-group row">
                            <label for="" class="col-sm-4 col-form-label">Phone Number</label>
                            <div class="col-sm-8">
                                <select class="form-control" formControlName="customerAccountId2" (change)="loadMobileBankingInfoById($event.target.value)"
                                >
                                    <option value="">Select</option>
                                    <ng-container *ngFor="let item of mobileBankingList | async">
                                        <option [value]="item.id">{{item.moblieNumber}}</option>
                                    </ng-container>
                                </select>
                            </div>
                        </div> -->

            <!-- <div class="form-group row">
                            <label for="name" class="col-sm-4 col-form-label">Phone Number</label>
                            <div class="col-sm-8">
                              <div class="input-group">
                                <input type="text" class="form-control" formControlName="customerAccountId2" [(ngModel)]="accountNoFund2"
                                 placeholder="Mobile Number"
                                 (keyup.enter)="loadMobileBankingInfoById($event.target.value)">

                                 
                              </div>
                            </div>
                        </div> -->

            <div class="form-group row">
              <label for="" class="col-sm-4 col-form-label"
                >Customer Name</label
              >
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  formControlName="customerCode"
                  value="{{ customerInfo_cashFundTransfer1 }}"
                  readonly
                />
              </div>
            </div>

            <div class="form-group row">
              <label for="" class="col-sm-4 col-form-label"
                >Maturity Amnt.</label
              >
              <div class="col-sm-8">
                <input
                  type="text"
                  style="text-align: right"
                  class="form-control"
                  formControlName="maturityAmount"
                  [value]="maturityAmount | number"
                  readonly
                />
              </div>
            </div>

            <div class="form-group row d-none">
              <label for="" class="col-sm-4 col-form-label"
                >Total Interest</label
              >
              <div class="col-sm-8">
                <input
                  type="text"
                  style="text-align: right"
                  class="form-control"
                  formControlName="totalInterest"
                  [value]="totalInterest | number"
                  readonly
                />
              </div>
            </div>

            <div class="form-group row">
              <label for="" class="col-sm-4 col-form-label"
                >Cr Account No</label
              >
              <div class="col-sm-8">
                <input
                  type="text"
                  (change)="ChangeCrAcc($event.target.value)"
                  style="text-align: right"
                  class="form-control"
                  formControlName="creditAccountNo"
                  [value]="creditAccountNo"
                />
              </div>
            </div>

            <!-- <div class="form-group row">
                            <label for="" class="col-sm-4 col-form-label">Account Name</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" formControlName="accountName" value="{{accountName}}" readonly>
                                <input type="hidden" name="customerAccountId2" formControlName="customerAccountId2" value="{{customerAccountId2}}">

                            </div>
                        </div> -->

            <!-- <div class="form-group row">
                            <label for="" class="col-sm-4 col-form-label">Payment Amount</label>
                            <div class="col-sm-8">
                                <input type="text" readonly style="text-align: right;" class="form-control" formControlName="voucherAmount" [value]="currentBalance | number:'1.0-2'" placeholder="0"
                                (input)="frmGroup.get('voucherAmount').setValue(formatNumber($event.target.value))" (keyup)="CheckBalance()">
                                <span *ngIf="frmGroup.get('voucherAmount').hasError('pattern')" class="text-danger">
                                    Only numeric values are allowed.
                                </span> 
                                
                            </div>
                        </div> -->

            <!-- <div class="form-group row">
                            <label for="" class="col-sm-4 col-form-label">Remarks</label>
                            <div class="col-sm-8">
                                <textarea class="form-control" formControlName="remarks" id="" cols="30" rows="2"></textarea>
                            </div>
                        </div> -->
          </div>
        </div>
        <button
          data-toggle="tooltip"
          (click)="onsubmit()"
          *ngIf="isBtnValid"
          title="Save"
          type="button"
          class="btn btn-success"
          style="float: right; margin-top: 5px"
        >
          EnCash
        </button>

        <button
          type="button"
          title="Refresh"
          class="btn btn-secondary"
          style="
            float: right;
            margin-top: 5px;
            margin-right: 5px;
            margin-left: 8px;
          "
          (click)="ResetForm()"
        >
          Refresh
        </button>
      </div>
    </div>
  </div>
</form>
