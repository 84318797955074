export class NidDetails{
    public fullNameEN: string;
    public fathersNameEN: string;
    public mothersNameEN: string;
    public spouseNameEN: string;
    public presentAddressEN: string;
    public permanentAddressEN: string;
    public fullNameBN: string;
    public fathersNameBN: string;
    public mothersNameBN: string;
    public spouseNameBN: string;
    public presentAddressBN: string;
    public permanentAddressBN: string;
    public gender: string;
    public profession: string;
    public dateOfBirth: string;
    public nationalIdNumber: string;
    public oldNationalIdNumber: string;
    public photoUrl: string;
}