import { Component, OnInit } from '@angular/core';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { Location } from "@angular/common";
@Component({
  selector: "app-income-expense-report",
  templateUrl: "./income-expense-report.component.html",
  styleUrls: ["./income-expense-report.component.scss"],
})
export class IncomeExpenseReportComponent implements OnInit {
  fromDate: Date = new Date();
  toDate: Date = new Date();
  reportBy: number;
  typeId: number;
  constructor(private pdfService: PdfService, private location: Location) {}

  ngOnInit(): void {}

  OpenPDF() {
    this.pdfService
      .AccountIncomeExpenseReport(
        this.fromDate,
        this.toDate,
        1,
        2,
        this.reportBy,
        this.typeId
      )
      .subscribe((res) => {
        let blob: Blob = res.body as Blob;
        let url = window.URL.createObjectURL(blob);

        window.open(url);
      });
  }

  goBack() {
    this.location.back();
  }
}