<div class="card" style="width: 100%;">
    <div class="card-body">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h5 class="card-title" style="color: black">DPS Encashment List</h5>
                </div>
                <!-- <div class="col" style="text-align: right;">
                    <button class="btn btn-primary" (click)="openingCreate()">Create</button>
                </div> -->


               
            </div>
<br>
<br>
            <div class="card-body">
                <div class="container">
    
                    <div class="form-group row">
                        <div class="col-6 row">
                            <div class="col-4 mt-1">
                                <label style="font-size: 16px;">Account Name</label>
                            </div>
                            <div class="col-8">
                                <div class="input-group-prepend">
                                    <p class="input-group-text" style="border-right: none;">
                                        <i class="fa fa-search"></i>
                                    </p>
                                    <input type="text" class="form-control" [(ngModel)]="accountName"
                                        (keyup.enter)="loadDPSRelatedData()" placeholder="Search by Account name ..."
                                        style="border-left: none; margin-left: -6px;">
                                </div>
                            </div>
                        </div>
    
                        <div class="col-6 row">
                            <div class="col-4">
    
                                <label style="font-size: 16px;">Filter Type</label>
                            </div>
    
                            <div class="input-group col-8">
    
                                <select class="form-control" [(ngModel)]="typeId"
                                    (change)="onFilterTypeChange($event.target.value)" (change)="SearchByParameter()">
                                    <option value="0"> Select</option>
                                    <option value="1"> Account No</option>
                                    <option value="2"> Encashment Date</option>
                                </select>
    
                            </div>
                        </div>
    
    
    
    
                    </div>
    
                    <div class="row">
                    
                        <div class="col-6 row" *ngIf="showaccountNoField">
                            <div class="col-4">
                                <label style="font-size: 16px;">Account No</label>
                    
                            </div>
                            <div class="col-8">
                    
                    
                    
                                <div class="input-group">
                                    <input type="text" class="form-control" [(ngModel)]="accountNo"
                                        placeholder="Search by Account No....">
                                    <button class="btn btn-danger" (click)="deleteFilterTypeFields(1)"><i class="fa fa-close"></i></button>
                                </div>
                    
                            </div>
                        </div>
                    
                        <div class="col-6 row">
                            <div class="col-4">
                                <label style="font-size: 16px;"></label>
                            </div>
                            <div class="col-8">
                    
                                <div class="input-group">
                    
                                </div>
                            </div>
                        </div>
                    
                    
                    </div>
                  
                    <div class="row" *ngIf="showencashmentDateField">
                        <div class="col-2 mt-4">Encashment Date</div>
                        <div class="col-4">
    
                            <label></label>
    
                            <div class="input-group">
                                <input type="date" class="form-control" [(ngModel)]="encashmentDate"
                                    placeholder="Select Encashment Date">
                                    <button class="btn btn-danger" (click)="deleteFilterTypeFields(2)"><i
                                        class="fa fa-close"></i></button>
                            </div>
    
                        </div>
                      
                    </div>
    
    
    
                    <div class="col-12 row">
                        <div class="col-2"></div>
                        <div class="col-10">
                            <!-- <button class="btn btn-info pull-right ml-2" (click)="refreshFilter()"> Refresh</button> -->
                            <button class="btn btn-success pull-right" (click)="loadDPSRelatedData()"> Search</button>
    
                        </div>
                    </div>
                </div>
            </div>
            <table id="example" class="table table-striped table-bordered mt-3" style="width:100%">
                <thead style="background-color: #f8f9fb;">
                    <tr>
                        <th>Action</th>
                        <th>Account No</th>
                        <th>Account Name</th>
                        <th>Encashment Amount</th>
                        <th>Encashment Date</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container ></ng-container>
                    <tr *ngFor="let item of dpsEncashmentList">
                        <td>
                            <!-- <button class="btn btn-primary" style="font-size: 14px; width: 120px !important; height: 35px;"
                                (click)="PrintKyc(item.customerAccount?.id)" title="Click here for download pdf">View PDF</button> -->
                            <button class="btn btn-primary mt-1"
                                style="font-size: 14px; width: 120px !important; height: 35px;"
                                (click)="DPSSchedulePDF(item.customerAccount?.id)"
                                title="Click here for download pdf">Schedule</button>
                            <button class="btn btn-primary mt-1" *ngIf="item.isApproved == 0"
                                style="font-size: 14px; width: 120px !important; height: 35px;"
                                (click)="Approve(item.id)"
                                title="Click here for download pdf">Approve</button>
                        </td>
                        <td>{{item.customerAccount?.customerAccountNo}}</td>
                        <td>{{item.customerAccount?.accountTilte}}</td>
                        <td>{{item.encashmentAmount}}</td>
                        <td>{{item.effectiveDate | date: 'yyyy-MM-dd'}}</td>
                        <td>{{item.isApproved == 0 ? 'Pending' : 'Approved'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>