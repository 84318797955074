import { Component, OnInit } from '@angular/core';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { DatePipe, Location } from "@angular/common";

@Component({
  selector: "app-loan-overdue",
  templateUrl: "./loan-overdue.component.html",
  styleUrls: ["./loan-overdue.component.scss"],
})
export class LoanOverdueComponent implements OnInit {
  asOnDate: Date = new Date();
  reportBy: number;
  overdueList: any[];

  totalLoanAmount: number = 0;
  totalInterestAmount: number = 0;
  totalDisburseAmount: number = 0;
  totalOutstandingAmount: number = 0;
  totaloverDue: number = 0;
  totaloverdueCount: number = 0;

  constructor(
    private pdfService: PdfService,
    private location: Location,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {}
  OpenPDF() {
    this.pdfService.LoanOverDueReportPdf(this.asOnDate).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    });
  }

  OpenExcel() {
    this.pdfService.LoanOverDueReportExcel(this.asOnDate).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    });
  }

  LoadData() {
    this.pdfService
      .LoanOverDueReportDataLoad(this.asOnDate)
      .subscribe((res) => {
        this.overdueList = res.body;
        this.updateTotals();
      });
  }

  goBack() {
    this.location.back();
  }

  // formatDateString(dateString: string): string {
  //   const formattedDate = this.datePipe.transform(dateString, "dd-MM-yyyy"); 
  //   return formattedDate || dateString;
  // }

  updateTotals(): void {
    this.totalLoanAmount = 0;
    this.totalInterestAmount = 0;
    this.totalDisburseAmount = 0;
    this.totalOutstandingAmount = 0;
    this.totaloverdueCount = 0;

    for (let i = 0; i < this.overdueList.length; i++) {
      const item = this.overdueList[i];
      this.totalLoanAmount += item.loanAmount || 0;
      this.totalInterestAmount += item.interestAmount || 0;
      this.totalDisburseAmount +=
        (item.loanAmount || 0) - (item.processingFee || 0);
      this.totalOutstandingAmount += item.outStanding || 0;

      this.totaloverDue += item.overDue || 0;
      this.totaloverdueCount += item.overdueCount || 0;
    }
  }
}
