import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

const apiUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class OtherAccountService {

  constructor(private http:HttpClient) { }

  getAutocompleteSuggestions(searchTerm: string): Observable<any[]> {
    return this.http.get<any[]>(apiUrl +'/api/Ledger/GetGLGenerateAutoCompleate', { params: { searchTerm } });
}
}
