import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { DatePipe, Location } from "@angular/common";
import { LoanProductService } from 'app/product/service/loan-product.service';
import { observable } from 'core-js/fn/symbol';

@Component({
  selector: "app-loan-disbursement",
  templateUrl: "./loan-disbursement.component.html",
  styleUrls: ["./loan-disbursement.component.scss"],
})
export class LoanDisbursementComponent implements OnInit {
  totalLoanAmount: number = 0;
  totalInterestAmount: number = 0;
  totalDisburseAmount: number = 0;
  totalOutstandingAmount: number = 0;
  totalinstallmentSize: number = 0;
  totalprocessingFee: number = 0;

  fromDate: Date = new Date();
  toDate: Date = new Date();
  mfsType: number = 0;
  mfsAccountList: any[];
  disbursementList: any[];
  constructor(
    private pdfservice: PdfService,
    private location: Location,
    private loanProductService: LoanProductService,
    private changeDetectorRef: ChangeDetectorRef,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.getMFSList();
  }

  OpenPdf() {
    this.pdfservice
      .LoanDisbursementReport(this.fromDate, this.toDate, this.mfsType)
      .subscribe((x) => {
        let blob: Blob = x.body as Blob;
        let url = window.URL.createObjectURL(blob);

        window.open(url);
      });
  }
  OpenExcel() {
    this.pdfservice
      .LoanDisbursemetExcelReport(this.fromDate, this.toDate, this.mfsType)
      .subscribe((x) => {
        let blob: Blob = x.body as Blob;
        let url = window.URL.createObjectURL(blob);

        window.open(url);
      });
  }

  goBack() {
    this.location.back();
  }

  getMFSList() {
    this.loanProductService.getMFSAccountList().subscribe((res) => {
      this.mfsAccountList = res;
      this.changeDetectorRef.detectChanges();
    });
  }

  LoanData() {
    this.pdfservice
      .LoadDisbursementData(this.fromDate, this.toDate, this.mfsType)
      .subscribe((res) => {
        this.disbursementList = res.body;
        this.updateTotals();
      });
  }

  formatDateString(dateString: string): string {
    const formattedDate = this.datePipe.transform(dateString, "dd-MM-yyyy");
    return formattedDate || dateString;
  }

  updateTotals(): void {
    this.totalLoanAmount = 0;
    this.totalInterestAmount = 0;
    this.totalDisburseAmount = 0;
    this.totalOutstandingAmount = 0;
    this.totalprocessingFee = 0;

    for (let i = 0; i < this.disbursementList.length; i++) {
      const item = this.disbursementList[i];
      this.totalLoanAmount += item.loanAmount || 0;
      this.totalInterestAmount += item.interestAmount || 0;
      this.totalinstallmentSize += item.installmentSize || 0;
      this.totalDisburseAmount +=
        ((item.loanAmount || 0) - (item.processingFee || 0));
      this.totalOutstandingAmount += item.outstanding || 0;
       this.totalprocessingFee += item.processingFee || 0;
    }
  }
}
