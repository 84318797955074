import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountingreportService } from '../accountingreport.service';
import { Location } from "@angular/common";
@Component({
  selector: "app-accounting-report",
  templateUrl: "./accounting-report.component.html",
  styleUrls: ["./accounting-report.component.scss"],
})
export class AccountingReportComponent implements OnInit {
  constructor(
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private accoutingreportService: AccountingreportService,
    private location: Location
  ) {}

  ngOnInit(): void {}
  goBack() {
    this.location.back();
  }

  onResized(event: any) {
    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }
  fireRefreshEventOnWindow() {
    throw new Error("Method not implemented.");
  }
  onUnderProClicked(type: number) {
    this.router.navigate(["/dashboard-custom/cutomer-lead"]);
    this.changeDetectorRef.detectChanges();
  }
  onWaiting(st: number) {
    this.router.navigate(["/accounting-reports/ledger-book-report"]);
    if (st == 1) {
      this.router.navigate(["/accounting-reports/cash-book"]);
    } else if (st == 2) {
      this.router.navigate(["/accounting-reports/bank-book "]);
    } else if (st == 3) {
      this.router.navigate(["/accounting-reports/ledger-book-report"]);
    } else if (st == 5) {
      this.router.navigate(["/accounting-reports/chart-of-account"]);
    } else if (st == 6) {
      this.router.navigate(["/accounting-reports/day-book"]);
    }
  }
}
