import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoanProductService } from 'app/product/service/loan-product.service';
import { Location } from "@angular/common";
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
const apiUrlDoc = environment.baseUrl + '/api/Reporting/';
@Component({
  selector: "app-loan-product-list",
  templateUrl: "./loan-product-list.component.html",
  styleUrls: ["./loan-product-list.component.scss"],
})
export class LoanProductListComponent implements OnInit {
  loanProductList: any[] = [];
  productDetails: any;
  closeResult: string;
  constructor(
    private _loanProductService: LoanProductService,
    private modalService: NgbModal,
    private location: Location,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getLoanProductBuilderInfoList();
  }

  getLoanProductBuilderInfoList() {
    this._loanProductService.getLoanProductList().subscribe((res) => {
      this.loanProductList = res;
    });
  }

  getLoanProductDetails(id: any) {
    this._loanProductService
      .getLoanProductBuilderDetailsById(id)
      .subscribe((data: any) => {
        this.productDetails = data;
        console.log(this.productDetails);
      });
  }

  openBuilderDetailsModal(content) {
    let options: any = {
      size: "xl",
      centered: false,
    };
    this.modalService.open(content, options).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed`;
      }
    );
  }

  edit(item: any) {
    this.router.navigate(['/product/loan-product-entry/'+item.id]);
  }
  delete(id:number,s:boolean){
    
    Swal.fire({
      title: 'Are you sure?',
      text: (s==true)?'You want to active!':'You want to deactive!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: (s==true)?'Active!':'Deactive!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        this._loanProductService.deActive(id,s).subscribe(
          (data: any) => {
            Swal.fire(
              'Deactivated!',
              (s==true)?'Activated!':'Deactivated!',
              'success'
            ).then((result) => {
              this.getLoanProductBuilderInfoList();
            });
          },
          (error) => {
            Swal.fire(
              'Failed!',
              (s==true)?'Unable to active!':'Unable to deactive!',
              'warning'
            )
          }
        );
      }
    });
  }
  download(){
    const fileUrl = `${apiUrlDoc}download?id=${this.productDetails?.productInfo?.id}`;
    window.open(fileUrl, '_blank');
  }
  goBack() {
    this.location.back();
  }
}
