import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardCustomService } from 'app/dashboard/dashboard-custom.service';
import { Location } from "@angular/common";
@Component({
  selector: "app-rejected-loan-app",
  templateUrl: "./rejected-loan-app.component.html",
  styleUrls: ["./rejected-loan-app.component.scss"],
})
export class RejectedLoanAppComponent implements OnInit {
  dataList: any[] = [];
  constructor(
    private router: Router,
    private _serviceDashboard: DashboardCustomService,
    private changeDetectorRef: ChangeDetectorRef,
    private location:Location
  ) {}

  ngOnInit(): void {
    this.getRejectedApplication();
  }

  getRejectedApplication() {
    this._serviceDashboard.getLoanLeadInfoForAdmin().subscribe((res) => {
      this.dataList = res.filter((item) => item.statusInfoId === 5);
      this.changeDetectorRef.detectChanges();
    });
  }

  goBack() {
    this.location.back();
  }
}
