<div class="card">
    <div class="card-header">
        <h4>Payment Success</h4>
    </div>

    <div class="card-body text-center">
        <p><b>PaymentId:</b> {{paymentInfo.paymentID}}</p>
        <p><b>TRXNo:</b> {{paymentInfo.trxNo}}</p>
        <p><b>Amount:</b> BDT {{paymentInfo.amount}}</p>
        <p><b>Status:</b> {{paymentInfo.status}}</p>
    </div>
</div>