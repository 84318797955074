import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-account-detail',
  templateUrl: './account-detail.component.html',
  styleUrls: ['./account-detail.component.scss']
})
export class AccountDetailComponent implements OnInit {
  accId: string;
  constructor(private route: ActivatedRoute) {    
    
   }

  ngOnInit(): void {
    this.accId = this.route.snapshot.paramMap.get("acc_id");
    this.loadAccountData(this.accId);
  }

  loadAccountData(id:string){
    //alert(id)

  }
}
