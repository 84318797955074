import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer } from 'app/customer/model/customer.model';
import { RegisterService } from 'app/services/register.service';
import { SharedService } from 'app/services/shared.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-otp-verify',
  templateUrl: './otp-verify.component.html',
  styleUrls: ['./otp-verify.component.scss']
})
export class OtpVerifyComponent implements OnInit {
  isPhoneNumberShow: boolean = true;
  isOtpShow: boolean = false;
  showResendButton = false;
  countdown = 120;
  countdownInterval: any;

  model = new Customer();
  imageInfos?: Observable<any>;
  isLoading: boolean = false;

  otpGenerationTime: number;



  // modelOtp = {
  //   otp: null,
  // };


  constructor(private registerService: RegisterService,
    private changeDetectorRef: ChangeDetectorRef,
    private shared: SharedService,
    private route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone) { }

  ngOnInit(): void {
  }

  clearOtpField() {
    this.model.otp = '';
  }

  onPhoneNumberSubmit(form) {

    const mobile = '88' + this.model.MobileNo;
    if (mobile.length === 13 && !isNaN(parseFloat(this.model.MobileNo)) && !isFinite(Number(this.model.MobileNo))) {
      alert("Give number in field not text");
    }
    else if (mobile.length != 13) {
      alert("Mobile Number Feild Minimum Length 13");
    }
    else {
      this.registerService.sendOTP( this.model.MobileNo, '', 1, 1).subscribe((res) => {
        if (res.status === 'ok') {

          this.isPhoneNumberShow = false;
          this.isOtpShow = true;

          this.otpGenerationTime = Date.now();
          this.clearOtpField();
          // this.isCutomerRestration = false;
          this.changeDetectorRef.detectChanges();
        }
        else if (res.status === "user") {
          alert(res.message);
          this.router.navigate(['/pages/login']);
        }
        this.startCountdown(form, 1);

      },
        (error) => {
          //console.error('Error submitting data:', error);
          this.isPhoneNumberShow = false;
          this.isOtpShow = true;
          //this.isCutomerRestration = false;
          this.changeDetectorRef.detectChanges();
        });

    }

    //this.startCountdown(form,1);
  }

  onOTPSubmit(form) {
    this.model.otp = this.model.otp1 + this.model.otp2 + this.model.otp3 + this.model.otp4
    //alert(this.model.otp)
    const timeDifference = (Date.now() - this.otpGenerationTime) / 1000;

    if (timeDifference > 120) {

      this.shared.ShowAlert("Expired", "OTP has expired. Please request a new OTP.", "error");
      return;
    }

    this.registerService.verifyOTP( this.model.MobileNo, '', 1, this.model.otp).subscribe((res) => {
      if (res.status === 'ok') {

        this.shared.ShowAlert("Matched", "Otp Matched", "success");
        this.changeDetectorRef.detectChanges();
        this.router.navigate(['/e-kyc/nid-verify',this.model.MobileNo]);
      }
      else if (res.status === 'expired') {

        this.shared.ShowAlert('Expired', res.message, 'error');
      }
      else if (res.status === 'oldOtp') {


        this.shared.ShowAlert('Expired', "OTP has expired. Please request a new OTP.", 'warning');
      }

      //

      else if (res.status === 'ok & nid') {
        this.router.navigate(['/ekyc/customer-info/' + this.model.MobileNo + '/' + res.nid]);
      }
      else {
        this.shared.ShowAlert("Wrong", "Otp Not Matched", "error");
      }
    },
      (error) => {
        this.shared.ShowAlert("Wrong", "Something wrong", "error");
      });


    // this.isCutomerRestration = true;
  }

  startCountdown(form, sl) {

    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }

    if (sl !== 1) {
      this.onPhoneNumberSubmit(form);
      this.countdown = 60;
    }

    this.countdownInterval = setInterval(() => {
      this.ngZone.run(() => {
        if (this.countdown <= 0) {
          this.showResendButton = true;
          clearInterval(this.countdownInterval);
        } else {
          this.countdown -= 1; // Decrement countdown by 1
          this.changeDetectorRef.detectChanges();
        }
      });
    }, 1000);
  }

  move(e: any, previous: any, current: any, next: any) {
    //console.log(e);
    var length = current.value.length;
    var maxLength = current.getAttribute('maxlength');

    if (length == maxLength) {
      if (next != '') {
        next.focus();
      }
    }

    if (e.key === "Backspace") {
      if (previous != '') {
        previous.focus();
      }
    }

  }


}
