import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LedgerService } from 'app/accounting/service/ledger.service';
import { SharedService } from 'app/services/shared.service';
import { Observable } from 'rxjs';
import { Ledger } from '../model/ledger';

@Component({
  selector: 'app-transactionevent',
  templateUrl: './transactionevent.component.html',
  styleUrls: ['./transactionevent.component.scss']
})
export class TransactioneventComponent implements OnInit {
  isEdit: boolean = false;
  frmGroup: FormGroup;
  list: any[] = [];
  
  model = new Ledger();
  LedgerList: any[] = [];
  
  glList: Observable<any[]>;
  currentPage = 1;
  itemsPerPage = 10;
  totalItems = 0;
  currentItems = 0;
  searchControl = "";
  
  
  constructor(
    private _LedgerService: LedgerService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private formBuilder: FormBuilder,
    private shared: SharedService,) { }

  ngOnInit(): void {
    this.showSaveForm = 'saveList';
    this.LedgerForm();
    this.GetAllTransactionEvent();
  }
  showSaveForm: string = '';

  LedgerForm() {
    this.frmGroup = this.formBuilder.group({
      id: [0],
      transactionCode: [''],
      transactionName: [''],
      description: [''],
      sortOrder: [0]
    });
  }

  onClickShowSaveForm(value: string) {
    this.showSaveForm = value;
    this.refresh();
  }
  refresh() {
    this.model = new Ledger;
    this.LedgerForm();
  }

  onChangeForShow(value: any){
    this.currentPage = 1;
    this.itemsPerPage = value;
    //this.GetGlGenerateList();
  }

  onSearch(){
    this.currentPage = 1;
  //  this.GetGlGenerateList();
    this.GetAllTransactionEvent();
  }

  onPageChange(pageNumber : number){
    this.currentPage = pageNumber;
    this.GetAllTransactionEvent();
  }

  GetAllTransactionEvent(){
  
    this._LedgerService.GetAllTransactionEventsPaginationAndSearch(this.currentPage, this.itemsPerPage, this.searchControl).subscribe((res:any)=>{
      this.glList = res.data;
      this.currentItems = res.LedgerMapping;
      this.totalItems = res.totalItems;
    })
  }
  onsubmit() {
    debugger
    
    if (this.isEdit) {
      this.model.id = this.frmGroup.value.id;
    }
    else{
      this.model.id = 0;
    }
    this.model.transactionCode = this.frmGroup.value.transactionCode;
    this.model.transactionName = this.frmGroup.value.transactionName;
    this.model.description = this.frmGroup.value.description;
    this.model.sortOrder = this.frmGroup.value.sortOrder;
    this._LedgerService.PostTransactionEvent(this.model).subscribe(res =>{
      if(this.isEdit){
        this.shared.ShowAlert('Success', 'Updated Succesfully!', 'success'); 
      }
      else{
        this.shared.ShowAlert('Success', 'Save Succesfully!', 'success');
      }    
      this.GetAllTransactionEvent(); 
      this.refresh();  
      this.showSaveForm = 'saveList';    
    })

    
  }

  edit(item: any) {
    this.isEdit = true;
    this.showSaveForm = 'saveForm';
    this.frmGroup.patchValue({
      id: item.id,
      transactionCode: item.transactionCode,
      transactionName: item.transactionName,
      description: item.description,
      sortOrder: item.sortOrder,

    })
  }

  delete(Id: any) {

    this._LedgerService.DeleteTransactionEventById(Id).subscribe(res => {
           this.shared.ShowAlert("Success", "Data deleted successfully", "success");
           this.GetAllTransactionEvent();
           this.showSaveForm='saveList';
    })
    
  }
 

}
