import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LedgerService } from 'app/accounting/service/ledger.service';
import { InterestProcessService } from '../interest-process.service';
import { SharedService } from 'app/services/shared.service';
import { DatePipe } from '@angular/common';
import { BatchService } from 'app/services/batch.service';
import { BatchDetail } from 'app/models/batch-detail';
import { PaymentService } from 'app/payment/payment.service';

@Component({
  selector: 'app-batch-process',
  templateUrl: './batch-process.component.html',
  styleUrls: ['./batch-process.component.scss']
})
export class BatchProcessComponent implements OnInit {
  types: string;
  fromDate: any = Date;
  toDate: any = Date;
  interval: any;
  dateInfo: any = [];
  businessDate: string;
  selectedType: string = "";
  batchDetails: any;
  isAllChecked: boolean = false;
  batchMasters: any[] = [];
  isLoading: boolean = false;


  checkedIds: any = [];

  isBalanceFDRChecked: boolean = false;
  isBalanceDPSChecked: boolean = false;
  isBalanceDepositChecked: boolean = false;
  isBalanceLoanChecked: boolean = false;
  isProvisionFDRChecked: boolean = false;
  isProvisionDPSChecked: boolean = false;
  isProvisionDepositChecked: boolean = false;
  isProvisionLoanChecked: boolean = false;

  isAllProcessed: boolean = false;

  constructor(
    private ledgerService: LedgerService,
    private changeDetectorRef: ChangeDetectorRef,
    private interestProcess: InterestProcessService,
    private shared: SharedService,
    private datePipe: DatePipe,
    private batchService: BatchService,
    private paymentService: PaymentService
  ) {

  }

  ngOnInit(): void {
    this.ledgerService.GetLastAciteBusinessDate().subscribe(res => {
      this.dateInfo = res;
      this.fromDate = this.datePipe.transform(this.dateInfo.business_Date, 'yyyy-MM-dd');
      this.toDate = this.datePipe.transform(this.dateInfo.business_Date, 'yyyy-MM-dd');
      this.changeDetectorRef.detectChanges();
    })


    this.shared.LastBusinessDate().subscribe(res => {


      const date = new Date(res.business_Date);
      this.businessDate = this.datePipe.transform(date, 'yyyy-MM-dd');
    })

    this.GetAllBatchDetail();

    setInterval(() => {
      this.UpdateAllBatchDetail();
    }, 5000);

  }

  GetAllBatchDetail() {
    this.interestProcess.GetAllBatchDetail().subscribe(res => {
      this.batchDetails = res;
      this.batchDetails.sort((a, b) => a.sortOrder - b.sortOrder);
      this.populateBatchMasters();
    })
  }

  UpdateAllBatchDetail() {
    this.interestProcess.GetAllBatchDetail().subscribe(res => {
      const resArray = [];
      const keys = Object.keys(res);
      for (let i = 0; i < keys.length; i++) {
        resArray.push({ id: res[i].batchDetail.id, processed: res[i].processed, remaining: res[i].remaining, percentage: res[i].percentage });
      }

      console.log(res);

      for (let i = 0; i < this.batchDetails.length; i++) {
        this.batchDetails[i].percentage = resArray[i].percentage;
        this.batchDetails[i].processed = resArray[i].processed;
        this.batchDetails[i].remaining = resArray[i].remaining;
      }


    })
  }

  populateBatchMasters() {
    for (let i = 0; i < this.batchDetails.length; i++) {
      const batchMaster = this.batchDetails[i].batchDetail.batchMaster;
      if (!this.batchMasters.find(master => master.batchCode === batchMaster.batchCode)) {
        this.batchMasters.push(batchMaster);
      }
    }

  }


  getBatchDetail(masterId) {
    let responseList: any = [];
    for (let i = 0; i < this.batchDetails.length; i++) {
      const batchMasterId = this.batchDetails[i].batchDetail.batchMasterId;

      if (batchMasterId == masterId) {
        responseList.push(this.batchDetails[i]);
      }
    }

    return responseList;
  }

  selectAllDetails(item: any) {
    const isSelected = item.selected;
    for (const detail of this.getBatchDetail(item.id)) {
      detail.selected = isSelected;
    }
  }


  ProcessAll() {
    // return;
    // this.interestProcess.processInterest(this.types, this.fromDate, this.toDate).subscribe(res => {
    //   if (res != null) {
    //     this.shared.ShowAlert('Thank you...', 'Process succesfully!', 'success');
    //   }
    //   else {
    //     this.shared.ShowAlert('warning', 'Something went wrong!', 'Try again.');
    //   }
    // })
  }



  ProcessByNameAndType(name: string, type: string) {
    if (name == 'Balance') {
      this.interestProcess.ProcessBalance(type, this.fromDate, this.toDate).subscribe(res => {
        if (res != null) {
          this.shared.ShowAlert('Thank you...', 'Process succesfully!', 'success');
        }
        else {
          this.shared.ShowAlert('warning', 'Something went wrong!', 'Try again.');
        }
      })
    }
    if (name == 'Provision') {
      this.interestProcess.ProcessProvision(type, this.fromDate, this.toDate).subscribe(res => {
        if (res != null) {
          this.shared.ShowAlert('Thank you...', 'Process succesfully!', 'success');
        }
        else {
          this.shared.ShowAlert('warning', 'Something went wrong!', 'Try again.');
        }
      })
    }


  }



  onChangeCheckbox(detailsId: number) {
    var id = 'row_' + detailsId;

    const index = this.checkedIds.indexOf(id);

    if (index !== -1) {
      this.checkedIds.splice(index, 1);
    } else {
      this.checkedIds.push(id);
    }

  }


  onChangeCheckboxAll() {
    if (this.isAllChecked) {
      this.isAllChecked = false;
    }
    else {
      this.isAllChecked = true;
    }

    this.checkedIds = [];

    for (const key in this.batchDetails) {
      if (this.batchDetails.hasOwnProperty(key)) {
        const item = this.batchDetails[key];
        if (item && item.batchDetail && item.batchDetail.id) {
          const id = item.batchDetail.id;

          this.onChangeCheckbox(id);

          // if (this.isAllChecked) {
          //   this.checkedIds.push('row_' + id);
          // }

        }
      }
    }

  }


  isIdChecked(id: string): boolean {
    const rowId = 'row_' + id;
    return this.checkedIds.includes(rowId);
  }

  getValue(paramName) {
    var value = '';
    if (paramName == 'fromdate') {
      value = this.fromDate;
    }
    else if (paramName == 'fromDate') {
      value = this.fromDate;
    }
    else if (paramName == 'todate') {
      value = this.toDate;
    }
    else if (paramName == 'toDate') {
      value = this.toDate;
    }
    else if (paramName == 'username') {
      value = 'opus.admin';
    }
    else {

    }

    return value;
  }


  async StartProcess() {
    this.batchDetails.sort((a, b) => a.sortOrder - b.sortOrder);
    console.log('batchDetails > ', this.batchDetails);

    for (const batchDetail of this.batchDetails) {
      //if (batchDetail.selected) {
        console.log('batchDetail > ', batchDetail.batchDetail);

        try {
          await this.ProcessAPI(batchDetail.batchDetail);
          this.isLoading = false; // Set isLoading to false after each API call
        } catch (error) {
          // Handle error if needed
        }
      // }
    }
    alert('Process Success');

  }

  Terminate() {
    this.batchService.terminateAllJob().subscribe(res => {
      if(res != null){
        alert('Terminated!');
        this.isLoading = false;
      }
    })
  }


  ProcessAPI(detail: BatchDetail): Promise<void> {
    this.isLoading = true;
    return new Promise((resolve, reject) => {
      this.batchService.apiCallForBatchProcess(detail).subscribe(
        response => {
          // alert('Process Success');
          resolve(); // Resolve the promise after successful API call
        },
        error => {
          // Handle error if needed
          reject(error); // Reject the promise if API call fails
        }
      );
    });
  }


  ShowProcessData(detailId){
    // alert(detailId);
    console.log(detailId);
  }

  Payment(bkashNo: string, amount: string, mfsType: string){
    if(mfsType == 'bKash'){
      this.paymentService.grantTokenAndCreatePayment(bkashNo, amount)
      .subscribe(response => {
          console.log('Response:', response); // Handle the response as needed
          console.log('Response:', response.createPaymentResponse.bkashURL); // Handle the response as needed

          window.location.href = response.createPaymentResponse.bkashURL;
      }, error => {
          console.error('Error:', error); // Handle error if any
      });
    }
    else if(mfsType == 'nagad'){
      this.paymentService.nagadInitialize(bkashNo, amount)
      .subscribe(response => {
          console.log('Nagad Response:', response);
          // console.log('Nagad Response:', response.createPaymentResponse.bkashURL); // Handle the response as needed

          // window.location.href = response.createPaymentResponse.bkashURL;
      }, error => {
          console.error('Error:', error); // Handle error if any
      });
    }
    else{
      alert("Not Available!");
    }

  }

}
