import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormBuilder, NgForm, FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { CountryInfo } from '../../basic-model/country-info';
import { CountryInfoService } from '../../basic-service/country-info.service';
import { SharedService } from 'app/services/shared.service';

@Component({
  selector: 'app-country-list',
  templateUrl: './country-list.component.html',
  styleUrls: ['./country-list.component.scss']
})
export class CountryListComponent implements OnInit {
  isEdit: boolean = false;
  countryForm: FormGroup;
  list: CountryInfo[] = [];
  model = new CountryInfo();
  frmGroup: FormGroup;
  frmmodel: CountryInfo = new CountryInfo();

  currentPage = 1;
  itemsPerPage = 10;
  totalItems = 0;
  currentItems = 0;
  searchControl = "";

  //showSaveForm: string = '';

  constructor(
    private _service: CountryInfoService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private formBuilder: FormBuilder,
    private shared: SharedService
  ) {

    this.getCountryList()
  
  }

  ngOnInit(): void {
    this.getCountryList();
  }

  onChangeForShow(value: any) {
    this.currentPage = 1;
    this.itemsPerPage = value;
    this.getCountryList();
  }

  onPageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.getCountryList();
  }

  onSearch() {
    this.currentPage = 1; // Reset the current page to 1 when a new search is performed
    this.getCountryList();
  }

  getCountryList() {
    this._service.getAllCountryWithPaginationSearch(this.currentPage, this.itemsPerPage, this.searchControl).subscribe((res: any) => {
      this.list = res.data;
      this.totalItems = res.totalItems;
      this.currentItems = res.currentItems;
    })
  }

}
