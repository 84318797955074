import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ComponentFactoryResolver } from '@angular/core';
import { ComponentLoaderDirective } from 'app/shared/directives/component-loader.directive';
import { LedgerBookReportComponent } from '../ledger-book-report/ledger-book-report.component';
import { TrialBalanceReportComponent } from '../trial-balance-report/trial-balance-report.component';
import { GroupSummaryComponent } from '../group-summary/group-summary.component';

interface MasterMenuItem {
  itemName: string;
  displayName: string;
  component: any; // Adjust the type based on the component types you're using
}

@Component({
  selector: 'app-report-builder',
  templateUrl: './report-builder.component.html',
  styleUrls: ['./report-builder.component.scss']
})
export class ReportBuilderComponent implements OnInit {

  masterMenuItems: MasterMenuItem[] = [
    { itemName: "LedgerBook", displayName: "Ledger Book", component: LedgerBookReportComponent },
    { itemName: "TrailBalance", displayName: "Trail Balance", component: TrialBalanceReportComponent },
    { itemName: "GroupSummery", displayName: "Group Summery", component: GroupSummaryComponent },
  ];

  activeItem: string;
  tabs: any[] = [];

  @ViewChildren(ComponentLoaderDirective) componentLoaders: QueryList<ComponentLoaderDirective>;
  
  constructor(private componentFactoryResolver: ComponentFactoryResolver) { } 

  ngOnInit(): void {
  }

  menuItemClick(clickedMasterMenuItem: MasterMenuItem) {
    this.activeItem = clickedMasterMenuItem.itemName;

    const matchingTabs = this.tabs.filter((tab) => tab.itemName == clickedMasterMenuItem.itemName);

    if (matchingTabs.length === 0) {
      this.tabs.push({
        tabIndex: this.tabs.length,
        itemName: clickedMasterMenuItem.itemName,
        displayName: clickedMasterMenuItem.displayName
      });

      setTimeout(() => {
        const componentLoadersArray = this.componentLoaders.toArray();
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(clickedMasterMenuItem.component);

        const viewContainerRef = componentLoadersArray[this.tabs.length - 1].viewContainerRef;
        const componentRef = viewContainerRef.createComponent(componentFactory);

        this.tabs[this.tabs.length - 1].viewContainerRef = viewContainerRef;

        if (clickedMasterMenuItem.component.name == "LedgerBookReportComponent") {
          const componentInstance = componentRef.instance as LedgerBookReportComponent;
          // componentInstance.message = "Hello to Countries";
        }
      }, 100);
    }
  }

  onCloseClick(clickedTab: any) {
    if (clickedTab && clickedTab.viewContainerRef) {
      clickedTab.viewContainerRef.remove();
      this.tabs.splice(this.tabs.indexOf(clickedTab), 1);
      if (this.tabs.length > 0) {
        this.activeItem = this.tabs[0].itemName;
      }
    }
    
  }
}
