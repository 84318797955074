import { Injectable, HostListener } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, interval, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IdleTimeServiceService {
  private baseUrl = environment.baseUrl;
  private intervalSubscription: Subscription;

  private lastActivityTime: number;
  private readonly idleTimeout = 600000;

  constructor() { 
    this.resetTimer();

    this.intervalSubscription = interval(5000).subscribe(() => {
      // this.checkAndRenewToken();
      //console.log('Idle: ' + this.getIdleTime())
    });

  }

  ngOnDestroy() {
    // Unsubscribe from the interval to avoid memory leaks when the service is destroyed
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }

  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:keypress', ['$event'])
  resetTimer() {
    this.lastActivityTime = Date.now();
  }

  getIdleTime(): number {
    const currentTime = Date.now();
    // console.log(currentTime);
    // console.log(this.lastActivityTime);
    return currentTime - this.lastActivityTime;
  }

  isUserInactive(): boolean {
    return this.getIdleTime() >= this.idleTimeout;
  }
}
