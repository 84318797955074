


<br>
<h4 class="card-title" style="color: black">Half-Yearly Interest Process</h4>
<hr>
<div class="card">
    <div class="card-body">
        <div class="form-group row">
            <label for="dateInput" class="col-md-4">Business Date:</label>
            <div class="col-md-4 input-group">
                <input type="date"  placeholder="yyyy-mm-dd" placeholder="yyyy-mm-dd" [(ngModel)]="businessDate" class="form-control">  
            </div>
            <div class="col-4">
                <button type="button" (click)="InterestProcess()" class="btn btn-primary" id="processButton">Process</button>
            </div>
         
        </div>

    </div>
</div>