<div class="card">
    <div class="card-body">
        <div class="form-group row">
            <label for="dateInput" class="col-md-2">Date:</label>
            <div class="col-md-4 input-group">
                <input type="text" (click)="d3.toggle()" placeholder="yyyy-mm-dd" placeholder="yyyy-mm-dd" [(ngModel)]="cobDate" class="form-control" id="dateInput" name="date" ngbDatepicker
                [dayTemplate]="customDay" [markDisabled]="isDisabled" #d3="ngbDatepicker">
                <div class="input-group-append">
                    <div class="input-group-text" (click)="d3.toggle()">
                    <i class="fa fa-calendar" style="cursor: pointer;"></i>
                    </div>
                </div>
            </div>
            <button type="button" (click)="ProcessCOB()" class="btn btn-primary btn-sm" id="processButton">Process COB</button>
        </div>



        
        <ng-template #customDay let-date="date" let-currentMonth="currentMonth" let-selected="selected"
              let-disabled="disabled">
            <span class="custom-day" [class.weekend]="isWeekend(date)" [class.bg-primary]="selected"
            [class.hidden]="date.month !== currentMonth" [class.text-muted]="disabled">
            {{ date.day }}
            </span>
        </ng-template>
        



        <div class="form-group row">
            <table class="table table-borderless">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Progress</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of progresses">
                        <td>{{ item.date | date }}</td>
                        <td>
                          <ngb-progressbar
                            [striped]="true"
                            style="width: 100%; height: 1.5rem;"
                            max="100"
                            showValue="true"
                            type="success"
                            [value]="item.progress"
                          ></ngb-progressbar>
                        </td>
                      </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>