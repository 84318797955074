<div class="card">
    <div class="col-sm-12 mt-3" style="min-height:500px">
      <h3 style="text-align: center;"><label class="HeadingColor">User Wise Page Assign Information</label></h3>
      <hr>
      <div class="row">
  
        <div class="col-sm-4">
            <div class="form-group row">
                <label class="col-md-4" style="text-align: right;">User</label>
                <div class="col-md-8">
                  <select class="form-control" [(ngModel)]="userId" (change)="getUserWiseRole()"
                    name="" style="border-radius: 25px;" required>
                    <option value="">Select User </option>
                    <option *ngFor="let item of userList" [value]="item.id">{{ item.userName }}</option>
                    
                
                </select>
      
                </div>
              </div>
        </div>
        <div class="col-md-4" style="padding-left:0px;">
          <div class="form-group row" *ngIf="isToggled == false" >
            <label class="col-md-4">User Role</label>
            <div class="col-md-8">
              <select class="form-control" [(ngModel)]="userTypeId" (change)="getUserMenuAccess()"
                name="applicationRoleId" style="border-radius: 25px;" required>
                <option  value="">Select Role </option>
                <option *ngFor="let item of userRoles" [value]="item.id">{{ item.name }}</option>
             
            
            </select>
  
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
              <button class="btn btn-info btn-sm pull-left" id="id2" *ngIf="isToggled == false" (click)="saveInactiveUserMenuAccess()"><span class="span1">Save</span></button>
              <button class="btn btn-info btn-sm pull-left" id="id1" *ngIf="isToggled == true" (click)="saveUserMenuAccess()"><span class="span1">Save</span></button>
              <!-- <button class="btn btn-warning btn-sm pull-left" (click)="toggle()"><span class="span1">{{ isToggled ? 'Role' : 'Other' }}</span></button> -->
              
              <label class="switch">
                <input (click)="toggle()" id="toggleSwitch" type="checkbox" checked>
                <span class="slider round"></span>
              </label>
          
          </div>
        </div>
      </div>
  
     
      <hr>
    <form id="form2" data-parsley-validate class="form-horizontal form-label-left">

        <div class="col-md-12" style="margin-left: 20%;">
          <div class="sidebar-content main-menu-content">
            <div class="nav-container">
              <ul class="navigation">

                <!-- First level menu -->
                <li appSidebarlink *ngFor="let menuItem of userMenu" [parent]="menuItem.title" [path]="menuItem.path"
                  level="{{level + 1}}">
                  <input type="checkbox" (change)="onCheckboxChange($event,menuItem.id)" [checked]="menuItem.isActive"
                    name="AllMenuIds" style="width: 17px; height: 17px;margin-right: 2%;">
                  <span class="menu-title" style="font-size: 18px;"><b>{{menuItem.title}}</b></span>


                  <!-- Second level menu -->
                  <ul appSidebarDropdown class="menu-content" *ngIf="menuItem.submenu.length > 0">
                    <li appSidebarlink *ngFor="let menuSubItem of menuItem.submenu" [parent]="menuItem.title"
                      level="{{level + 2}}">
                      <input type="checkbox" (change)="onCheckboxChange($event,menuSubItem.id)"
                        [checked]="menuSubItem.isActive" name="AllMenuIds"
                        style="width: 17px; height: 17px;margin-right: 2%;">
                      <span class="menu-title">{{menuSubItem.title}}</span>



                      <!-- Third level menu -->
                      <ul appSidebarDropdown class="menu-content" *ngIf="menuSubItem.submenu.length > 0">
                        <li appSidebarlink *ngFor="let menuSubsubItem of menuSubItem.submenu"
                          [parent]="menuSubItem.title" [path]="menuSubsubItem.path" level="{{level + 3}}">
                          <input type="checkbox" (change)="onCheckboxChange($event,menuSubsubItem.id)"
                            [checked]="menuSubsubItem.isActive" name="AllMenuIds"
                            style="width: 17px; height: 17px; margin-right: 2%;">
                          <span class="menu-title">{{menuSubsubItem.title}}</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      
    </form>
    </div>
  </div>
  
  
  
  
 