import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from "@angular/common";

@Component({
  selector: 'app-dps-mis-report',
  templateUrl: './dps-mis-report.component.html',
  styleUrls: ['./dps-mis-report.component.scss']
})
export class DpsMISReportComponent implements OnInit {

  constructor(
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private location: Location
  ) {}

  ngOnInit(): void {}
  goBack() {
    this.location.back();
  }

  onResized(event: any) {
    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }
  fireRefreshEventOnWindow() {
    throw new Error("Method not implemented.");
  }
  onUnderProClicked(type: number) {
    this.router.navigate(["/dashboard-custom/cutomer-lead"]);
    this.changeDetectorRef.detectChanges();
  }
  onWaiting(st: number) {
    if (st == 1) {
      this.router.navigate(["/dps-account/upcoming-matured-report"]);
    } else if (st == 2) {
      this.router.navigate(["/dps-account/prematured-encashment-report"]);
    } else if (st == 3) {
      this.router.navigate(["/dps-account/interest-report"]);
    } else if (st == 4) {
      this.router.navigate(["/dps-account/encashment-report"]);
    } else if (st == 5) {
      this.router.navigate(["/dps-account/dps-loan-report"]);
    }
  }

}
