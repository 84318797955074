import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
const apiUrl=environment.baseUrl +'/api/AccountGroup/';

@Injectable({
  providedIn: 'root'
})
export class SubGroupService {

  constructor(private http:HttpClient) { }
  GetAccountGroupList():Observable<any>{
    return this.http.get(apiUrl+'GetAccountSubGroupList');
  }

  GetAccountSubGroupList(currentPage: any, itemsPerPage : any, searchValue : string):Observable<any>{
    return this.http.get(apiUrl + 'GetAccountSubGroupWithPaginationAndSearch?page=' + currentPage + '&pageSize=' + itemsPerPage + '&search=' + searchValue);
  }

   SaveAccountGroup(model:any){
    return this.http.post(apiUrl +'SaveAccountGroup',model);
   }
  
   DeleteaccountGroupById(id: any) {
    return this.http.delete(apiUrl + 'DeleteaccountGroupById?Id=' + id)
  }

 
}
