import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { EmployeeRegisterComponent } from '../employee-component/employee-register/employee-register.component';
import { EmployeeListComponent } from '../employee-component/employee-list/employee-list.component';

const routes: Routes = [
  {path: "employeeregister",component: EmployeeRegisterComponent,data: {title: "BankUser",},},
  {path: "employee-list",component: EmployeeListComponent,data: {title: "BankUserList",},},
 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmployeeRoutingModule { }
