import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Fiscalyear } from '../../basic-model/fiscalyear';
import { FiscalyearService } from '../../basic-service/fiscalyear.service';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SharedService } from 'app/services/shared.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-fiscalyear',
  templateUrl: './fiscalyear.component.html',
  styleUrls: ['./fiscalyear.component.scss']
})

export class FiscalyearComponent implements OnInit {
  isEdit: boolean = false;
  frmGroup: FormGroup;
  list: Fiscalyear[] = [];
  model = new Fiscalyear();

  currentPage = 1;
  itemsPerPage = 10;
  totalItems = 0;
  currentItems = 0;
  searchControl = "";
  showSaveForm: string = '';

  constructor(
    private _service: FiscalyearService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private formBuilder: FormBuilder,
    private shared: SharedService,
    private datePipe: DatePipe,
  ) {
    this.frmGroup = new FormGroup({
      id: new FormControl(),
      yearName: new FormControl(),
      yearStatus: new FormControl(),
      startDate: new FormControl(),
      endDate: new FormControl(),
      lockDate: new FormControl(),

    });
  }

  ngOnInit(): void {
    this.getFiscalYearList();
    this.showSaveForm = 'saveList';
  }

  onChangeForShow(value: any) {
    this.currentPage = 1;
    this.itemsPerPage = value;
    this.getFiscalYearList();
  }
  onPageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.getFiscalYearList();
  }

  onSearch() {
    this.currentPage = 1; // Reset the current page to 1 when a new search is performed
    this.getFiscalYearList();
  }

  onClickShowSaveForm(value: string) {
    this.showSaveForm = value;
    this.reset();
  }

  reset() {
    this.isEdit = false;
    this.frmGroup.patchValue({
      id: null,
      yearName: null,
      yearStatus: null,
      startDate: null,
      endDate: null,
      lockDate: null
    })
  }

  getFiscalYearList() {
    this._service.getListWithSerchAndPagination(this.currentPage, this.itemsPerPage, this.searchControl).subscribe((res: any) => {
      this.list = res.data;
      this.totalItems = res.totalItems;
      this.currentItems = res.currentItems;
    })
  }

  refresh(data: Fiscalyear) {
    this.model.yearName = "";
    this.model.startDate = this.model.startDate;
    this.model.endDate = this.model.endDate;
    this.model.yearStatus = "";
    this.model.lockDate = this.model.lockDate;
  }


  onsubmit() {
    if (this.isEdit) {
      this.model.Id = this.frmGroup.value.id;
    }
    this.model.yearName = this.frmGroup.value.yearName;
    this.model.yearStatus = this.frmGroup.value.yearStatus;
    this.model.startDate = this.frmGroup.value.startDate;
    this.model.endDate = this.frmGroup.value.endDate;
    this.model.lockDate = this.frmGroup.value.lockDate;

    if (this.model.Id) {
      this._service.save(this.model).subscribe(res => {
        this.shared.ShowAlert("Success", "Updated Successfully", "success");
        this.isEdit = false;
        this.showSaveForm = 'saveList';
        this.getFiscalYearList();
      })
    } 
    else {
      this._service.save(this.model).subscribe(res => {
        this.shared.ShowAlert("Success", "Save Successfully", "success");
        this.getFiscalYearList();
        this.isEdit = false;
        this.showSaveForm = 'saveList';
      })
    }
  }

  delete(Id: any) {
    this._service.delete(Id).subscribe(res => {
      this.shared.ShowAlert("Success", "Data deleted successfully", "success");
      this.getFiscalYearList();
    })
  }

  edit(item: any) {
    this.isEdit = true;
    debugger
    this.showSaveForm = 'saveForm';
    this.frmGroup.patchValue({
      id: item.id,
      yearName: item.yearName,
      yearStatus: item.yearStatus,
      startDate: this.datePipe.transform(item.startDate, 'yyyy-MM-dd'),
      endDate: this.datePipe.transform(item.endDate, 'yyyy-MM-dd'),
      lockDate: this.datePipe.transform(item.lockDate, 'yyyy-MM-dd')
    })
  }
}
