<div class="card" style="width: 100%;">

<div class="row card-header py-3 d-flex flex-row align-items-center">
    <div class="col">
        <h5 class="card-title" style="color: black">New Accounts List</h5>
    </div>
    <!-- <div class="col" style="text-align: right;">
                    <button class="btn btn-primary" (click)="openingCreate()">Create</button>
                </div> -->

    <div class="col-1">
        <button class="btn btn-secondary" title="Go Back" (click)="goBack()">

            <i class="icon-arrow-left" style="font-size: 17px;"> </i>
        </button>
    </div>

</div>


<hr>
<div class="row" style="margin-left: 2%;">

    <div class="col-4">
        <label>From Date</label>
        <div class="col-12" style="margin-left: -16px;">

            <div class="input-group">
                <input type="date" class="form-control" [(ngModel)]="startDate" (ngModelChange)="onDateChange()"
                    placeholder="Select From Date">

            </div>
        </div>
    </div>

    <div class="col-4">
        <label>To Date</label>
        <div class="col-12" style="margin-left: -16px;">

            <div class="input-group">
                <input type="date" class="form-control" [(ngModel)]="EndDate" (ngModelChange)="onDateChange()"
                    placeholder="Select To Date">
            </div>

        </div>
    </div>

    <div class="col-4">
        <label></label>

        <div class="col-12 mt-1">
            <button class="btn btn-primary" style="background-color: #1fca74;color: white;"
                (click)="getCustomerDPSAccount()">Search</button>
        </div>
    </div>

</div>




    <div  class="card-body" style="overflow: auto;max-height: 600px;">
        <div class="container">
            
            <table id="example" class="table table-striped table-bordered scrollable-table" style="width:100%">
                <thead  style="position: sticky;top:0;z-index: 1;background-color: #f8f9fa;">
                    <tr>
                        <th>Sl.</th>
                        <th>Action</th>
                        <th>Account Type</th>
                        <th>Account Name</th>
                        <th>Account No</th>
                        <th>Account Create Date</th>
                        <th>Amount</th>
                
                    </tr>
                </thead>
                <tbody>
                    <ng-container ></ng-container>
                    <tr *ngFor="let item of customerList;let i = index">
                            <td style="width: 2%!;">{{i+1}}</td>
                        <td>
                            <button class="btn btn-info btn-sm" style="font-size: 12px; width: 80px !important; height: 25px;"
                                (click)="PrintKyc(item.accountId)" title="Click here for download pdf">View PDF</button>
                            <button class="btn btn-success btn-sm mt-2" style="font-size: 12px; width: 80px !important; height: 25px;"
                                (click)="DPSSchedulePDF(item.accountId)" title="Click here for download pdf">Schedule</button>
                        </td>
                        <td>{{item.accountType}}</td>
                        <td>{{item.title}}</td>
                        <td>{{item.accNo}}</td>
                        <td>{{item.createdDate | date: 'yyyy-MM-dd'}}</td>
                        <td>{{item.balance}}</td>
                
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>