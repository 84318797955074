import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { AccountingReportsRoutingModule } from './accounting-reports-routing.module';
import { AccountingReportComponent } from './accounting-report/accounting-report.component';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TrialBalanceReportComponent } from './trial-balance-report/trial-balance-report.component';
import { GroupSummaryComponent } from './group-summary/group-summary.component';
import { CcwiseReportComponent } from './ccwise-report/ccwise-report.component';
import { LedgerBookReportComponent } from './ledger-book-report/ledger-book-report.component';
import { ReportBuilderComponent } from './report-builder/report-builder.component';
import { SharedModule } from 'app/shared/shared.module';
import { ProfitLossReportComponent } from './profit-loss-report/profit-loss-report.component';
import { BalannceSheetReportComponent } from './balannce-sheet-report/balannce-sheet-report.component';
import { CashBookComponent } from './cash-book/cash-book.component';
import { BankBookComponent } from './bank-book/bank-book.component';
import { StatementReportComponent } from './statement-report/statement-report.component';
import { ChartOfAccountComponent } from './chart-of-account/chart-of-account.component';
import { AccountStatmentReportComponent } from './account-statment-report/account-statment-report.component';
import { DayBookComponent } from './day-book/day-book.component';
import { SubLedgerBookComponent } from './sub-ledger-book/sub-ledger-book.component';
import { LoanOutstandingComponent } from './loan-outstanding/loan-outstanding.component';
import { LoanOverdueComponent } from './loan-overdue/loan-overdue.component';

import { IncomeExpenseReportComponent } from './income-expense-report/income-expense-report.component';
import { LoanDisbursementComponent } from './loan-disbursement/loan-disbursement.component';
import { MisReportComponent } from './mis-report/mis-report.component';
import { LoanCollectionComponent } from './loan-collection/loan-collection.component';
import { LoanInterestIncomeComponent } from './loan-interest-income/loan-interest-income.component';
import { LoanSettlementReportComponent } from './loan-settlement-report/loan-settlement-report.component';
import { PaymentReceiveReportComponent } from './payment-receive-report/payment-receive-report.component';
import { NgSelectModule } from "@ng-select/ng-select";
import { CobBatchSettingComponent } from './cob-batch-setting/cob-batch-setting.component';
import { ProcessingfeeReportComponent } from './processingfee-report/processingfee-report.component';
import { SearchCobFileComponent } from './search-cob-file/search-cob-file.component';

@NgModule({
  declarations: [
    AccountingReportComponent,
    TrialBalanceReportComponent,
    GroupSummaryComponent,
    CcwiseReportComponent,
    LedgerBookReportComponent,
    ReportBuilderComponent,
    ProfitLossReportComponent,
    BalannceSheetReportComponent,
    CashBookComponent,
    BankBookComponent,
    StatementReportComponent,
    ChartOfAccountComponent,
    AccountStatmentReportComponent,
    DayBookComponent,
    SubLedgerBookComponent,
    LoanOutstandingComponent,
    LoanOverdueComponent,
    IncomeExpenseReportComponent,
    LoanDisbursementComponent,
    MisReportComponent,
    LoanCollectionComponent,
    LoanInterestIncomeComponent,
    LoanSettlementReportComponent,
    PaymentReceiveReportComponent,
    CobBatchSettingComponent,
    ProcessingfeeReportComponent,
    SearchCobFileComponent
  ],
  imports: [
    CommonModule,
    AccountingReportsRoutingModule,
    BrowserModule,
    RouterModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgSelectModule,
  ],
  providers: [DatePipe],
  entryComponents: [
    GroupSummaryComponent,
    LedgerBookReportComponent,
    TrialBalanceReportComponent,
  ],
})
export class AccountingReportsModule {}
