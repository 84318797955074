<div class="row">
    <hr>
<div class="col-md-12 card">
        
     
                <div class="row mt-4">
                    <div class="col-10 ">
                <h5 class="card-title" style="color: #888888; font-family: Arial; font-size: 20px !important;">Loan Overdue Report</h5>
                
            </div>
            <div class="col-2"><button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
                    <i class="icon-arrow-left" style="font-size: 17px;"> </i>
                  </button>
                </div>
               
            </div>
            <hr>
            <div class="card-body">
                <div class="col-md-12">
                    <div class="form-group row" id="DateRangeSelect">
                        <label for="asOnDate" class="col-sm-2 col-form-label text-left">As on Date <span class="redStar">*</span></label>
                        <div class="col-sm-4 input-group">
                            <input type="date" class="form-control" [(ngModel)]="asOnDate" placeholder="Select From Date">
                        </div>
                        <div class="col-sm-4">
                            <button class="btn btn-success" style="margin-left: 5px; float: right;" (click)="OpenPDF()">Print</button>
                            <button class="btn btn-info" style="margin-left: 5px; float: right;" (click)="OpenExcel()">Excel</button>
                            <button class="btn btn-warning" style="margin-left: 5px; float: right;" (click)="LoadData()">Load</button>

                        </div>
                       
                    </div>
                    
                    <!-- <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Get Report By</label>
                        <div class="col-sm-10">
                            <select type="text" class="form-control" [(ngModel)]="reportBy" default="12">
                                <option value="1">Loan Overdue</option>
                            </select>
                        </div>
                    </div> -->

                    <div class="form-group row" style="margin-top: 10px;">
                        <label class="col-sm-8"></label>
                        
                    </div>
                </div>
            </div>
        
 
</div>
<div class="col-12 card" style="overflow: auto;max-height: 600px;">
    <table  *ngIf="overdueList && overdueList.length > 0"  class="table table-striped table-bordered scrollable-table" style="width:100%;margin-top: 2%;">
        <thead style="position: sticky;top:0;z-index: 1;background-color: #f8f9fa;">
            <tr>
                <th style="width: 5%;">Sl.</th>
                <th>Account No & Title</th>
                <th>Disbursement Date</th>
                <th>Loan Amount</th>
                <th>Interest Amount</th>
                <th>Credit in MFS amount</th>
                <th>Total Overdue EMI Number</th>
                <th>Outstanding Amount</th>
                <th>Overdue Amount</th>
                <th>CL Status</th>
            </tr>
        </thead>
        <tbody>
                <tr *ngFor="let item of overdueList;let i=index"> 
                       <td>{{i+1}}</td>
                        <td>{{item.accountNo}} <br/>{{item.accounT_TITLE}}</td>
                        <td>{{item.disbursementDate}}</td>
                        <td style="text-align: right !important;">{{item.loanAmount}}</td>
                        <td style="text-align: right !important;">{{item.interestAmount}}</td>
                        <td style="text-align: right !important;">{{item.loanAmount-item.processingFee}}</td>
                        <td style="text-align: right !important;">{{item.overdueCount}}</td>
                        <td style="text-align: right !important;">{{item.outStanding}}</td>
                        <td style="text-align: right !important;">{{item.overDue}}</td>
                        <td style="text-align: right !important;">{{item.clStatus}}</td>
                </tr>
        </tbody>

        <tfoot>
            <tr>
                <td colspan="3" style="text-align: right;"> Total</td>
                <td style="text-align: right !important;">{{ totalLoanAmount | number:'1.2-2' }}</td>
                <td style="text-align: right !important;">{{ totalInterestAmount | number:'1.2-2' }}</td>
                <td style="text-align: right !important;">{{ totalDisburseAmount | number:'1.2-2' }}</td>
                <td style="text-align: right !important;">{{ totaloverdueCount | number:'1.0-2' }}</td>
                <td style="text-align: right !important;">{{ totalOutstandingAmount | number:'1.2-2' }}</td>
                <td style="text-align: right !important;">{{ totaloverDue | number:'1.2-2' }}</td>
            </tr>
        </tfoot>
    </table>
</div>

<div class="col-md-12 " *ngIf="!overdueList || overdueList.length === 0">

    <p style="text-align: center;"></p>
</div>
</div>

