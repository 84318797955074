import { Component, OnInit, ChangeDetectorRef, NgZone  } from '@angular/core';
import { CustomerService } from "../service/customer.service";
import { RegisterService } from 'app/services/register.service';
import { Customer } from "../../customer/model/customer.model";
import { FormBuilder, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'app/services/shared.service';//for sweetalert
import { NidBasic } from '../model/nid-basic.model';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-customercreate',
  templateUrl: './customercreate.component.html',
  styleUrls: ['./customercreate.component.scss']
})
export class CustomercreateComponent implements OnInit {
  
  //isPhoneNumberShow: boolean =false ;
  //isOtpShow: boolean = false;
  //isNIDShow: boolean = false;
  //isNIDOkShow: boolean = false;
  //isNIDData: boolean = false;
  isCutomerRestration: boolean = true;

  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  message = '';
  preview = '';
  proPreview = '';
  
  frontPreview = '';
  backPreview = '';

  mobileNumber: string = null;
  nidNumber: string = null;

  imageInfos?: Observable<any>;
  isEdit: boolean = false;
  model = new Customer();
  nid = new NidBasic();


  showResendButton = false;
  countdown = 120;
  countdownInterval: any;


  constructor(//
    private _service: CustomerService,
    private registerService: RegisterService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private shared:SharedService,
    private ngZone: NgZone
  ) {
    
  }

  ngOnInit(): void {
    this.loadGenderList();
    this.loadDistrictList();
    // this.getCustomerByMobile();
    // this.getCustomerByMobile();

    this.route.params.subscribe(params=>{
      this.mobileNumber=params['parameter'];
      this.nidNumber=params['parameter1'];
      
    });
    this.getCustomerByMobile(this.nidNumber,this.mobileNumber);

  }

  getCustomerByMobile(nidNumber:string,mobileNumber:string){
    this._service.getCustomerByMobile(nidNumber,mobileNumber).subscribe(res=>{
       
      this.model.CustomerNameEng=res['fullNameEN'];
      this.model.CustomerNameBan=res['fullNameBN'];
      this.model.FatherNameEng=res['fathersNameEN'];
      this.model.FatherNameBan=res['fathersNameBN'];
      this.model.MotherNameEng=res['mothersNameEN'];
      this.model.MotherNameBan=res['mothersNameBN']; 
      this.model.profileImage=res['photoUrl'];
      this.proPreview=res['photoUrl'];
      this.model.presentAddressEN=res['presentAddressEN'];
      this.model.permenantAddressEN=res['permenantAddressEN'];
      //this.model.GenderId=res['gender'];
      this.model.SpouseNameEng=res['spouseNameEN'];
      this.model.NidNo=res['nid'];
      this.model.MobileNo=res['customer'];
      this.model.DateOfBirth=res['dob'];



      //for gender
      const genderName = res['gender'];
      this.genderList.pipe(
        map((genderArray: any[]) => {
          const genderItem = genderArray.find(item => item.name === genderName);
          if (genderItem) {
            this.model.GenderId = genderItem.id;
          }
        })
      ).subscribe();
      this.changeDetectorRef.detectChanges();
    });
    this.changeDetectorRef.detectChanges();
  }

  genderList: any;
  loadGenderList() {
    this._service.GetGenderList().subscribe(res => {

      this.genderList = of(res);
    });
    this.changeDetectorRef.detectChanges();
  }

  districtList: Observable<any[]>;
  loadDistrictList() {
    this._service.GetDistrtList().subscribe(res => {

      this.districtList = of(res);
    });
    this.changeDetectorRef.detectChanges();
  }


  onDistrictChange(dId: any): void {
    if (dId > 0 || dId != null || dId != "") {
      this.getUpazila(dId);
    }
  }

  upazilaList: Observable<any[]>;
  getUpazila(dId: number): void {
    this._service.GetUpazilaListByDistrictId(dId).subscribe(res => {
      this.upazilaList = of(res as any[]);
    });
    this.changeDetectorRef.detectChanges();
  }

  resetCust() {
    this.isEdit = false;
  }

  onsubmit(data: Customer) {

    // if (data.valid) {
    // }

    //alert(this.model.CustomerNameBan);

    this.model.profileImage=this.proPreview;
    this.model.CustomerRCode = "0001";
    this.model.CustomerNameEng = this.model.CustomerNameEng;
    this.model.CustomerNameBan = this.model.CustomerNameBan;
    this.model.FatherNameEng = this.model.FatherNameEng;
    this.model.FatherNameBan = this.model.FatherNameBan;
    this.model.MotherNameEng = this.model.MotherNameEng;
    this.model.MotherNameBan = this.model.MotherNameBan;
    this.model.DateOfBirth = this.model.DateOfBirth;
    this.model.GenderId = this.model.GenderId;
    // this.model.MobileNo = '880' + this.model.MobileNo;
    this.model.MobileNo =  this.model.MobileNo;
    this.model.Email = this.model.Email;
    this.model.NidNo = this.model.NidNo;
    this.model.MaritalStatus = this.model.MaritalStatus;
    this.model.SpouseNameEng = this.model.SpouseNameEng;
    this.model.SpouseNameBan = this.model.SpouseNameBan;
    this.model.SpouseMobileNo = this.model.SpouseMobileNo;
    //this.model.otp = this.model.otp.toString();
    //console.log(this.model);

    // if (this.isEdit) {
    //   this._service.update(data).subscribe(res => {
    //     this.shared.ShowAlert("Success","You updated succesfully!","Ok");
    //     this.resetCust();
    //   })
    // }
    // else {
    //   //alert(JSON.stringify(this.model));
    //   this._service.save(this.model).subscribe(res => {
    //     //console.log(this.model)
    //     const message = res['message'];
    //     this.shared.ShowAlert("Success",message,"success");
    //     this.router.navigate(['/pages/login']);


    //   })
    // }
    // this.changeDetectorRef.detectChanges();


    this._service.saveCustomerInfo(this.model).subscribe((res)=>{

      const message = res['message'];
      const token = res['obj'];
     
      localStorage.setItem('token', res['obj']);
      //sessionStorage.setItem('currentUser',`{"jwt":"${res['obj']}","userInfo":"${res['userInfo']}","role":"Customer"}`);

      sessionStorage.setItem('currentUser', JSON.stringify({
        jwt: res['obj'],
        userInfo: res['userInfo'],
        role: 'Customer'
      }));
      

      this.shared.ShowAlert("Success",message,"success");
      setTimeout(() => {
        //this.router.navigate(['/user']);
        this.router.navigate(['/dashboard/dashboard-new']);
      }, 2000);
    },(error) => {
      this.shared.ShowAlert("Wrong","Something wrong","error");
    });
  }

  // onPhoneNumberSubmit(form) {
  //   const mobile= '88' + this.model.MobileNo;
  //   if(mobile.length===13 && !isNaN(parseFloat(this.model.MobileNo)) && !isFinite(Number(this.model.MobileNo))){
  //     alert("Give number in field not text");
  //   }
  //   else if(mobile.length!=13){
  //     alert("Mobile Number Feild Minimum Length 13");
  //   }
  //   else{
  //     this.registerService.sendOTP('88' + this.model.MobileNo, '', 1, 1).subscribe((res) => {
  //       if (res.status === 'ok') {
  //         this.isPhoneNumberShow = false;
  //         this.isOtpShow = true;
  //         this.isCutomerRestration = false;
  //         this.changeDetectorRef.detectChanges();
  //       }
  //       else if(res.status ==="user"){
  //         alert(res.message);
  //         this.router.navigate(['/pages/login']);
  //       }
  //       this.startCountdown(form,1);
  //     },
  //       (error) => {
  //         //console.error('Error submitting data:', error);
  //         this.isPhoneNumberShow = false;
  //         this.isOtpShow = true;
  //         this.isCutomerRestration = false;
  //         this.changeDetectorRef.detectChanges();
  //       });
  
  //   }
  // }

  // onOTPSubmit(form) {
  //   this.registerService.verifyOTP('88' + this.model.MobileNo, '', 1, this.model.otp).subscribe((res) => {
  //     if (res.status === 'ok') {
  //       this.shared.ShowAlert("Matched","Otp Matched","success");
  //       this.isPhoneNumberShow = false;
  //       this.isOtpShow = false;
  //       this.isNIDShow = true;
  //       this.isNIDData = false;
  //       this.isCutomerRestration = false;
  //       this.changeDetectorRef.detectChanges();
  //     }
  //     else if (res.status === 'expired') {
  //       // this.showAlert("Expired",res.message,"error");
  //       this.shared.ShowAlert('Expired', res.message, 'error');
  //     }
  //     else if (res.status === 'oldOtp') {
  //       this.shared.ShowAlert('Expired', res.message, 'warning');
  //     }
  //     else {
  //       this.shared.ShowAlert("Wrong","Otp Not Matched","error");
  //     }
  //   },
  //     (error) => {
  //       this.shared.ShowAlert("Wrong","Something wrong","error");
  //     });



  //   //this.isCutomerRestration = true;
  // }
  // onNidOK() {
  //       this.isPhoneNumberShow = false;
  //       this.isOtpShow = false;
  //       this.isNIDShow = false;
  //       this.isCutomerRestration = true;
  //       this.changeDetectorRef.detectChanges();
      
  // }

  // startCountdown() {
  //     this.showResendButton = false;
  //     this.countdown = 15;
      
  //     this.countdownInterval = setInterval(() => {
  //         this.ngZone.run(() => {
  //           console.log(this.countdown);
  //             if (this.countdown <= 0) {
  //                 this.showResendButton = true;
  //                 clearInterval(this.countdownInterval);
  //             }
  //             else{
  //               this.countdown--;
  //             }
  //             console.log(this.showResendButton);
  //         });
  //     }, 1000);
  // }


  // startCountdown(form, sl) {
  
  //   if (this.countdownInterval) {
  //     clearInterval(this.countdownInterval);
  //   }
  
  //   if (sl !== 1) {
  //     this.onPhoneNumberSubmit(form);
  //     this.countdown = 60;
  //   }
  
  //   this.countdownInterval = setInterval(() => {
  //     this.ngZone.run(() => {
  //       if (this.countdown <= 0) {
  //         this.showResendButton = true;
  //         clearInterval(this.countdownInterval);
  //       } else {
  //         this.countdown -= 1; // Decrement countdown by 1
  //         this.changeDetectorRef.detectChanges();
  //       }
  //     });
  //   }, 1000);
  // }


  // onNidSubmit(form) {
  //   this.model.NidNo=this.model.NidNo;
  //   this.model.DateOfBirth=this.model.DateOfBirth;

  //   //this.registerService.verifyNID(this.model.NidNo, this.model.DateOfBirth).subscribe((res) => {
  //     this.registerService.verifyNID(this.model).subscribe((res) => {
     
  //     if (res.status === 'ok') {
  //       this.shared.ShowAlert("Verified",res.message,"success");
  //       this.isNIDOkShow=true;
  //       this.isNIDData = true;
  //       this.nid=res.nid;
  //       this.changeDetectorRef.detectChanges();
  //       // this.isPhoneNumberShow = false;
  //       // this.isOtpShow = false;
  //       // this.isNIDShow = false;
  //       // this.isCutomerRestration = true;
  //       // this.changeDetectorRef.detectChanges();
  //     }
  //     else if(this.model.NidNo==="1234567890"){
  //       this.shared.ShowAlert("Verified","NID Verified Successfully.","success");
  //       this.isPhoneNumberShow = false;
  //       this.isOtpShow = false;
  //       this.isNIDShow = false;
  //       this.isCutomerRestration = true;
  //       this.changeDetectorRef.detectChanges();
  //     }
  //     else {
  //       alert('Invalid NID');
  //     }
  //   },
  //     (error) => {
  //       //console.error('Error submitting data:', error);
  //       this.isPhoneNumberShow = false;
  //       this.isOtpShow = false;
  //       this.isNIDShow = false;
  //       this.isCutomerRestration = true;
  //       this.changeDetectorRef.detectChanges();
  //     });


  // }

  selectProfilePicture(event: any): void {
    this.message = '';
    this.proPreview = '';
    this.progress = 0;
    this.selectedFiles = event.target.files;

    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.proPreview = '';
        this.currentFile = file;

        const reader = new FileReader();

        reader.onload = (e: any) => {
          this.proPreview = e.target.result;
          this.changeDetectorRef.detectChanges();
          const div = document.getElementById('imageProPreview');
          div.style.width = 90 + '%';
          div.style.height = 90 + '%';
        };
        reader.readAsDataURL(this.currentFile);
      }
    }
  }

  selectFile(event: any): void {
    this.message = '';
    this.preview = '';
    this.progress = 0;
    this.selectedFiles = event.target.files;

    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.preview = '';
        this.currentFile = file;

        const reader = new FileReader();

        reader.onload = (e: any) => {
          //console.log(e.target.result);
          this.preview = e.target.result;

        };

        reader.readAsDataURL(this.currentFile);
      }
    }
  }

 

}
