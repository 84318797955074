import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { PdfReportingRoutingModule } from './pdf-reporting-routing.module';
import { VoucherReportComponent } from './voucher-report/voucher-report.component';
import { LedgerBookComponent } from './ledger-book/ledger-book.component';


@NgModule({
  declarations: [
    VoucherReportComponent,
    LedgerBookComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    PdfReportingRoutingModule
  ]
})
export class PdfReportingModule { }
