<form [formGroup]="frmGroup">
    <div class="col-md-12">
        <div class="card" style="width: 100%;">
            <div class="card-header">
                <h5 class="card-title" style="color: black;">Transfer to Nagad</h5>
            </div>
                
            <div class="card-body" style="margin-top: 30px;">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="customerCode" class="col-sm-4 col-form-label">Debit Account</label>
                            <div class="col-sm-8"> 
                                <select class="form-control" name="customerCode" formControlName="customerAccountId"  [(ngModel)]="accountNotFound1"
                                (change)="loadUserDataByAccountNoFound1($event.target.value)">
                                    <option value="">Select</option>
                                    <ng-container *ngFor="let item of AccountNoList | async">
                                        <option [value]="item.id">{{item.customerAccountNo}} - {{item.accountType}}</option>
                                    </ng-container>
                                </select>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="" class="col-sm-4 col-form-label">Current Balance</label>
                            <div class="col-sm-8">
                                <input type="text" style="text-align: right;" class="form-control"  [value]="currentBalance | number:'1.0-2'" readonly>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="" class="col-sm-4 col-form-label">Debit Customer</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" formControlName="customerCode" value="{{customerInfo_cashFundTransfer1}}" readonly>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="name" class="col-sm-4 col-form-label">Phone Number</label>
                            <div class="col-sm-8">
                              <div class="input-group">
                                <input type="text" class="form-control" formControlName="customerAccountId2" [(ngModel)]="accountNoFund2"
                                 placeholder="Phone Number"
                                >    
                              </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="" class="col-sm-4 col-form-label">Amount</label>
                            <div class="col-sm-8">
                                <input type="text" style="text-align: right;" class="form-control" formControlName="voucherAmount"  placeholder="0"
                                (input)="frmGroup.get('voucherAmount').setValue(formatNumber($event.target.value))" (keyup)="checkBalance()"
                                (keyup)="decreaseAmountValue($event.target.value)">
                                <span *ngIf="frmGroup.get('voucherAmount').hasError('pattern')" class="text-danger">
                                    Only numeric values are allowed.
                                </span> 
                                <span class="text-danger" style="font-size: 10px; font-weight: bold;" *ngIf="!isvalid">Invalid Amount. Amount cannot be more than the Balance.</span>
                            </div>
                        </div>

                        <!-- <div class="form-group row">
                            <label for="" class="col-sm-4 col-form-label">Account Name</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" value="{{accountName}}" readonly>
                            </div>
                        </div> -->

                        <div class="form-group row">
                            <label for="" class="col-sm-4 col-form-label">Remarks</label>
                            <div class="col-sm-8">
                                <textarea class="form-control" formControlName="remarks" cols="30" rows="2"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <button data-toggle="tooltip" (click)="onsubmit()" *ngIf="isBtnValid" title="Save" type="button"
                class="btn btn-success" style="float:right; margin-top:5px;">Send </button>

            <button type="button" title="Refresh" class="btn btn-secondary"
                style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;" (click)="ResetForm()">Refresh</button>
            </div>
        </div>
    </div>
</form>
