<div class="card" style="width: 100%;">

    <div class="card-header row">
        <div class="col-6">
            <h5 class="m-0 font-weight-bold text-primary">CIB Subject Report</h5>
        </div>
        <div class="col-6">
            <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">

                <i class="icon-arrow-left" style="font-size: 17px;"> </i>
            </button>
        </div>
    </div>
    <hr>
    <div class="card-body">

        <div class="container">
            <!-- <form #frmSalaryProcess="ngForm" (ngSubmit)="submitForm()"> -->
            <form #frmSalaryProcess="ngForm">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group row">
                            <label for="salaryPeriodId" class="col-md-3 col-form-label">Select Month<span
                                    style="color:red;">*</span></label>
                            <div class="col-md-6">
                                <!-- <input type="date" class="form-control" formControlName="generateDate" placeholder=""> -->
                                <select [(ngModel)]="monthsId" class="form-control" required>
                                    <option [value]="0">Select Month</option>
                                    <option [value]="1">January</option>
                                    <option [value]="2">February</option>
                                    <option [value]="3">March</option>
                                    <option [value]="4">April</option>
                                    <option [value]="5">May</option>
                                    <option [value]="6">June</option>
                                    <option [value]="7">July</option>
                                    <option [value]="8">August</option>
                                    <option [value]="9">September</option>
                                    <option [value]="10">October</option>
                                    <option [value]="11">November</option>
                                    <option [value]="12">December</option>
                                
                                </select>




                                <!-- <select name="salaryPeriodId" id="salaryPeriodId" class="form-control" required
                                    (change)="loadSalaryPeriods()" [(ngModel)]="selectedSalaryPeriod">
                                    <option value="">Select Salary Period</option>
                                    <option *ngFor="let period of salaryPeriods" [value]="period.id">{{
                                        period.periodName }}</option>
                                </select> -->
                               
                            </div>
                        
                           
                        </div>



                    </div>


                    <div class="col-md-12">
                        <div class="form-group row">
                            
                            <!-- <label for="" class="col-md-3 col-form-label">Select Customer<span style="color:red;">*</span></label>
                            <div class="col-md-6">
                    
                                <input type="text" class="form-control" formControlName="customerName"
                                    placeholder="Search By customer name / code..." />
                    
                            </div> -->
                            <div class="col-3"></div>
                            
                            <div class="col-md-6">
                                <button type="button" class="btn btn-info mr-2" (click)="PreviewData()">Preview</button>
                    
                                <button type="button" class="btn btn-success" (click)="OpenTextWithDate()">Download Text</button>
                    
                            </div>
                        </div>
                    
                    
                    
                    </div>
                </div>
            </form>
        </div>
    </div>






<div class="col-12 card" style="overflow: auto;max-height: 600px;">
    <table *ngIf="subjectDataList && subjectDataList.length > 0"
        class="table table-striped table-bordered scrollable-table" style="width:100%;margin-top: 2%;">
        <thead style="position: sticky;top:0;z-index: 1;background-color: #f8f9fa;">

            <tr>
                <th style="width:5%;">Sl.</th>
                <th>Record Type</th>
                <th>Customer Code</th>
                <th> Customer Full Name</th>
                <th>Father Name</th>
                <th>Mother Name</th>
                <th> Gender</th>
                <th> Birth Date</th>
                <th> NID No</th> 
                <th>E TIN</th>
                
               <th> Present Address</th>
                
                <th>Permanent Address</th>
                
                <th> Phone</th>
            </tr>

        <tbody>
            <tr *ngFor="let item of subjectDataList ; let i = index">
                <td>{{i+1}} .</td>
                <td>{{item.recordType}}</td>
                <td>{{item.customerCode}}</td>
                <td>{{item.customerFullName}}</td>
                <td>{{item.fatherName}}</td>
                <td>{{item.motherName}}</td>
                <td>{{item.gender}}</td>
                <td>{{item.birthDate | date:'dd-MM-yyyy'}}</td>
                <td>{{item.nidNo}}</td>
                <td>{{item.eTin}}</td>
              
                <td style="text-align: right !important;">{{item.presentAddress}}</td>
                <td style="text-align: right !important;">{{item.permanentAddress}}</td>
                <td style="text-align: right !important;">{{item.phone}}</td>
                
            </tr>
        </tbody>
        


    </table>
</div>






</div>