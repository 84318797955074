<section>
  <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveForm'">

    <div class="card-body">
      <div class="container">
        <div class="row">
          <div class="col">
            <h5 class="card-title" style="color: black">Internal GL Ledger Info</h5>
            <button type="button" data-toggle="tooltip" title="Go to list" class="btn btn-secondary"
              (click)="goToList()"
              style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">List View</button>

          </div>

        </div>
        <hr>
        <form [formGroup]="frmGroup">
          <div class="row">
            <div class="col-md-12">

              <div class="form-group row">
                <label for="" class="col-sm-4 ">GL Type</label>
                <div class="col-sm-8">
                  <select (change)="onNatureGroupChange($event.target.value)" class="form-control" name="natureId" formControlName="natureId">
                    <option value="0">Select</option>
                    <ng-container *ngFor="let item of natureList">
                      <option [value]="item.id">{{item.natureName}} - {{item.natureCode}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>              
              <div class="form-group row">
                <label for="" class="col-sm-4 ">Group  <span style="color: red;">
                  *</span></label>
                <div class="col-sm-8">
                  <select (change)="onGroupChange($event.target.value)" class="form-control" name="groupId" formControlName="groupId">
                    <option value="0">Select</option>
                    <ng-container *ngFor="let item of groupListByNature">
                      <ng-container *ngIf="!item.parentGroupId; else childOption">
                        <!-- Render options for parent groups -->
                        <option [value]="item.id" disabled  style="color:blue">{{item.groupName}} - {{item.groupCode}}</option>
                      </ng-container>
                      <ng-template #childOption>
                        <!-- Render options for child groups -->
                        <option [value]="item.id" style="color:darkblue">&nbsp;&nbsp;&nbsp;&nbsp; {{item.groupName}} - {{item.groupCode}}</option>
                      </ng-template>
                    </ng-container>
                  </select>
                  
                  <input type="hidden" class="form-control" name="id" formControlName="id">
                </div>
              </div>
             

              <div class="form-group row">
                <label for="" class="col-sm-4 col-form-label">Account Name  <span style="color: red;">
                  *</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" name="accountName" formControlName="accountName"
                    placeholder="Account Name">
                </div>
              </div>

              <div class="form-group row">
                <label for="" class="col-sm-4 ">Account Code</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" name="accountCode" formControlName="accountCode" readonly
                    placeholder="Account Code">
                </div>
              </div>
              <div class="form-group row">
                <label for="" class="col-sm-4 ">ledger Type  <span style="color: red;">
                  *</span></label>
                <div class="col-sm-8">
                  <select class="form-control" name="ledgerType" formControlName="ledgerType" >
                    <option value="0">Select</option>
                    <option value="Cash">Cash</option>
                    <option value="Bank">Bank</option>
                    <option value="General">General</option>

                  </select>
                  

                </div>
              </div>
              <!-- <div class="form-group row">
                    <label for="" class="col-sm-4 ">Effective Date</label>
                    <div class="col-sm-8">
                      <input type="date" class="form-control" name="accountCode" effectiveDate="effectiveDate" >
                    </div>
                </div> -->
            </div>
            <div class="col-md-12">

              


              <div class="form-group row">
                <label for="" class="col-sm-4 col-form-label">Remarks</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" name="remarks" formControlName="remarks"
                    placeholder="Remarks">
                </div>
              </div>
              <!-- <div class="form-group row">
                <label for="" class="col-sm-4 col-form-label">Is Active</label>
                <div class="col-sm-2">
                  <input type="checkbox" [value]="1" style="width: 20px;height: 20px;margin-top: 6px"
                    class="form-control" name="isActive" formControlName="isActive" placeholder="isActive">
                </div>
              </div> -->
            </div>
          </div>
          <button data-toggle="tooltip" (click)="onsubmit()" title="Save" type="button" class="btn btn-success"
            style="float:right; margin-top:5px;">Save </button>

        </form>
      </div>

    </div>


  </div>
  <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveList'">
    <div class="card-body">
      <div class="container">
        <div class="row">
          <div class="col">
            <h5 class="card-title" style="color: black">Ledger List</h5>
            <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary"
              (click)="onClickShowSaveForm('saveForm')"
              style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">Add New</button>
          </div>

        </div>
        <hr>
        <table id="example" class="table table-bordered  ">
          <thead>
            <tr>
              <th>SL No.</th>
              <th>Nature</th>
              <th>Group</th>
              <th>Sub Group</th>
              <th>Ledger Code</th>
              <th>Ledger Name</th>
             
            
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of list; let i = index;">
              <td>{{i + 1}}</td>
              <td>{{item.group.nature.natureName}} - {{item.group.nature.natureCode}}</td>
              <td>{{item.group.groupName}}</td>
              <td>{{item.group.groupName}}</td>
              <td>{{item.accountCode}}</td>
              <td>{{item.accountName}}</td>
              <td>
                <button class="btn btn-primary" (click)="edit(item)"><i class="fa fa-edit 3x"></i></button>
                <button [disabled]="isEdit" class="btn btn-danger" style="margin-left: 5px;"
                  (click)="delete(item.id)"><i class="fa fa-trash-o 3x"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>