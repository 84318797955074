import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { DatePipe, Location } from "@angular/common";
import { LoanProductService } from 'app/product/service/loan-product.service';

@Component({
  selector: "app-loan-outstanding",
  templateUrl: "./loan-outstanding.component.html",
  styleUrls: ["./loan-outstanding.component.scss"],
})
export class LoanOutstandingComponent implements OnInit {
  asOnDate: Date = new Date();
  mfsType: number = 0;
  accountNo: string = null;
  mfsAccountList: any[];
  outstandingLoanList: any[];

  totalLoanAmount: number = 0;
  totalInterestAmount: number = 0;
  totalDisburseAmount: number = 0;
  totalOutstandingAmount: number = 0;
  totalPaidAmount: number = 0;
  constructor(
    private pdfService: PdfService,
    private location: Location,
    private productService: LoanProductService,
    private changeDetectorRef: ChangeDetectorRef,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.getMFSList();
  }
  OpenPDF() {
    this.pdfService
      .LoanOutstandingReport(this.accountNo, this.asOnDate, this.mfsType)
      .subscribe((res) => {
        let blob: Blob = res.body as Blob;
        let url = window.URL.createObjectURL(blob);

        window.open(url);
      });
  }
  OpenExcel() {
    this.pdfService
      .LoanOutstandingExcelReport(this.accountNo, this.asOnDate, this.mfsType)
      .subscribe((res) => {
        let blob: Blob = res.body as Blob;
        let url = window.URL.createObjectURL(blob);

        window.open(url);
      });
  }
  goBack() {
    this.location.back();
  }

  getMFSList() {
    this.productService.getMFSAccountList().subscribe((res) => {
      this.mfsAccountList = res;
      this.changeDetectorRef.detectChanges();
    });
  }

  LoadData() {
    this.pdfService
      .LoanOutstandingData(this.accountNo, this.asOnDate, this.mfsType)
      .subscribe((res) => {
        this.outstandingLoanList = res.body;
        this.updateTotals();
      });
  }

  formatDateString(dateString: string): string {
    const formattedDate = this.datePipe.transform(dateString, "dd-MM-yyyy"); // Adjust the format as needed
    return formattedDate || dateString;
  }

  updateTotals(): void {
    this.totalLoanAmount = 0;
    this.totalInterestAmount = 0;
    this.totalDisburseAmount = 0;
    this.totalOutstandingAmount = 0;
    this.totalPaidAmount = 0;

    for (let i = 0; i < this.outstandingLoanList.length; i++) {
      const item = this.outstandingLoanList[i];
      this.totalLoanAmount += item.loanAmount || 0;
      this.totalInterestAmount += item.interestAmount || 0;
      this.totalDisburseAmount +=
        (item.loanAmount || 0) + (item.interestAmount || 0);
      this.totalOutstandingAmount +=
        (item.principleOutstanding || 0) + (item.interestOutstanding || 0);
        this.totalPaidAmount += item.paidAmount || 0;
    }
  }
}
