import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { SharedService } from 'app/services/shared.service';
import { CustomerService } from 'app/customer/customer.service';
import { Observable } from 'rxjs';
import { CashPosting } from '../model/cash-posting';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { CashPostingService } from '../service/cash-posting.service';
import { sub } from 'date-fns';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mobile-recharge',
  templateUrl: './mobile-recharge.component.html',
  styleUrls: ['./mobile-recharge.component.scss']
})
export class MobileRechargeComponent implements OnInit {

  frmGroup: FormGroup;
  accountNoFund: string = '';
  currentBalance: number;
  totalCurrentBalance: number;
  voucherAmount:number;
  isvalid: boolean = true;
  isBtnValid: boolean = true;
  isvalidNumber: boolean = true;
  options = ['PrePaid', 'PostPaid',];
  paidType: string;
  customerInfo_cashFundTransfer1: any = '';
  model = new CashPosting();
  customerIdPayment: number;
  customerAccountId: number;
  glAccountName: any;
  customerInfo1: any = '';
  billInfo: any = '';
  accountNoFund1: string = '';
  accountNoFund2: string = '';
  customerAccountId2: number;
  accountName: string = '';
  MobileNumber: string = '';
  OperatorType: string= '';
  isEdit: boolean = false;


  constructor(
    private shared: SharedService,
    private _CustomerService: CustomerService,
    private formBuilder: FormBuilder,
    private _cashService: CashPostingService,
    private router: Router,
  ) {

    this.frmGroup = new FormGroup({
      customerCode: new FormControl(),
      operatorCode: new FormControl(),
      MobileNo: new FormControl(),
      //voucherAmount: new FormControl,
      MobileNumber: new FormControl(),
      OperatorType: new FormControl(),

    })
    this.frmGroup = this.formBuilder.group({
      customerCode: new FormControl(''),
      operatorCode: new FormControl(''),
      MobileNo: new FormControl(''),
      customerAccountId2: new FormControl(''),

      voucherAmount: ['', [Validators.required, Validators.pattern('^[0-9,.]*$')]]
    });

  }

  ngOnInit(): void {
    this.GetCustomerAccountNo();
   this.GetMobileRechargeByMobileNo();
    this.GetMobileOperator();
  }

  AccountNoList: Observable<any[]>;
  GetCustomerAccountNo() {
    this._CustomerService.GetCustomerAccountNo().subscribe(res => {
       
      this.AccountNoList = res;
    });
  }
  loadUserDataByAccountNoFundFirst(accountId: number) {

    this._CustomerService.GetCustomerInfoByAccountNoById(accountId).subscribe(res => {
      if (res != null) {

        if (res.glAccountName == null) {
          this.currentBalance = res.currentBalance.toLocaleString();
          this.totalCurrentBalance = res.currentBalance.toLocaleString();
          //this.customerIdPayment = res.customersId;
          this.customerInfo1 = res.customerNameEng + '; ' + res.mobileNo + '; ' + res.nidNo;
          this.customerAccountId = res.id;
          // this.isBtnValid = true;
          // this.glAccountName = '';
        }

      }
      else {
        // this.refreshAc();
        swal('Warning', 'Invalid Account No', 'warning');
      }
    })
  }


  OperatorsList: Observable<any[]>;
  GetMobileOperator() {
    this._CustomerService.GetMobileOperator().subscribe(res => {
      console.log("MobileOperator")
      console.log(res)
      this.OperatorsList = res;
    })
  }
  loadUserDataByAccountNoFundLast(OperatorType: string) {
    
      this._CustomerService.GetMobileOperatorsList(OperatorType).subscribe(res => {
        
        this.accountName = res.name;
        //this.customerAccountId2 = res.id;
      })
     
  }

  mobileRechargeList: Observable<any[]>;
  GetMobileRechargeByMobileNo() {
    this._CustomerService.GetMobileRechargeInfo().subscribe(res => {
      this.mobileRechargeList = res;
    })
  }


  loadMobileBankingInfoById(MobileNumber: string) {
    this._CustomerService.GetMobileRechargeByMobileNo(MobileNumber).subscribe(res => {    
      this.accountName = res.accountName;
      //this.customerAccountId2 = res.id;
    })
  }

  formatNumber(value: string): string {
    // Remove existing commas and format the value
    const formattedValue = value.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return formattedValue;
  }
 

  CheckBalance() {

    var balanceAmount = this.currentBalance.toString().replace(/,/g, '');
    var inputAmount = parseInt(this.frmGroup.value.voucherAmount.replace(/,/g, ''), 10);
     const value = parseInt(balanceAmount) - inputAmount
      
    if (inputAmount > parseInt(balanceAmount, 10)) {
      
    
      this.isvalid = false;
    }
    else {

      this.isvalid = true;
    }
  }
  voucherAmountValue(value: any) {
     var balanceAmount = this.totalCurrentBalance.toString().replace(/,/g, '');
     var inputAmount = parseInt(value.replace(/,/g, ''), 10);

     if(inputAmount>0){
      this.currentBalance = parseInt(balanceAmount) - inputAmount;
     }
     else{
      this.currentBalance = this.totalCurrentBalance;
     }
  }
  

  refreshAc() { }

  onsubmit() {

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to Reacharge!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, send it!"
    }).then((res) => {
      if (res.isConfirmed) {

    if (this.isEdit) {
      this.model.id = this.frmGroup.value.id;
    }
    else {
      this.model.id = 0;
    }

    this.model.customerCode = this.frmGroup.value.customerCode;
    this.model.operatorCode = this.frmGroup.value.operatorCode;
    this.model.MobileNo = this.frmGroup.value.MobileNo;

    this.model.transactionType = 3;
    this.model.transactionTypeId = 12;
    this.model.customerAccountId = this.customerAccountId;
        this.model.customerAccountId2 = this.frmGroup.value.MobileNo;
    //this.model.remarks = this.frmGroup.value.remarks;
    //this.model.currencyId = this.frmGroup.value.currencyId;

    this.model.voucherAmount = this.frmGroup.value.voucherAmount.replace(/,/g, '');

    // this.model.gLGenerateId =  this.gLGenerateId;
    if (this.model.voucherAmount > 0) {
      if (this.model.id == 0) {

        this._cashService.SaveMobileRecharge(this.model).subscribe((res: number) => {

          if (res > 0) {
            //this.ResetForm();
            this.refreshAc();

            this.shared.ShowAlert("Success", "Save Successfully", "success");
            this.isEdit = false;
          }
          else {
            this.shared.ShowAlert("Warning", "Failed to Save", "warning");
            this.isEdit = false;
          }

        })
      }
      else {
        this._cashService.SaveMobileRecharge(this.model).subscribe(res => {
          //this.ResetForm();
          this.refreshAc();

          this.shared.ShowAlert("Success", "Save Successfully", "success");
          this.isEdit = false;
        })
      }
    }
  }
  else if (res.isDismissed) {
    this.router.navigate(['/accounting/mobile-recharge']);
  }
});

} 



}
