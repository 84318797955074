import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-statment-report',
  templateUrl: './account-statment-report.component.html',
  styleUrls: ['./account-statment-report.component.scss']
})
export class AccountStatmentReportComponent implements OnInit {
  fromDate:Date =new Date();
  toDate:Date = new Date();
  reportBy:number;
  AccountStatmentList:  any[] = [];
  constructor() { }

  ngOnInit(): void {
  }

   

  OpenPDF(){

  }

}
