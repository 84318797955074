import { Component, OnInit } from '@angular/core';
import { CustommeraccountService } from '../custommeraccount.service';
import { Customeraccount } from '../model/customeraccount';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { Location } from "@angular/common";

@Component({
  selector: 'app-customer-account-list',
  templateUrl: './customer-account-list.component.html',
  styleUrls: ['./customer-account-list.component.scss']
})
export class CustomerAccountListComponent implements OnInit {

  customerAccountlist: Customeraccount[] = [];
  constructor(private _service: CustommeraccountService,private pdfService:PdfService,
    private location: Location) { 
    this.getCustomerAccountListInfo();
  }

  ngOnInit(): void {
  }
  getCustomerAccountListInfo() {
    this._service.getCustomerAccountList().subscribe(res => {
      this.customerAccountlist = res;
    })
  }
  openingCreate(){
    window.open('/customeraccount/customeraccount');
  }
  PrintKyc(id:number){
    this.pdfService.OpenKycPdf(id).subscribe(res => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    })
  }
  
  goBack() {
    this.location.back();
  }
}
