<div class="card" style="width: 100%;">
  <div class="card-body">
    <div class="container">    
      <div class="card" style="width: 100%;"*ngIf="showSaveForm==='saveForm'">
        <div class="" style="margin-top: 3%;">
          <h3 class="card-title" style="color: black;text-align: center;">District Info</h3>
          <button type="button" data-toggle="tooltip" title="Add new"  class="btn btn-secondary" (click)="onClickShowSaveForm('saveList')" style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">List View</button>
        </div>
        <div class="card-body">
          <form>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="" class="col-sm-4 ">District Code</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" name="districtCode" [(ngModel)]="model.districtCode"
                      placeholder="District Code">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="" class="col-sm-4 col-form-label">Country</label>
                  <div class="col-sm-8">
                    <select class="form-control" name="countryId" [(ngModel)]="model.countryId" (change)="onCountryChange($event.target.value)">
                      <option value="">Select</option>
                      <ng-container *ngFor="let item of CountryList | async">
                        <option [value]="item.id">{{item.countryName}}</option>
                      </ng-container>
                    </select>
                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="" class="col-sm-4 col-form-label">District Name</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" name="districtName" [(ngModel)]="model.districtName" placeholder="District Name">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="" class="col-sm-4 col-form-label">Division</label>
                  <div class="col-sm-8">
                    <select class="form-control" name="divisionsId" [(ngModel)]="model.divisionsId">
                      <option value="">Select</option>
                      <ng-container *ngFor="let item of divisionList | async">
                        <option [value]="item.id">{{item.divisionName}}</option>
                      </ng-container>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <button data-toggle="tooltip" (click)="onsubmit()" title="Save" type="button" class="btn btn-success" style="float:right; margin-top:5px;">Save </button>
          </form>
        </div>
      </div>
      <div class="card" style="width: 100%;"*ngIf="showSaveForm==='saveList'">
        <div class="card-body">
          <div class="container">
            <h5 class="card-title" style="color: black">District List</h5>
            <button type="button" data-toggle="tooltip" title="Add new"  class="btn btn-secondary" (click)="onClickShowSaveForm('saveForm')"style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">Add New</button>
            <hr>
            <div class="row">
              <div class="col-md-6" style="text-align: left">
                <select type="number" (change)="onChangeForShow($event.target.value)" class="form-control"
                  style="width:16%">
                  <option value="5">5</option>
                  <option selected value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div class="form-group col-md-6" style="text-align: right">
                <input type="text" class="form-control" [(ngModel)]="searchControl" placeholder="Search" (keyup)="onSearch()">
              </div>
            </div>
            <table id="example" class="table table-bordered">
              <thead>
                <tr>
                  <th>SL No.</th>
                  <th>District Code</th>
                  <th>District Name </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of list | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }; let i = index">
                  <td>{{ (currentPage - 1) * itemsPerPage + i + 1 }}</td>
                  <td>{{item.districtCode}}</td>
                  <td>{{item.districtName}}</td>
                  <td>
                    <button class="btn btn-primary" (click)="edit(item)"><i class="fa fa-edit 3x"></i></button>
                    <button class="btn btn-danger" style="margin-left: 5px;" (click)="delete(item.id)"><i class="fa fa-trash-o 3x"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="row">
              <div class="col-md-6" style="text-align: left">
                <span>Showing {{currentItems}} of {{totalItems}} entries</span>
              </div>
              <div class="col-md-6" style="text-align: right">
                <pagination-controls (pageChange)="onPageChange($event)"> </pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>