import { NidData } from "./nid-data.model";
import { NidError } from "./nid-error.model";
import { PermanentAddress } from "./permanent-address.model"
import { PresentAddress } from "./present-address.model"

export class NidBasic {
    public transactionId: string;
    public creditCost: string;
    public creditCurrent: string;
    public status: string;
    public data: NidData;
    public errors: NidError[];
}
