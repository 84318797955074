import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

const apiUrl=environment.baseUrl + '/api/InterestCalculation/'

@Injectable({
  providedIn: 'root'
})
export class InterestCalculationService {

  constructor(private http:HttpClient) { }

  getFrequencyList():Observable<any>{
    return this.http.get(apiUrl+ 'GetAllFrequencyList')
  }
  getFrequencyListByProductType(id:number):Observable<any>{
    return this.http.get(apiUrl+ 'GetAllFrequencyListByProBuilderType?id='+id)
  }

  getFrequencyById(id:number){
    return this.http.get(apiUrl + 'GetFrequencyById')
  }

  saveFrequency(model:any){
    return this.http.post(apiUrl + 'SaveFrequency',model)
  }

  deleteFrequency(id:any){
    return this.http.delete(apiUrl + 'DeleteFrequency/' + id)
  }


//InterestCalculationType
GetAllInterestCalculationType():Observable<any>{
  return this.http.get(apiUrl+ 'GetAllInterestCalculationTypeWithDetail')
}
GetAllInterestCalculationTypeByProductType(id:number):Observable<any>{
  return this.http.get(apiUrl+ 'GetAllInstCalTypeWithDetailByProBuilderType?id='+id)
}
GetInterestCalculationTypeById(id:number){
  return this.http.get(apiUrl + 'GetInterestCalculationTypeById')
}

GetDetailsByMasterId(id:any){
  return this.http.get(apiUrl+'GetInterestDetailbyMasterId/'+ id);
}

SaveInterestCalculationType(model:any){
  return this.http.post(apiUrl + 'SaveInterestCalculationType',model)
}

DeleteInterestCalculationType(id:any){
  return this.http.delete(apiUrl + 'DeleteInterestCalculationType/' + id)
}

getListFreqWithSerchAndPagination(currentPage: any, itemsPerPage: any, searchValue: string) {
  return this.http.get(apiUrl + 'GetFrequencyListWithSerchAndPagination?page='+ currentPage + '&pageSize=' +itemsPerPage + '&search=' + searchValue);
}
getListWithSerchAndPagination(currentPage: any, itemsPerPage: any, searchValue: string) {
  return this.http.get(apiUrl + 'GetCalculationTypeListWithSerchAndPagination?page='+ currentPage + '&pageSize=' +itemsPerPage + '&search=' + searchValue);
}

}
