<form [formGroup]="frmGroup">
  <!-- ... -->
  <div class="col-md-12">
    <div class="card" style="width: 100%;">
      <div class="row card-header  d-flex flex-row align-items-center">
        <div class="col-6">
          <h5 class="card-title" style="color: black">Add Money</h5>
        </div>
        <div class="col-6">
          <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
            <!-- <i class="ft-chevrons-left"> Back</i> -->
            <i class="icon-arrow-left" style="font-size: 17px;"> </i>
          </button>
        </div>
      </div>
      
      <hr style="height: 10%;">
      <div class="card-body">
        <div>
         
 
          <div class="row">

            <div class="col-6">

              <div class="form-group row">
                <label for="" class="col-sm-4 ">Received Account</label>
                <!-- <label for="" class="col-sm-4 ">Received Account/BD GENERATE CODE</label> -->
                <div class="col-sm-8">
                  <select class="form-control" formControlName="customerCode" [(ngModel)]="accountNo"
                    (change)="loadUserDataByAccountNoCashPayment($event.target.value)">
                    <option value="">Select</option>
                    <ng-container *ngFor="let item of AccountNoList | async">
                      <option [value]="item.id">{{item.customerAccountNo}} - {{item.accountType}}</option>
                    </ng-container>
                  </select>


                  <!-- <div class="input-group">
                    <input type="text" class="form-control" name="customerCode" formControlName="customerCode"
                      [(ngModel)]="accountNo" placeholder="Type Account No...."
                      (keyup.enter)="loadUserDataByAccountNoCashPayment()">
                    <div class="input-group-append">
                      <button class="btn btn-primary btn-sm"
                        (click)="loadUserDataByAccountNoCashPayment()">Find</button>
                    </div>
                  </div> -->

                  <input type="hidden" class="form-control" name="customerId" formControlName="customerId">
                  <input type="hidden" class="form-control" formControlName="gLGenerateId">
                </div>
              </div>
              <div class="form-group row">
                <label for="" class="col-sm-4 ">Account Name</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" id="AccountName" formControlName="AccountName"
                    value="{{glAccountName}} {{customerInfo_cashPayment}}" readonly>
                </div>
              </div>

              <div class="form-group row">
                <label for="" class="col-sm-4 col-form-label"> Balance</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" [value]="currentBalance | number:'1.0-2'"
                    style="text-align: right;" readonly>
                </div>
              </div>
              <div class="form-group row">
                <label for="businessDateId" class="col-sm-4 col-form-label">Business Date<span
                    style="color:red; font-size: 15px; "></span></label>
                <div class="col-sm-8">
                  <input type="hidden" id="businessDateId" formControlName="businessDateId" value="">
                  <input type="text" class="form-control" [readonly]="isAuto" formControlName="businessDate">
                </div>
              </div>

            </div>
            <div class="col-6">
              <!-- <div class="form-group row">
                <label for="name" class="col-sm-4 col-form-label">Phone Number</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="customerAccountId2"
                    placeholder="Phone Number">
                </div>
              </div> -->
              <!-- <div class="form-group row">
                <label for="" class="col-sm-4 col-form-label">Debit Account</label>
                <div class="col-sm-8">
                  <select class="form-control" formControlName="ledgerRelationId">
                    <option value="">Select</option>
                    <ng-container *ngFor="let item of LedgerList | async">
                      <option [value]="item.id">{{item.ledger.accountName}}</option>
                    </ng-container>
                  </select>
                </div>
              </div> -->

              <div class="form-group row">
                <label for="" class="col-sm-4 ">Currency</label>
                <div class="col-sm-8">
                  <select class="form-control" name="currencyId" formControlName="currencyId">
                    <option value="">Select</option>
                    <ng-container *ngFor="let item of countryList | async">
                      <option [value]="item.id">{{item.currency}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>

              <div class="form-group row">
                <label for="voucherAmount" class="col-sm-4 col-form-label">Amount</label>
                <div class="col-sm-8">
                  <!-- <input type="number" class="form-control" min="0" style="text-align: right;" name="voucherAmount"
                    formControlName="voucherAmount" placeholder="Amount...." (blur)="CheckBalance()"> -->
                  <input type="text" style="text-align: right;" class="form-control" formControlName="voucherAmount"
                    placeholder="0" (input)="frmGroup.get('voucherAmount').setValue(formatNumber($event.target.value))"
                    (blur)="CheckBalance()">
                  <span *ngIf="frmGroup.get('voucherAmount').hasError('pattern')" class="text-danger"> Only numeric
                    values are allowed. </span>
                  <!-- <span class="text-danger" style="font-size: 10px; font-weight: bold;" *ngIf="!isvalid">Invalid Amount. Amount cannot be more than the Balance.</span> -->
                </div>
              </div>
              <div class="form-group row">
                <label for="remarks" class="col-sm-4 col-form-label">Remarks</label>
                <div class="col-sm-8">
                  <textarea class="form-control" formControlName="remarks" id="" cols="30" rows="2"></textarea>
                </div>
              </div>

            </div>
          </div>
        </div>

        <button data-toggle="tooltip" (click)="onsubmit()" *ngIf="isBtnValid" title="Save" type="button"
          class="btn btn-success" style="float:right; margin-top:5px;">Save </button>

        <button type="button" title="Refresh" class="btn btn-secondary"
          style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>
      </div>
    </div>
  </div>

</form>