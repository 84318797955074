import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductBuilderService } from 'app/product/service/product-builder.service';
import { Balancetype } from 'app/master-data/products/model/balancetype';
import { Excise_duty } from 'app/master-data/products/model/Excise_Duty';
import { InterestCalculationFrequency } from 'app/master-data/products/model/interest-calculation-frequency';
import { DaySize } from 'app/master-data/products/model/day-size';
import { Accured } from 'app/master-data/products/model/accured';
import { CapitalizationRule } from 'app/master-data/products/model/capitalizationRule';
import { BalancetypeService } from 'app/master-data/products/service/balancetype.service';
import { ExciseDutyService } from 'app/master-data/products/service/excise-duty.service';
import { InterestCalculationService } from 'app/master-data/products/service/interest-calculation.service';
import { AccuredService } from 'app/master-data/products/service/accured.service';
import { DaySizeService } from 'app/master-data/products/service/day-size.service';
import { TaxKeyService } from 'app/master-data/products/service/tax-key.service';
import { CapitalizationRuleService } from 'app/master-data/products/service/capitalization-rule.service';
import { Commission } from 'app/master-data/products/model/commission';
import { CommissionService } from 'app/master-data/products/service/commission.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CountryInfoService } from 'app/master-data/basic/basic-service/country-info.service';
import { CountryInfo } from 'app/master-data/basic/basic-model/country-info';
import { SlabService } from 'app/master-data/products/service/slab.service';
import { CapitalizeFrequency } from 'app/master-data/products/model/capitalize-frequency';
import { CapitalizeFrequencyService } from 'app/master-data/products/service/capitalize-frequency.service';
import { ChargeService } from 'app/master-data/products/service/charge.service';
import { Charge } from 'app/master-data/products/model/charge';
import { LedgerService } from 'app/accounting/service/ledger.service';

@Component({
  selector: 'app-product-builder-edit',
  templateUrl: './product-builder-edit.component.html',
  styleUrls: ['./product-builder-edit.component.scss']
})
export class ProductBuilderEditComponent implements OnInit {
  list: any[] = [];
  ledgerList: any[] = [];
  productDetails: any;
  balanceTypeList : Balancetype []=[];
  exciseDutyList:Excise_duty[]=[];
  exciseDutyDetails:any[]=[];
  sortExcDutyDetails:any[]=[];
  exciseDuty:any;
  capRuleList : CapitalizationRule []=[];
  capRuleSelectList:CapitalizationRule []=[];
  capFrequencyList : CapitalizeFrequency []=[];
  intCalculationTypeList:any[]=[];
  intCalTypeDetails:any[]=[];
  storeIntCalTypeDetails:any[]=[];
  storeIntCalType:any;
  intCalculationFreqList : [InterestCalculationFrequency] []=[];
  accuredList : Accured[]=[];
  daySizeList : DaySize []=[];
  currencyList : CountryInfo []=[];
  taxKeyList:any[]=[];
  taxKey:any;
  taxKeyDetails:any[]=[];
  allSlab:any[]=[];
  instCalTypeSlab:any[]=[];
  isEdit: boolean = false;
  intCalTypeDiv: boolean = true;
  intCalTypeMultipleDiv: boolean = false;
  chargeList:Charge[]=[];
  selectedChargeList:Charge[]=[];
  commissionList:Commission[]=[];
  selectedCommissionList:Commission[]=[];
  showEntry = false;
  frmGroup: FormGroup;
  model: any = {};
  closeResult: string;
  constructor(
    private _service: ProductBuilderService,
    private _CommissionService:CommissionService,
    private _blanaceTypeService: BalancetypeService,
    private _exciseDutyService: ExciseDutyService,
    private _capitaliseRuleService: CapitalizationRuleService,
    private _intCalculationService: InterestCalculationService,
    private _accuredService: AccuredService,
    private _capitaliseFrequencyService: CapitalizeFrequencyService,
    private _daySizeService: DaySizeService,
    private _taxKeyService: TaxKeyService,
    private _countryService: CountryInfoService,
    private _slabService: SlabService,
    private _chargeService:ChargeService,
    private _ledgerService :LedgerService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private activeRoute: ActivatedRoute,
    private router: Router
    
    ) 
    {
    this.getProductBuilderInfoList();
  }

  loadLedgerList() {
    this._ledgerService.getLedgerRelationlist().subscribe(res => {

      this.ledgerList = res;
    });
  }
  ngOnInit() {
    this.populateForm();
    this. loadLedgerList();
    // this.formModel=new window.bootstrap.model(
    //   document.getElementById("taxModal")
    // );
    this.activeRoute.paramMap.subscribe(params => {
      const ProductBuilderId = +params.get('id');
      if (ProductBuilderId > 0) {
        this.getProductBuilderById(ProductBuilderId);
      }
    });
  }

  // getProductDetails(id : any) {
  //   this._service.getProductBuilderDetailsById(id).subscribe(
  //     (data : any) => {
  //       this.productDetails = data;
  //       console.log(data);
  //     },
  //     (error) => {
  //       console.error('Error fetching product details:', error);
  //     }
  //   );
  // }
  getProductBuilderById(id: number) {
    this.isEdit = true;
  }
  populateForm() {
    this.frmGroup = this.formBuilder.group({
      id: [null],
      Code: [''],
      Name: [''],
      Version: [''],
      Description: [''],
      InterestRateMin: [''],
      InterestRateMax: [''],
      Currency: [''],
      DaySizeId: [''],
      taxKeyId: [''],
      exciseDutyId: [''],
      InterestCalculationTypeId: [0],
      InterestCalculationTypeSlabId: [''],
      interestCalculationTypeSelect: [0],
      InterestCalculationFrequencyId: [''],
      CapitalizationRuleId: [''],
      capitalizationFrequencyId: [''],
      BalanceTypeId: [''],
      inputorId: [''],
      authorizerId: [''],
      AccuredId: [''],
      status: [''],
      chargeId: [''],
      commissionId: [''],
      ledgerRelationId: [''],
    })
  }

  getProductBuilderInfoList() {
    this._service.getList().subscribe(res => {
      this.list = res;

    })
  }

  onsubmit() {
    if (this.isEdit) {
      this.model.id = this.frmGroup.value.id;
    }
    this.model.Code = this.frmGroup.value.Code;
    this.model.Name = this.frmGroup.value.Name;
    this.model.Version = this.frmGroup.value.Version;
    this.model.Description = this.frmGroup.value.Description;
    this.model.InterestRateMin = this.frmGroup.value.InterestRateMin;
    this.model.InterestRateMax = this.frmGroup.value.InterestRateMax;
    this.model.Currency = this.frmGroup.value.Currency;
    this.model.DaySizeId = this.frmGroup.value.DaySizeId;
    this.model.taxKeyId = this.frmGroup.value.taxKeyId;
    this.model.exciseDutyId = this.frmGroup.value.exciseDutyId;
    this.model.InterestCalculationTypeId = this.frmGroup.value.InterestCalculationTypeId;
    this.model.InterestCalculationFrequencyId = this.frmGroup.value.InterestCalculationFrequencyId;
    // this.model.CapitalizationRuleId = this.frmGroup.value.CapitalizationRuleId,
    this.model.BalanceTypeId = this.frmGroup.value.BalanceTypeId,
    //this.model.inputorId = this.frmGroup.value.inputorId,
    // this.model.authorizerId = this.frmGroup.value.authorizerId,
    //this.model.AccuredId = this.frmGroup.value.AccuredId,
    this.model.status = (this.frmGroup.value.status===1)?true:false;
    this.model.InterestCalculationTypeSlabId = this.instCalTypeSlab;
    this.model.selectedCapitalizationRuleId = this.capRuleSelectList;
    this.model.selectedChargeId = this.selectedChargeList;
    this.model.selectedCommissionId = this.selectedCommissionList
    this.model.ledgerRelationId = this.frmGroup.value.ledgerRelationId
    //console.log(this.model)

    if (this.model.id) {
      this._service.save(this.model).subscribe(res => {
        this.router.navigate(['/product/product-builder-list']);
      })
    }

    else {
      this._service.save(this.model).subscribe(res => {
        this.router.navigate(['/product/product-builder-list']);
      })
    }

    // if (this.model.id) {
    //   this._service.save(this.model).subscribe(res => {
     
    //     this.getProductBuilderInfoList();
    //     this.showArea = 1;
    //   })
    // }
    //  else {
    //   this._service.save(this.model).subscribe(res => {
    //     this.getProductBuilderInfoList();
    //     this.showArea = 1;
    //   })
    // }


  }

  reset() {
    this.isEdit = false;
    this.populateForm();
    this.model = {};
  }

  edit(item: any) {   
    this.isEdit = true;
    //this.showArea = 2;
    this.frmGroup.patchValue({
      id: item.id,
      Code: item.code,
      Name: item.name,
      Version: item.version,
      Description: item.description,
      InterestRateMin: item.interestRateMin,
      InterestRateMax: item.interestRateMax,
      Currency: item.currency,
      DaySizeId: item.daySizeId,
      taxKeyId: item.taxKeyId,
      exciseDutyId: item.exciseDutyId,
      InterestCalculationTypeId: item.interestCalculationTypeId,
      InterestCalculationFrequencyId: item.interestCalculationFrequencyId,
      CapitalizationRuleId: item.iapitalizationRuleId,
      BalanceTypeId: item.balanceTypeId,
      inputorId: item.inputorId,
      authorizerId: item.authorizerId,
      AccuredId: item.accuredId,
      status: item.status,
    });
    if(item.id>0){
      this.findAddMultipleCapRuleMulti(item.id);
    }
  }

  showArea = 1;
  areaView(areaNo): void {
    this.showArea = areaNo;
    this.getExciseDuty();
    this.getDaySize();
    this.getTaxKey();
    this.getInterestCalculationType();
    this.getInterestCalculationFrequency();
    this.getCapitalizationRule();
    this.getCapitalizationFrequency();
    this.getBalanceType();
    //this.getAccured();
    this.GetAllCommissionList();
    this.GetAllChargeList();
    this.getCountryList();
    this.getSlabList();
  }

  //   delete(id : any){
  //      this._service.delete(id).subscribe(res =>{
  //       this.goProductBuilderList(); 
  //     })
  //   }
  //   goProductBuilderList() : void{

  //     this.router.navigate(['/loan/loan-type-list'])
  //  }
  //#region 
  //#region 
  getSlabList(){
    this._slabService.getlist().subscribe(res => {
      this.allSlab = res;
    })
  }  
  getBalanceType(){
    this._blanaceTypeService.getlist().subscribe(res => {
      this.balanceTypeList = res;
    })
  }  
  getExciseDuty(){
    this._exciseDutyService.getExciseDutyList().subscribe(res => {
      for (const exDuty of res) {
        this.exciseDutyList.push(exDuty['exciseDuty']);
        if(exDuty['exciseDutyDetails'].length>0){
          for (const dtt of exDuty['exciseDutyDetails']){
            this.exciseDutyDetails.push(dtt);
          }
        }
      }
    })
  }
  onExciseDutyChange(){
    this.exciseDuty=null;
    this.sortExcDutyDetails=[];
    for (const dt of this.exciseDutyDetails) {
      if(this.frmGroup.value.exciseDutyId==dt.exciseDutyId){
        this.sortExcDutyDetails.push(dt);
        this.exciseDuty=dt.exciseDuty;
      }
    }
  }
  getCapitalizationRule(){
    this._capitaliseRuleService.getlist().subscribe(res => {
      this.capRuleList = res;
    })
  }
  findAddMultipleCapRuleMulti(id : any){
    
    this._capitaliseRuleService.GetProductWiseIntaCalculationRuleId(id).subscribe((resData:any) => {
      this.capRuleSelectList=resData;
    })
  }
  addMultipleCapRuleMulti(){
    const check=this.capRuleSelectList.find(obj=>obj.id==this.frmGroup.value.capitalizeRuleSlabId)
    if(this.frmGroup.value.CapitalizationRuleId>0 && check==undefined){
      this.capRuleSelectList.push(this.capRuleList.find(obj=>obj.id==this.frmGroup.value.CapitalizationRuleId))
    }
  }
  getCapitalizationFrequency(){
    this._capitaliseFrequencyService.getCapitalizeFrequencyList().subscribe(res => {
      this.capFrequencyList = res;
    })
  }
  onCalTypeSelect(){
    if(this.frmGroup.value.interestCalculationTypeSelect=="0"){
        this.intCalTypeDiv=true;
        this.intCalTypeMultipleDiv=false;
    }
    else{
      this.intCalTypeDiv=false;
      this.intCalTypeMultipleDiv=true;
    }
  }
  addSlabForIntCalTypeMulti(){
    const check=this.instCalTypeSlab.find(obj=>obj.id==this.frmGroup.value.InterestCalculationTypeSlabId)
    if(this.frmGroup.value.InterestCalculationTypeSlabId>0 && check==undefined){
      this.instCalTypeSlab.push(this.allSlab.find(obj=>obj.id==this.frmGroup.value.InterestCalculationTypeSlabId))
    }
  }
  getInterestCalculationType(){
    this.intCalculationTypeList=[];
    this.intCalTypeDetails=[];
    this._intCalculationService.GetAllInterestCalculationType().subscribe(res => {
      for (const type of res) {        
        this.intCalculationTypeList.push(type['instCalType']);
        if(type['instCalTypeDetail'].length>0){
          for (const dtt of type['instCalTypeDetail']){
            this.intCalTypeDetails.push(dtt);
          }
        }
      }
    })
  }
  onCalTypeChange(){
    this.storeIntCalTypeDetails=[];
    this.storeIntCalType=null;
    for (const ty of this.intCalTypeDetails) {
      if(this.frmGroup.value.InterestCalculationTypeId==ty.interestCalculationTypeId){
        this.storeIntCalTypeDetails.push(ty);
        this.storeIntCalType=ty.interestCalculationType;
      }
    }
  }
  getAccured(){
    this._accuredService.getAccuredList().subscribe(res => {
      this.accuredList = res;
    })
  }
  GetAllCommissionList(){
    this._CommissionService.GetAllCommissionList().subscribe(res => {
      this.commissionList = res;
    })
  }
  addMultipleCommission(){
    const check=this.selectedCommissionList.find(obj=>obj.id==this.frmGroup.value.commissionId)
    if(this.frmGroup.value.chargeId>0 && check==undefined){
      this.selectedCommissionList.push(this.commissionList.find(obj=>obj.id==this.frmGroup.value.commissionId))
    }
  }
  
  GetAllChargeList(){
    this._chargeService.GetAllChargeList().subscribe(res => {
      this.chargeList = res;
    })
  }
  addMultipleCharge(){
    const check=this.selectedChargeList.find(obj=>obj.id==this.frmGroup.value.chargeId)
    if(this.frmGroup.value.chargeId>0 && check==undefined){
      this.selectedChargeList.push(this.chargeList.find(obj=>obj.id==this.frmGroup.value.chargeId))
    }
  }
  getInterestCalculationFrequency(){
    this._intCalculationService.getFrequencyList().subscribe(res => {
      this.intCalculationFreqList = res;
    })
  }
  getDaySize(){
    this._daySizeService.getDaySizeList().subscribe(res => {
      this.daySizeList = res;
    })
  }
  getCountryList(){
    this._countryService.getlist().subscribe(res => {
      this.currencyList = res;
    })
  }
  getTaxKey(){
    this._taxKeyService.getList().subscribe(res => {
      this.taxKeyList = res;
    })
  }
  onTaxChange(){
    this.taxKeyDetails=[];
    this.taxKey=null;
    for (const dt of this.taxKeyList) {
      if(this.frmGroup.value.taxKeyId==dt.taxKey.id){
        this.taxKeyDetails=dt.taxDetails;
        this.taxKey=dt.taxDetails[0].taxKey;
      }
    }
  }


  //#endregion
  //#region Detail

  openModal(content) {

      this.modalService.open(content).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
          this.closeResult = `Dismissed`;
      });
  }

  openBuilderDetailsModal(content) {
    let options: any = {
      size: 'xl',
      centered: false
    }
      this.modalService.open(content, options).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
          this.closeResult = `Dismissed`;
      });
  }
  //#endregion
}