import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CustomerService } from 'app/customer/service/customer.service';
import { TransectionType } from 'app/master-data/products/model/transection-type';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { LayoutService } from 'app/shared/services/layout.service';
import swal from 'sweetalert';

@Component({
  selector: 'app-fund-transfer-list',
  templateUrl: './fund-transfer-list.component.html',
  styleUrls: ['./fund-transfer-list.component.scss']
})
export class FundTransferListComponent implements OnInit {
  accountNo: string = '';
  accountList: any[] = [];
  startDate: Date = new Date();
  EndDate: Date = new Date();

  transactionList: any[] = [];
  SearchAccNo: boolean = false;
  pdfView: boolean = false;

  currentUser: string = '';
  transactionType: string='';
  noDataAvailable: boolean = false;
  FundtransactionList: any = [];

  constructor(private customerService: CustomerService,
    private pdfService: PdfService,
    private layoutService: LayoutService,
    private changeDetect: ChangeDetectorRef,
    private datePipe: DatePipe) {

  }

  ngOnInit(): void {
    this.gettransactionList();


    this.layoutService.getCurrentUserName().subscribe(res => {
      this.currentUser = res;
    }
    );

  }


  gettransactionList() {
    this.customerService.getFundTransferList().subscribe(res => {  
       
      this.transactionList = res;
      this.changeDetect.detectChanges();

    })

  }


}
