import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'app/services/shared.service';
import { LayoutService } from 'app/shared/services/layout.service';
import { FdrdetailsService } from '../fdrdetails.service';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { CustommeraccountService } from 'app/customer-account/custommeraccount.service';


@Component({
  selector: 'app-fdr-select',
  templateUrl: './fdr-select.component.html',
  styleUrls: ['./fdr-select.component.scss']
})
export class FdrSelectComponent implements OnInit {
  productlist: any= [];  
  isAdmin: boolean = false;
  accountNo: string = '';
   constructor( 
     private _service: CustommeraccountService,
    private _fdrservice:FdrdetailsService,
    private router: Router,
    private modalService: NgbModal,
    private changeDetectorRef: ChangeDetectorRef,
    private shared: SharedService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private layoutService: LayoutService) 
    { }

    ngOnInit(): void {
      this.getProduct();
      this.getCurrentUserRole();
    }
    
    getProduct(){
      this._fdrservice.GetProductForFDR().subscribe(res=>{
      this.productlist=res;
      })
    }

  getCurrentUserRole() {
    this._service.GetUserRole().subscribe((userData: any) => {

      if (userData.roles.includes('Admin')) 
      {
        this.isAdmin = true;
      }
      else
       {
        this.isAdmin = false;
        this.accountNo = userData.userName;

      }
    });
  }

  goAccountForm(Id: any) {
    if (this.isAdmin==true) {
      this.router.navigate(['/fdr-select/create-fdr-account/' + Id]);
    }
     else {
      this.router.navigate(['/fdr-select/customer-fdr/' + Id]);
    }
  }
  

}
