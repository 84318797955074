<!-- <p>score-que-answer works!</p> -->

<section>
    <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveForm'">
        <div class="card-body">
            <div class="container">
                <h5 class="card-title" style="color: black">Add Answer</h5>
                <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary d-none"
                    (click)="onClickShowSaveForm('ListView')"
                    style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">List View</button>
                <hr>
                <form [formGroup]="ansFromGrp">
                    <div asp-validation-summary="All" class="text-danger"></div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="questionId" class="col-sm-4 col-form-label"> Question Name</label>
                                <div class="col-sm-8">
                                    <select class="form-control" formControlName="questionId"
                                        (change)="onQuestionChange($event.target.value)">
                                        <option value="0">Select</option>
                                        <ng-container *ngFor="let item of questionList">
                                            <option [value]="item.questionId">{{item.question}}</option>
                                        </ng-container>

                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="mark" class="col-sm-4 col-form-label">Total Marks</label>
                                <div class="col-sm-8">
                                    <input type="number" min="0" formControlName="mark" class="form-control"
                                        placeholder="Type here...">
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="answerOption" class="col-sm-4 col-form-label"> Answer </label>
                                <div class="col-sm-8">
                                    <input type="text" formControlName="answerOption" class="form-control"
                                        placeholder="Type here...">
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="status" class="col-sm-4 col-form-label"> Status</label>
                                <div class="col-sm-8">
                                    <select class="form-control" formControlName="status">
                                        <option value="">Select</option>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="sortOrder" class="col-sm-4 col-form-label">Sort Order</label>
                                <div class="col-sm-8">
                                    <input type="number" min="0" formControlName="sortOrder" class="form-control"
                                        placeholder="Type here...">
                                </div>
                            </div>
                        </div>

                    </div>

                    <button [disabled]="!ansFromGrp.valid" data-toggle="tooltip" title="Save" type="submit"
                        value="Submit" (click)="onSubmit()" class="btn btn-success"
                        style="float:right; margin-top:5px;">
                        <span>{{ isEdit ? 'Update' : 'Save' }}</span>
                    </button>
                    <button type="button" data-toggle="tooltip" title="Refresh" (click)="refresh()"
                        class="btn btn-secondary"
                        style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>

                </form>
            </div>
        </div>
    </div>

    <!-- <div class="card" style="width: 100%;" *ngIf="showSaveForm==='ListView'">
        <div class="card-body">
            <div class="container">
                <h5 class="card-title" style="color: black">Answer List</h5>
                <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary"
                    (click)="onClickShowSaveForm('saveForm')"
                    style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">Add New</button>
                <hr>
                <table id="example" class="table table-striped table-bordered" style="width:100%">
                    <thead>
                        <tr>
                            <th>SL No.</th>
                            <th>Answer</th>
                            <th>Type</th>
                            <th style="width: 25%;text-align: center;">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of answerList; let i = index">
                            <td>{{i + 1}}</td>
                            <td></td>
                            <td></td>
                            <td style="width: 25%;">
                                <button [disabled]="isEdit" class="btn btn-danger" style="margin-left: 5px;"><i
                                        class="fa fa-trash-o 3x"></i></button>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div> -->

</section>

