<div class="card" style="width: 100%;">

    <div class="card-header py-3 d-flex flex-row align-items-center">
       

        <div class="col-6">
            <h6 class="m-0 font-weight-bold text-primary">Rejected Loan List</h6>
        </div>
        <div class="col-6">
            <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
               
                <i class="icon-arrow-left" style="font-size: 17px;"> </i>
            </button>
        </div>
    </div>
    <div class="card-body">


        <table id="example" class="table table-striped table-bordered" style="width:100%">
            <thead>
                <tr>
                    <th>SL No.</th>
                    <th>Lead Code </th>
                    <th>Customer Info </th>
                    <th>Loan Type </th>
                    <th>Status</th>
                    
                </tr>
            </thead>

            <tbody>

                <tr *ngFor="let item of dataList; let i = index">
                    <td>{{i+1}}</td>
                    <td>{{item.leadCode}}</td>
                    <td>
                        <span>Name: {{item?.customer?.customerNameEng}}</span><br>
                        <span>Mobile No.: {{item?.customer?.mobileNo}}</span>
                    </td>
                    <td>{{item.loanProduct?.loanType?.typeName}}</td>
                    
                    <td> 
                       
                        Rejected
                   
                      
                      
                      </td>
                </tr>
            </tbody>
        </table>

    </div>
</div>