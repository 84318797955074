<div >

    <div class="card-header" style="background-color:rgb(231, 238, 238);">
    <h5 class="card-title" style="color: black;font-family: Arial;font-size: 20px !important;">Transaction Limit</h5>

    </div>
    <br>
    <form [formGroup]="frmGroup">
    <div class="col-md-12">
        <h5 style="font-size:18px;">Deposit Declearation</h5>
        <hr>
        <div class="form-group row">

            <div class="col-sm-12">
                <table class="table table-bordered">
                    <thead>
                        <tr style="background-color:rgb(221, 241, 248);">
                            <td>Sl No.</td>
                            <td class="pl-2 p-2 m-2" >Method</td>
                            <td class="pl-1 p-0 m-0">Monthly potential total deposit number</td>
                            <td class="pl-1 p-0 m-0">Monthly potential total deposit amount</td>
                            <td class="pl-1 p-0 m-0">Single maximum deposit amount</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of list ;let i = index" >
                           <!-- <ng-container *ngIf="item.type === 1"> -->
                            <td>{{i + 1}}</td>
                            <td class="pl-1 p-0 m-0">
                                {{item.methodType?.methodName}}

                            </td>
                            <td class="p-1 m-0">
                                {{item.monthlyTRCount}}
                            </td>
                            <td class="p-1 m-0">
                                {{item.monthlyTRTotalAmount}}
                            </td>
                            <td class="p-1 m-0">
                                {{item.monthlyTRMaxAmount}}
                            </td>
                            <!-- </ng-container> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    </form>
    </div>

