import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Observable } from 'rxjs';
import { COBStatusViewModel } from './models/cobstatus-view-model';
import { environment } from 'environments/environment';
import { CobProgress } from './models/cob-progress';

@Injectable({
  providedIn: 'root'
})
export class CobService {
  private apiUrl = environment.baseUrl;
  
  private hubConnection: signalR.HubConnection;

  constructor(private http: HttpClient) {
    this.hubConnection = new signalR.HubConnectionBuilder()
    .configureLogging(signalR.LogLevel.Trace)
    .withUrl("https://localhost:44395/cobHub", {
      skipNegotiation: true,
      transport: signalR.HttpTransportType.WebSockets
    })
    .withAutomaticReconnect()
    .build();


    this.startConnection();
  }

  processCOB(date:Date){
    return this.http.get(this.apiUrl + '/api/COBProcess/ProcessCOB?processDate=' + date);
  }

  startConnection(): void {
    if (this.hubConnection.state === signalR.HubConnectionState.Disconnected) {
      this.hubConnection.start().then(() => {
        console.log('Connected!');
      }).catch(err => {
        console.log('err: ', err);
      });
    } else {
      console.log('Connection is already in a non-disconnected state.');
    }
  }

  LoadProgresses(): Observable<CobProgress[]> {
    return this.http.get<CobProgress[]>(this.apiUrl + '/api/COBProcess/LoadProgresses');
  }

  loadCOBStatus(): Observable<COBStatusViewModel> {
    return new Observable<COBStatusViewModel>(observer => {
      this.hubConnection.on('ReceiveCOBProcessStatus', (cob: COBStatusViewModel) => {
        observer.next(cob);
      });
    });
  }
}
