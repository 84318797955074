<form [formGroup]="frmGroup">
  <div class="col-md-12">
    <div class="card" style="width: 100%;">
      <div class="card-header">
        <h5 class="card-title" style="color: black;font-family: Arial;font-size: 20px !important;">BREB</h5>

      </div>

      <div class="card-body" style="margin-top: 30px; padding-left: 30px; padding-right: 30px;">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group row">
              <label for="" class="col-sm-4 col-form-label">Debit Account No <span style="color: red;"> *</span></label>
              <div class="col-sm-8">
                <select class="form-control" formControlName="customerCode" [(ngModel)]="accountNoFund1"
                  (change)="loadUserDataByAccountNoFundFirst($event.target.value)">
                  <option value="">Select</option>
                  <ng-container *ngFor="let item of AccountNoList">
                    <option [value]="item.id">{{item.customerAccountNo}} - {{item.accountType}}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label for="" class="col-sm-4 col-form-label">Debit Customer</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="customerName" [value]="customerInfo1" readonly>
              </div>
            </div>

            <div class="form-group row">
              <label for="" class="col-sm-4 col-form-label">Debit Balance</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" value="{{currentBalance}}" readonly style="text-align: right;">
              </div>
            </div>
            <div class="form-group row">
              <label for="" class="col-sm-4 col-form-label">Select Date</label>
              <div class="col-sm-8">

                <input type="date" class="form-control" [(ngModel)]="BillPayDate" placeholder="Select To Date">
              </div>
            </div>

           


          </div>

          <div class="col-md-6">

            <div class="form-group row">
              <label for="name" class="col-sm-4 col-form-label">Bill Number</label>
              <div class="col-sm-8">
                <div class="input-group">
                  <!-- <input type="text" class="form-control" formControlName="customerAccountId2" [(ngModel)]="BillNumber"
                     placeholder="Bill Number......."
                     (keyup.enter)="loadBillAccountInfoById($event.target.value,'WASA')"> -->
                  <input type="text" class="form-control" formControlName="BillNumber" [(ngModel)]="BillNumber"
                    placeholder="Bill Number......." min="10" max="13" (keyup.enter)="loadBillAccountInfo()">


                </div>
              </div>
            </div>

            <!-- <div class="form-group row">
              <label for="" class="col-sm-4 col-form-label">Account Name</label>
              <div class="col-sm-8">
               
                <input type="text" class="form-control" formControlName="accountName" value="{{billInfo}}" readonly>

              </div>
            </div> -->


            <div class="form-group row">
              <label for="" class="col-sm-4 col-form-label">Amount</label>
              <div class="col-sm-8">

                <input type="text" style="text-align: right;" class="form-control" formControlName="voucherAmount"
                  placeholder="Enter Your Amount...." style="text-align: right"
                  (input)="frmGroup.get('voucherAmount').setValue(formatNumber($event.target.value))"
                  (keyup)="CheckBalance()" (keyup)="voucherAmountValue($event.target.value)">

                <span class="text-danger" style="font-size: 10px; font-weight: bold;" *ngIf="!isvalid">Invalid Amount.
                  Amount cannot be more than the Balance.</span>
                <span *ngIf="frmGroup.get('voucherAmount').hasError('pattern')" class="text-danger">
                  Only numeric values are allowed.
                </span>

              </div>

            </div>

            <div class="form-group row">
              <label for="monthId" class="col-sm-4 col-form-label">Select Month <span style="color: red;">
                  *</span></label>
            
              <div class="col-sm-8">
                <select class="form-control" type="number" formControlName="monthId" [(ngModel)]="monthId"
                  (change)="billPayMonth($event.target.value)">
                  <option value=""> Select Month</option>
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June </option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label for="remarks" class="col-sm-4 col-form-label">Remarks <span style="color: red;"> *</span></label>
              <div class="col-sm-8">
                <textarea class="form-control" formControlName="remarks" id="" cols="30" rows="2"
                  placeholder="Remarks...."></textarea>
              </div>
            </div>

          </div>


          <div class="col-md-12">
            <button data-toggle="tooltip" (click)="onsubmit()" title="Save" type="button" class="btn btn-primary"
              style="float:right; margin-top:5px;">Send
            </button>
          </div>

        </div>
      </div>

    </div>
  </div>
</form>