import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { AccountingreportService } from '../accountingreport.service';

@Component({
  selector: 'app-ccwise-report',
  templateUrl: './ccwise-report.component.html',
  styleUrls: ['./ccwise-report.component.scss']
})
export class CcwiseReportComponent implements OnInit {
  fromDate:Date =new Date();
  toDate:Date = new Date();
  reportBy:number;
  constructor(private pdfService:PdfService,
    private _accountReportService:AccountingreportService,
    private changeDetectorRef: ChangeDetectorRef,) 
    { }

  ngOnInit(): void {
  }

  OpenPDF(){
    this.pdfService.TrialBalanceReport(this.fromDate,this.toDate,1,2,this.reportBy).subscribe(res => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    })
  }

}
