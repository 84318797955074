<div class="card">
    <!-- <div class="card-header">
        Ledger Book Report
    </div> -->
    <div class="card-header" style="background-color:rgb(231, 238, 238);">
        <h5 class="card-title" style="color: black;font-family: Arial;font-size: 20px !important;">Ledger Book Report</h5>         
    </div>
  

    <div class="card-body row">
        <label for="fromDate" class="col-form-label col-md-2">From Date</label>
        <div class="col-md-3">
            <input type="date" id="toDate" [(ngModel)]="fromDate" class="form-control">
        </div>
        
        <label for="toDate" class="col-form-label col-md-2">To Date</label>
        <div class="col-md-3">
            <input type="date" id="toDate" [(ngModel)]="toDate" class="form-control">
        </div>

        <button href="javascript:void(0)" class="btn btn-success btn-sm" (click)="OpenPDF()">Pdf Report</button>
    </div>
</div>