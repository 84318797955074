import { Component, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { GroupService } from '../service/group.service';
import { Observable } from 'rxjs/internal/Observable';


@Component({
  selector: 'app-tree-table-test',
  templateUrl: './tree-table-test.component.html',
  styleUrls: ['./tree-table-test.component.scss']
})
export class TreeTableTestComponent implements OnInit {

  data: TreeNode[];
  constructor(private _service: GroupService) { this.data = [];}

  ngOnInit() { 
     this.GetAllAccountGroupSubGroupList();
    
    
} 

GetAllAccountGroupSubGroupList()
{
  this._service.AllAccountGroupSubGroupList().subscribe(res =>{   
     this.data = this.mapToTree(res);
    console.log(this.data);
  })
}
mapToTree(data: any[]): TreeNode[] {
    return data.map(item => {
      const node: TreeNode = {
        data: item.data,
        children: item.children ? this.mapToTree(item.children) : []
      };
      return node;
      
    });
  }
}
