<!--Statistics cards Starts-->
<div class="row" (resized)="onResized($event)">
  <div class="col-12 row">
    <div class="col-lg-6 col-md-6 col-12 col-sm-12">
      <div class="card" (click)="onWaiting(1)" style="cursor: pointer;background-color: #b292ee;">
        <div class="card-content">
          <div class="card-body py-0">
            <div class="media pb-4">
              <div class="media-body white text-left mt-2 pl-4">
                <span style="font-size: 20px;">Waiting Application </span>
                <h3 class="font-large-1 white mt-2">{{waitingApplication}}</h3>

              </div>
              <div class="media-right white text-right mt-4 pr-4">
                <i class="fa fa-gears font-large-2"></i>

              </div>

            </div>
          </div>
          <!--  <div id="Widget-line-chart" class="WidgetlineChart WidgetlineChartshadow mb-2">
                      <x-chartist class="height-75" [data]="WidgetlineChart1.data" [type]="WidgetlineChart1.type" [options]="WidgetlineChart1.options" [responsiveOptions]="WidgetlineChart1.responsiveOptions"
                       [events]="WidgetlineChart1.events">
                      </x-chartist>
                  </div> -->
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-12 col-sm-12">
      <div class="card " (click)="onForwarded(2)" style="cursor: pointer;background-color:#526BA0 ;">
        <div class="card-content">
          <div class="card-body py-0">
            <div class="media pb-4">
              <div class="media-body white text-left  mt-2 pl-4">
                <span style="font-size: 20px;">Forwarded Application</span>
                <h3 class="font-large-1 white mt-2">{{forwardedApplication}}</h3>
              </div>
              <div class="media-right white text-right mt-4 pr-4">
                <i class="fa fa-list font-large-2"></i>
              </div>
            </div>
          </div>
          <!--   <div id="Widget-line-chart" class=" WidgetlineChart WidgetlineChartshadow mb-2">
                      <x-chartist class="height-75" [data]="WidgetlineChart.data" [type]="WidgetlineChart.type" [options]="WidgetlineChart.options" [responsiveOptions]="WidgetlineChart.responsiveOptions"
                       [events]="WidgetlineChart.events">
                      </x-chartist>
                  </div>  -->
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 row">
    <div class="col-lg-6 col-md-6 col-12 col-sm-12">
      <div class="card" (click)="onApproved(3)" style="cursor: pointer;background-color: #07f3a4;">
        <div class="card-content">
          <div class="card-body py-0">
            <div class="media pb-4">
              <div class="media-body white text-left mt-2 pl-4">

                <span style="font-size: 20px;"> Approved Loan</span>
                <h3 class="font-large-1 white mt-2">{{approvedLoan}}</h3>
              </div>
              <div class="media-right white text-right  mt-4 pr-4">
                <i class="fa fa-check font-large-2"></i>

              </div>
            </div>
          </div>
          <!--  <div id="Widget-line-chart" class="WidgetlineChart WidgetlineChartshadow mb-2">
                      <x-chartist class="height-75" [data]="WidgetlineChart2.data" [type]="WidgetlineChart2.type" [options]="WidgetlineChart2.options" [responsiveOptions]="WidgetlineChart2.responsiveOptions"
                       [events]="WidgetlineChart2.events">
                      </x-chartist>
                  </div> -->
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-12 col-sm-12">
      <div class="card " (click)="onRejected(4)" style="cursor: pointer;background-color: #f85656;">
        <div class="card-content">
          <div class="card-body py-0">
            <div class="media pb-4">
              <div class="media-body white text-left mt-2 pl-4">
                <span style="font-size: 20px;">Rejected Loan</span>
                <h3 class="font-large-1 white mt-2">{{rejectedLoan}}</h3>

              </div>
              <div class="media-right white text-right mt-4 pr-4">
                <i class="ft-credit-card font-large-2"></i>
              </div>
            </div>
          </div>
          <!--  <div id="Widget-line-chart" class="WidgetlineChart WidgetlineChartshadow mb-2">
                      <x-chartist class="height-75" [data]="WidgetlineChart3.data" [type]="WidgetlineChart3.type" [options]="WidgetlineChart3.options" [responsiveOptions]="WidgetlineChart3.responsiveOptions"
                       [events]="WidgetlineChart3.events">
                      </x-chartist>
                  </div> -->
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 row">
   
    <div class="col-lg-6 col-md-6 col-12 col-sm-12">
      <div class="card" (click)="onDisburse(5)" style="cursor: pointer;background-color: #eece3e8e;">
        <div class="card-content">
          <div class="card-body py-0">
            <div class="media pb-4">
              <div class="media-body white text-left mt-2 pl-4">

                <span style="font-size: 20px;color:white">Schedule Generate</span>
                <h3 class="font-large-1 white mt-2">{{waitingForDisburse}}</h3>
              </div>
              <div class="media-right white text-right  mt-4 pr-4">
                <i class="fa fa-th-list font-large-2"></i>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-md-6 col-12 col-sm-12">


      <div class="card" (click)="ApprovedDisburse(5)" style="cursor: pointer;background-color: #09adb38e;">
        <div class="card-content">
          <div class="card-body py-0">
            <div class="media pb-4">
              <div class="media-body white text-left mt-2 pl-4">

                <span style="font-size: 20px;color:white"> Scheduled List</span>
                <h3 class="font-large-1 white mt-2">{{disbursmentList}}</h3>
              </div>
              <div class="media-right white text-right  mt-4 pr-4">
                <i class="fa fa-list font-large-2"></i>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-12 col-sm-12">


      <div class="card" (click)="FinalDisburse(7)" style="cursor: pointer;background-color: #4f6f708e;">
        <div class="card-content">
          <div class="card-body py-0">
            <div class="media pb-4">
              <div class="media-body white text-left mt-2 pl-4">

                <span style="font-size: 20px;color:white"> Disbursment List</span>
                <h3 class="font-large-1 white mt-2">{{finalDisbursList}}</h3>
              </div>
              <div class="media-right white text-right  mt-4 pr-4">
                <i class="ft-trending-up font-large-2"></i>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
</div>