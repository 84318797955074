import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FdrSelectComponent } from './fdr-select/fdr-select.component';
import { CreateFdrAccountComponent } from './create-fdr-account/create-fdr-account.component';
import { FdrListComponent } from './fdr-list/fdr-list.component';
import { CustomerFdrComponent } from './customer-fdr/customer-fdr.component';

const routes: Routes = [
  {
    path: 'fdr-select',
    component: FdrSelectComponent,
    data: {
      title: 'fdr-select'
    }
  },
  {
    //path: 'create-fdr-account',
    path: 'create-fdr-account/:Id',
    component: CreateFdrAccountComponent,
    data: {
      title: 'create-fdr-account'
    }
  },
  {
    //path: 'create-fdr-account',
    path: 'customer-fdr/:Id',
    component: CustomerFdrComponent,
    data: {
      title: 'customer-fdr'
    }
  },
  {
    //path: 'create-fdr-account',
    path: 'fdr-list',
    component: FdrListComponent,
    data: {
      title: 'fdr-list'
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FdrAccountRoutingModule { }
