<div class="card" style="width: 100%;">
    <div class="card-header py-3 d-flex flex-row align-items-center">
      <div class="col-md-6">
        <h6 class="m-0 font-weight-bold text-primary">Loan Process List</h6>
      </div>
      <div class="col-md-6" style="text-align: right;">
        <button type="button" data-toggle="tooltip" title="Go to dashboard" class="btn btn-secondary"
          (click)="goDashboard()">Dashboard</button>
      </div>
  
    </div>
  
    <div class="card-body">
      <div class="container">
  
        <table id="example" class="table table-striped table-bordered" style="width:100%">
          <thead>
            <tr>
              <th>SL No.</th>
              <th>Lead Code</th>
              <th>Customer</th>
              <th>Loan Amount</th>
             <th>Status</th>
              <th style="width: 15%;text-align: center;">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of LeadList; let i = index;">
              <td>{{i + 1}}</td>
              <td>{{item.leadCode}}</td>
              <td><span>Name:{{item.customer?.customerNameEng}}</span> <br>
                 <span>Mobile No:{{item.customer?.mobileNo}} </span><br>
              </td>
               <td>{{item.loanAmount}}</td>
              <td>
                <div class="badge badge-success" >
                  Approved </div>
              </td>
              <td style="width: 21%;text-align: -webkit-center; padding-right: 0;padding-left: 0;">
  
            
                <button class="btn btn-sm btn-info" type="button" (click)="openpayAmount(item.id)" title="Bill Pay" 
                  style="height: 30px;margin-right: 5px;"> Bill Pay </button>
  
                  <button class="btn btn-sm btn-info" type="button" (click)="openCollection(item.id)" title="Bill Pay" 
                  style="height: 30px;"> Collection</button>
              </td>
            </tr>
          </tbody>
        </table>
  
      </div>
  
    </div>
  </div>



