
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { AccountingreportService } from '../accountingreport.service';
import { id } from '@swimlane/ngx-datatable';
import { Observable, of } from 'rxjs';
import { LedgerService } from 'app/accounting/service/ledger.service';
import { Location } from "@angular/common";
@Component({
  selector: "app-sub-ledger-book",
  templateUrl: "./sub-ledger-book.component.html",
  styleUrls: ["./sub-ledger-book.component.scss"],
})
export class SubLedgerBookComponent implements OnInit {
  fromDate: Date = new Date();
  toDate: Date = new Date();
  reportBy: number;
  Sbulist: any = [];
  projectlist: any = [];
  subledgerId: any = 0;
  ledgerId: any = [];
  AccountGroupList: Observable<any[]>;
  AccountNoList: Observable<any[]>;
  AccountSubLedgerList: Observable<any[]>;
  //AccountSubLedgerList: any[] = [];
  subledgerStatus: string = "hide";

  constructor(
    private pdfService: PdfService,
    private accountingreportService: AccountingreportService,
    private _LedgerService: LedgerService,
    private accountReportService: AccountingreportService,
    private changeDetectorRef: ChangeDetectorRef,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.getSbuList();
    this.GetProjectist();
    this.GetAllSubLedger();
    //this.getLedgerListBysubLedgerId();
    //this.GetAccountGroupList();
  }
  OpenPDF() {
    //this.pdfService.LedgerBookReportDataViewpdf(this.ledgerId,this.subledgerId,this.fromDate,this.toDate,1,2).subscribe(res => {
    this.pdfService
      .SubLedgerBookReportDataViewpdf(
        this.ledgerId,
        this.subledgerId,
        this.fromDate,
        this.toDate,
        1,
        2
      )
      .subscribe((res) => {
        let blob: Blob = res.body as Blob;
        let url = window.URL.createObjectURL(blob);

        window.open(url);
      });
  }

  getSbuList() {
    this.accountingreportService.GetSbuList().subscribe((res) => {
      this.Sbulist = res;
    });
  }
  GetProjectist() {
    this.accountingreportService.GetProjectist().subscribe((res) => {
      this.projectlist = res;
    });
  }
  GetAllSubLedger() {
    this._LedgerService.GetAllSubLedger().subscribe((res) => {
      this.AccountSubLedgerList = of(res);
      
    });
  }

  onLedgerChange(Id: any): void {
    this.ledgerId = Id;

    //this.getLedgerListBysubLedgerId(Id);
  }

  onSubLedgerChange(Id: any): void {
  
    this.subledgerId = Id;
    this.getLedgerListBysubLedgerId(Id);
  }

  getLedgerListBysubLedgerId(Id: number) {
  
    this._LedgerService.GetAllSubLedgerbyLId(Id).subscribe((res) => {
      this.AccountNoList = of(res);
      console.log(res)
    });
  }
  goBack() {
    this.location.back();
  }
}
