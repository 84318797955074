
  // barChart
  export var CashflowbarChartOptions: any = {
    elements: {
      rectangle: {
        borderWidth: 2,
        borderSkipped: 'bottom'
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    responsiveAnimationDuration: 500,
    legend: {
      position: 'top',
    },
    scales: {
      xAxes: [{
        display: true,
        gridLines: {
          color: "#F5F5F5",
          drawTicks: false,
        },
        scaleLabel: {
          display: true,
        }
      }],
      yAxes: [{
        display: true,
        gridLines: {
          color: "#F5F5F5",
          drawTicks: false,
        },
        ticks: {
          marginTop: 10, // Add margin top to the ticks
        },
        scaleLabel: {
          display: true,
        }
      }]
    },
    title: {
      display: true,
      text: 'Cash Flow'
    }
  };
  
  
  export var CashflowbarChartType = 'bar';
  export var CashflowbarChartLegend = true;
  export const CashflowbarChartColors: any[] = [
    {
        backgroundColor: "#A18C61",
        borderColor: "transparent",
        pointBackgroundColor: "transparent",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgba(148,159,177,0.8)",
    },
    {
        backgroundColor: "#F7941D",
        borderColor: "transparent",
        pointBackgroundColor: "transparent",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgba(148,159,177,0.8)",
    },
];


  
  // Profit/Loss
  export var PLbarChartOptions: any = {
    elements: {
      rectangle: {
        borderWidth: 2,
        borderSkipped: 'bottom'
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    responsiveAnimationDuration: 500,
    legend: {
      position: 'top',
    },
    scales: {
      xAxes: [{
        display: true,
        gridLines: {
          color: "#F5F5F5",
          drawTicks: false,
        },
        scaleLabel: {
          display: true,
        }
      }],
      yAxes: [{
        display: true,
        gridLines: {
          color: "#F5F5F5",
          drawTicks: false,
        },
        scaleLabel: {
          display: true,
        }
      }]
    },
    title: {
      display: true,
      text: 'Profit/Loss'
    }
  
  };
 
  export var PLbarChartType = 'bar';
  export var PLbarChartLegend = true;
  export var PLbarChartColors: Array<any> = [
    {
      backgroundColor: "#007FFF",
      borderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(148,159,177,0.8)",
    },
    {
      backgroundColor: "#031088",
      borderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(148,159,177,0.8)",
    },
  ];


    
  // Doughnut
  export var APdoughnutChartLabels: string[] = ["Paid","Not Paid"];
// Calculate the pending percentage based on the total
const paidPercentage = 80;
const pendingPercentage = 100 - paidPercentage;

// Update the chart data array
export var APdoughnutChartData: number[] = [paidPercentage, pendingPercentage];

// Update the chart colors array
export var APdoughnutChartColors: any[] = [
  { backgroundColor: ["#0000FF", "#87CEEB"] }, // Blue for paid, Red for pending
];

export var APdoughnutChartType = 'doughnut';
export var APdoughnutChartOptions: any = {
  animation: {
    duration: 0
  },
  responsive: true,
  maintainAspectRatio: false,
  tooltips: {
    callbacks: {
      label: function(tooltipItem, data) {
        var dataset = data.datasets[tooltipItem.datasetIndex];
        var total = dataset.data.reduce((previousValue, currentValue, currentIndex, array) => {
          return previousValue + currentValue;
        });
        var currentValue = dataset.data[tooltipItem.index];
        var percentage = Math.round((currentValue / total) * 100);
        return percentage + "%";
      }
    }
  }
};

  