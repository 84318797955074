<div class="card" style="width: 100%;">
  <div class="card-header row py-3 d-flex flex-row align-items-center">
    <div class="col-md-9">
      <h6 class="m-0 font-weight-bold text-primary">Loan Process List</h6>
    </div>
    <div class="col-md-2" style="text-align: right;">
      <button type="button" data-toggle="tooltip" title="Go to dashboard" class="btn btn-secondary"
        (click)="goDashboard()">Dashboard</button>
    </div>

    
      
    
      <div class="col-1">
        <button class="btn btn-secondary" title="Go Back" (click)="goBack()">
    
          <i class="icon-arrow-left" style="font-size: 17px;"> </i>
        </button>
      </div>
    
    
    
    <hr>

  </div>

  <div class="card-body">
    <div class="container">

      <table id="example" class="table table-striped table-bordered" style="width:100%">
        <thead>
          <tr>
            <th>SL No.</th>
            <th>Loan Code</th>
            <th>Customer</th>
            <th>Loan Product</th>

            <!-- <th>Product</th>
                        <th>Loan Amount</th> -->
            <!--  <th>From</th>
                        
                        <th>Applied On</th> -->
            <th>Status</th>
            <th style="width: 15%;text-align: center;">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of LeadList; let i = index;">
            <td>{{i + 1}}</td>
            <td>{{item.leadCode}}</td>
            <td><span>Name:{{item.customer?.customerNameEng}}</span> <br>
              <span>Mobile No:{{item.customer?.mobileNo}} </span><br>
              <!-- <span>NID No:{{item.clientInfo?.NIDNo}}</span> <br> -->
              <!-- <span>Date Of Birth:{{formatDate(item.clientInfo?.dateOfBirth)}}</span></td> -->
            </td>
            <!-- <td>{{item.loanProduct?.productName}}</td>
                    <td>{{item.loanRegisterMaster?.loanAmount}}</td>  -->
             <td>{{item.loanProduct?.name}} <br> {{item.tenure?.monthName}}</td>
            <td>
              <div class="badge badge-pill" *ngIf="type==1" [ngClass]="{'bg-light-primary': item.isComplete == 0 && item.statusInfoId == 1}">
                Apply </div>

                  <div class="badge badge-pill" *ngIf="type==3" [ngClass]="{'bg-light-primary': item.isComplete == 0 && item.statusInfoId == 3}">
                    Approved </div>
            </td>
            <td style="width: 21%;text-align: -webkit-center;">

              <!-- <button class="btn btn-sm btn-secondary" (click)="printPDF(item.id)" type="button" title="Print Preview" style="background-color: #6f7270;">Print</button> -->
              <!-- <button class="btn btn-sm btn-info" type="button" title="Print Preview" style="background-color:darkgrey;"><a routerLink="/loan/loan-list">Process</a></button> -->

              <!-- <button class="btn btn-sm btn-info" type="button" title="Click here to complete process">
                <a [routerLink]="'/loan/loan-application-form-self/' + item.loanProductId + '/' + item.leadCode"
                  style="color:white;font-weight: 700;">Re-Submit</a>
              </button> -->

              <button class="btn btn-sm btn-info" type="button" title="Click here to go to form"
                (click)="goLoanForm(item.id,item.customerId,item.loanProductId,item.tenureId)" *ngIf="type==1"> Go to Form </button>

              <button class="btn btn-sm btn-info" type="button" title="Click here to complete process"
                (click)="goLoanView(item.leadCode)" *ngIf="type==3" style="height: 30px;width: 60px;"> View </button>

              <!-- <button class="btn btn-sm btn-info" type="button" title="Click here to complete process" *ngIf="type==3">
                View </button>

              <button class="btn btn-sm btn-success" type="button" title="Click here to complete process"
                *ngIf="type==3" style="margin-left: 8px;"> Schedule </button> -->
                <!-- <button class="btn btn-sm btn-info" type="button" title="Click here to complete process" *ngIf="type==4"
                  style="height: 30px;width: 60px;"> View </button> -->



                  <!-- <button class="btn btn-sm btn-info" (click)="OpenPDF(item?.id)" type="button" title="Disburse Loan"
                    style="background-color: #6f7270;" *ngIf="type==3"> Schedule View</button> -->

             

            </td>
          </tr>
        </tbody>
      </table>

    </div>

  </div>
</div>