import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { CibreportsService } from "../cibreports.service";
import swal from "sweetalert";
@Component({
  selector: "app-cibsubject-pdf",
  templateUrl: "./cibsubject-pdf.component.html",
  styleUrls: ["./cibsubject-pdf.component.scss"],
})
export class CibsubjectPdfComponent implements OnInit {
  fromDate: Date = new Date();
  monthsId: number = 0;
  subjectDataList: any = [];

  constructor(
    private location: Location,
    private cibService: CibreportsService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  goBack() {
    this.location.back();
  }

  OpenTextWithDate() {
    const currentYear = new Date().getFullYear();

    if (this.monthsId === 0) {
      swal("Warning", "Please select month..", "warning");
      return false;
    }
     else {
      
      this.cibService
        .GenerateCIBSubjectPersonTextWithDate(this.monthsId, currentYear)
        .subscribe((x) => {
          
          let blob: Blob = x.body as Blob;
          let url = window.URL.createObjectURL(blob);

          const a = document.createElement("a");
          a.href = url;

          a.download = "CIBSubjectReport";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        });
    }
  }

  PreviewData() {
    const currentYear = new Date().getFullYear();
    //const currentDay = new Date().getDate();
    // const formattedDate = `${currentYear}-${this.getMonthString(
    //   this.monthsId
    // )}-${currentDay}`;

    if (this.monthsId === 0) {
      swal("Warning", "Please select month..", "warning");
      return false;
    } else {
      this.cibService
        .GetCIBSubjectPerson(this.monthsId, currentYear)
        .subscribe((res) => {
          if (Array.isArray(res) && res.length === 0) {
            swal("No Data", "No data available for this month.", "info");
          } else {
            this.subjectDataList = res;
            this.changeDetectorRef.detectChanges();
          }
        });
    }
  }
}
