import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
const apiUrl=environment.baseUrl + '/api/Tax/'
@Injectable({
  providedIn: 'root'
})
export class TaxKeyService {

  constructor(private http: HttpClient) {
    
  }
  getList(): Observable<any> {
    return this.http.get(apiUrl + 'GetAllTax' );
   }
   getListByType(id:number): Observable<any> {
     return this.http.get(apiUrl + 'GetAllTaxKeyByProductBuilderType?id='+id );
    }
   GetDetailsByMasterId(id:any){
    return this.http.get(apiUrl+'GetTaxDetailbyMasterId/'+ id);
      }
   savetaxkey(model: any){
    return this.http.post(apiUrl + 'SaveKeyDetail' , model);
    }
 gettansactionList(): Observable<any> {
  return this.http.get(apiUrl + 'GetAllTransactiontype' );
 }

  deletetaxkey(id: any){
    return this.http.delete(apiUrl + 'DeleteTax/'+ id );
 }

  
 getListWithSerchAndPagination(currentPage: any, itemsPerPage: any, searchValue: string) {
  return this.http.get(apiUrl + 'GetTaxListWithSerchAndPagination?page='+ currentPage + '&pageSize=' +itemsPerPage + '&search=' + searchValue);
}

  }
