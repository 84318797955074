import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  token:string="";


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.token=localStorage.getItem('token');
    // if(this.token===null){
    //   this.token=localStorage.getItem('token');
    //   sessionStorage.setItem('token',this.token);
    // }
    const modifiedRequest = request.clone({
      setHeaders: {
        'Authorization': `Bearer ${this.token}`,
        'Custom-Header': 'custom-value'
      }
    });
    // Pass the modified request to the next handler
    return next.handle(modifiedRequest);
  }
}
