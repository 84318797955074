import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
const apiUrl=environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class CashApprovalService {

  constructor(private http: HttpClient) { }
    getAlllist():Observable<any>
    {
        return this.http.get(apiUrl + '/api/CashApprover/GetAllCashApproverMaster');
    }

    getUserlist():Observable<any>
    {
         return this.http.get(apiUrl +  '/api/CashApprover/GetBankUserList');
    }

    getApprovalMasterWithDetalislist():Observable<any>
    {
         return this.http.get(apiUrl +  '/api/CashApprover/GetAllCashApproverMasterWithDetails');
    }




    saveApprovalMasterDetails(model: any)
     {
   
       return this.http.post(apiUrl + '/api/CashApprover/SaveCashApprovalMasterDetails', model);
     }



     GetApprovalDetailsByMasterId(id:any){
       return this.http.get(apiUrl+'/api/CashApprover/GetCashApprovalDetailsbyMasterId?id='+ id);
      }
   

     delete(id:any){
      return this.http.delete(apiUrl + '/api/CashApprover/DeleteCashApprovalMaster/' + id);
    }
  
}
