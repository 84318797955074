import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LeadInfoService } from '../lead-info.service';
import { DatePipe } from '@angular/common';
import { environment } from 'environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardCustomService } from '../dashboard-custom.service';
import { PdfService } from 'app/pdf-reporting/pdf.service';
const apiUrlPDF = environment.baseUrl+'/LeadReport/GetLoanInfoByLeadId/';
import { Location } from "@angular/common";
@Component({
  selector: "app-cutomer-lead",
  templateUrl: "./cutomer-lead.component.html",
  styleUrls: ["./cutomer-lead.component.scss"],
})
export class CutomerLeadComponent implements OnInit {
  LeadList: any[] = [];

  type: number = 0;
  statusId: number = 0;

  constructor(
    //private datePipe: DatePipe,
    private _leadService: LeadInfoService,
    private _serviceDashboard: DashboardCustomService,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private pdfService: PdfService,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.type = params["parameter"];
    });
    this.type;

    //this.getLeadList();
    this.getCustomerLeadList();
  }

  goDashboard() {
    //this.router.navigate(['/dashboard/customer-dashboard'])
    this.router.navigate(["/dashboard/dashboard-new"]);
  }

  getCustomerLeadList() {
    this._serviceDashboard
      .getListForCustomerDashboard(this.type)
      .subscribe((res: any) => {
        this.LeadList = res;
        this.changeDetectorRef.detectChanges;
      });
  }

  goLoanForm(
    leadId: any,
    customersId: any,
    loanProductsId: any,
    tenureId: any
  ) {
    //this.router.navigate(['/loan-application/loan-application', leadId]);
    this.router.navigate([
      "/loan-application/loan-application/" +
        leadId +
        "/" +
        customersId +
        "/" +
        loanProductsId +
        "/" +
        tenureId,
    ]);
  }

  goLoanView(leadCode: string) {
    this.router.navigate([
      "/loanapplication-approval/loanapplication-approval/" +
        leadCode +
        "/" +
        2,
    ]);
  }

  // formatDate(leadStartDate: any): string {
  //   const dateObject = new Date(leadStartDate);
  //   if (!leadStartDate || isNaN(dateObject.getTime())) {
  //    return '';
  //   } else {
  //    return this.datePipe.transform(dateObject, 'dd-MM-yyyy') || '';
  //   }

  // }
  printPDF(id: number) {
    const fileUrl = `${apiUrlPDF}${id}`;
    window.open(fileUrl, "_blank");
  }

  OpenPDF(leadId) {
    this.pdfService.OpenSchedulePdf(leadId).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    });
  }
  goBack() {
    this.location.back();
  }
}
