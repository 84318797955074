import { Component, OnInit } from '@angular/core';
import { PasswordPolicy } from 'app/models/Security/password-policy';
import { SecurityService } from 'app/services/security.service';

@Component({
  selector: 'app-password-policy-setup',
  templateUrl: './password-policy-setup.component.html',
  styleUrls: ['./password-policy-setup.component.scss']
})
export class PasswordPolicySetupComponent implements OnInit {
  passwordPolicy: PasswordPolicy = new PasswordPolicy(); // Initialize a new instance of PasswordPolicy

  constructor(private securityService: SecurityService) { }

  ngOnInit(): void {
    this.getPasswordPolicy();
  }


  onSubmit() {
    console.log(this.passwordPolicy);

    this.securityService.savePasswordPolicy(this.passwordPolicy)
      .subscribe(
        (response) => {
          alert('Updated Successfully!');
          console.log('Password policy saved successfully:', response);
        },
        (error) => {
          alert('Updated Failed!');
          console.error('Error saving password policy:', error);
        }
      );

  }


  getPasswordPolicy(): void {
    this.securityService.getPasswordPolicy()
      .subscribe(
        (policy: PasswordPolicy) => {
          this.passwordPolicy = policy;
          console.log('Password policy retrieved successfully:', this.passwordPolicy);
        },
        (error) => {
          console.error('Error retrieving password policy:', error);
        }
      );
  }



}
