



<br>
<h4 class="card-title" style="color: black">Monthly Interest Process</h4>
<hr>
<div class="card">
    <div class="card-body">
        <div class="form-group row">
            <label for="year" class="col-md-4">YEAR:</label>
            <div class="col-md-4 input-group">
                    <select class="form-control" formControlName="year" placeholder="year" (change)="onyearStatusChange($event.target.value)">
                      <option value="select">Select</option>
                      <option value="2023">2023</option>
                      <option value="2023">2024</option>
                      <option value="2023">2025</option>
                      <option value="2023">2026</option>
                      <option value="2023">2027</option>
                      <option value="2023">2028</option>
                      <option value="2023">2029</option>
                      <option value="2023">2030</option>
                      
                    </select>
               </div>
        </div>
        <div class="form-group row">
            <label for="month" class="col-md-4">MONTH:</label>
            <div class="col-md-4 input-group">
                    <select class="form-control" formControlName="month" placeholder="month" (change)="onmonthStatusChange($event.target.value)">
                      <option value="select">Select</option>
                      <option value="1">January</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>
               </div>
        </div>

        <div class="col-4">
            <button type="button" (click)="InterestProcess()" class="btn btn-primary" id="processButton">Process</button>
        </div>

    </div>
</div>