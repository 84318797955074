import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';


const apiUrl=environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class ScoretagService {

  constructor(private http:HttpClient) { }

  GetScoreTagList():Observable<any>{
    return this.http.get(apiUrl + '/api/Score/GetScoreTagList');
  }
  
  GetScoreTagById(id:any):Observable<any>{
    return this.http.get(apiUrl + '/api/Score/GetScoreTagById/' +id);
  }

  save(model : any){
    //alert(22);
    return this.http.post(apiUrl + '/api/Score/SaveScoreTag', model);
  }

  delete(id:any){
    return this.http.get(apiUrl + '/api/Score/DeleteScoreTagById/' +id);
  }

  getAllBankList():Observable<any>{
    return this.http.get(apiUrl + '/api/Score/GetBankList');
  }



  getAllMasterBankList(): Observable<any> {
    return this.http.get(apiUrl + '/api/Score/GetAllMasterBankList');
  }

  bankSave(model : any){
    return this.http.post(apiUrl + '/api/Score/SaveBankName', model)
  }
  
  bankDelete(id:any){
    return this.http.get(apiUrl + '/api/Score/DeleteBankById/' +id);
  }
//#region 
GetBankBeneficiaryAccountNo():Observable<any>{
  return this.http.get(apiUrl + '/api/Customers/GetBankBeneficiaryAccountNo');
}

GetBankBeneficiaryAccountListWithSearchAndPagination(currentPage : any, itemsPerPage : any, searchValue : string):Observable<any>{
  return this.http.get(apiUrl + '/api/Customers/GetBankBeneficiaryAccountNoWithSearchAndPagination?page=' + currentPage + '&pageSize=' + itemsPerPage + '&search=' + searchValue);
}

GetBankBeneficiaryAccountNoById(id:any):Observable<any>{
  return this.http.get(apiUrl + '/api/Customers/GetBankBeneficiaryAccountNoById/' +id);
}

SaveBankBeneficiary(model : any){
  return this.http.post(apiUrl + '/api/Score/SaveBankBeneficiary', model);
}

DeleteBankBeneficiar(id:any){
  return this.http.get(apiUrl + '/api/Customers/DeleteBankBeneficiary/' +id);
}
GetAllBankBranch():Observable<any>{
  return this.http.get(apiUrl + '/api/Customers/GetAllBankBranch');
}
GetLedgerRelationlist():Observable<any>{
  return this.http.get(apiUrl + '/api/Ledger/GetLedgerRelationlist');
}
GetbankbenificiaryById(id:any):Observable<any>{
  return this.http.get(apiUrl + '/api/Score/GetBankBeneficiaryById/' +id);
}

  GetAllBankBranchByBankId(bankId:number): Observable<any> {
    return this.http.get(apiUrl + '/api/Score/GetAllBankBranchByBankId?bankId=' + bankId);
  }

//#endregion



}
