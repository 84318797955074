import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CashPostingService } from '../service/cash-posting.service';
import { LedgerService } from '../service/ledger.service';
import { SharedService } from 'app/services/shared.service';
import { CashPosting } from '../model/cash-posting';
import { CountryInfoService } from 'app/master-data/basic/basic-service/country-info.service';
import { Observable, of } from 'rxjs';
import { CustomerService } from 'app/customer/customer.service';
import { BusinessDate } from '../model/business-date';
import swal from 'sweetalert';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
@Component({
  selector: 'app-cash-posting',
  templateUrl: './cash-posting.component.html',
  styleUrls: ['./cash-posting.component.scss']
})
export class CashPostingComponent implements OnInit {
  selectedOption: string;
  customerIdPayment: number;
  customerAccountId: number;
  customerAccountId2: number;
  currentBalance: number;
  isvalid: boolean = true;
  isBtnValid: boolean = false;
  form: FormGroup;
  //BDGenerateCode: string;

  BDGenerateCode: string = '';
  customerCode: string = '';
  accountNo: string = '';
  accountNoRecieve: string = '';
  accountNoFund1: string = '';
  accountNoFund2: string = '';
  AutoCompleate: any[] = [];

  customerInfo_cashPayment: any = '';
  customerInfo_cashReceive: any = '';
  customerInfo_cashFundTransfer1: any = '';
  customerInfo_cashFundTransfer2: any = '';
 
  //BDGenerateCode: string = '';
  //customerCode: string = '';

  isEdit: boolean = false;
  frmGroup: FormGroup;
  list: CashPosting[] = [];
  model = new CashPosting();
  formControl = new FormControl();
  suggestions: any[] = [];
  //hideShow: any;
  hideShow: string = 'Show';
  searchType: string = '';
  isAuto: boolean=false;
  constructor(
    private router: Router,
    private _service: CashPostingService,
    private _LedgerService: LedgerService,
    private _CountryInfoService: CountryInfoService,
    private _CustomerService: CustomerService,
    private changeDetectorRef: ChangeDetectorRef,
    private fb: FormBuilder,
    private formBuilder: FormBuilder,
    private shared: SharedService,
  ) {
    {
      this.frmGroup = new FormGroup({
        id: new FormControl(),
        customerCode: new FormControl(),
        transactionType: new FormControl(),
        customerId: new FormControl(),
        //voucherNo: new FormControl(),
        //postingType: new FormControl(),
        remarks: new FormControl(),
        currencyId: new FormControl(),
        voucherAmount: new FormControl(),
        BDGenerateCode: new FormControl(),
        businessDateId:new FormControl(),
        businessDate:new FormControl(),
        gLGenerateId:new FormControl(),
        
        

        
      });
      this.form = this.fb.group({
        BDGenerateCode: [''],
        customerCode: [''],
        // Other form controls here
      });
    }

  }

  ngOnInit(): void {
    this.loadLedgerList();
    this.loadCountryList();
    //this.AutoCode();
    this.formControl.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(value => this._LedgerService.getAutocompleteSuggestions(value))
    ).subscribe(data => {
      this.suggestions = data;
      console.log(555)
      console.log(this.suggestions)
    });

    this.hideShow = 'Show';
    this.GetBusinessDatebyActiveStatus();
    
  }
  selectedSuggestion: any = null;
  gLGenerateId: any = null;
  AccountName: any = null;
  glAccountName:any;

  // selectSuggestion(item: any) {
    
  //   this.formControl.setValue(item.bdGenerateCode); 
  //   //this.suggestions = [];
  //   this.gLGenerateId=item.id;
  //   this.AccountName=item.ledger.accountName;
  //   this.selectedSuggestion = item;
  //   this.hideShow = 'Hide';


  // }

  hideAfterSelectValue(value: any) {
    //alert(value);
    this.hideShow = value;
  }
  // selectSearchType(type: string) {
  //   this.searchType = type;
  //   this.BDGenerateCode = '';
  //   this.customerCode = '';
  //   // if (type === 'BDGenerateCode') {
  //   //   this.customerCode = ''; // Reset Payment Account fields
  //   // } else if (type === 'PaymentAccount') {
  //   //   this.BDGenerateCode = ''; // Reset BD Generate Code fields
  //   // }
  //   this.form.reset();
  // }

  navigateTo(route: string): void {
    this.router.navigate([route]);
  }

  LedgerList: Observable<any[]>;
  loadLedgerList() {
    this._LedgerService.getLedgerRelationlist().subscribe(res => {

      this.LedgerList = of(res);
    });
  }

  countryList: Observable<any[]>;
  loadCountryList() {
    this._CountryInfoService.getlist().subscribe(res => {
      this.countryList = of(res);
    });
  }

  onsubmit() {
    if (this.isEdit) {
      this.model.id = this.frmGroup.value.id;
    }
    else {
      this.model.id = 0;
    }

    this.model.customerCode = this.frmGroup.value.customerCode;
    this.model.transactionType = 1;
    this.model.customerAccountId = this.customerAccountId;
    this.model.remarks = this.frmGroup.value.remarks;
    this.model.currencyId = this.frmGroup.value.currencyId;
    this.model.voucherAmount = this.frmGroup.value.voucherAmount;
    this.model.businessDateId = this.frmGroup.value.businessDateId;
    this.model.gLGenerateId =  this.frmGroup.value.gLGenerateId;

    if (this.model.voucherAmount > 0 && this.model.currencyId > 0) {
      if (this.model.id == 0) {
        this._service.save(this.model).subscribe((res: number) => {
          if (res > 0) {
            this.ResetForm();

            this.shared.ShowAlert("Success", "Save Successfully", "success");
            this.isEdit = false;
          }
          else {
            this.shared.ShowAlert("Warning", "Failed to Save", "warning");
            this.isEdit = false;
          }

        })
      }
      else {
        this._service.save(this.model).subscribe(res => {
          this.ResetForm();

          this.shared.ShowAlert("Success", "Save Successfully", "success");
          //this.getLedgerList();
          this.isEdit = false;
          //this.showSaveForm='saveList';
        })
      }
    }
  }


  loadUserDataByAccountNoCashPayment() {
    //alert(this.frmGroup.value.customerCode) 
    this._CustomerService.getCustomerByAccountNo(this.accountNo).subscribe(res => {
      
      if (res != null) {

        if(res.glAccountName==null){
          console.log('glName');
          console.log(res.glAccountName);
        this.currentBalance = res.currentBalance;
        this.customerInfo_cashPayment = res.customerNameEng + '; ' + res.mobileNo + '; ' + res.nidNo;
        this.customerIdPayment = res.customersId;
        this.customerAccountId = res.id;
        this.isBtnValid = true;
        this.glAccountName='';
        }
        else{
          this.glAccountName=res.glAccountName;
          this.customerInfo_cashPayment ='';
          this.isBtnValid = true;
        }

        // this.currentBalance = res.currentBalance;
        // this.customerInfo_cashPayment = res.customerNameEng + '; ' + res.mobileNo + '; ' + res.nidNo;
        // this.customerIdPayment = res.customersId;
        // this.customerAccountId = res.id;
        // this.isBtnValid = true;
        //this.glAccountName=res.glAccountName;
      }
      
      else {
        this.refreshAc();
        swal('Warning', 'Invalid Account No', 'warning');
      }
    })
  }


  refreshAc() {
    this.currentBalance = 0;
    this.customerInfo_cashPayment = '';
    //this.customerInfo_cashFundTransfer1 = '';
    //this.customerInfo_cashFundTransfer2 = '';
    this.customerIdPayment = 0;
    this.customerAccountId = 0;
    this.isBtnValid = false;
  }
  CheckBalance() {
    var currentBalance = this.currentBalance;
    var inputValue = this.frmGroup.value.voucherAmount;
    if (currentBalance < inputValue) {
      this.isvalid = false;
    }
    else {
      this.isvalid = true;
    }

  }


  ResetForm() {
    this.frmGroup.reset();

    this.customerInfo_cashPayment = '';
    //this.customerInfo_cashReceive = '';
    //this.customerInfo_cashFundTransfer1 = '';
    //this.customerInfo_cashFundTransfer2 = '';
  }
  //#endregion
  GetBusinessDatebyActiveStatus(){
   
    this._LedgerService.GetBusinessDatebyActiveStatus().subscribe(res =>{
    
      if(res != null){
       
        this.isAuto=true;
        this.frmGroup.patchValue({
               
          businessDateId: res.id,
          businessDate: res.business_Date,
        });
      }
      else{
        this.frmGroup = this.formBuilder.group({
               
          businessDateId:'',
         
        });
      }
    })
  }

  

}
