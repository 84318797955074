<div class="" style="width: 100%;">
    <div class="" >
      <div class="container">
        <div class="" style="margin-top: 3%;">
          <h3 class="card-title" style="color: black;text-align: center;" >User Role Info</h3>
        </div>       
        <hr style="height: 10%;">
        <div class="card" > 
          <div class="card-body" >
            <form >
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label for="" class="col-sm-4 col-form-label">User Name</label>
                      <div class="col-sm-8">
                        <select  class="form-control" name="userName"
                          placeholder="" [(ngModel)]="model.userName">
                          <option value="">Select</option>
                          <ng-container *ngFor="let item of UserList | async">
                            <option [value]="item.userName">{{item.userName}}-{{item.fullName}}</option>
                          </ng-container>
                        </select>
                      </div>
                    </div>
                  </div> 
                  <div class="col-md-6">
                    <div class="form-group row">
                        <label for="" class="col-sm-4 col-form-label">Role Name</label>
                        <div class="col-sm-8">
                          <select  class="form-control" name="roleName"
                            placeholder="" [(ngModel)]="model.roleName">
                            <option value="">Select</option>
                            <ng-container *ngFor="let item of RoleList | async">
                              <option [value]="item.name">{{item.name}}</option>
                            </ng-container>
                          </select>
                        </div>
                      </div>
                  </div>
                </div>
                <button data-toggle="tooltip"  (click)="onsubmit()" title="Save" type="button"
                  class="btn btn-success" style="float:right; margin-top:5px;">Save </button>
  
            </form>
          </div>
  
        
        </div>
  <div class="card">
    <div class="card-body">
      <div class="container">
        <h5 class="card-title" style="color: black">User Role List</h5>
        <hr>
        <table id="example" class="table table-bordered  ">
          <thead>
              <tr>
                <th>SL No.</th>
                <th>User Name</th>
                <th>Role Name </th>
                <th>Action</th>
              </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of list; let i = index;">
              <td>{{i + 1}}</td>
              <td>{{item.userName}} ({{item.fullName}})</td>
             
              <td>{{item.roleName}}</td>
              <td >
                  <button class="btn btn-primary" (click)="edit(item)"><i class="fa fa-edit 3x"></i></button>
                  <button [disabled]="isEdit" class="btn btn-danger" style="margin-left: 5px;" (click)="delete(item.Id)"><i class="fa fa-trash-o 3x"></i></button>
              </td>
            </tr>
           </tbody>
      </table>
      </div>  
    </div>
  </div>
      </div>
    </div>
  </div>