
    <div class="card">
        <!-- Modal content goes here -->
        <div class="card-body" style="margin-left: -50%; width: 200%;">
            <div class="card-header">
                <h4 class="modal-title">Loan Product Builder Details</h4>
                <button type="button" class="close" style="size: 150%;" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <table class="table-bordered">
                <th class="col-3">Code: </th>
                <td class="col-3">{{ productDetails?.productInfo?.code }}</td>
                <th class="col-3">Name: </th>
                <td class="col-3">{{ productDetails?.productInfo?.name }}</td>
            </table>
            <table class="table-bordered">
                <th class="col-3">Version: </th>
                <td class="col-3">{{ productDetails?.productInfo?.version }}</td>
                <th class="col-3">Description: </th>
                <td class="col-3">{{ productDetails?.productInfo?.description }}</td>
            </table>
            <table class="table-bordered">
                <th class="col-3">Currency: </th>
                <td class="col-3">{{ productDetails?.productInfo?.currency }}</td>
                <th class="col-3">Day Size: </th>
                <td class="col-3">{{ productDetails?.productInfo.daySize?.name }}</td>
            </table>
            <table class="table-bordered">
                <th class="col-3">Tax Key: </th>
                <td class="col-3">{{ productDetails?.productInfo.taxKey?.taxKeyName }}</td>
                <th class="col-3">Excise Duty: </th>
                <td class="col-3">{{ productDetails?.productInfo.exciseDuty?.exciseDutyName }}</td>
            </table>
            <table class="table-bordered">
                <th class="col-3">Interest Calculation Type: Slab</th>
                <td class="col-3">
                    <ul>
                        <li *ngFor="let item of productDetails?.productInstCalTypes">
                            {{item.slab.min}}-{{item.slab.max}} ({{item.slab.amountParcent}})%
                        </li>
                    </ul>
                </td>


                <th class="col-3">Interest Calculation Frequency: </th>
                <td class="col-3">{{productDetails?.productInfo?.interestCalculationFrequency?.name }} -
                    {{productDetails?.productInfo?.interestCalculationFrequency?.days }} {{productDetails?.productInfo?.interestCalculationFrequency?.days !=null? "days" : "" }}</td>
            </table>
            <table class="table-bordered">
                <th class="col-3">Capitalize Rule:</th>
                <td class="col-3">
                    <ul>
                        <li *ngFor="let item of productDetails?.productRules"> {{item.rule?.code}}
                        </li>
                    </ul>
                </td>
                <th class="col-3">Capitalization Frequency:</th>
                <td class="col-3">
                    {{productDetails?.productInfo?.capitalizationFrequency?.name }} - {{
                    productDetails?.productInfo?.capitalizationFrequency?.days }} {{
                    productDetails?.productInfo?.capitalizationFrequency?.days !=null? "days" :
                    ""}}
                </td>
            </table>
            <table class="table-bordered">
                <th class="col-3">Balance Type:</th>
                <td class="col-3">{{ productDetails?.productInfo?.balanceType?.name }}</td>
                <th class="col-3">Status:</th>
                <td class="col-3">
                    {{ productDetails?.productInfo?.status ===0 ? "in-Active" :
                    "Active"}}
                </td>
            </table>
            <table class="table-bordered">
                <th class="col-3">Charge:</th>
                <td class="col-3">
                    <ul>
                        <li *ngFor="let item of productDetails?.productCharge">
                            {{item.charge?.code}}-{{item.charge?.name}}
                        </li>
                    </ul>
                </td>
                <th class="col-3">Commission:</th>
                <td class="col-3">
                    <ul>
                        <li *ngFor="let item of productDetails?.productCommission">
                            {{item.commission?.code}}-{{item.commission?.name}}
                        </li>
                    </ul>
                </td>
            </table>


            <div class="modal-footer">
                <button type="button" class="btn btn-secondary">Close</button>
            </div>
        </div>
    </div>
