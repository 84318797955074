import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductBuilderService } from 'app/product/service/product-builder.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pending-retail-product',
  templateUrl: './pending-retail-product.component.html',
  styleUrls: ['./pending-retail-product.component.scss']
})
export class PendingRetailProductComponent implements OnInit {

  productList: any[] = [];
  productDetails: any;
  closeResult: string;
  clickedProductId: number = 0;

  constructor(private _loanBuilderService:ProductBuilderService,private modalService: NgbModal,
    private router: Router) 
    { }

  ngOnInit(): void {
    this.GetAllPendingProduct();
  }

  GetAllPendingProduct() {
    this._loanBuilderService.GetAllPendingProductByRole().subscribe(res => {
      this.productList = res;
    })
  }

  Approve(productId:number){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to Approve',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Approved',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this._loanBuilderService.AproveLoanProductByUserRole(productId).subscribe(res => {
          if(res == 1){
            Swal.fire({
              title: 'Success?',
              text: 'Approved Successfully!',
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok'
            }).then((e) =>{
              window.location.reload();
            })
          }
          else{
            Swal.fire({
              title: 'Failed',
              text: 'Failed to Approve',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonText: 'Ok',
              //cancelButtonText: 'No'
            })
          }
        })
      }
    })
  }

  getLoanProductDetails(id:any){
    this.clickedProductId = id;
    this._loanBuilderService.getProductBuilderDetailsById(id).subscribe(
      (data : any) => {
        this.productDetails = data;
        console.log(this.productDetails)
      }
    );
  }

  openBuilderDetailsModal(content) {
    let options: any = {
      size: 'xl',
      centered: false
    }
      this.modalService.open(content, options).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
          this.closeResult = `Dismissed`;
      });
  }

}
