import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { UserListComponent } from "./user-list/user-list.component";
import { RoleComponent } from "./user-management/user-component/role/role.component";
import { UserRoleAssignComponent } from "./user-management/user-component/user-role-assign/user-role-assign.component";
import { RoleCreateComponent } from "./user-management/user-component/role-create/role-create.component";

const routes: Routes = [
  {path: "",component: UserListComponent,data: {title: "User",},},
  {path: "role",component: RoleComponent,data: {title: "Role",},},
  {path: "role-create",component: RoleCreateComponent,data: {title: "Role-Create",},},
  {path: "userrole",component: UserRoleAssignComponent,data: {title: "User Role",},},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserRoutingModule {}
