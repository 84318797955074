<div class="card" style="width: 100%;">
    <div class="card-body">
        <div class="container">
            <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveForm'">
                <div class="" style="margin-top: 3%;">
                    <h3 class="card-title" style="color: black; align-items: center;">Bank Info</h3>
                    <button type="button" data-toggle="tooltip" title="Add new"  class="btn btn-secondary" (click)="onClickShowSaveForm('saveList')" style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">List View</button>
                </div>
                <div class="card-body">
                    <form [formGroup]="bankForm">
                        <div class="row">
                            <div class="col-md-12">
                               <div class="form-group row">
                                <label for="" class="col-sm-4">Bank Name</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" name="bankName" [(ngModel)]="model.bankName" placeholder="Bank Name">
                                </div>
                               </div>
                            </div>
                        </div>
                        <button data-toggle="tooltip"[disabled]="!bankForm.valid" (click)="onsubmit()" title="Save" type="button" class="btn btn-success" style="float:right; margin-top:5px;">Save </button>
                    </form>
                </div>
            </div>
            <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveList'" >
                <div class="card-body">
                    <div class="container">
                        <h5 class="card-title" style="color: black">Bank List</h5>
                        <button type="button" data-toggle="tooltip" title="Add new"  class="btn btn-secondary" (click)="onClickShowSaveForm('saveForm')"style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">Add New</button>
                        <hr>
                        <table id="example" class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>SL No.</th>
                                    <th>Bank Name</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let item of bankList; let i = index">
                                <tr>
                                    <td>{{i + 1}}</td>
                                    <td>{{item.bankName}}</td>
                                    <td>
                                        <button class="btn btn-primary" (click)="edit(item)"><i class="fa fa-edit 3x"></i></button>
                                        <button class="btn btn-danger" style="margin-left: 5px;" (click)="delete(item.id)"><i class="fa fa-trash-o 3x"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
