import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { id } from '@swimlane/ngx-datatable';

const apiUrl = environment.baseUrl;


@Injectable({
  providedIn: 'root'
})
export class DivisionService {

  constructor(private http: HttpClient) { }

  save(model: any) {

    return this.http.post(apiUrl + '/api/Division/SaveDivision', model);

  }

  getlist(): Observable<any> {
    return this.http.get(apiUrl + '/api/Division/GetAllDivision');
  }

  GetAllCountry(): Observable<any> {
    return this.http.get(apiUrl + '/api/Country/GetAllCountry');
  }
  getListWithSerchAndPagination(currentPage: any, itemsPerPage: any, searchValue: string) {
    return this.http.get(apiUrl + '/api/Division/GetAllDivisionWithPaginationSearch?page=' + currentPage + '&pageSize=' + itemsPerPage + '&search=' + searchValue);
  }

  delete(id: any) {

    return this.http.delete(apiUrl + '/api/Division/Delete/' + id);
  }
  

}
