import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DashboardCustomService } from 'app/dashboard/dashboard-custom.service';
import { FdrdetailsService } from 'app/fdr-account/fdrdetails.service';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { SharedService } from 'app/services/shared.service';

@Component({
  selector: 'app-dormant-account',
  templateUrl: './dormant-account.component.html',
  styleUrls: ['./dormant-account.component.scss']
})
export class DormantAccountComponent implements OnInit {

  
  DormantAccountList: any[] = [];

  constructor(
    private pdfService: PdfService,
    private _fdrService: FdrdetailsService,
    private _serviceDashboard: DashboardCustomService,
    private changeDetect: ChangeDetectorRef,
    private shared: SharedService,
  ) {}

  ngOnInit(): void {
    this .GetAllDormantAccountList();
  }

  

  GetAllDormantAccountList() {
    this._serviceDashboard.GetAlldormantAccount().subscribe((res) => {
      if (res.length > 0) {

      
        this.DormantAccountList = res;

        this.changeDetect.detectChanges();
      }
    });
  }


  Approve(id: number){
    this._serviceDashboard.ApproveDormantAccount(id).subscribe((res) => {
      if (res != null) {
        this.shared.ShowAlert("Success", "Approved Successful", "success");
     location.reload();
      }
    });
  }

}
