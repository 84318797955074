import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaymentService } from '../payment.service';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {
  transId: string;
  transLog: any;

  constructor(private route: ActivatedRoute, private paymentService: PaymentService) {}

  ngOnInit() {
    this.transId = this.route.snapshot.paramMap.get("trans_id");
    this.loadTransactionLog(this.transId);
  }

  loadTransactionLog(transId:string){
    this.paymentService.getTransactionLogByTransactionId(transId).subscribe((data) => {
      console.log(data);
      this.transLog = data;
    })
  }
  
}
