<!-- <p>score-question works!</p> -->

<section>
    <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveForm'">
        <div class="card-body">
            <div class="container">
                <h5 class="card-title" style="color: black">Loan Menu Setup</h5>
                <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary"
                    (click)="onClickShowSaveForm('ListView')"
                    style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">List View</button>
                <hr>
                <form [formGroup]="loanFromGrp">
                    <div asp-validation-summary="All" class="text-danger"></div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="name" class="col-sm-4 col-form-label">Name</label>
                                <div class="col-sm-8">
                                    <input type="text" formControlName="name" class="form-control"
                                        placeholder="Type here...">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="nameBn" class="col-sm-4 col-form-label">NameBn</label>
                                <div class="col-sm-8">
                                    <input type="text" formControlName="nameBn" class="form-control"
                                        placeholder="Type here...">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="position" class="col-sm-4 col-form-label">Position </label>
                                <div class="col-sm-8">
                                    <select class="form-control"  formControlName="position">
                                        <option value="">--Select--</option>
                                        <option value="1">Vertical</option>
                                        <option value="0">Horizontal</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                       

                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="parentId" class="col-sm-4 col-form-label"> Parent Name</label>
                                <div class="col-sm-8">
                                    <select class="form-control" formControlName="parentId"
                                        (change)="onParentChange($event.target.value)">
                                        <option value="0">Select</option>
                                        <ng-container *ngFor="let item of ParentList">
                                            <option [value]="item.id">{{item.name}}</option>
                                        </ng-container>

                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="applicationRoleId" class="col-sm-4 col-form-label">Role Type </label>
                                <div class="col-sm-8">
                                    <select class="form-control" formControlName="applicationRoleId">
                                        <option value="">Select</option>
                                        <option value="0">All</option>
                                        <option *ngFor="let item of userTypelist" [value]="item.id">{{item.name}}</option>
                                    </select>                                   
                                </div>                                
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="LoanProductId" class="col-sm-4 col-form-label">Loan Product</label>
                                <div class="col-sm-8">
                                    <select class="form-control" formControlName="LoanProductId"
                                        (change)="onParentChange($event.target.value)">
                                        <option value="0">Select</option>
                                        <ng-container *ngFor="let item of productList">
                                            <option [value]="item.id"> {{ item.name }} - {{ item.code }}</option>
                                        </ng-container>

                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="status" class="col-sm-4 col-form-label"> Status</label>
                                <div class="col-sm-8">
                                    <select class="form-control" formControlName="status">
                                        <option value="">Select</option>
                                        <option value="1">Mandatory</option>
                                        <option value="0">Optional</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="url" class="col-sm-4 col-form-label">Url</label>
                                <div class="col-sm-8">
                                    <input type="text" formControlName="url" class="form-control"
                                        placeholder="Type here...">
                                </div>
                            </div>
                        </div>
                     

                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="sortOrder" class="col-sm-4 col-form-label">Sort Order</label>
                                <div class="col-sm-8">
                                    <input type="number" min="0" formControlName="sortOrder" class="form-control"
                                        placeholder="Type here...">
                                </div>
                            </div>
                        </div>

                    </div>

                    <button data-toggle="tooltip" title="Save" type="submit"
                        value="Submit" (click)="onSubmit()" class="btn btn-success"
                        style="float:right; margin-top:5px;">
                        <span>{{ isEdit ? 'Update' : 'Save' }}</span>
                    </button>
                    <!-- <button data-toggle="tooltip" [disabled]="!loanFromGrp.valid" (click)="onSubmit()" title="Save" type="button" class="btn btn-success" style="float:right; margin-top:5px;">Save </button> -->
                    <button type="button" data-toggle="tooltip" title="Refresh" (click)="refresh()"
                        class="btn btn-secondary"
                        style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>

                </form>
            </div>
        </div>
    </div>

    <div class="card" style="width: 100%;" *ngIf="showSaveForm==='ListView'">
        <div class="card-body">
            <div class="container">
                <h5 class="card-title" style="color: black">Loan Menu Setup List</h5>
                <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary"
                    (click)="onClickShowSaveForm('saveForm')"
                    style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">Add New</button>
                <hr>
                <table id="example" class="table table-striped table-bordered table-responsive" style="width:100%">
                    <thead>
                        <tr>
                            <th>SL No.</th>
                            <th>Name</th>
                            <th>NameBn</th>
                            <th>Position</th>
                            <!-- <th>Parent Name</th> -->
                            <th>Role Type</th>
                            <th>Loan Product</th>
                            <th>Status</th>
                            <th>Url</th>
                            <th>Sort Order</th>
                            <th style="width: 25%;text-align: center;">Action</th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr *ngFor="let item of ParentList; let i = index">
                            <td>{{i + 1}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.nameBn}}</td>
                            <td>{{item.position === "1" ? 'Vertical' : "Horizontal"}}</td>
                            <!-- <td>{{item.parentId}}</td> -->
                            <td>{{item.applicationRole.name}}</td>
                            <td>{{item.loanProduct.name}}</td>
                            <td>{{item.status===1?'Mandatory':"Optional"}}</td>
                            <td>{{item.url}}</td>
                            <td>{{item.sortOrder}}</td>
                            <td style="width: 25%;">
                                <button class="btn btn-sm btn-primary mr-2" (click)="edit(item)"><i class="fa fa-edit 3x"></i></button>
                                <button [disabled]="isEdit" class="btn btn-sm btn-danger" style="margin-left: 5px;" (click)="DeleteLoanMenuSetup(item.id)"><i class="fa fa-trash-o 3x"></i></button>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</section>