//Service calling
//https://www.positronx.io/angular-httpclient-http-service/

import { Injectable, inject } from '@angular/core'
import { Login } from 'app/models/login';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {CookieService} from 'ngx-cookie-service';
import { environment } from 'environments/environment';
import { RouteInfo } from 'app/shared/vertical-menu/vertical-menu.metadata';
const apiUrl=environment.baseUrl;
@Injectable({
  providedIn: 'root'
  })
export class LoginService {
  private isAuthenticate = false;

  // Method to check if the user is authenticated
  isAuthenticated(): boolean {
    if(localStorage.getItem("token")){
      return true;
    }
    // return !!sessionStorage.getItem('token');
  }
  cookieService=inject(CookieService);

  constructor(private _http: HttpClient) {

  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  // HttpClient API get() method => Fetch employees list
  getEmployees(): Observable<Login[]> {
    return this._http
          .get<Login[]>(apiUrl + '/api/employee/getall')
          .pipe(retry(1), catchError(this.handleError));
  }

  getEmployeeByCode(empCode: string): Observable<Login> {
    return this._http
          .get<Login>(apiUrl + '/api/employee/' + empCode)
          .pipe(retry(1), catchError(this.handleError));
  }

  login(log: Login):Observable<Login>{
    //const headers = new HttpHeaders().set('XSRF-TOKEN',  this.cookieService.get('XSRF-TOKEN'));
    sessionStorage.removeItem('token');
   // return this._http.post<Login>(apiUrl + '/api/Auth/LogIn', log);
     var result = this._http.post<Login>(apiUrl + '/api/Auth/LogIn', log);
     this.isAuthenticate = true;
     return result;


    //// result.subscribe((response: any) => {
    ////   if(response['status'] === 'Logged'){
    ////     this.isAuthenticate = true;
   // //   }
    //// },
    //// (error: any) => {
    ////   console.error(error);
   // // })


  }
  getNavbars(): Observable<RouteInfo[]>{
    return this._http.get<RouteInfo[]>(apiUrl + '/api/User/GetNavbar');
  }
  // Error handling
  handleError(error: any) {

    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }

  getUserByPhone(Mobile: string): Observable<any>
  {
    return this._http.get<any>(apiUrl+'api/Auth/GetUserByPhone/phone=' + Mobile, { responseType: 'json' });
  }

  getUserByEmail(Email: string): Observable<any>
  {
    return this._http.get<any>(apiUrl+'api/Auth/GetUserByEmail/email=' + Email, { responseType: 'json' });
  }

  checkUserAccess(username: string, route: string) {
    return this._http.get<boolean>(apiUrl+'/api/validate-route?username=' + username + '&route=' + route, { responseType: 'json' });
  }

}
