import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CustommeraccountService } from 'app/customer-account/custommeraccount.service';
import { DashboardCustomService } from 'app/dashboard/dashboard-custom.service';
import { FdrdetailsService } from 'app/fdr-account/fdrdetails.service';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { Location, DatePipe } from "@angular/common";
import { LayoutService } from 'app/shared/services/layout.service';
@Component({
  selector: "app-today-dps-list",
  templateUrl: "./today-dps-list.component.html",
  styleUrls: ["./today-dps-list.component.scss"],
})
export class TodayDpsListComponent implements OnInit {
  customerList: any[] = [];

  businessDate: any;

  startDate: Date = new Date();
  EndDate: Date = new Date();

  formattedStartDate: string;
  formattedEndDate: string;
  constructor(
    private location: Location,
    private _service: CustommeraccountService,
    private pdfService: PdfService,
    private layoutService: LayoutService,
    private _serviceDashboard: DashboardCustomService,
    private changeDetect: ChangeDetectorRef,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.getCurrentBusinessDate();
    this.getCustomerDPSAccount();
  }

  getCurrentBusinessDate() {
    this.layoutService.getCurrentBusinessDate().subscribe((res: any) => {
      this.businessDate = res.business_Date;
    });
  }

  formatDates() {
    this.formattedStartDate = this.datePipe.transform(
      this.startDate,
      "dd-MM-yyyy"
    );
    this.formattedEndDate = this.datePipe.transform(this.EndDate, "dd-MM-yyyy");
  }
  onDateChange() {
    this.formatDates();
  }

  getCustomerDPSAccount() {

     const formattedStartDate = this.datePipe.transform(
       this.startDate,
       "yyyy-MM-dd"
     );
     const formattedEndDate = this.datePipe.transform(
       this.EndDate,
       "yyyy-MM-dd"
     );

     this._serviceDashboard
       .getcCustomerDPSByDate(formattedStartDate, formattedEndDate)
       .subscribe((res) => {
         if (res.length > 0) {
           this.customerList = res;
           this.changeDetect.detectChanges();
         }
       });


    // this._serviceDashboard.getCustomerAccountByCustomerId().subscribe((res) => {
    //   if (res.length > 0) {
    //     this.customerList = res.filter(
    //       (customer) =>
    //         customer.accountType === "DPS" &&
    //         formatDate(customer.createdDate, "yyyy-MM-dd", "en-US") ===
    //           formatDate(this.businessDate, "yyyy-MM-dd", "en-US")
    //     );
    //     this.changeDetect.detectChanges();
    //   }
    // });
  }

  PrintKyc(id: number) {
    console.log(id);
    this.pdfService.OpenKycPdf(id).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    });
  }

  DPSSchedulePDF(accountId: number) {
    this.pdfService.DPSOpenSchedulePdf(accountId).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }

  goBack() {
    this.location.back();
  }
}
