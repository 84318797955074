import { Component, OnInit } from '@angular/core';
import { CobService } from '../cob.service';
import { COBStatusViewModel } from '../models/cobstatus-view-model';
import { CobProgress } from '../models/cob-progress';
import { NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

const now = new Date();

@Component({
  selector: 'app-coboperation',
  templateUrl: './coboperation.component.html',
  styleUrls: ['./coboperation.component.scss']
})
export class CoboperationComponent implements OnInit {
  cobs: COBStatusViewModel[] = [];
  cobDate: any = now;
  interval: any;
  progresses: CobProgress[];
  
  //----Date Picker Start----//
  d3: any;
  model: NgbDateStruct;
  popupModel;
  date: {year: number, month: number};
  navigation = 'select';
  //----Date Picker End----//
  constructor(private cobService: CobService) { 
    
  }

  ngOnInit(): void {
    this.selectToday();
    // this.cobService.startConnection();
    // this.cobService.loadCOBStatus().subscribe(cob => {
    //   this.cobs.push(cob);
    //   console.log(this.cobs);
    // });
    this.interval = setInterval(() => {
      this.LoadProgresses();
    }, 1000);
  }


  ProcessCOB(){
    const year = this.cobDate.year;
    const month = ('0' + (this.cobDate.month + 1)).slice(-2);
    const day = ('0' + this.cobDate.day).slice(-2);

    this.cobDate = `${year}-${month}-${day}`;
    
    
    this.cobService.processCOB(this.cobDate).subscribe(res => {
      // console.log('Success:', res);
    }, err => {
      // console.error('Error:', err);
    })
  }


  LoadProgresses(): any {
    this.cobService.LoadProgresses().subscribe(
      res => {
        this.progresses = res;
        // console.log('Success:', res);
      },
      err => {
        // console.error('Error:', err);
      }
    );
  }










  //----Date Picker Start----//
  // Selects today's date
  selectToday() {
    this.model = {year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()};
  }

  // Custom Day View Starts
  isWeekend(date: NgbDateStruct) {
    const d = new Date(date.year, date.month - 1, date.day);
    return d.getDay() === 0 || d.getDay() === 6;
  }

  isDisabled(date: NgbDateStruct, current: {month: number}) {
    return date.month !== current.month;
  }
  // Custom Day View Ends
  //----Date Picker End----//
}
