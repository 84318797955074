<div class="card">
  <div class="col-sm-12 mt-3" style="min-height:500px">
    <h3 style="text-align: center;"><label class="HeadingColor">Role Wise Page Assign</label></h3>
    <hr>
    <div class="row">

      <div class="col-sm-3">

      </div>
      <div class="col-md-5" style="padding-left:0px;">
        <div class="form-group row">
          <label class="col-md-4">User Role</label>
          <div class="col-md-8">
            <select class="form-control" [(ngModel)]="userTypeId" (change)="getUserMenuAccess()"
              name="applicationRoleId" style="border-radius: 25px;" required>
              <option value="">Select Role </option>
              <option *ngFor="let item of userRoles" [value]="item.id">{{ item.name }}</option>
            </select>

          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <div class="col-md-4" >
            <button class="btn btn-success  pull-left" (click)="saveUserMenuAccess()">Save</button>
          </div>
        </div>
      </div>
    </div>

    <form id="form2" data-parsley-validate class="form-horizontal form-label-left">

            <div class="nav-container" style="margin-left: 22%;">
              <ul class="navigation">

                <!-- First level menu -->
                <li  *ngFor="let menuItem of userMenu" [parent]="menuItem.title" [path]="menuItem.path"
                  level="{{level + 1}}">
                  <input type="checkbox" (change)="onCheckboxChange($event,menuItem.id)" [checked]="menuItem.isActive"
                    name="AllMenuIds" style="width: 17px; height: 17px;margin-right: 2%;">
                  <span class="menu-title" style="font-size: 18px;"><b>{{menuItem.title}}</b></span>


                  <!-- Second level menu -->
                  <ul  class="menu-content" *ngIf="menuItem.submenu.length > 0">
                    <li *ngFor="let menuSubItem of menuItem.submenu" [parent]="menuItem.title"
                      level="{{level + 2}}">
                      <input type="checkbox" (change)="onCheckboxChange($event,menuSubItem.id)"
                        [checked]="menuSubItem.isActive" name="AllMenuIds"
                        style="width: 17px; height: 17px;margin-right: 2%;">
                      <span class="menu-title" style="font-weight: 600;">{{menuSubItem.title}}</span>



                      <!-- Third level menu -->
                      <ul  class="menu-content" *ngIf="menuSubItem.submenu.length > 0">
                        <li  *ngFor="let menuSubsubItem of menuSubItem.submenu"
                          [parent]="menuSubItem.title" [path]="menuSubsubItem.path" level="{{level + 3}}">
                          <input type="checkbox" (change)="onCheckboxChange($event,menuSubsubItem.id)"
                            [checked]="menuSubsubItem.isActive" name="AllMenuIds"
                            style="width: 17px; height: 17px; margin-right: 2%;">
                          <span class="menu-title">{{menuSubsubItem.title}}</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          
      
    </form>
  </div>
</div>