import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
const apiUrl = environment.baseUrl+'/api/ExciseDuty/';
@Injectable({
  providedIn: 'root'
})

export class ExciseDutyService {

  constructor(private http:HttpClient) { }

 saveExciseDuty(model: any){

    return this.http.post(apiUrl +'PostExciseDuty' , model);
 }
  
  getExciseDutyList(): Observable<any> {
    return this.http.get(apiUrl + 'GetExciseDuty');
   } 
   getExciseDutyListByType(id:number): Observable<any> {
    return this.http.get(apiUrl + 'GetExciseDutyByProductBuilderType?id='+id );
   } 
   getExciseDutyDetailsList(): Observable<any> {
    return this.http.get(apiUrl + 'GetExciseDutyDetails');
   } 

   delete(id: any){
    return this.http.delete(apiUrl + 'DeleteExciseDutyById/'+ id );

 }
 gettansactionList(): Observable<any> {
  return this.http.get(apiUrl + 'GetAllTransactiontype' );
 }
 GetDetailsByMasterId(id:any){
  return this.http.get(apiUrl+'GetExciseDutyDetailsByMasterId/'+ id);
    }
 
    getListWithSerchAndPagination(currentPage: any, itemsPerPage: any, searchValue: string) {
      return this.http.get(apiUrl + 'GetExciseDutyListWithSerchAndPagination?page='+ currentPage + '&pageSize=' +itemsPerPage + '&search=' + searchValue);
    }

}