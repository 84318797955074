import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { id } from '@swimlane/ngx-datatable';

const apiUrl=environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class SlabService {

  
  constructor(private http: HttpClient) { }

  save(model: any){
     return this.http.post(apiUrl + '/api/Slab/SaveSlab',model);
    
   }

    getlist():Observable<any>
   {
        return this.http.get(apiUrl + '/api/Slab/GetAllslabs');
   }

    getlistByProBuilderType(id:number):Observable<any>
   {
        return this.http.get(apiUrl + '/api/Slab/GetAllSlabsByProBuilderType?id='+id);
   }
   getListWithSerchAndPagination(currentPage: any, itemsPerPage: any, searchValue: any) {
    return this.http.get(apiUrl + '/api/Slab/GetSlabListWithSerchAndPagination?page='+ currentPage + '&pageSize=' +itemsPerPage + '&search=' + searchValue);
  }
   DeleteSlabById(id:any){
    return this.http.delete(apiUrl + '/api/Slab/DeleteSlabById?Id=' + id)
  }
}
