import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardCustomService } from 'app/dashboard/dashboard-custom.service';

@Component({
  selector: 'app-forward-loan-app',
  templateUrl: './forward-loan-app.component.html',
  styleUrls: ['./forward-loan-app.component.scss']
})
export class ForwardLoanAppComponent implements OnInit {

  dataList:any []=[];
  constructor(private router: Router,
    private _serviceDashboard: DashboardCustomService,
    private changeDetectorRef: ChangeDetectorRef) 
    {  
    }

  ngOnInit(): void {
    this.getForwardApplication();
  }

  getForwardApplication(){
    this._serviceDashboard.getLoanLeadInfoForAdmin().subscribe((res) =>{
      this.dataList =res.filter(item => item.statusInfoId ===2);
      this.changeDetectorRef.detectChanges();
    })
  }

  // ApplicationApproval(leadCode:string,type:number){
  //   this.router.navigate(['/loanapplication-approval/loanapplication-approval/'+ leadCode +'/'+ type]);
  // }
  
}
