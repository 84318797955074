import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LedgerService } from 'app/accounting/service/ledger.service';
import { SharedService } from 'app/services/shared.service';
import { InterestProcessService } from '../interest-process.service';

@Component({
  selector: 'app-quarterly-close',
  templateUrl: './quarterly-close.component.html',
  styleUrls: ['./quarterly-close.component.scss']
})
export class QuarterlyCloseComponent implements OnInit {

  businessDate: any = '';
  interval: any;
  dateInfo: any = [];


  constructor(
    private changeDetectorRef:ChangeDetectorRef,
    private shared: SharedService,
    private datePipe: DatePipe,
    private ledgerService:LedgerService,
    private interestProcess:InterestProcessService,
  ) { }

  ngOnInit(): void {
    this.ledgerService.GetLastAciteBusinessDate().subscribe(res=>{
      this.dateInfo = res;
      this.businessDate = this.datePipe.transform(this.dateInfo.business_Date, 'yyyy-MM-dd');
        this.changeDetectorRef.detectChanges();
     })
  }

 
  // InterestProcess(){
  //   const year = this.businessDate.year;
  //   const month = ('0' + (this.businessDate.month + 1)).slice(-2);
  //   const day = ('0' + this.businessDate.day).slice(-2);

  //   this.businessDate = `${year}-${month}-${day}`;
    
    
    
  // }
  InterestProcess(){
    this.interestProcess.QuarterlyProcessInterest(this.businessDate,this.quarter).subscribe(res => {
      console.log(res)
      if(res!=null)
      {
        this.shared.ShowAlert('Thank you...', 'Process succesfully!', 'success');
      } 
      else{
        this.shared.ShowAlert('warning', 'Something went wrong!', 'Try again.');
      } 
    
     
    })
  }
  quarter: string = 'select';
  onquarterStatusChange(value: string) {
    this.quarter = value;
  }
}
