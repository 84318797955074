import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ScoreCreateComponent } from './score-create/score-create.component';
import { ScoreListComponent } from './score-list/score-list.component';
import { ScoreQuestionComponent } from './score-question/score-question.component';
import { ProvideInfoComponent } from './provide-info/provide-info.component';
import { BankComponent } from './bank/bank.component';
import { ScoreQueAnswerComponent } from './score-que-answer/score-que-answer.component';
import { AuthGuard } from 'app/shared/auth/auth-guard.service';
import { BankBeneficiarCreateComponent } from './bank-beneficiar-create/bank-beneficiar-create.component';
import { BankBeneficiarListComponent } from './bank-beneficiar-list/bank-beneficiar-list.component';
const routes: Routes = [
  {
    path: '', children: [
      { path: "score-create", component: ScoreCreateComponent, data: { title: "score-create" },canActivate:[AuthGuard] },
      { path: 'score-create/:id', component: ScoreCreateComponent,data: {title: 'score-create'},canActivate:[AuthGuard]},
      { path: "score-list", component: ScoreListComponent, data: { title: "score-list" },canActivate:[AuthGuard] },
      { path: 'question-create', component: ScoreQuestionComponent, data: { title: 'Question Add' } ,canActivate:[AuthGuard]},
      { path: 'answer-create', component: ScoreQueAnswerComponent, data: { title: 'Answer Add' },canActivate:[AuthGuard] },
      {path:'provide-info',component:ProvideInfoComponent,data:{title:'provide-info'},canActivate:[AuthGuard]},
      {path: "bank-list",component:BankComponent,data:{title:"bank-list"},canActivate:[AuthGuard]},
      {path: "bank-beneficiar-create",component:BankBeneficiarCreateComponent,data:{title:"bank-beneficiar-create"},canActivate:[AuthGuard]},
      { path: 'bank-beneficiar-create/:id', component: BankBeneficiarCreateComponent,data: {title: 'score-create'},canActivate:[AuthGuard]},
      {path: "bank-beneficiar-list",component:BankBeneficiarListComponent,data:{title:"bank-beneficiar-list"},canActivate:[AuthGuard]},

    ]
  }
 

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ScoreRoutingModule { }
