<div class="modal-dialog modal-xl">
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">Batch Details</h4>
            <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group row">
                <label for="batchMasterId" class="col-md-2">API</label>
                <div class="col-md-4">
                    <select class="form-control" id="batchMasterId" [(ngModel)]="batchData.batchMasterId" (ngModelChange)="ChangeJobInfo($event)">
                        <option value="{{item.id}}" *ngFor="let item of cobJobInfo">{{item.jobName}}</option>
                    </select>
                </div>
            </div>


            <div class="form-group row">
                <label for="name" class="col-md-2">Job Name</label>
                <input type="hidden" [(ngModel)]="batchData.id">
                <input type="hidden" id="batchMasterId" [(ngModel)]="batchData.batchMasterId">
                <div class="col-md-4">
                    <input type="text" class="form-control" id="name" readonly [(ngModel)]="batchData.name" />
                </div>

                <label for="apiName" class="col-md-2">API</label>
                <div class="col-md-4">
                    <input type="text" class="form-control" id="apiName" readonly [(ngModel)]="batchData.apiName" />
                </div>
            </div>

            <div class="form-group row">
                <label for="cobJobProcessType" class="col-md-2">Process Type</label>
                <div class="col-md-4">
                    <select class="form-control" [(ngModel)]="batchData.cobJobProcessType">
                        <option value="Daily">Daily</option>
                        <option value="Weekly">Weekly</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Yearly">Yearly</option>
                    </select>
                </div>

                <label for="processTime" class="col-md-2">Process Time</label>
                <div class="col-md-4">
                    <input type="text" class="form-control" value="00:00:00" placeholder="HH:MM:SS" id="processTime" [(ngModel)]="batchData.processTime" />
                </div>
            </div>

            <div class="form-group row">
                <label for="isAutoProcess" class="col-md-2">Is Auto Process</label>
                <div class="col-md-4">
                    <select class="form-control" [(ngModel)]="batchData.isAutoProcess">
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                    </select>
                </div>

                <label for="readyState" class="col-md-2">Ready State</label>
                <div class="col-md-4">
                    <select class="form-control" [(ngModel)]="batchData.readyState">
                        <option value="true">Active</option>
                        <option value="false">Inactive</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-success btn-sm" (click)="InsertBatchDetail()">Add Job</button>
            <button type="button" class="btn btn-secondary">Close</button>
        </div>
    </div>
</div>
