import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
const apiUrl = environment.eKycUrl+'/api/EKYC';

@Injectable({
  providedIn: 'root'
})
export class NidVerificationService {

  constructor(private http: HttpClient) { }

  getAllNidInformation(): Observable<any> {
    return this.http.get(apiUrl + "/GetEKYCNIDInformation");
  }

  getNidInformationByNid(nid:string): Observable<any> {
    return this.http.get(apiUrl + "/GetEKYCInformationByNid/"+nid);
  }

}
