import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

const apiurl=environment.baseUrl;
@Injectable({
  providedIn: 'root'
})
export class EmployeeserviceService {

  constructor(private http:HttpClient) { }
  save(model: any)
  {
    return this.http.post(apiurl + '/api/Auth/Register',model);
  }

  getUserlist():Observable<any>
  {
       return this.http.get(apiurl + '/api/Auth/GetAllUserList');
  }

  getActiveUserWithSearchAndPagination(currentPage : any, itemsPerPage : any, searchValue : string){
    return this.http.get(apiurl +'/api/Auth/GetAllActiveUserWithSearchAndPagination?page=' + currentPage + '&pageSize=' + itemsPerPage + '&search=' + searchValue);
  }
  ResetPassword(model: any){
    return this.http.post(apiurl +'/api/CustomerAccount/UserResetPassword' , model);
  }
  ChangePassword(model: any){
    return this.http.post(apiurl +'/api/CustomerAccount/UserChangePassword' , model);
  }
 
}
