<div class="card" style="width: 100%;">
    <div class="card-body">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h5 class="card-title" style="color: black">Pending Product List</h5>
                </div>
                <div class="col" style="text-align: right;">
                    <!-- <button type="button" data-toggle="tooltip" [routerLink]="['/product/loan-product-entry']" class="btn btn-info"
                        style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Go to Entry</button> -->
                </div>
            </div>
            <hr>
            <table id="example" class="table table-striped table-bordered" style="width:100%">
                <thead>
                    <tr>
                        <th>SL No.</th>
                        <th>Product Builder Code</th>
                        <th>Product Builder Name</th>
                        <th>Version</th>
                        <th>Description</th>
                        <th>Routes</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of loanProductList; let i = index;">
                        <td>{{i + 1}}</td>
                        <td>{{item?.code}}</td>
                        <td>{{item?.name}}</td>
                        <td>{{item?.version}}</td>
                        <td>{{item?.description}}</td>
                        <td style="padding: 10px;" style="width: 25%;">
                            <a *ngFor="let approver of item.routes; let j = index;">{{j + 1}}. {{approver?.role?.name}} ({{approver?.updatedBy}})<br></a>
                        </td>
                        <!-- <td>{{item.status === 1 ? 'Active' : "in-Active"}}</td> -->
                        <td>
                            <button class="btn btn-info mb-1" style="margin-left: 5px;"
                            (click)="openBuilderDetailsModal(productBuilderDetails)" (click)="getLoanProductDetails(item.id)"><i
                                class="fa fa-list"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<ng-template #productBuilderDetails let-c="close" let-d="dismiss">
    <div class="modal-dialog">
        <!-- Modal content goes here -->
        <div class="modal-content" style=" width: 112%;">
            <div class="modal-header">
                <h4 class="modal-title">Loan Product Builder Details</h4>
                <button type="button" class="close" style="size: 150%;" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <table class="table-bordered">
                <th class="col-3">Code: </th>
                <td class="col-3">{{ productDetails?.productInfo?.code }}</td>
                <th class="col-3">Name: </th>
                <td class="col-3">{{ productDetails?.productInfo?.name }}</td>
            </table>
            <table class="table-bordered">
                <th class="col-3">Version: </th>
                <td class="col-3">{{ productDetails?.productInfo?.version }}</td>
                <th class="col-3">Description: </th>
                <td class="col-3">{{ productDetails?.productInfo?.description }}</td>
            </table>
            <table class="table-bordered">
                <th class="col-3">Loan Type: </th>
                <td class="col-3">{{ productDetails?.productInfo?.loanType?.typeName }}</td>
                <th class="col-3">Payment Type: </th>
                <td class="col-3">{{ productDetails?.productInfo?.paymentType?.typeName }}</td>
            </table>
            <table class="table-bordered">
                <th class="col-3">Overdue Cal Days: </th>
                <td class="col-3">{{ productDetails?.productInfo?.overDueCalculationAfterDays }}</td>
                <th class="col-3">Grace Period Days: </th>
                <td class="col-3">{{ productDetails?.productInfo?.gracePeriodDays }}</td>
            </table>
            <table class="table-bordered">
                <th class="col-3">Currency: </th>
                <td class="col-3">{{ productDetails?.productInfo?.currency?.currency }}</td>
                <th class="col-3">Day Size: </th>
                <td class="col-3">{{ productDetails?.productInfo?.daySize?.name }}</td>
            </table>
            <table class="table-bordered">
                <!-- <th class="col-3">Tax Key: </th>
                <td class="col-3">{{ productDetails?.productInfo.taxKey?.taxKeyName }}</td> -->
                <th class="col-3">Balance Type:</th>
                <td class="col-3">{{ productDetails?.productInfo?.balanceType?.name }}</td>
                <th class="col-3">Excise Duty: </th>
                <td class="col-3">{{ productDetails?.productInfo?.exciseDuty?.exciseDutyName }}</td>
            </table>
            <table class="table-bordered">
                <th class="col-3">Interest Calculation Type: Slab</th>
                <td class="col-3">
                    <ul>
                        <li *ngFor="let item of productDetails?.productInstCalTypes">
                            {{item?.slab?.min}}-{{item?.slab?.max}} ({{item?.slab?.amountParcent}})%
                        </li>
                    </ul>
                </td>


                <th class="col-3">Interest Calculation Frequency: </th>
                <td class="col-3">{{productDetails?.productInfo?.interestCalculationFrequency?.name }} -
                    {{productDetails?.productInfo?.interestCalculationFrequency?.days }} {{productDetails?.productInfo?.interestCalculationFrequency?.days !=null? "days" : "" }}</td>
            </table>
            <table class="table-bordered">
                <th class="col-3">Capitalize Rule:</th>
                <td class="col-3">
                    <ul>
                        <li *ngFor="let item of productDetails?.productRules"> {{item.rule?.code}}
                        </li>
                    </ul>
                </td>
                <th class="col-3">Capitalization Frequency:</th>
                <td class="col-3">
                    {{productDetails?.productInfo?.capitalizationFrequency?.name }} - {{
                    productDetails?.productInfo?.capitalizationFrequency?.days }} {{
                    productDetails?.productInfo?.capitalizationFrequency?.days !=null? "days" :
                    ""}}
                </td>
            </table>
            <table class="table-bordered">
                <th class="col-3">Charge:</th>
                <td class="col-3">
                    <ul>
                        <li *ngFor="let item of productDetails?.charges">
                            {{item?.code}}-{{item?.name}}
                        </li>
                    </ul>
                </td>
                <th class="col-3">Commission:</th>
                <td class="col-3">
                    <ul>
                        <li *ngFor="let item of productDetails?.productCommission">
                            {{item?.commission?.code}}-{{item?.commission?.name}}
                        </li>
                    </ul>
                </td>
            </table>
            <table class="table-bordered">
                <th class="col-3">Loan Tenure: </th>
                <td class="col-3">
                    <ul>
                        <li *ngFor="let item of productDetails?.productTenures">
                            {{item?.duration?.monthName}}-{{item?.duration?.details}}
                        </li>
                    </ul>
                </td>
                <th class="col-3">Status:</th>
                <td class="col-3">
                    {{ productDetails?.productInfo?.status ===0 ? "in-Active" :
                    "Active"}}
                </td>
            </table>


            <div class="modal-footer">
                <button type="button" class="btn btn-succcess btn-sm" style="background-color: green;color: white;" (click)="Approve(clickedProductId)">Approve</button>
                <button type="button" class="btn btn-info btn-sm" (click)="c('Close click')">Close</button>
            </div>
        </div>
    </div>
</ng-template>