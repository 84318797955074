import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NidListComponent } from './nid-list/nid-list.component';

const routes: Routes = [
  {
    path: "nid-verification-list",
    component: NidListComponent,
    data: {
      title: "nid-verification-list",
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NidverificationRoutingModule { }
