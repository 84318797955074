import { Component, OnInit } from '@angular/core';
import { FormGroup,FormBuilder } from '@angular/forms';
import { ApiServiceService } from '../api-service.service';

@Component({
  selector: 'app-api-credential',
  templateUrl: './api-credential.component.html',
  styleUrls: ['./api-credential.component.scss']
})
export class ApiCredentialComponent implements OnInit {

  frmGroup: FormGroup;
  name:string;
  pass:string;
  uName:string;
  valid:boolean=true;
  invalid:boolean=false;
  constructor(
    private formBuilder: FormBuilder,
    private _apiService: ApiServiceService,) { }

  ngOnInit(): void {
    this.populateFrom();
    this.generatePassword();
  }
  generatePassword(length: number = 12) {
    const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const numericChars = '0123456789';
    const specialChars = '!@#$%^&*()-_=+';

    const allChars = uppercaseChars + lowercaseChars + numericChars + specialChars;

    let password = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * allChars.length);
      password += allChars[randomIndex];
    }
    this.pass=password;
  }
  populateFrom(){
    this.frmGroup = this.formBuilder.group({
      //password:[""],
      name:[""],
      username:[""],
    })
  }
  getUserName(id){
    this.name=this.frmGroup.value.name.replace(" ","");
    if(id==2){
      this.name=this.frmGroup.value.username.replace(" ","");
    }
    this._apiService.checkUserName(this.name).subscribe(res => {
      if(res==true){
        this.valid=true;
        this.invalid=false;
        if(id==2){
          this.uName=this.frmGroup.value.username.replace(" ","");
        }
        else{
          this.uName=this.frmGroup.value.name.replace(" ","");
        }
      }
      else{
        this.valid=false;
        this.invalid=true;
      }
    })
    //this.uName=username;
  }
}
