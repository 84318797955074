<div class="card" style="width: 100%;">
    <div class="card-body">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h5 class="card-title" style="color: black">Dormant Account List</h5>
                </div>
               
            </div>
            <table id="example" class="table table-striped table-bordered mt-3" style="width:100%">
                <thead style="background-color: #f8f9fb;">
                    <tr>
                        <th>Action</th>
                        <th>Account No</th>
                        <th>Account Name</th>
                        <th>Date</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container ></ng-container>
                    <tr *ngFor="let item of DormantAccountList">
                        <td>
                            
                           
                            <button class="btn btn-primary mt-1" *ngIf="item.status == 0"
                                style="font-size: 14px; width: 120px !important; height: 35px;"
                                (click)="Approve(item.id)"
                               >Approve</button>
                        </td>
                        <td>{{item.customerAccount?.customerAccountNo}}</td>
                        <td>{{item.customerAccount?.accountTilte}}</td>
                        
                        <td>{{item.effectiveDate | date: 'yyyy-MM-dd'}}</td>
                        <td>{{item.status == 0 ? 'Pending' : 'Approved'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>