
<section id="navs">
    <div>
        <!-- <div class="col-md-12"> -->
            <h3 class="card-title" style="color: black;"> FDR Account Create</h3>
        <!-- </div> -->
    </div>
    <hr style="height: 10%;">
    <div class="card">
        <div class="card-body">
            <form [formGroup]="frmGroup">
                <ul ngbNav #basicnav="ngbNav" [(activeId)]="active" (navChange)="onNavChange($event,0)"
                    class="nav-tabs mr-3">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink>Account Info</a>
                        <ng-template ngbNavContent>
                            <div asp-validation-summary="All" class="text-danger"></div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 ">Customer Number <span class="redColor">
                                            *</span></label>
                                    <div class="col-sm-8">
                                        <div class="input-group">
                                            <input type="text" class="form-control" name="CustomersInfoNumber"
                                                formControlName="CustomersInfoNumber" [(ngModel)]="accountNo"
                                                placeholder="Type Username...."
                                                (keyup.enter)="GetAllCustomersByMobileNo()">
                                            <div *ngIf="isAdmin" class="input-group-append">
                                                <button class="btn btn-primary btn-sm"
                                                    (click)="GetAllCustomersByMobileNo()">Find</button>
                                            </div>
                                        </div>
                                        <input type="hidden" class="form-control" name="CustomersInfoId"
                                            formControlName="CustomersInfoId">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 ">Customer Info <span class="redColor">
                                            *</span></label>
                                    <div class="col-sm-8">
                                        <div class="input-group">
                                            <input type="text" class="form-control" formControlName="customersInfo"
                                                readonly placeholder="Customer Info ...." />
                                        </div>
                                        <input type="hidden" class="form-control" name="CustomersInfoId"
                                            formControlName="CustomersInfoId">
                                    </div>
                                </div>
                               
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Account Title <span class="redColor">
                                            *</span></label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" formControlName="AccountTilte"
                                            placeholder="Account Title ....">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Account Title (Bangla) <span
                                            class="redColor"> *</span></label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" formControlName="AccountTilteBn"
                                            placeholder="Account Title Bn....">
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="name" class="col-sm-4 col-form-label">Product <span class="redColor">*</span></label>
                                    <div class="col-sm-8">
                                        <select class="form-control" [(ngModel)]="selectedProductId" 
                                            formControlName="ProductBuilderId" readonly>
                                            <option *ngFor="let item of productlist" [value]="item.id">{{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">GL Code</label>
                                    <div class="col-sm-8">
                                        <input type="text" readonly class="form-control"
                                            formControlName="ledgerRelationName" placeholder="GL">
                                        <input type="hidden" readonly class="form-control" name="ledgerRelationId"
                                            formControlName="ledgerRelationId">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 d-none">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Account No</label>
                                    <div class="col-sm-8">
                                        <input type="text" readonly class="form-control"
                                            formControlName="customerAccountNo" placeholder="Account No">

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Currency</label>
                                    <div class="col-sm-8">
                                        <input type="text" readonly class="form-control"
                                            formControlName="accountCurrency" placeholder="Currency ....">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Interest Rate<span class="redColor"> *</span></label>
                                    <div class="col-sm-8">
                                        <input type="number" class="form-control" formControlName="InterestRate" readonly>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Open Date<span class="redColor"> *</span></label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" formControlName="OpenDate" value="{{OpenDate  | date:'yyyy-MM-dd'}}"  readonly>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Maturity Date<span class="redColor"> *</span></label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" formControlName="MaturityDate" value="{{MaturityDate | date:'yyyy-MM-dd'}}" readonly >
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Deposit Amount<span class="redColor"> *</span></label>
                                    <div class="col-sm-8">
                                        <input type="number" class="form-control" formControlName="OpeningBalance" (keyup)="calculateInterestAmount()" placeholder="deposit amount">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Interest Amount<span class="redColor"> *</span></label>
                                    <div class="col-sm-8">
                                        <input type="number" class="form-control" formControlName="InterestAmount" readonly>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Security<span class="redColor"> *</span></label>
                                    <div class="col-sm-8">
                                        <select class="form-control" formControlName="Security">
                                            <option value="0">No</option>
                                            <option value="1">Yes</option>
                                        </select>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Is Renew ?<span class="redColor"> *</span></label>
                                    <div class="col-sm-8">
                                        <select class="form-control" formControlName="checkRenew">
                                            <option value="0">No</option>
                                            <option value="1">Yes</option>
                                        </select>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Purpose <span class="redColor">
                                            *</span></label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" formControlName="accountPurpose"
                                            placeholder="Purpose ....">
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-12">
                                <div class="form-group row pull-right">
                                    <!-- <button class="btn btn-sm btn-info" (click)="onNavChange($event,1)">Next</button> -->
                                    <button class="btn btn-sm btn-info" (click)="onNavChange($event,3)">Next</button>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2"class="d-none">
                        <a ngbNavLink>Product Info</a>
                        <ng-template ngbNavContent>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="name" class="col-sm-4 col-form-label">Product <span class="redColor">*</span></label>
                                    <div class="col-sm-8">
                                        <select class="form-control" [(ngModel)]="selectedProductId" 
                                            formControlName="ProductBuilderId" readonly>
                                            <option *ngFor="let item of productlist" [value]="item.id">{{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">GL Code</label>
                                    <div class="col-sm-8">
                                        <input type="text" readonly class="form-control"
                                            formControlName="ledgerRelationName" placeholder="GL">
                                        <input type="hidden" readonly class="form-control" name="ledgerRelationId"
                                            formControlName="ledgerRelationId">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 d-none">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Account No</label>
                                    <div class="col-sm-8">
                                        <input type="text" readonly class="form-control"
                                            formControlName="customerAccountNo" placeholder="Account No">

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Currency</label>
                                    <div class="col-sm-8">
                                        <input type="text" readonly class="form-control"
                                            formControlName="accountCurrency" placeholder="Currency ....">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Interest Rate<span class="redColor"> *</span></label>
                                    <div class="col-sm-8">
                                        <input type="number" class="form-control" formControlName="InterestRate" readonly>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Open Date<span class="redColor"> *</span></label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" formControlName="OpenDate" value="{{OpenDate  | date:'yyyy-MM-dd'}}"  readonly>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Maturity Date<span class="redColor"> *</span></label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" formControlName="MaturityDate" value="{{MaturityDate | date:'yyyy-MM-dd'}}" readonly >
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Deposit Amount<span class="redColor"> *</span></label>
                                    <div class="col-sm-8">
                                        <input type="number" class="form-control" formControlName="OpeningBalance" (keyup)="calculateInterestAmount()" placeholder="deposit amount">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Interest Amount<span class="redColor"> *</span></label>
                                    <div class="col-sm-8">
                                        <input type="number" class="form-control" formControlName="InterestAmount" readonly>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Security<span class="redColor"> *</span></label>
                                    <div class="col-sm-8">
                                        <select class="form-control" formControlName="Security">
                                            <option value="0">No</option>
                                            <option value="1">Yes</option>
                                        </select>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Is Renew ?<span class="redColor"> *</span></label>
                                    <div class="col-sm-8">
                                        <select class="form-control" formControlName="checkRenew">
                                            <option value="0">No</option>
                                            <option value="1">Yes</option>
                                        </select>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Purpose <span class="redColor">
                                            *</span></label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" formControlName="accountPurpose"
                                            placeholder="Purpose ....">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row pull-right">
                                    <button class="btn btn-sm btn-info" (click)="onNavChange($event,2)">Next</button>
                                    
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="3"class="d-none">
                        <a ngbNavLink>Minor Info</a>
                        <ng-template ngbNavContent>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">If Account create for a Minor</label>
                                    <div class="col-sm-8">
                                        <input type="checkbox" class="col-1 form-control" formControlName="accountMinor"
                                            (change)="checkMinorOrNot($event)" style="cursor: pointer;">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 row pr-0 mr-0" *ngIf="isMinor">
                                <div class="col-md-2"></div>
                                <div class="col-md-10 pr-0">
                                    <div class="form-group row">
                                        <label for="" class="col-sm-4 col-form-label">Minor Name <span *ngIf="isMinor"
                                                class="redColor"> *</span></label>
                                        <div class="col-sm-8" *ngIf="isMinor">
                                            <input type="text" class="form-control" formControlName="accountMinorName">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="" class="col-sm-4 col-form-label">Minor DOB <span *ngIf="isMinor"
                                                class="redColor"> *</span></label>
                                        <div class="col-sm-8" *ngIf="isMinor">
                                            <input type="text" class="form-control" formControlName="accountMinorDOB">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="" class="col-sm-4 col-form-label">Minor Guardian <span
                                                *ngIf="isMinor" class="redColor"> *</span></label>
                                        <div class="col-sm-8" *ngIf="isMinor">
                                            <input type="text" class="form-control"
                                                formControlName="accountMinorGuardian" readonly>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="" class="col-sm-4 col-form-label">Minor Guardian Relation <span
                                                *ngIf="isMinor" class="redColor"> *</span></label>
                                        <div class="col-sm-8" *ngIf="isMinor">
                                            <input type="text" class="form-control"
                                                formControlName="accountMinorGuardianRelation">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Verify Status</label>
                                    <div class="col-sm-8">
                                        <input type="checkbox" class="col-1 btn btn-sm form-control" readonly
                                            checked><span> e-KYC
                                            Verified</span>
                                    </div>

                                    
                                </div>
                            </div>
                           
                            <div class="col-md-12">
                                <div class="form-group row pull-right">
                                    <button class="btn btn-sm btn-info" (click)="onNavChange($event,3)">Next</button>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="4">
                        <a ngbNavLink>Nominee Info</a>
                        <ng-template ngbNavContent>              
                          <div class="col-md-12">
                            <div class="form-group row">
                              
                              <form [formGroup]="nomineeGroup">
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="form-group row">
                                      <label for="" class="col-sm-4 col-form-label">Nominee's Name <span style="color: red;"> *</span></label>
                                      <div class="col-sm-8">
                                        <input type="text" class="form-control" formControlName="nomineeName" placeholder="Nominee Name....">
                                        <input type="hidden" formControlName="nomineeNameSl" value="0">
                                      </div>
                                    </div>
                                    <div class="form-group row">
                                      <label for="" class="col-sm-4 col-form-label">Nominee's DOB <span style="color: red;"> *</span></label>
                                      <div class="col-sm-8">
                                        <input type="date" class="form-control" formControlName="nomineeDOB" placeholder="Nominee Date Of Birth....">
                                      </div>
                                    </div>
                                    <div class="form-group row">
                                      <label for="" class="col-sm-4 col-form-label">Nominee's Present Address <span style="color: red;"> *</span></label>
                                      <div class="col-sm-8">
                                        <input type="text" class="form-control" formControlName="nomineePresentAddress" placeholder="Nominee Present Address....">
                                      </div>
                                    </div>
            
                                    <div class="form-group row">
                                      <label for="" class="col-sm-4 col-form-label">NID Picture <span style="color: red;"> *</span></label>
                                      <div class="col-sm-8">
                                        <input type="file" accept="image/*" id="image" (change)="selectNidBackPic($event)"formControlName="photo"
                                                    style="display: none;" #fileInputBack />
            
                                                <div style="height: 125px; width: 150px; cursor: pointer;"
                                                    (click)="fileInputBack.click()">
                                                    <img src="{{ backPreview ? backPreview : 'assets/img/gallery/upload_image.png' }}"
                                                        id="image" style="width: 100%; height: 100%;" class="proPreview"
                                                        title="Click here to upload photo" />
                                                </div>                   
                                              </div>
                                    </div>
                                    
                                  </div>
                                  <div class="col-md-6">
                                    <div class="form-group row">
                                      <label for="" class="col-sm-4 col-form-label">Relation<span style="color: red;"> *</span></label>
                                      <div class="col-sm-8">
                                        <select class="form-control" formControlName="relationId" (change)="getRelation($event.target.value)">
                                    
            
                                         
                                            <option value="">Select</option>
                                            <option *ngFor="let item of relationList" [value]="item.id">{{item.name}}</option>
                                           </select>
                                      </div>
                                    </div>
                                    <div class="form-group row">
                                      <label for="" class="col-sm-4 col-form-label">Nominee's Share <span style="color: red;"> *</span></label>
                                      <div class="col-sm-8">
                                        <input type="number" class="form-control" min="1" max="100" formControlName="nomineeShare" placeholder="Nominee's Share....">
                                      </div>
                                    </div>
                                    <div class="form-group row">
                                      <label for="" class="col-sm-4 col-form-label">Nominee's Identifier <span style="color: red;"> *</span></label>
                                      <div class="col-sm-8">
                                        <div class="form-group row">
                                          <label for="" class="col-sm-4 col-form-label">Nominee's NID</label>
                                          <div class="col-sm-8">
                                            <input type="text" class="form-control" (input)="onNumberInput($event)" formControlName="nomineeNID" placeholder="Nominee NID....">
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label for="" class="col-sm-4 col-form-label">Nominee's Passport</label>
                                          <div class="col-sm-8">
                                            <input type="text" class="form-control" formControlName="nomineePassport" placeholder="Nominee Passport.....">
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label for="" class="col-sm-4 col-form-label">Nominee's Birth Identity No</label>
                                          <div class="col-sm-8">
                                            <input type="text" class="form-control" formControlName="nomineeBirthIdentity" placeholder="Nominee Birth Identity No.....">
                                          </div>
                                        </div>
                                       
                                      </div>
                                    </div>
                                  </div>
                                 
                                </div>
                                <button type="button" class="btn btn-success" (click)="addNominee()">Add</button>
          
                              
                              </form>
                              
                            </div>
                            <div class="form-group row">
                              <label for="" class="col-sm-2 col-form-label"></label>
                              <div class="col-sm-10">
                                <table class="table table-bordered">
                                  <tbody>
                                    <tr *ngFor="let item of nolineeList ;let i = index">
                                      <td style="width: 10%;">{{i+1}}</td>
                                      <td>
                                        <ul>
                                          <li><span style="font-weight: bold;">Nominee Name </span>: {{item.nomineeName}}</li>
                                    
                                          <li><span style="font-weight: bold;">Nominee DOB</span> : {{item.nomineeDOB}}</li>
                                          <li><span style="font-weight: bold;">Present Address </span> : {{item.nomineePresentAddress}}</li>
                                     
                                          <li><span style="font-weight: bold;">Occupation</span>  : {{item.nomineeOccupation}}</li>
                                          <li><span style="font-weight: bold;">NID</span>  : {{item.nomineeNID}}</li>
                                          <li><span style="font-weight: bold;">Passport</span>  : {{item.nomineePassport}}</li>
                                          <li><span style="font-weight: bold;">Birth Identity No </span> : {{item.nomineeBirthIdentity}}</li>
                                  
                                          <li><span style="font-weight: bold;">Share</span>  : {{item.nomineeShare}}</li>
                                          <li>
                                            <span style="font-weight: bold;">Relation</span>  : {{ nomineeGroup.value.relationName }}
                                            <span style="display: none;" >  {{item.relationId}} </span>
                                      
                                          
                                          </li>
                                          <li>
                                            <span style="font-weight: bold;">NID Picture</span>:
                                          
                                            <img class="proPreview"  src="{{backPreview}}" alt="proPreview" height="35" width="35" />
                                          </li>
                                        </ul>
                                      </td>
                                      <td>
                                        <a class="btn btn-sm btn-info mr-2" (click)="editNominee(item)"><i class="fa fa-edit"></i></a>
                                        <a class="btn btn-sm btn-danger" (click)="deleteNominee(item)"><i class="fa fa-trash"></i></a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          
                          <div class="col-md-12">
                            <!-- <div class="form-group row pull-right">
                              <button class="btn btn-sm btn-info" (click)="onNavChange($event,4)">Next</button>
                            </div> -->
                          </div>
                          <button [disabled]="!frmGroup.valid" data-toggle="tooltip" title="Save" type="submit" (click)="onsubmit()" value="Submit" class="btn btn-success" style="float:right; margin-top:5px;">
                              <span>{{ isEdit ? 'Update' : 'Save' }}</span>
                            </button>
                            <button type="button" data-toggle="tooltip" title="Refresh" (click)="refresh()" class="btn btn-secondary" style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>
                        </ng-template>
                      </li>
                    <li [ngbNavItem]="5"class="d-none">
                        <a ngbNavLink>Declearation</a>
                        <ng-template ngbNavContent>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Deposit Declearation</label>
                                    <div class="col-sm-8">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <td class="pl-1 p-0 m-0">Method</td>
                                                    <td class="pl-1 p-0 m-0">Monthly potential total deposit number</td>
                                                    <td class="pl-1 p-0 m-0">Monthly potential total deposit amount</td>
                                                    <td class="pl-1 p-0 m-0">Single maximum deposit amount</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of declearationList ;let i = index">
                                                    <td class="pl-1 p-0 m-0">
                                                        {{item.methodName}}
                                                        <input type="hidden" class="form-control" value="{{item.id}}">
                                                    </td>
                                                    <td class="p-1 m-0">
                                                        <input type="number" class="form-control"
                                                            (keyup)="updateDeclearationList(item.id,1,$event)"
                                                            placeholder="Number ....">
                                                    </td>
                                                    <td class="p-1 m-0">
                                                        <input type="number" class="form-control"
                                                            (keyup)="updateDeclearationList(item.id,2,$event)"
                                                            placeholder="Amount ....">
                                                    </td>
                                                    <td class="p-1 m-0">
                                                        <input type="number" class="form-control"
                                                            (keyup)="updateDeclearationList(item.id,3,$event)"
                                                            placeholder="Maximum ....">
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Withdrow Declearation</label>
                                    <div class="col-sm-8">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <td class="pl-1 p-0 m-0 ">Method</td>
                                                    <td class="pl-1 p-0 m-0 ">Monthly potential total deposit number
                                                    </td>
                                                    <td class="pl-1 p-0 m-0 ">Monthly potential total deposit amount
                                                    </td>
                                                    <td class="pl-1 p-0 m-0 ">Single maximum deposit amount</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of declearationList ;let i = index">
                                                    <td class="pl-1 p-0 m-0">
                                                        {{item.methodName}}
                                                        <input type="hidden" class="form-control" value="{{item.id}}">
                                                    </td>
                                                    <td class="p-1 m-0">
                                                        <input type="number"
                                                            (keyup)="updateDeclearationList1(item.id,1,$event)"
                                                            class="form-control" placeholder="Number ....">
                                                    </td>
                                                    <td class="p-1 m-0">
                                                        <input type="number"
                                                            (keyup)="updateDeclearationList1(item.id,2,$event)"
                                                            class="form-control" placeholder="Amount ....">
                                                    </td>
                                                    <td class="p-1 m-0">
                                                        <input type="number"
                                                            (keyup)="updateDeclearationList1(item.id,3,$event)"
                                                            class="form-control" placeholder="Maximum ....">
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label" style=" font-weight: bold;">What kind
                                        of profession are you engaged in? <span style="color:red">*</span></label>
                                    <div class="col-sm-8">
                                        <select class="form-control" formControlName="riskGrading1">
                                            <option value="">Select</option>
                                            <option *ngFor="let item of riskGradingList" value="{{item.id}}">
                                                {{item.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <button [disabled]="!frmGroup.valid" data-toggle="tooltip" title="Save" type="submit"
                                (click)="onsubmit()" value="Submit" class="btn btn-success"
                                style="float:right; margin-top:5px;">
                                <span>{{ isEdit ? 'Update' : 'Save' }}</span>
                            </button>
                            <button type="button" data-toggle="tooltip" title="Refresh" (click)="refresh()"
                                class="btn btn-secondary"
                                style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="basicnav" class="mt-2"></div>
                <pre>Active: {{ kActive }}</pre>
            </form>
        </div>
    </div>
</section>
<!-- Modal -->
<ng-template #contentNominee let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Nominee Info</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="nomineeGroup">
            <div class="col-md-12">
                <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Nominee's Name <span style="color: red;">
                            *</span></label>
                    <div class="col-sm-8">
                        <input type="text" class="form-control" formControlName="nomineeName"
                            placeholder="Nominee Name....">
                        <input type="hidden" formControlName="nomineeNameSl" value="0">
                    </div>
                </div>
                <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Nominee's Father <span style="color: red;">
                            *</span></label>
                    <div class="col-sm-8">
                        <input type="text" class="form-control" formControlName="nomineeFather"
                            placeholder="Nominee Father.....">
                    </div>
                </div>
                <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Nominee's Mother <span style="color: red;">
                            *</span></label>
                    <div class="col-sm-8">
                        <input type="text" class="form-control" formControlName="nomineeMother"
                            placeholder="Nominee Mother.....">
                    </div>
                </div>
                <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Nominee's Spouse</label>
                    <div class="col-sm-8">
                        <input type="text" class="form-control" formControlName="nomineeSpouse"
                            placeholder="Nominee Spouse.....">
                    </div>
                </div>
                <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Nominee's DOB <span style="color: red;">
                            *</span></label>
                    <div class="col-sm-8">
                        <input type="date" class="form-control" formControlName="nomineeDOB"
                            placeholder="Nominee Date Of Birth....">
                    </div>
                </div>
                <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Nominee's Present Address <span style="color: red;">
                            *</span></label>
                    <div class="col-sm-8">
                        <input type="text" class="form-control" formControlName="nomineePresentAddress"
                            placeholder="Nominee Present Address....">
                    </div>
                </div>
                <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Nominee's Permanent Address <span style="color: red;">
                            *</span></label>
                    <div class="col-sm-8">
                        <input type="text" class="form-control" formControlName="nomineePermanentAddress"
                            placeholder="Nominee's Permanent Address....">
                    </div>
                </div>
                <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Nominee's Occupation <span style="color: red;">
                            *</span></label>
                    <div class="col-sm-8">
                        <select class="form-control" formControlName="nomineeOccupation">
                            <option value="">Select</option>
                            <option *ngFor="let item of occupationList" value="{{item.occupationName}}">
                                {{item.occupationName}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Nominee's Share <span style="color: red;">
                            *</span></label>
                    <div class="col-sm-8">
                        <input type="number" class="form-control" min="1" max="100" formControlName="nomineeShare"
                            placeholder="Nominee's Share....">
                    </div>
                </div>
                <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Nominee's Identifier <span style="color: red;">
                            *</span></label>
                    <div class="col-sm-8">
                        <div class="form-group row">
                            <label for="" class="col-sm-4 col-form-label">Nominee's NID</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" (input)="onNumberInput($event)"
                                    formControlName="nomineeNID" placeholder="Nominee NID....">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="" class="col-sm-4 col-form-label">Nominee's Passport</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" formControlName="nomineePassport"
                                    placeholder="Nominee Passport.....">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="" class="col-sm-4 col-form-label">Nominee's Birth Identity No</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" formControlName="nomineeBirthIdentity"
                                    placeholder="Nominee Birth Identity No.....">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="" class="col-sm-4 col-form-label">Nominee's E-TIN</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" formControlName="nomineeETin"
                                    placeholder="Nominee E-TIN....">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="" class="col-sm-4 col-form-label">Nominee's Driving Licence</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" formControlName="nomineeDriveLinc"
                                    placeholder="Nominee Driving Licence....">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="addNominee()" (click)="c('Close click')">Add</button>
        <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
    </div>
</ng-template>
<!-- Modal -->