

<section>
    <div class="card" style="width: 100%;">
      <div class="row card-header  d-flex flex-row align-items-center">
        <div class="col-6">
          <h5 class="card-title" style="color: black">Balance List</h5>
        </div>
        <div class="col-6">
          <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
            <!-- <i class="ft-chevrons-left"> Back</i> -->
            <i class="icon-arrow-left" style="font-size: 17px;"> </i>
          </button>
        </div>
      </div>
      <hr>
    <div class="card-body">
        <div class="container">
         
          
          <table id="example" class="table table-striped table-bordered" style="width:100%">
            <thead>
                <tr>
                  <th>SL No.</th>
                    <th> Account No</th>
                    <th> Balance </th>
                
                    <!-- <th>Action</th> -->
                </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of list; let i = index;">
                <td>{{i + 1}}</td>
                <td>{{item.account?.customerAccountNo}}</td>
                <td>{{item.balance | number}}</td>
              </tr>
             </tbody>
        </table>
        </div>  
      </div>
  </div>   
  </section>