import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
const apiUrl = environment.baseUrl+'/api/LoanProductBuilder/';

@Injectable({
  providedIn: 'root'
})
export class BnplProductService {

  
  constructor(private http: HttpClient) { }


  saveloanCollections(model:any){
    return this.http.post(apiUrl + 'SaveloanCollections', model);
  }

  SaveloanCollectionsbyCustomer(model:any){
    return this.http.post(apiUrl + 'SaveloanCollectionsbyCustomer', model);
  }

  SaveloanSettlementbyCustomer(model:any){
    return this.http.post(apiUrl + 'SaveloanSettlementbyCustomer', model);
  }


  getLoanProductList(): Observable<any> {
    return this.http.get(apiUrl + 'GetAllBNPLProdictList/');
   }

   getLoanProductListByLoanTypeId(loanTypeId: number): Observable<any> {
    return this.http.get(apiUrl + 'GetAllLoanProdictListByLoanTypeId/'+loanTypeId);
   }

   
   getNanoLoanProduct(): Observable<any> {
    return this.http.get(apiUrl + 'GetAllNanoLoanProdictList/');
   }
  
  
   GetAllPendingProductByUserName(): Observable<any> {
    return this.http.get(apiUrl + 'GetAllPendingProductByUserName');
   }
  
   GetAllPendingProductByRole(): Observable<any> {
    return this.http.get(apiUrl + 'GetAllBNPLPendingProductByRole');
   }

   AproveLoanProductAndShiftNext(productId: number): Observable<any> {
    return this.http.get(apiUrl + 'AproveLoanProductAndShiftNext?productId=' + productId);
   }

   AproveLoanProductByUserRole(productId: number): Observable<any> {
    return this.http.get(apiUrl + 'AproveLoanProductByUserRole?productId=' + productId);
   }

   getProductBuilderList():Observable<any>{
    return this.http.get(apiUrl + 'GetAllProductBuilderList');
   }

   getLoanProductDetailsById(id): Observable<any>{
    return this.http.get(apiUrl + 'GetLoanProductDetailsById/'+id);
   }

   saveLoanProduct(model: any){
    return this.http.post(apiUrl +'SaveLoanProductBuilders' , model);
  }

  getLoanProductBuilderDetailsById(id: number): Observable<any> {
    return this.http.get(apiUrl + 'GetLoanProductBuilderDetailsById2/' + id);
  }


  getFeesInfoByProductId(id: number): Observable<any> {
    return this.http.get(apiUrl + 'GetFeesInfoByProductId/' + id);
  }

  getOtherFeesInfoByProductId(id: number): Observable<any> {
    return this.http.get(apiUrl + 'GetOtherFeesInfoByProductId/' + id);
  }


  getLoanProductByCode(Code: string): Observable<any>
  {
    return this.http.get<any>(apiUrl+'GetLoanProductBuilderByCode/' + Code, { responseType: 'json' });
  }
  getLoanProductVersionByCode(version: string,code:string): Observable<any>
  {
    return this.http.get<any>(apiUrl+'GetLoanProductBuilderByVersionCode?version=' +version+'&code='+ code, { responseType: 'json' });
  }

  getAllReducingMethod(): Observable<any> {
    return this.http.get(apiUrl + 'GetAllReducingMethodList');
   }

  getAllLoanDuration(): Observable<any> {
    return this.http.get(apiUrl + 'GetAllLoanDurationList');
   }

   getLoanProductAmontFromLimit(id: number): Observable<any> {
    return this.http.get(apiUrl + 'GetLoanProductAmountByProductId/' + id);
  }

  getLoanProductCodeByType(typeId: number): Observable<any>
  {
    return this.http.get<any>(apiUrl+'GenerateLoanProductCode/'+typeId, { responseType: 'json' });
  }


   getTenureInfoByTenureId(id: number): Observable<any> {
    return this.http.get(apiUrl + 'GetNanoLoanDurationByTenureId/' + id);
  }
  deActive(id: any,s: boolean) {
    return this.http.delete(apiUrl + "DeActiveProductBuilder?id=" + id+'&status='+s);
  }
  getMFSAccountList():Observable<any>{
    return this.http.get(apiUrl + 'GetAllMFSAccountList');
   }

}
