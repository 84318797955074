import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardCustomService } from '../dashboard-custom.service';
import { LoanProductService } from 'app/product/service/loan-product.service';
import { PaymentService } from 'app/payment/payment.service';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { SharedService } from 'app/services/shared.service';
import { PaymentRequestVm } from 'app/payment/models/payment-request-vm';
import Swal from 'sweetalert2';
import { Location } from "@angular/common";
@Component({
  selector: "app-loan-settlement",
  templateUrl: "./loan-settlement.component.html",
  styleUrls: ["./loan-settlement.component.scss"],
})
export class LoanSettlementComponent implements OnInit {
  frmGroup: FormGroup;
  leadId: 0;
  List: any[] = [];
  model: any = {};
  modelRes: any = {};
  dueAmount: number;
  SelectedProductId: string;
  constructor(
    private formbuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _serviceDashboard: DashboardCustomService,
    private _loanProductService: LoanProductService,
    private _paymentService: PaymentService,
    private _pdfService: PdfService,
    private location: Location,
    private _sharedService: SharedService
  ) {
    this.frmGroup = this.formbuilder.group({
      leadId: 0,
      outstanding: "",
      collectionAmount: "",
      //collectionAmount: ['', [Validators.required, Validators.pattern('^[0-9,.]*$')]],
      customername: "",
      leadCode: "",
      accountNo: "",
      productBuilder: "",
      settelementFeeAmount: "",
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.leadId = params["parameter"];
    });
    this.getCustomerAccountByLeadId();
  }

  getCustomerAccountByLeadId() {
    this._serviceDashboard.getCustomerAccount().subscribe((res: any) => {
      this.List = res;
    });
  }

  // getCustomerLeadList(leadId){
  //   this._serviceDashboard.GetOutStadingByLeadId(leadId).subscribe((res:any)=>{
  //     if(res!=null){
  //       this.frmGroup.patchValue({
  //         leadId: res.leadId,
  //         outstanding :res.outstading,
  //         customername :res.customername,
  //         leadCode :res.leadCode,
  //         accountNo :res.accountNo,
  //         productBuilder :res.productBuilder,
  //         collectionAmount:res.outstading
  //       })
  //     }
  //   })
  // }

  getCustomerLeadList(leadId) {
    this._serviceDashboard
      .GetSetteletementOutStandingByLeadId(leadId)
      .subscribe((res: any) => {
        if (res != null) {
          this.frmGroup.patchValue({
            leadId: res.leadId,
            outstanding: res.outstanding,
            collectionAmount: res.finalSettelement,
            settelementFeeAmount: res.settelementFeeAmount,
            //penaltyAmount :res.penaltyAmount,
            //finalOverDue :res.finalOverDue,
            customername: res.customername,
            leadCode: res.leadCode,
            accountNo: res.accountNo,
            productBuilder: res.productBuilder,
            // emiAmount :res.emiAmount,
          });
        }
      });
  }

  SaveLoanSettlement() {
    this.model.leadId = this.frmGroup.value.leadId;
    this.model.installmentAmount = this.frmGroup.value.outstading;
    //this.model.collectionAmount=this.frmGroup.value.collectionAmount.replace(/,/g,'');
    this.model.collectionAmount = this.frmGroup.value.outstanding;

    if (this.model.leadId == 0) {
      this._sharedService.ShowAlert(
        "Warning",
        "Please select any loan account first",
        "warning"
      );
    } else if (
      this.model.collectionAmount == null ||
      this.model.collectionAmount == "" ||
      this.model.collectionAmount == 0
    ) {
      this._sharedService.ShowAlert(
        "Warning",
        "Loan Settlement can't be empty or 0.",
        "warning"
      );
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to pay this loan!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes,pay loan!",
      }).then((res) => {
        if (res.isConfirmed) {
          // this._loanProductService.SaveloanSettlementbyCustomer(this.model).subscribe((res)=>{
          //   this.modelRes=res;
          //   if(this.modelRes.result=='Failed'){
          //     this._sharedService.ShowAlert('Sorry',this.modelRes.errorMessage,'warning');
          //   }
          //   else{
          //this.dueAmount = this.frmSettlement.value.outstanding - parseFloat(this.frmSettlement.value.collectionAmount.replace(/,/g, ''));
          this.dueAmount = this.frmGroup.value.collectionAmount;
          if (this.dueAmount > 0) {
            const paymentRequest: PaymentRequestVm = {
              name: [this.frmGroup.value.customername],
              price: [parseFloat(this.frmGroup.value.collectionAmount)],
              url: ["url1"],
              qty: [1],
              pname: "Loan Settlement",
              purl: "Loan Settlement",
              pprice: parseFloat(this.frmGroup.value.collectionAmount),
              customer_account: this.frmGroup.value.accountNo,
              card_no: this.frmGroup.value.accountNo,
              valueType: "settlement",
            };

            this._paymentService.checkoutPayment(paymentRequest).subscribe(
              (res) => {
                if (res.gatewayPageURL) {
                  window.location.href = res.gatewayPageURL;
                } else {
                  console.error("No gatewayPageURL found in the response");
                }
              },
              (error) => {
                console.error("Payment request failed", error);
              }
            );
            this._sharedService.ShowAlert(
              "Thank you...",
              "Loan payment succesfull!",
              "success"
            );
          } else {
            this._sharedService.ShowAlert(
              "Sorry...",
              "Loan payment Failed",
              "warning"
            );
          }

          //   }
          // })
        }
      });
    }
  }
  SchedulePDF() {
    this.model.leadId = this.frmGroup.value.leadId;

    if (this.model.leadId > 0) {
      this._pdfService
        .OpenSchedulePdfForCustomer(this.model.leadId)
        .subscribe((res) => {
          let blob: Blob = res.body as Blob;
          let url = window.URL.createObjectURL(blob);

          window.open(url);
        });
    } else {
      this._sharedService.ShowAlert(
        "Warning...",
        "Please select any loan account First!",
        "warning"
      );
    }
  }

  CollentionPDF() {
    this.model.leadId = this.frmGroup.value.leadId;

    if (this.model.leadId > 0) {
      this._pdfService.OpenCollectionPdf(this.model.leadId).subscribe((res) => {
        let blob: Blob = res.body as Blob;
        let url = window.URL.createObjectURL(blob);

        window.open(url);
      });
    } else {
      this._sharedService.ShowAlert(
        "Warning...",
        "Please select any loan account First!",
        "warning"
      );
    }
  }

  formatNumber(value: string): string {
    const formattedValue = value
      .replace(/,/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedValue;
  }
  goBack() {
    this.location.back();
  }
}
