import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardCustomService } from 'app/dashboard/dashboard-custom.service';
import { PaymentRequestVm } from 'app/payment/models/payment-request-vm';
import { PaymentService } from 'app/payment/payment.service';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { SharedService } from 'app/services/shared.service';
import Swal from 'sweetalert2';
import {  Location } from "@angular/common";

@Component({
  selector: "app-waiting-payment",
  templateUrl: "./waiting-payment.component.html",
  styleUrls: ["./waiting-payment.component.scss"],
})
export class WaitingPaymentComponent implements OnInit {
  dataList: any[] = [];
  status: number;
  dueAmount: number;
  paymentName: string = "";
  isLoading: boolean = false;
  constructor(
    private router: Router,
    private _serviceDashboard: DashboardCustomService,
    private pdfService: PdfService,
    private shared: SharedService,
    private paymentService: PaymentService,
    private location: Location,

    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getFinalDisburse();
  }

  getFinalDisburse() {
    this.status = 9;
    this._serviceDashboard
      .getFinalDisbursLoan(this.status)
      .subscribe((res: any[]) => {
        this.dataList = res;
        this.changeDetectorRef.detectChanges();
      });
  }

  OpenPDF(leadId) {
    this.pdfService.OpenSchedulePdf(leadId).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    });
  }

  checkPayment(leadId, customerAccountNo, loanAmount) {
    this.dueAmount = loanAmount;
    this.paymentName = customerAccountNo;
    if (this.dueAmount > 0) {
      const paymentRequest: PaymentRequestVm = {
        name: [this.paymentName],
        price: [this.dueAmount],
        url: ["url1"],
        qty: [1],
        pname: "Loan Disbursement",
        purl: "Loan Disbursement",
        pprice: this.dueAmount,
        customer_account: this.paymentName,
        card_no: "",
        valueType: "disbursement",
      };

      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to disburse this loan!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, disburse it!",
      }).then((res) => {
        if (res.isConfirmed) {
          // this.paymentService.checkoutPayment(paymentRequest).subscribe(
          //   (response) => {
          //     if (response.gatewayPageURL) {
          //       window.location.href = response.gatewayPageURL;
          //     } else {
          //       console.error("No gatewayPageURL found in the response");
          //     }
          //   },
          //   (error) => {
          //     console.error("Payment request failed", error);
          //   }
          // );

          this.paymentService.checkoutPaymentNagad(paymentRequest).subscribe(
            (response) => {
              if (response !== null) {
                this.shared.ShowAlert(
                  "Success...",
                  "Disbursement Successfull!",
                  "success"
                );
                this.router.navigate(["/dashboard/application-disburse-list"]);
                this.isLoading = false;
              } else {
                this.shared.ShowAlert(
                  "Warning...",
                  "Please check again !",
                  "warning"
                );
              }

              this.isLoading = false;
            },
            (error) => {
              console.error("Payment request failed", error);
              this.isLoading = false;
            }
          );
        } else if (res.isDismissed) {
          this.router.navigate(["/dashboard/app-approve-disburse"]);
        }
      });
    }
  }
  goBack() {
    this.location.back();
  }

  // ApplicationApproval(leadCode:string,type:number){
  //   this.router.navigate(['/loanapplication-approval/loanapplication-approval/'+ leadCode +'/'+ type]);
  // }
}
