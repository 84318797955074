import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Incomesource } from '../../basic-model/incomesource';
import { IncomesourceService } from '../../basic-service/incomesource.service';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { SharedService } from 'app/services/shared.service';

@Component({
  selector: 'app-incomesource',
  templateUrl: './incomesource.component.html',
  styleUrls: ['./incomesource.component.scss']
})
export class IncomesourceComponent implements OnInit {
  isEdit:boolean=false;
  list:Incomesource[]=[];
  model=new Incomesource();

  currentPage = 1;
  itemsPerPage = 10;
  totalItems = 0;
  currentItems=0;
  searchControl="";
  

  constructor(
    private _service:IncomesourceService,
     private changeDetectorRef: ChangeDetectorRef,
    private router:Router,
    private shared: SharedService,
  ) { }

  ngOnInit(): void {
    this.getIncomeSourceList();
    this.showSaveForm = 'saveList';
  }

  //  getIncomeSourceList(){
  //   this._service.getlist().subscribe(res => {
  //     console.log(res)
  //     this.list=res;
  //   })
  // }

//#region For pagination
onChangeForShow(value: any) {
  this.currentPage = 1;
  this.itemsPerPage=value;
  this.getIncomeSourceList();
}
onPageChange(pageNumber: number) {
  this.currentPage = pageNumber;
  this.getIncomeSourceList();
}

onSearch() {
  this.currentPage = 1; // Reset the current page to 1 when a new search is performed
  this.getIncomeSourceList();
}
showSaveForm:string='';
onClickShowSaveForm(value:string){
  this.showSaveForm=value,
  this.refresh();
}

getIncomeSourceList() {
  this._service.getListWithSerchAndPagination(this.currentPage, this.itemsPerPage, this.searchControl).subscribe((res : any) => {
    this.list = res.data;
    this.totalItems = res.totalItems;
    this.currentItems=res.currentItems;
  })
}

  onsubmit() {
   debugger
   
    if (this.model.Id) {
      this._service.save(this.model).subscribe(res => {
        this.shared.ShowAlert("Success", "Update Successfully", "success");
        this.isEdit = false;
        this.showSaveForm = 'saveList';
        this.getIncomeSourceList();

      })
    }
    else {
      this._service.save(this.model).subscribe(res => {
        this.shared.ShowAlert("Success", "Save Successfully", "success");
        this.isEdit = false;
        this.showSaveForm = 'saveList';
        this.getIncomeSourceList();
    
      })
    }
  }
  delete(Id:any){
   
    this._service.delete(Id).subscribe(res=>{

      this.shared.ShowAlert("Success","Data deleted successfully","success");
      this.getIncomeSourceList();
    })
  }




  edit(item: any) {

    this.isEdit = true;
    this.showSaveForm = 'saveForm';
    this.model = {
      Id: item.id,
      SourceName: item.sourceName,
      SourceNameBn: item.sourceNameBn,
      Status:item.status,
      
    };
  }
  refresh() {
    this.isEdit = false;
    this.model={
      Id: null,
      SourceName: null,
      SourceNameBn: null,
      Status:null
      
    }
  }
}
