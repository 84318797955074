import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CashApprover } from '../model/cash-approver';
import { Router } from '@angular/router';
import { SharedService } from 'app/services/shared.service';
import { CashApprovalService } from '../service/cash-approval.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cash-approval',
  templateUrl: './cash-approval.component.html',
  styleUrls: ['./cash-approval.component.scss']
})
export class CashApprovalComponent implements OnInit {
  isEdit: boolean = false;
  frmGroup: FormGroup;
  model = new CashApprover();

  list: CashApprover[] = [];
  dlist: any = {};
  userlist: any[] = [];
  masterDetailslist: any[] = [];
  masterId: any = 0;

  //
  approverId: string;
  approverName: string;
  detailsId: number;
  //
  approvalDetails: { approverId: string; approverName: string; detailsId: number; }[] = [];
  editingIndex: number = -1;

  constructor(
    private _service: CashApprovalService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private formBuilder: FormBuilder,
    private shared: SharedService,
    private modalService: NgbModal
  )
  { 
    this.getApprovaMasterDetailslList();
  }

  ngOnInit(): void {
    this.getApprovaUserlList();
    this.getApprovaMasterDetailslList();
    this.showSaveForm = 'saveList';
    this.populateForm();
    this.refresh();
  }

  showSaveForm: string = '';
  onClickShowSaveForm(value: string) {
    this.showSaveForm = value;
    this.refresh();

  }

  

  populateForm() {
    const lastMaxAmount = this.masterDetailslist.reduce
    (
      (max, item) => (item.cashApproverMaster.maxAmount > max ? item.cashApproverMaster.maxAmount : max),0
    );
    const initialMinAmount = this.masterDetailslist.length > 0 ? lastMaxAmount + 1 : 0;
  
    this.frmGroup = this.formBuilder.group({
      id: [0],
      maxAmount: [0],
      minAmount: [initialMinAmount],
      approverId: [''],
      isActive: ['']
    });
  }
  



  refresh() {
    this.isEdit = false;
    this.populateForm();
  }


  getApprovaMasterDetailslList() {
    this._service.getApprovalMasterWithDetalislist().subscribe(res => {
      this.masterDetailslist = res;
    })
  }



  getApprovaUserlList() {
    this._service.getUserlist().subscribe(res => {
      this.userlist = res;
    })
  }
  // getApprovalmasterList() {
  //   this._service.getAlllist().subscribe(res => {
  //     this.list = res;
  //   })
  // }


  delete(id: any) {
    this._service.delete(id).subscribe(res => {
      this.shared.ShowAlert('Alert', 'Data deleted successfully.', 'warning');
      this.getApprovaMasterDetailslList();
    })
  }


  Updatedetails(id: number) {
    debugger
    this.isEdit = true;
    this._service.GetApprovalDetailsByMasterId(id).subscribe(res => {
      this.dlist = res;
      this.approvalDetails = [];

      this.dlist.forEach((item: any) => {
        this.approvalDetails.push({
          approverId: item.approverId,
          approverName: item.approver.fullName,
          detailsId: item.id

        });
      });
    })

  }

  edit(item: any) {

    const statusString = item.cashApproverMaster.isActive === false ? '0' : '1';
    this.isEdit = true;
    this.masterId = item.cashApproverMaster.id
    this.showSaveForm = 'saveForm';
    this.frmGroup.patchValue({
      id: item.cashApproverMaster.id,
      minAmount: item.cashApproverMaster.minAmount,
      maxAmount: item.cashApproverMaster.maxAmount,
      approverId: item.cashApproverMaster.approverId,
      isActive: statusString
    });
    this.Updatedetails(this.masterId)

  }

 
  addApprover() {
    if (this.frmGroup.valid) {
      if (this.editingIndex === -1) {
        const selectedUser = this.userlist.find(item => item.userId === this.approverId);
        if (selectedUser) {
          this.approvalDetails.push({
            approverId: this.approverId,
            approverName: `${selectedUser.userName} - ${selectedUser.fullName}`,
            detailsId: 0,
          });
        }
      } 
      else {
        this.approvalDetails[this.editingIndex] = {
          approverId: this.approverId,
          approverName: this.approverName,
          detailsId: this.detailsId,
        };
        this.editingIndex = -1;
      }
      this.approverId = null;
    }
  }



  editApprover(index: number) {
    this.editingIndex = index;
    this.approverId = this.approvalDetails[index].approverId;
    this.detailsId = this.approvalDetails[index].detailsId;

  }

  deleteApprovar(index: number) {
    this.approvalDetails.splice(index, 1);
    if (index === this.editingIndex) {
      this.editingIndex = -1;
      this.approverId = null;
    }
  }


  SendDataForSubmitInAPI() {
    const formData = this.frmGroup.value;
    formData.approverDetails = this.approvalDetails;
    if (formData.isActive === "1") {
      formData.isActive = true;
    }
    else {
      formData.isActive = false;
    }
    return formData;
  }

  onSubmit() {

    if (this.frmGroup.valid) {
      const details = this.SendDataForSubmitInAPI();
      this._service.saveApprovalMasterDetails(details).subscribe(

        (res: any) => {
          if (res === null) {
            this.shared.ShowAlert("Error", "No Information Given for Details.", "error");
          }
          else {
            this.shared.ShowAlert("Success", "Save Successfully", "success");
            this.getApprovaMasterDetailslList();
            this.isEdit = false;
            this.showSaveForm = 'saveList';
          }
        },
        error => {
          //console.log(error);
          this.shared.ShowAlert("Error", "Failed to save data.", "error");
        }
      );
    }
  }


}
