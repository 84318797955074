import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ScoreQueAnsService } from '../services/score-que-ans.service';
import { ScoretagService } from '../scoretag.service';
import { Bank } from '../model/bank';
import { SharedService } from 'app/services/shared.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bank',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.scss']
})
export class BankComponent implements OnInit {

  bankList : Observable<any[]>;
  showSaveForm:string="saveList";
  model = new Bank();
  isEdit: boolean = false;
  bankForm : FormGroup;
  BankModel:any={};

  constructor(
    private _scoreService : ScoretagService,
    private shared: SharedService,
    private formBuilder : FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private route : Router,

  ) { 
    this.buildForm();
  }

  ngOnInit(): void {
    this.getBankList();
    this.buildForm();
  }

  getBankList(){
    this._scoreService.getAllBankList().subscribe(data=>{
      //console.log(data);
      this.bankList = data;
    })
  }

  onClickShowSaveForm(value:string){
    this.showSaveForm = value;
  }

  onsubmit(){
    if(this.isEdit = true){
      this.model.id = this.bankForm.value.id;
      
    }
    this.model.bankName = this.bankForm.value.bankName;

    if(this.model.id){
      this._scoreService.bankSave(this.model).subscribe(data=>{
        this.shared.ShowAlert("Success", "Updated Successfully", "success");
        this.getBankList();
        this.showSaveForm = 'saveList';
      })
    }
    else{
      this._scoreService.bankSave(this.model).subscribe(data=>{
        this.shared.ShowAlert("Success", "Saved Successfully", "success");
        this.isEdit = false;
        this.reset();
        this.getBankList();
        this.showSaveForm = 'saveList';
      })
    }
   
    this.changeDetectorRef.detectChanges();
  }

  reset(){
    this.model.bankName= '';
  }

  buildForm(){
    
    this.bankForm = this.formBuilder.group({
      id: [0],
      bankName: ['', Validators.required],
    })
  }

  edit(item:any){
    this.isEdit = true;
    this.showSaveForm = 'saveForm';
    this.bankForm.patchValue({
      id: item.id,
      bankName : item.bankName
    })
    
  }

  delete(id:any){
    this._scoreService.bankDelete(id).subscribe(data=>{
      this.shared.ShowAlert("Alert", "Data Deleted Successfully", "warning");
      this.getBankList();
    })
  }
}
