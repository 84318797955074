<div class="card" style="width: 100%;">

  <div class="card-header py-3 d-flex flex-row align-items-center">
    <h6 class="m-0 font-weight-bold text-primary">Waiting Application List</h6>
  </div>
  <div class="card-body">
    <div class="container">
     
      <table id="example" class="table table-striped table-bordered" style="width:100%">
        <thead>
          <tr>
            <th>SL No.</th>
            <th>Lead Code </th>
            <th>Customer Info </th>
            <th>Loan Type </th>
            <th>Status</th>
            <th>Action </th>
          </tr>
        </thead>

        <tbody>

          <tr *ngFor="let item of dataList; let i = index">
            <td>{{i+1}}</td>
            <td>{{item.leadCode}}</td>
            <td>
              <span>Name: {{item?.customer?.customerNameEng}}</span><br>
              <span>Mobile No.: {{item?.customer?.mobileNo}}</span>
            </td>
            <td>{{item.loanProduct?.loanType?.typeName}}</td>
            <td> 
          
              <div class="badge badge-pill"
              [ngClass]="{'bg-light-primary': item.isComplete ==0  && item.statusInfoId == 1,
               'bg-light-success': item.isComplete ==1  &&  item.statusInfoId == 1}">

              {{ item.isComplete ==0  && item.statusInfoId == 1 ? "Created"  :  item.isComplete == 1  && item.statusInfoId == 1 ? "Applied" : ""}}
              </div>

            </td>
            <td>
            <button class="btn btn-sm btn-info" type="button" title="Click here to Approve" *ngIf="item.isComplete==1">
              <a [routerLink]="'/loanapplication-approval/loanapplication-approval/' + item.leadCode + '/' + 1" style="color: white; font-weight: 700;">Approval</a>
              <!-- <a [routerLink]="'/dashboard/new-admin-dashboard/' + item.leadCode + '/' + 1" style="color: white; font-weight: 700;">Approval</a> -->
            </button>



          
            <!-- <button class="btn btn-sm btn-success mr-1" (click)="ApplicationApproval(item.leadCode,1)" type="button"
            title="Approval Log List" >Approval</button> -->
          
          </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>