import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DatePipe, Location } from "@angular/common";
import { CibreportsService } from '../cibreports.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'app/services/shared.service';

@Component({
  selector: "app-cibsubject-generate",
  templateUrl: "./cibsubject-generate.component.html",
  styleUrls: ["./cibsubject-generate.component.scss"],
})
export class CibsubjectGenerateComponent implements OnInit {
  generateDate: Date = new Date();
  monthsId: number = 0;
  constructor(
    private location: Location,
    private cibReports: CibreportsService,
    private router: Router,
    private route: ActivatedRoute,
    private shared: SharedService,
    private changeDetectorRef: ChangeDetectorRef,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.monthsId;
  }

  goBack() {
    this.location.back();
  }

  cibSubjectGenerate() {
    const currentYear = new Date().getFullYear();
    const currentDay = new Date().getDate();
    const formattedDate = `${currentYear}-${this.getMonthString(
      this.monthsId
    )}-${currentDay}`;

    this.cibReports.GenerateCIBSubject(formattedDate).subscribe((res) => {
      if (res !== null) {
        this.shared.ShowAlert(
          "Success",
          "Subject report generate successfully!",
          "success"
        );
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  private getMonthString(monthId: number): string {
    return monthId.toString().padStart(2, "0"); 
  }
}
