import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomerService } from 'app/customer/customer.service';
import { PaymentRequestVm } from 'app/payment/models/payment-request-vm';
import { PaymentService } from 'app/payment/payment.service';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { SharedService } from 'app/services/shared.service';
import { Observable, of } from 'rxjs';
import swal from 'sweetalert';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dps-immature-encashment',
  templateUrl: './dps-immature-encashment.component.html',
  styleUrls: ['./dps-immature-encashment.component.scss']
})
export class DpsImmatureEncashmentComponent implements OnInit {

  frmGroup : FormGroup;
  formControl = new FormControl();
  AccountNoList : Observable<any[]>;
  customerAccountId : number;
  customerAccountId2 : number;
  customerAccountIds : string;
  currentBalance: number;
  maturityDate: Date;
  maturityAmount: Date;
  creditAccountNo: string = '';
  totalInterest: number;
  totalReceivableAmount: number;
  totalPayment: number;
  accountId : number;
  customerInfo_cashFundTransfer1: any = '';
  isBtnValid: boolean = true;
  glAccountName:any;
  accountNotFound1 : string  = '';
  customerAccountNo: string;

  dueAmount: number = 500.0;
  accountNoFund2: string = '';
  isvalid: boolean = true;
  //model = new Bkash();
  model : any= {};
  isEdit: boolean = false;
  mobileBankingList: Observable<any[]>;
  accountType: string = '';
  accountTypes : Observable<any[]>;
  id:number;
  accountName: string = '';
  selectedSuggetion: any = null;
  hideShow: string = 'Show';
  searchType: string = '';
  suggestions: any[] = [];
  totalCurrentBalance: number;
  // totalBalance: number;


  constructor(
    private _CustomerService: CustomerService,
    private shared: SharedService,
    private router: Router,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private paymentService: PaymentService,
    private pdfService: PdfService
    
  ) { 
    this.frmGroup = new FormGroup({
      id: new FormControl(),
      customerAccountId: new FormControl(),
      // customerAccountId2: new FormControl(), 
        customerCode: new FormControl(),
        // transactionType: new FormControl(),
        // customerId: new FormControl(),
        // voucherNo: new FormControl(),
        // postingType: new FormControl(),
        // remarks: new FormControl(),
        // currencyId: new FormControl(),
        // customersId: new FormControl(),
        // voucherAmount: new FormControl(),
        // gLGenerateId:new FormControl(),
        maturityDate: new FormControl(),
        totalPayment: new FormControl(),
        totalReceivableAmount: new FormControl(),
        maturityAmount: new FormControl(),
        totalInterest: new FormControl(),
        creditAccountNo: new FormControl(),
     

    })
    this.frmGroup = this.formBuilder.group({ 
       
      voucherAmount: ['', [Validators.required, Validators.pattern('^[0-9,.]*$')]] ,
      customerCode: ['', Validators.required],
      customerAccountId: [0, Validators.required],
      voucherNo: ['', Validators.required],
      remarks: ['', Validators.required],
      currencyId: [0, Validators.required]

    }); 
    
  }

  ngOnInit(): void {
    this.GetCustomerDPSAccountNo();
    // this.GetMobileBankingAccountInfo();
    this.GetDPSPaymentTypes();

  }

 
  GetCustomerDPSAccountNo() {
    this._CustomerService.GetCustomerDPSAccountNo().subscribe(res => {
      this.AccountNoList = of(res);
    });
  }

  GetDPSPaymentTypes() {
    this._CustomerService.GetDPSPaymentTypes().subscribe(res => {
      this.accountTypes = of(res);
      console.log(this.accountTypes);
    });
  }


  GetCustomerDPSInfoForEncashment(accountId:number){
    this._CustomerService.GetCustomerDPSInfoForEncashment(accountId).subscribe(res=>{
      console.log("res>", res);

      if(res != null){
        // this.totalCurrentBalance = res.currentBalance.toLocaleString();
        this.customerInfo_cashFundTransfer1 = res.customerAccount?.customers?.customerNameEng;
        this.maturityDate = res.maturityDate;
        this.maturityAmount = res.maturityAmount;
        this.totalPayment = res.totalPayment;
        this.totalInterest = res.totalInterest;
        this.totalReceivableAmount = res.totalReceivableAmount;
        this.glAccountName = '';
        this.customerAccountId = res.customerAccount.id;
        this.isBtnValid = true;
      }
      else{
        this.glAccountName = res.glAccountName;
        this.customerInfo_cashFundTransfer1 = '';
        this.isBtnValid = true;
      }
    })
  }

  GetDPSOSByAccountId(event:any){
    console.log(event);
    this.customerAccountNo = event.target.textContent.replace("Select", "");
    this._CustomerService.GetDPSOSByAccountId(event.target.value).subscribe(res=>{
      console.log(res);
      this.currentBalance = res;
    })
  }



  // GetMobileBankingAccountInfo(){
  //   this._CustomerService.GetMobileBanking(this.accountType).subscribe(res=>{
  //     this.mobileBankingList = of(res);
  //     console.log(this.mobileBankingList);
  //   })
  // }


  onsubmit(){

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to send this Amount!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Withdraw"
    }).then((res) => {
      if (res.isConfirmed) {
   
    if(this.isEdit){
      
      this.model.id = this.frmGroup.value.id;
    }
    else{
      this.model.id = 0;
    }
    this.model.transactionType = 3;
    this.model.customerAccountId = this.customerAccountId;
    this.model.maturityAmount = this.maturityAmount;
    this.model.maturityDate = this.maturityDate;
    this.model.totalPayment = this.totalPayment;
    this.model.totalReceivableAmount = this.totalReceivableAmount;
    this.model.totalInterest = this.totalInterest;
    this.model.creditAccountNo = this.creditAccountNo;
    // debugger;
    // this.model.transactionTypeId = 2;
    // this.model.ledgerRelationId = 110;
    //this.model.remarks = this.frmGroup.value.remarks;
    // this.model.voucherAmount = this.frmGroup.value.voucherAmount.replace(/,/g, '');
    if(this.model.totalReceivableAmount > 0){
      this._CustomerService.SaveDPSEncashment(this.model).subscribe((res) => {
        this.shared.ShowAlert("Success", "Save Successful", "success");
        setTimeout(() => {
          location.reload();
        }, 3000);
  
        // this.checkPayment();
        // this.router.navigate(["/dps-account/dps-immature-encashment"]);
      });
      
    }
    else{
      Swal.fire({
        title: "Failed!",
        text: "Invalid Payment Amount",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok"
      })
    }
  }
  else if (res.isDismissed) {
    this.router.navigate(['/accounting/b-kash']);
  }
});

} 


  formatNumber(value: string): string {

    const formattedValue = value.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return formattedValue;
  }

  CheckBalance(){
    var balanceAmount = this.currentBalance.toString().replace(/,/g, '');
    var inputAmount = parseInt(this.frmGroup.value.voucherAmount.replace(/,/g, ''), 10);

    if (inputAmount > parseInt(balanceAmount, 10))
    {
      this.isvalid = false;
      this.isBtnValid = true;
    }
     else
    {
      this.isvalid = true;
      this.isBtnValid = true;
    }

    //this.totalBalance = this.currentBalance;
    // console.log(this.currentBalance);
    // console.log(inputAmount);
    //this.currentBalance = this.totalBalance - inputAmount;
  }

  ResetForm() {
    this.frmGroup.reset();
    this.customerInfo_cashFundTransfer1 = '';
    this.currentBalance = 0;
    this.accountName  = '';
    this.isBtnValid = false;
  }



  checkPayment() {
    this.dueAmount = this.frmGroup.value.voucherAmount;
    if (this.dueAmount > 0) {
      const paymentRequest: PaymentRequestVm = {
        name: [this.frmGroup.value.customerAccountNo],
        price: [this.dueAmount],
        url: ["url1"],
        qty: [1],
        pname: "Account Opening Fee",
        purl: "Account Opening Fee Url",
        pprice: this.dueAmount,
        customer_account: this.customerAccountNo,
        card_no: "",
        valueType: "dps",
      };
      this.paymentService.checkoutPayment(paymentRequest).subscribe(
        (response) => {
          if (response.gatewayPageURL) {
            window.location.href = response.gatewayPageURL;
          } else {
            console.error("No gatewayPageURL found in the response");
          }
        },
        (error) => {
          console.error("Payment request failed", error);
        }
      );
    }
  }

  initiatePayment() {
    const backendUrl = "https://localhost:44395/api/Payment/InitiatePayment";

    const paymentData = {
      total_amount: "100.00",
      currency: "BDT",
    };

    this.http.post(backendUrl, paymentData).subscribe(
      (response: any) => {
        if (response && response.GatewayPageURL) {
          window.location.href = response.GatewayPageURL;
        } else {
          console.error("Payment initiation failed");
        }
      },
      (error) => {
        console.error("Error initiating payment:", error);
      }
    );
  }
  //End Save
  //Reporting Start
  PrintKyc(id: number) {
    this.pdfService.OpenKycPdf(id).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    });
  }
  //Reporting End
  //Extra
  onNumberInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    let inputValue = inputElement.value;

    if (inputValue.startsWith("0")) {
    } else {
      inputValue = inputValue.replace(/\D/g, "");
    }
    inputElement.value = inputValue;
  }
  ChangeCrAcc(value){
    this.creditAccountNo = value;
  }
  ChangeCrAccType(value){
    //this.GetMobileBankingAccountInfo();
  }
}
