<div class="card" style="width: 100%;">

    <div class="row card-header  d-flex flex-row align-items-center">
        <div class="col-6">
            <h6 class="m-0 font-weight-bold text-primary">DPS List</h6>
        </div>
        <div class="col-6">
            <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
                <!-- <i class="ft-chevrons-left"> Back</i> -->
                <i class="icon-arrow-left" style="font-size: 17px;"> </i>
            </button>
        </div>
    </div>
  <hr>
    <div class="row d-none" style="margin-left: 2%;" >

        <div class="col-4">
            <label>From Date</label>
            <div class="col-12" style="margin-left: -16px;">

                <div class="input-group">
                    <input type="date" class="form-control" [(ngModel)]="startDate" (ngModelChange)="onDateChange()" placeholder="Select From Date">

                </div>
            </div>
        </div>

        <div class="col-4">
            <label>To Date</label>
            <div class="col-12" style="margin-left: -16px;">

                <div class="input-group">
                    <input type="date" class="form-control" [(ngModel)]="EndDate" (ngModelChange)="onDateChange()" placeholder="Select To Date">
                </div>

            </div>
        </div>

        <div class="col-4">
            <label></label>

            <div class="col-12 mt-1">
                <button class="btn btn-primary" (click)="GetLoanOutstandingDataByDateRange()">Search</button>
            </div>
        </div>

    </div>


    <div class="card-body" style="overflow: auto;max-height: 600px;">
        <table id="example" class="table table-striped table-bordered scrollable-table"  style="width:100%">
            <thead style="position: sticky;top:0;z-index: 1;background-color: #f8f9fa;">
                <tr>
                    <th>SL No.</th>
                    <th>Account No</th>
                    <th>Account Title</th>
                    <th>DPS Name</th>
                    <th>DPS Amount(Monthly)</th>
                    <th>Collection Amount(Total)</th>
                   

                </tr>
            </thead>

            <tbody>

                <tr *ngFor="let item of dataList; let i = index">
                    <td>{{i+1}}</td>
                    <td>{{item?.accounT_NO}}</td>
                    <td>{{item?.accounT_TITLE}}</td>
                    <td>{{item?.name}}</td>
                    <td>{{item?.scheduleAmount}}</td>
                    <td>{{item?.collectionAmount}}</td>
                   

                </tr>
            </tbody>
        </table>

    </div>
</div>