<div class="card-body">
    <div class="container">

        <div style="display: flex; justify-content: space-between; align-items: center;">
            <h3 class="card-title" style="color: black; text-align: center;">Country List</h3>
            <div>
              <button class="btn " style="background-color: #975AFF;"> <a routerLink="/country/country" style="color: white;font-weight: 700;">Create</a></button>
            </div>
          </div>
          
      <hr>
      <div class="row">
        <div class="col-md-6" style="text-align: left">
          <select type="number" (change)="onChangeForShow($event.target.value)" class="form-control"
            style="width:16%">
            <option value="5">5</option>
            <option selected value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <div class="form-group col-md-6" style="text-align: right">
          <input type="text" class="form-control" placeholder="Search"
            (keyup)="onSearch()">
        </div>
      </div>

      <table id="example" class="table table-bordered  ">
        <thead>
          <tr>
            <th>SL No.</th>
            <th>Country Code</th>
            <th>Country Name </th>
            <th>Country Currency </th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let item of list | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }; let i = index">
            <td>{{ (currentPage - 1) * itemsPerPage + i + 1 }}</td>
            <td>{{item.countryCode}}</td>
            <td>{{item.countryName}}</td>
            <td>{{item.currency}}</td>
            <td>
              <button class="btn btn-primary" (click)="edit(item)"><i class="fa fa-edit 3x"></i></button>
              <button [disabled]="isEdit" class="btn btn-danger" style="margin-left: 5px;"
                (click)="delete(item.id)"><i class="fa fa-trash-o 3x"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col-md-6" style="text-align: left">
          <span>Showing {{currentItems}} of {{totalItems}} entries</span>
        </div>
        <div class="col-md-6" style="text-align: right">
          <pagination-controls (pageChange)="onPageChange($event)">
          </pagination-controls>
        </div>
      </div>
    </div>
  </div>