import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SharedService } from 'app/services/shared.service';
import { ScoreQueAnsService } from '../services/score-que-ans.service';

@Component({
  selector: 'app-score-que-answer',
  templateUrl: './score-que-answer.component.html',
  styleUrls: ['./score-que-answer.component.scss']
})
export class ScoreQueAnswerComponent implements OnInit {

  answerList: any[] = [];
  questionList: any[] = [];
  isEdit: boolean = false;
  ansFromGrp: FormGroup;

  ansModel: any = {};

  showSaveForm: string = '';
  parentNameId: any;


  constructor(private formBuilder: FormBuilder,
    private shared: SharedService,
    private _answerService: ScoreQueAnsService) { }

  ngOnInit(): void {
    this.getQuesList();
    this.showSaveForm = 'saveForm';
    this.answerForm();
  }

  answerForm() {
    this.ansFromGrp = this.formBuilder.group({
      id: [0],
      questionId: [0],
      answerOption: [''],
      mark: [0],
      sortOrder: [''],
      status: ['']
    })
  }

  refresh() {
    this.isEdit = false;
    this.answerForm();
    this.ansModel = {};
  }

  onClickShowSaveForm(value: string) {
    this.showSaveForm = value;
    this.refresh();
  }

  getQuesList() {
    this._answerService.getAllQuestionList().subscribe(res => {
      console.log('ques');
      console.log(res);
      this.questionList = res;
    })
  }

  onQuestionChange(value: any) {
    if (value != 0) {
      this.parentNameId = value;
    }
  }

  onSubmit() {
    if (this.isEdit) {
      this.ansModel.id = this.ansFromGrp.value.id;
    }
    this.ansModel.questionId = this.ansFromGrp.value.questionId;
    this.ansModel.answerOption = this.ansFromGrp.value.answerOption;
    this.ansModel.mark = this.ansFromGrp.value.mark;
    this.ansModel.sortOrder = this.ansFromGrp.value.sortOrder;
    this.ansModel.status = this.ansFromGrp.value.status;
    console.log(this.ansModel);
    this._answerService.saveAnswer(this.ansModel).subscribe(res => {
      //alert('Save successfully');

      this.shared.ShowAlert("Success", "Save Successfully", "success");
      //this.showSaveForm='ListView';
      this.refresh();
    })
  }


}
