<!-- Your existing button divs -->

<form [formGroup]="frmGroup">
  <!-- ... -->
  <div class="col-md-12">
    <div class="card" style="width: 100%;">
      <div class="card-body">
        <div >
          <h5  class="card-title" style="color: black">Cash Payment</h5>
          <hr />
          <div class="row">
            <div class="col-md-12">
      

              <!-- <form [formGroup]="form">  
                <div class="form-group row">
                  <label class="col-sm-4">Search By:</label>
                  <div class="col-sm-8">
                    <div class="btn-group">
                      <button type="button" class="btn btn-primary mr-2" (click)="selectSearchType('BDGenerateCode')">BD GENERATE CODE</button>
                      <button type="button" class="btn btn-primary" (click)="selectSearchType('PaymentAccount')">Payment Account</button>
                    </div>
                  </div>
                </div>
              
                <div class="form-group row" *ngIf="searchType === 'BDGenerateCode'">
                  <label for="BDGenerateCode" class="col-sm-4">BD GENERATE CODE</label>
                  <div class="col-sm-8">
                    <div class="input-group">
                      <input type="hidden" id="gLGenerateId" formControlName="gLGenerateId"value="{{gLGenerateId}}">
                      <input type="text" class="form-control" id="BDGenerateCode"formControlName="BDGenerateCode" 
                             [formControl]="formControl" placeholder="Account Code">
                    </div>
                    <ul class="list-group mt-2" *ngIf="hideShow === 'Show'">
                      <li class="list-group-item" *ngFor="let suggestion of suggestions" (click)="selectSuggestion(suggestion)" (click)="hideAfterSelectValue('Hide')">
                        {{ suggestion.bdGenerateCode }}
                      </li>
                    </ul>
                  </div>
                </div>
              </form> -->
              <div class="form-group row">
                <label for="" class="col-sm-4 ">Payment Account/BD GENERATE CODE</label>
                <div class="col-sm-8">
                  <div class="input-group">
                    <input type="text" class="form-control" name="customerCode" formControlName="customerCode"
                      [(ngModel)]="accountNo" placeholder="Type Account No...."
                      (keyup.enter)="loadUserDataByAccountNoCashPayment()">
                    <div class="input-group-append">
                      <button class="btn btn-primary btn-sm"
                        (click)="loadUserDataByAccountNoCashPayment()">Find</button>
                    </div>
                  </div>
                  <input type="hidden" class="form-control" name="customerId" formControlName="customerId">
                  <input type="hidden" class="form-control" formControlName="gLGenerateId">
                </div>
              </div>
              <div class="form-group row">
                <label for="" class="col-sm-4 ">Account Name</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" id="AccountName" formControlName="AccountName"value="{{glAccountName}} {{customerInfo_cashPayment}}"
                    readonly>
                </div>  
              </div>
              <!-- <div class="form-group row">
                <label for="" class="col-sm-4 ">Customer Info</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" [value]="customerInfo_cashPayment" name="customerName"
                    readonly>
                </div>
              </div> -->
            </div>
          </div>
        </div>
       
        <div class="row">
          <div class="col-md-12">
            <div class="form-group row">
              <label for="" class="col-sm-4 ">Currency</label>
              <div class="col-sm-8">
                <select class="form-control" name="currencyId" formControlName="currencyId">
                  <option value="">Select</option>
                  <ng-container *ngFor="let item of countryList | async">
                    <option [value]="item.id">{{item.currency}}</option>
                  </ng-container>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group row">
              <label for="voucherAmount" class="col-sm-4 col-form-label">Amount</label>
              <div class="col-sm-8">
                <input type="number" class="form-control" min="0" name="voucherAmount" formControlName="voucherAmount" placeholder="Amount...." (keyup)="CheckBalance()">
                <span class="text-danger" style="font-size: 10px; font-weight: bold;" *ngIf="!isvalid">Invalid Amount. Amount cannot be more than the Balance.</span>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group row">
              <label for="remarks" class="col-sm-4 col-form-label">Remarks</label>
              <div class="col-sm-8">
                <textarea class="form-control" formControlName="remarks" id="" cols="30" rows="2"></textarea>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group row">
              <label for="businessDateId" class="col-sm-4 col-form-label">Business Date<span style="color:red; font-size: 15px; "></span></label>
              <div class="col-sm-8">
                <input type="hidden" id="businessDateId" formControlName="businessDateId"value="">
                <input type="text" class="form-control" [readonly]="isAuto" formControlName="businessDate" placeholder="business Date Id">
              </div>
            </div>
          </div>
        </div>
        <button data-toggle="tooltip" (click)="onsubmit()" *ngIf="isBtnValid" title="Save" type="button" class="btn btn-success"
          style="float:right; margin-top:5px;">Save </button>

        <button type="button" title="Refresh" class="btn btn-secondary"
          style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>
      </div>
    </div>
  </div>
  
</form>