export class CashApprover {
    id:number;
    minAmount:number;
    maxAmount:number;
    approverId:string;
    isActive:string;
    detailId:number;
    isActivedetails:string;
    approverName:string;
}
