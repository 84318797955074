import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormBuilder, NgForm, FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { District } from '../../basic-model/district';
import { DistrictService } from '../../basic-service/district.service';
import { SharedService } from 'app/services/shared.service';

@Component({
  selector: 'app-district',
  templateUrl: './district.component.html',
  styleUrls: ['./district.component.scss']
})
export class DistrictComponent implements OnInit {

  isEdit: boolean = false;
  districtForm: FormGroup;
  list: District[] = [];
  CountryList: Observable<any[]>;
  divisionList: Observable<any[]>;
  model = new District();

  currentPage = 1;
  itemsPerPage = 10;
  totalItems = 0;
  currentItems = 0;
  searchControl = "";
  showSaveForm:string="saveList";

  constructor(
    private _service: DistrictService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private formBuilder: FormBuilder,
    private shared:SharedService
  ) {

    this.buildForm();
    this.loadCountryList();
  }

  ngOnInit(): void {
    this.getDistrictList();
    this.loadCountryList();
  }

  onChangeForShow(value: any) {
    this.currentPage = 1;
    this.itemsPerPage = value;
    this.getDistrictList();
  }

  onPageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.getDistrictList();
  }

  onSearch() {
    this.currentPage = 1; // Reset the current page to 1 when a new search is performed
    this.getDistrictList();
  }

  getDistrictList() {
    this._service.getAllDistrictWithPaginationSearch(this.currentPage, this.itemsPerPage, this.searchControl).subscribe((res: any) => {
      this.list = res.data;
      this.totalItems = res.totalItems;
      this.currentItems = res.currentItems;
    })
  }

  loadCountryList() {
    this._service.GetAllCountry().subscribe(res => {
      this.CountryList = of(res);
    });
  }

  onCountryChange(cId: any): void {
    if (cId > 0 || cId != null || cId != "") {
      this.getDivision(cId);
    }
  }

  getDivision(cId: number): void {
    this._service.GetDivisionListByCountryId(cId).subscribe(res => {
      this.divisionList = of(res as any[]);
    });
  }

  buildForm() {
    this.districtForm = this.formBuilder.group({
      DistrictCode: ['', Validators.required],
      DistrictName: ['', Validators.required]

    });
  }

  reset(){
    this.model.districtCode =''
      this.model.districtName = '';
      this.model.shortName = '';  
   }

  onsubmit() {
    if (this.model.id===null){
      this.model.id=0;
    }
    this._service.save(this.model).subscribe(res => {
      if(this.isEdit){
        this.shared.ShowAlert("Success", "Updated Successfully", "success");
      }
      else{
        this.shared.ShowAlert("Success", "Save Successfully", "success");
      }
      this.reset();
      this.getDistrictList();
      this.showSaveForm = 'saveList';

    })
  } 

  edit(item: any) {
    this.isEdit = true;
    this.showSaveForm = 'saveForm';
    this.getDivision(item.divisions?.countrysId);
    this.model = {
      id: item.id,
      districtCode: item.districtCode,
      districtName: item.districtName,
      countryId:item.divisions?.countrysId,
      divisionsId:item.divisionsId,
      shortName:item.shortName
    };
  }

  onClickShowSaveForm(value:string){
    this.showSaveForm=value;
    this.refresh();
  }

  delete(id:any){  
    this._service.delete(id).subscribe(res=>{
      this.shared.ShowAlert('Alert','Data deleted successfully.','warning');
      this.getDistrictList();
      this.showSaveForm='saveList';
    })
  }

  refresh() {
    this.isEdit = false;
    this.model={
      id: null,
      districtCode: null,
      districtName: null,
      divisionsId:null,
      countryId:null,
      shortName:null      
    }
  }
}
