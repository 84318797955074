import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardCustomService } from '../dashboard-custom.service';

@Component({
  selector: 'app-customer-dashboard',
  templateUrl: './customer-dashboard.component.html',
  styleUrls: ['./customer-dashboard.component.scss']
})
export class CustomerDashboardComponent implements OnInit {

  UserName:string;

  underProcerssCount: number;
  appliedLoanCount: number;
  approvedLoanCount: number;
  rejectedLoanCount: number;
  finalApproveCount: number;

  constructor(
    private router: Router,
    private _serviceDashboard: DashboardCustomService,
    private changeDetectorRef: ChangeDetectorRef,
  ) 
  { 

  }

  ngOnInit(): void {
    //this.UserName=JSON.parse(localStorage.getItem('userName'));
    //alert(this.UserName)
    this.getloanCountInfo();
    

  }


  getloanCountInfo() {
    this._serviceDashboard.getCountLoanInfo().subscribe({
      next: (res: any) => {

      this.underProcerssCount = res.leadProcess; 
      this.appliedLoanCount = res.appliedLoan; 
      this.approvedLoanCount = res.approvedLoan; 
      this.rejectedLoanCount = res.rejectedLoan; 
      this.finalApproveCount = res.finalApprove; 
      this.changeDetectorRef.detectChanges();
      },
      error: (e) => {
        console.log(e);
      },
      complete: () => {
   
      }
    });

  }

  getDashboardListByType(type : number){
    this.router.navigate(['/dashboard/cutomer-lead/' + type]);
  }

  getEMIPayBillList(){
    this.router.navigate(['/dashboard/emi-amount-pay']);
  }


  onResized(event: any) {
    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }
  fireRefreshEventOnWindow() {
    throw new Error('Method not implemented.');
  }

}
