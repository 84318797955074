import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

const apiUrl = environment.baseUrl;
@Injectable({
  providedIn: 'root'
})
export class BalancetypeService {

  constructor(private http: HttpClient) { }

  getlist(): Observable<any> {
    return this.http.get(apiUrl + '/api/BalanceType/AllBalanceTypeList');
  }

  getlistByProBulderType(id:number): Observable<any> {
    return this.http.get(apiUrl + '/api/BalanceType/GetAllBalanceTypeListByProBuilderType?id='+id);
  }
  getAllCategorylist(): Observable<any> {
    return this.http.get(apiUrl + '/api/BalanceType/AllProductCategoryList');
  }

  getListWithSerchAndPagination(currentPage: any, itemsPerPage: any, searchValue: string) {
    return this.http.get(apiUrl + '/api/BalanceType/GetBalanceTypeWithSerchAndPagination?page=' + currentPage + '&pageSize=' + itemsPerPage + '&search=' + searchValue);
  }

  saveBalancetype(model: any) {
    return this.http.post(apiUrl + '/api/BalanceType/ekyc/SaveUpdateProductBalanceType', model);
  }

  delete(Id: any) {
    return this.http.delete(apiUrl + '/api/BalanceType/BalanceTypeDeleteById?Id=' + Id);
  }
}
