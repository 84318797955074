import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { SharedService } from 'app/services/shared.service';

@Component({
  selector: 'app-provide-info',
  templateUrl: './provide-info.component.html',
  styleUrls: ['./provide-info.component.scss']
})
export class ProvideInfoComponent implements OnInit {

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private shared: SharedService,
  ) { }

  ngOnInit(): void {
  }
  onsubmit() {
    // if (this.model.id === null) {
    //   this.model.id = 0
    // }
    // this._service.save(this.model).subscribe(res => {
    //   if (res != '') {
    //     if (this.isEdit) {
    //       this.shared.ShowAlert('Saved', 'Updated successfully.', 'success');
    //     }
    //     else {
    //       this.shared.ShowAlert('Saved', 'Saved successfully.', 'success');
    //     }
    //    // this.getDivisionList();
        
    //   }
    // })
    // this.changeDetectorRef.detectChanges();
  }
}
