import { Component, OnInit } from '@angular/core';
import { NidVerificationService } from '../nid-verification.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-nid-list',
  templateUrl: './nid-list.component.html',
  styleUrls: ['./nid-list.component.scss']
})
export class NidListComponent implements OnInit {
  nidList: any[] = [];
  nidDetails: any;
  constructor(private _nidVerifyService: NidVerificationService,private modalService: NgbModal,) { }

  ngOnInit(): void {
    this.getAllNIDList();
  }

  getAllNIDList() {
    this._nidVerifyService.getAllNidInformation().subscribe((res) => {
      this.nidList = res;
    });
  }

  fnDetails(idNo:string){
    this._nidVerifyService.getNidInformationByNid(idNo).subscribe((res) => {
      this.nidDetails = res;
    });
  }

}
