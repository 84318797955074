<div class="" style="width: 100%">
  <div class="">
    <div class="container">
      <div class="" style="margin-top: 3%">
        <!-- <h3 class="card-title" style="color: black; text-align: center">
          Role Info
        </h3> -->
      </div>
      <hr style="height: 10%" />
      <!-- <div class="card">
        <div class="card-body">
          <form>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <label for="" class="col-sm-3">Role Name</label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      class="form-control"
                      [(ngModel)]="model.name"
                      name="name"
                      placeholder="Type Role Name to Create"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3"></div>
                  <div class="col-md-9">
                    <button
                      data-toggle="tooltip"
                      (click)="onsubmit()"
                      title="Save"
                      type="button"
                      class="btn btn-success"
                      style="float: right; margin-top: 5px"
                    >
                      Create
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div> -->
      <!-- <div class="card">
    <div class="card-body">
      <div class="container">
        <h5 class="card-title" style="color: black">Role List</h5>
        <hr>
        <table id="example" class="table table-bordered  ">
          <thead>
              <tr>
                <th>SL No.</th>
                <th>Role Name </th>
                <th>Action</th>
              </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of list; let i = index;">
              <td>{{i + 1}}</td>
              <td>{{item.name}}</td>
              <td >
                  <button class="btn btn-primary" (click)="edit(item)"><i class="fa fa-edit 3x"></i></button>
                  <button [disabled]="isEdit" class="btn btn-danger" style="margin-left: 5px;" (click)="delete(item.Id)"><i class="fa fa-trash-o 3x"></i></button>
              </td>
            </tr>
           </tbody>
      </table>
      </div>  
    </div>
  </div> -->

      <div class="card">
        <div class="card-header">
          <h5 class="card-title" style="color: black">Assign Menus</h5>
        </div>
        <div class="card-body">
          <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
            <ngb-panel *ngFor="let item of list">
              <ng-template ngbPanelTitle>
                <span (click)="UpdateUserTypeId(item.id)" style="color: black"
                  >&#9733; <b> {{ item.name }}</b></span>
                <span style="color: rgb(112, 112, 112); position: absolute; right: 0;"><i class="fa fa-arrow-down"></i></span>
              </ng-template>
              <ng-template *ngIf="openTab == item.id" ngbPanelContent>
                <div class="row">
                  <div class="col-md-12 mb-4" style="display: flex; justify-content: center;">
                    <button
                      class="btn btn-warning pull-left" style="border: 1px solid black;"
                      (click)="saveUserMenuAccess()"
                    >
                      Update
                    </button>
                  </div>
                  <div class="col-md-12">
                    <form
                      id="form2"
                      data-parsley-validate
                      class="form-horizontal form-label-left"
                    >
                      <div class="nav-container" style="margin-left: 22%">
                        <ul class="navigation">
                          <!-- First level menu -->
                          <li
                            *ngFor="let menuItem of userMenu"
                            [parent]="menuItem.title"
                            [path]="menuItem.path"
                            level="{{ level + 1 }}"
                          >
                            <input
                              type="checkbox"
                              (change)="onCheckboxChange($event, menuItem.id)"
                              [checked]="menuItem.isActive"
                              name="AllMenuIds"
                              style="
                                width: 17px;
                                height: 17px;
                                margin-right: 2%;
                              "
                            />
                            <span class="menu-title" style="font-size: 18px"
                              ><b>{{ menuItem.title }}</b></span
                            >

                            <!-- Second level menu -->
                            <ul
                              class="menu-content"
                              *ngIf="menuItem.submenu.length > 0"
                            >
                              <li
                                *ngFor="let menuSubItem of menuItem.submenu"
                                [parent]="menuItem.title"
                                level="{{ level + 2 }}"
                              >
                                <input
                                  type="checkbox"
                                  (change)="
                                    onCheckboxChange($event, menuSubItem.id)
                                  "
                                  [checked]="menuSubItem.isActive"
                                  name="AllMenuIds"
                                  style="
                                    width: 17px;
                                    height: 17px;
                                    margin-right: 2%;
                                  "
                                />
                                <span
                                  class="menu-title"
                                  style="font-weight: 600"
                                  >{{ menuSubItem.title }}</span
                                >

                                <!-- Third level menu -->
                                <ul
                                  class="menu-content"
                                  *ngIf="menuSubItem.submenu.length > 0"
                                >
                                  <li
                                    *ngFor="
                                      let menuSubsubItem of menuSubItem.submenu
                                    "
                                    [parent]="menuSubItem.title"
                                    [path]="menuSubsubItem.path"
                                    level="{{ level + 3 }}"
                                  >
                                    <input
                                      type="checkbox"
                                      (change)="
                                        onCheckboxChange(
                                          $event,
                                          menuSubsubItem.id
                                        )
                                      "
                                      [checked]="menuSubsubItem.isActive"
                                      name="AllMenuIds"
                                      style="
                                        width: 17px;
                                        height: 17px;
                                        margin-right: 2%;
                                      "
                                    />
                                    <span class="menu-title">{{
                                      menuSubsubItem.title
                                    }}</span>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </form>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </div>
    </div>
  </div>
</div>
