import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { id } from '@swimlane/ngx-datatable';

const apiUrl = environment.baseUrl;


@Injectable({
  providedIn: 'root'
})
export class DistrictService {


  constructor(private http: HttpClient) { }

  save(model: any) {

    console.log(model);
    //alert(34543)
    return this.http.post(apiUrl + '/api/District/SaveDistrict', model);

  }

  getlist(): Observable<any> {
    return this.http.get(apiUrl + '/api/District/GetAllDistrict');
  }
  getDistrictNamelist(districtName:string): Observable<any> {
 
    return this.http.get<any>(apiUrl + '/api/District/GetAllDistrictByDistrictName?districtName=' + districtName);
     
  }

  getAllDistrictWithPaginationSearch(currentPage: any, itemsPerPage: any, searchValue: string) {
    return this.http.get(apiUrl + '/api/District/GetAllDistrictWithPaginationSearch?page=' + currentPage + '&pageSize=' + itemsPerPage + '&search=' + searchValue);
  }
  GetAllCountry(): Observable<any> {
    return this.http.get(apiUrl + '/api/Country/GetAllCountry');
  }
  GetDivisionListByCountryId(cId: number) {

    return this.http.get(apiUrl + '/api/Division/GetDivisionListByCountryId?cId=' + cId);
  }
  delete(id:any){

    return this.http.delete(apiUrl + '/api/District/Delete/' + id);
  }

  getDistrictByDivisionId(divId:number){
    return this.http.get(apiUrl+ '/api/District/GetDistrictsByDivisionId?divId=' + divId);
  }
  getDistrictByDivisionId2(divId:number){
    return this.http.get(apiUrl+ '/api/District/GetDistrictsByDivisionId?divId=' + divId);
  }

}
