<section>
    <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveForm'">
        <div class="card-body" >
          <div class="container">
            
              <h5 class="card-title" style="color: black;text-align: center;" >Cash Approval</h5>
              <button type="button" data-toggle="tooltip" title="Add new"  class="btn btn-secondary" (click)="onClickShowSaveForm('saveList')" style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">List View</button>
              <hr>
            
           
         
                <form [formGroup]="frmGroup">
                   <div asp-validation-summary="All" class="text-danger"></div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label for="" class="col-sm-4 ">Min. Amount</label>
                          <div class="col-sm-8">
                            <input type="number" formControlName="minAmount" class="form-control"  placeholder="Min. Amount" readonly>
                          </div>
                        </div>
    
                        <div class="form-group row">
                            <label for="" class="col-sm-4 col-form-label">Status</label>
                            <div class="col-sm-8">
                                <select  class="form-control" formControlName="isActive">
                                  <option value="">Select</option>
                                  <option value="1">Active</option>
                                  <option value="0">Inactive</option>
                                </select>
                            </div>
                        </div>
                        
                      </div> 
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label for="" class="col-sm-4 col-form-label">Max. Amount</label>
                          <div class="col-sm-8">
                            <input type="number" class="form-control" formControlName="maxAmount" placeholder="Max. Amount">
                          </div>
                        </div>
    
                       
                      </div>
    
                    </div>
                   
                </form>
          </div>
        </div>
    
        <div class="card-body">
            <div class="container">
              <h5 class="card-title" style="color: black">Add Approver </h5>
              <hr>     
              <div class="row">
                <div class="col-md-6">
                  
    
    
                  <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Approver Name</label>
                    <div class="col-sm-8">
                      
                      <select  class="form-control" [(ngModel)]="approverId">
                          <option value="">Select</option>
                          <option *ngFor="let item of userlist"  [value]="item.userId">{{item.userName}} -{{item.fullName}}</option>
                      </select>
    
                    </div>
                  </div>
    
                  <!-- <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Status</label>
                    <div class="col-sm-8">
                        <select class="form-control" [(ngModel)]="isActivedetails">
                          <option value="1">Active</option>
                          <option value="0">Inactive</option>
                        </select>
                    </div>
                  </div> -->
                </div>
               
                <div class="col-md-2">
                  <button type="button" (click)="addApprover()" data-toggle="tooltip" title="Add new" class="btn btn-primary">Add</button> 
                </div>
              </div>
    
    
            </div>     
           
            <div class="container">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Sl.</th>
                    <th>Approver Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr  *ngFor="let data of approvalDetails; let i = index">
                    <td>{{ i+1 }}</td>
                    <!-- <td style="text-align: center;">{{ data.approverId }}</td> -->
                    <td style="text-align: left;"> 
                      {{data.approverName}}
                      <input type="hidden" class="form-control" value="{{data.approverId}}">
                    </td>
                  
                 
                    <td>
                      <button type="button" class="btn btn-info  mr-2" (click)="editApprover(i)">Edit</button>
                      <button type="button" class="btn btn-danger" (click)="deleteApprovar(i)">Delete</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="form-group">
            <button type="button" data-toggle="tooltip" title="Submit" (click)="onSubmit()" class="btn btn-secondary" style="float:right; margin-top:-31px;margin-right:38px;margin-left: 8px;">Submit</button>
    
          </div>
    </div>
    
    
    
    
    
    
       <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveList'">
        <div class="card-body">
            <div class="container">
              <h5 class="card-title" style="color: black">Cash Approval List</h5>
              <button type="button" data-toggle="tooltip" title="Add new"  class="btn btn-secondary" (click)="onClickShowSaveForm('saveForm')" style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">Add New</button>
              <hr>
    
              <!-- <div class="row">
                <div class="col-md-6" style="text-align: left">
                  <select type="number" (change)="onChangeForShow($event.target.value)" class="form-control" style="width:16%">
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option selected value="5">5</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div class="form-group col-md-6" style="text-align: right">
                  <input type="text" class="form-control" [(ngModel)]="searchControl" placeholder="Search" (keyup)="onSearch()">
                </div>
              </div> -->
    
              <table id="example" class="table table-striped table-bordered" style="width:100%">
                <thead>
                  <tr>
                    <th style="width: 10%;">SL No.</th>
                      <th>Min. Amount</th>
                      <th>Max. Amount</th>
                     <th>Approver Detail </th>
                      <th>Action</th>
                  </tr>
                </thead>
                <!-- *ngFor="let item of list : { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }; let i = index" -->
                <tbody>
                  <!-- <tr *ngFor="let master of list; let i = index"> -->
                  <tr *ngFor="let item of masterDetailslist; let i = index">
                  <!-- <td>{{ (currentPage - 1) * itemsPerPage + i + 1 }}</td> -->
                  <td>{{ i + 1 }}</td>
                    <!-- <td>{{item.taxKey.taxCode}} - {{item.taxKey.taxKeyName}}</td> -->
    
                    <td>{{item.cashApproverMaster.minAmount}}</td>
                    <td>{{item.cashApproverMaster.maxAmount}}</td>
    
                    <td>
                      <ul>
                        <li *ngFor="let detail of item.cashApproverDetails">
                          {{detail.approver.fullName}}
                          ({{detail.approver.userName}} )</li>
                      </ul>
                    </td> 
                   
                    <td>
                      <button class="btn btn-primary" (click)="edit(item)">
                        <i class="fa fa-edit 3x"></i>
                      </button>
                      <button class="btn btn-danger" style="margin-left: 5px;" (click)="delete(item.id)">
                        <i class="fa fa-trash-o 3x"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
            </table>
    
            <!-- <div class="row">
              <div class="col-md-6" style="text-align: left">
                <span>Showing {{currentItems}} of {{totalItems}} entries</span>
              </div>
              <div class="col-md-6" style="text-align: right">
                <pagination-controls (pageChange)="onPageChange($event)">
                </pagination-controls>
              </div>
            </div> -->
    
            </div>  
          </div>
      </div>  
</section>
