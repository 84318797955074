<div class="card" style="width: 100%;">
    <div class="card-body">
      <div class="container">
        <h5 class="card-title" style="color: black">Pending Voucher List</h5>
  
        <hr>
        <table id="example" class="table table-striped table-bordered" style="width:100%">
          <thead>
            <tr>
              <th style="width: 10%;">SL No.</th>
              <th>Voucher No </th>
              <th>Voucher Amount </th>
              <th>Voucher Date </th>
              <th>Voucher Type </th>
              <th>Actions </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of voucherMaster; let i = index">
              <td>{{i + 1}}</td>
              <td>{{item.voucherMaster.voucherNo}}</td>
              <td>{{item.voucherMaster.voucherAmount}}</td>
              <td>{{item.voucherMaster.voucherDate | date:'yyyy-MM-dd'}}</td>
              <td>{{item.voucherMaster.voucherType?.voucherTypeName}}</td>
  
  
              <td>
              
  
                <button class="btn btn-info btn-sm mb-1" style="margin-left: 5px;"
                  (click)="openVoucherDetailsModal(voucherDetails)" (click)="getVoucherDetails(item.voucherMaster.id)">Details</button>
                <!-- <button class="btn btn-success btn-sm mb-1" style="margin-left: 5px;">Approve</button> -->
                <button class="btn btn-success btn-sm mb-1" style="margin-left: 5px;"
                  (click)="ApproveVoucher(item.voucherMaster.id)">Approve</button>


              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  
  
  
  <ng-template #voucherDetails let-c="close" let-d="dismiss">
    <div class="modal-dialog">
  
      <div class="modal-content" style="margin-left: -50%; width: 200%;">
        <div class="modal-header">
          <h4 class="modal-title">Voucher Details</h4>
          <button type="button" class="close" style="size: 150%;" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
  
  
        <table id="example" class="table table-striped table-bordered" style="width:100%">
          <thead>
            <tr>
              <th style="width: 10%;">SL No.</th>
              <th> Amount </th>
              <th>Transection Mode </th>
              <th>GL </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of voucherDetailsInfo; let ii = index">
              <td>{{ii + 1}}</td>
              <td>{{data.amount}}</td>
              <td>{{data.transectionMode.modeName}}</td>
              <td>{{data.ledgerRelation.ledger.accountName}}</td>
            </tr>
          </tbody>
        </table>
  
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
        </div>
      </div>
    </div>
  </ng-template>