
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LeadInfoService } from '../lead-info.service';
import { DatePipe } from '@angular/common';
import { environment } from 'environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardCustomService } from '../dashboard-custom.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';
@Component({
  selector: 'app-emi-pay-bill',
  templateUrl: './emi-pay-bill.component.html',
  styleUrls: ['./emi-pay-bill.component.scss']
})
export class EMIPayBillComponent implements OnInit {

  LeadList:any []=[];

  type:number=0;
  statusId:number=0;
  closeResult: string;
  frmGroupSearch: FormGroup;
  constructor(
    //private datePipe: DatePipe,
    private _leadService:LeadInfoService,
    private _serviceDashboard: DashboardCustomService,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {

    this.route.params.subscribe(params=>{
      this.type=params['parameter']
    });
    this.type;

    //this.getLeadList();
    this.getCustomerLeadList();
  }

  goDashboard(){
    this.router.navigate(['/dashboard/customer-dashboard'])
  }

  getCustomerLeadList(){
    this._serviceDashboard.getListForCustomerDashboard(this.type).subscribe((res: any)=>{
      
      this.LeadList=res;
      this.changeDetectorRef.detectChanges;
    });
  }

  goLoanForm(leadId: any,customersId:any,loanProductsId:any){
     
   
    this.router.navigate(['/loan-application/loan-application/'+ leadId + '/'+ customersId + '/'+  loanProductsId]);
  }

  openpayAmount(leadId){
    this.router.navigate(['/dashboard/emi-amount-pay/'+leadId])
  }

  openCollection(leadId){
    this.router.navigate(['/dashboard/collection-detls/'+leadId])
  }

 

}
