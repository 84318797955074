
<br>
<h4 class="card-title" style="color: black">Daily Interest Process</h4>
<hr>
<div class="card">
    <div class="card-body">
        <div class="form-group row">
            <label for="dateInput" class="col-md-2">From Date:</label>
            <div class="col-md-2 input-group">
                <input type="date" [min]="businessDate"  [(ngModel)]="fromDate" class="form-control" > 
            </div>

            <label for="dateInput" class="col-md-2">To Date:</label>
            <div class="col-md-2 input-group">
                <input type="date" [min]="businessDate" [(ngModel)]="toDate" class="form-control">
            </div>

           <label for="type" class="col-md-1">Type:</label>
            <div class="col-2">
                <select type="text" class="form-control"  [(ngModel)]="types">
                    <option value="Deposit">Deposit</option>
                    <option value="FDR">FDR</option>
                    <option value="DPS">DPS</option>
                    <option value="Loan">Loan</option>
                </select>
            </div>
         
        </div>
        <div class="form-group row">
            
            <div class="col-md-9 input-group">
              
            </div>

       
            

            <div class="col-3">
                <button type="button" (click)="InterestProcess()" class="btn btn-primary" id="processButton">Process</button>
            </div>
         
        </div>

    </div>
</div>