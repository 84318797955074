import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { OccupationComponent } from '../basic-component/occupation/occupation/occupation.component';
import { CountryInfoComponent } from '../basic-component/country-info/country-info.component';
import { IncomesourceComponent } from '../basic-component/incomesource/incomesource.component';
import { DivisionComponent } from '../basic-component/division/division.component';

import { DistrictComponent } from '../basic-component/district/district.component';
import { UpazilaComponent } from '../basic-component/upazila/upazila.component';
import { FiscalyearComponent } from '../basic-component/fiscalyear/fiscalyear.component';
import { CountryListComponent } from '../basic-component/country-list/country-list.component';
import { RelationComponent } from '../basic-component/relation/relation.component';

const routes: Routes = [
  {path: "occupation",component: OccupationComponent,data: {title: "occupation",},},
  {path: "incomesource",component: IncomesourceComponent,data: {title: "Income-Source",},},
  {path: "country",component: CountryInfoComponent,data: {title: "Country",},},
  {path: "country-list",component: CountryListComponent,data: {title: "Country",},},
  {path: "division",component: DivisionComponent,data: {title: "Division",},},
  {path: "district",component: DistrictComponent,data: {title: "District",},},
  {path: "upazila",component: UpazilaComponent,data: {title: "Upazila",},},
  {path: "fiscalyear",component: FiscalyearComponent,data: {title: "FiscalYear",},},
  {path: "relation",component: RelationComponent,data: {title: "Relation",},},

];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BasicRoutingModule { }
