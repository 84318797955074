import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NidVerifyComponent } from './nid-verify/nid-verify.component';
import { OtpVerifyComponent } from './otp-verify/otp-verify.component';
import { CustomercreateComponent } from 'app/customer/customercreate/customercreate.component';
import { AutoRegistrationComponent } from './auto-registration/auto-registration.component';


const routes: Routes = [
  {path: '', children: [{ path: 'otp-verify',component: OtpVerifyComponent,data: { title: 'OTP Verify'}},] },
  {path: '', children: [{ path: 'nid-verify/:parameter',component: NidVerifyComponent,data: { title: 'NID Verify'}},] },
  {path:'',children:[{path:'customercreate/:parameter/:parameter1',component:CustomercreateComponent,data:{title:'Customer Create'}}]},
  { path: '', children: [{ path: 'auto-registration', component: AutoRegistrationComponent, data: { title:'auto-registration'}}]}
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EKycRoutingModule { }
