<div class="card">

  <div class="row card-header  d-flex flex-row align-items-center">
    <div class="col-9">
      <h5 class="card-title" style="color: black">Bank Beneficiary</h5>
    </div>
    <div class="col-2">
      <button type="button" data-toggle="tooltip" class="btn btn-secondary" routerLink="/score/bank-beneficiar-list"
        style="float:right;margin-right:5px;margin-left: 8px;">List View</button>
    </div>
    <div class="col-1">
      <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">

        <i class="icon-arrow-left" style="font-size: 17px;"> </i>
      </button>
    </div>
  </div>

  <hr style="height: 10%;">

  <div class="card" style="width: 100%;">
    <div class="card-body">
      <form [formGroup]="bankForm">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group row">
              <label for="" class="col-sm-4 col-form-label">Bank Name</label>
              <div class="col-sm-8">
                  <!--   <select class="form-control" formControlName="bankInfoId" placeholder="" value="selectedBankName"
                      [(ngModel)]="model.bankInfoId" (change)="GetAllBankBranchByBankId($event.target.value)"> 
                      <ng-option value="">Select</ng-option> 
                      <ng-container *ngFor="let item of bankInfoList">
                        <ng-option [value]="item.id">{{item.bankName}}</ng-option>
                      </ng-container>
                   </ng-select> -->
                <ng-select class="form-control"
                [placeholder]="model.bankInfoId ? '' : 'Select Bank Name'"
                   formControlName="bankInfoId" 
                  readonly style="cursor: pointer;" 
                 [items]="bankInfoList"
                 bindValue="id"
                  bindLabel="bankName"
                  [(ngModel)]="model.bankInfoId"  
                  (change)="GetAllBankBranchByBankId($event)" [searchable]="true">
                  <ng-container *ngFor="let item of bankInfoList">
                    <ng-option [value]="item">{{item.bankName}}</ng-option>
                  </ng-container>
                </ng-select>


              </div>
            </div>
            <div class="form-group row">
              <label for="" class="col-sm-4 col-form-label">Branch Name</label>
              <div class="col-sm-8">
                <select class="form-control" formControlName="branchId" placeholder="">
                  <option value="">Select</option>
                  <ng-container *ngFor="let item of branchList">
                    <option [value]="item.id">{{item.branchNameEn}}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <!-- <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Ledger Relation</label>
                                    <div class="col-sm-8">
                                      <select class="form-control" formControlName="ledgerRelationId" placeholder="" [(ngModel)]="model.ledgerRelationId">
                                        <option value="">Select</option>
                                        <ng-container *ngFor="let item of list">
                                          <option [value]="item.id">{{item.ledger.accountName}}</option>
                                        </ng-container>
                                      </select>
                                    </div>
                                  </div> -->

            <div class="form-group row" *ngIf="model.bankInfoId != 1">
              <label for="" class="col-sm-4">Account Number </label>
              <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="AccountNumber"
                  [(ngModel)]="model.AccountNumber" placeholder="">




              </div>
            </div>

            <div class="form-group row" *ngIf="model.bankInfoId == 1">
              <label for="" class="col-sm-4">Account Number </label>
              <div class="col-sm-8">
                <div class="input-group">
                  <input type="text" class="form-control" formControlName="AccountNumber"
                    [(ngModel)]="model.AccountNumber" placeholder="">


                  <div class="input-group-append">
                    <button class="btn btn-primary btn-sm"
                      (click)="loadUserDataByAccountNoCashPayment(model.AccountNumber)">Find</button>
                  </div>
                </div>


              </div>
            </div>


            <div class="form-group row">
              <label for="" class="col-sm-4">Account Name</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="AccountName" [(ngModel)]="model.AccountName"
                  value="{{AccountName}}">

                <input type="hidden" formControlName="customerAccountId" [(ngModel)]="model.customerAccountId"
                  value="{{customerAccountId}}">
              </div>
            </div>
            <div class="form-group row">
              <label for="status" class="col-sm-4 col-form-label"> Status</label>
              <div class="col-sm-8">
                <select class="form-control" formControlName="status">
                  <option value="">Select</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <button data-toggle="tooltip" [disabled]="!bankForm.valid" (click)="onsubmit()" title="Save" type="button"
          class="btn btn-primary" style="float:right; margin-top:5px;">Save </button>
      </form>
    </div>
  </div>

</div>