import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ScoretagService } from '../scoretag.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'app/services/shared.service';
import { Score } from '../score-model/score';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { id } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-score-create',
  templateUrl: './score-create.component.html',
  styleUrls: ['./score-create.component.scss']
})
export class ScoreCreateComponent implements OnInit {

  model = new Score();
  scoreTagForm: FormGroup;
  isEdit: boolean = false;

  id:number;
  tagName:any;
  tagPattern:any;

  constructor(
    private _scoreService : ScoretagService,
    private router: Router,
    private shared: SharedService ,
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private activeRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.activeRoute.params.subscribe(params=>{
      this.id=params['id'];
    })
    this.buildForm();
    this.getScoreTag();

  }

  onsubmit(){
    
    this.model.id = this.id; 
    this.model.tagName = this.scoreTagForm.value.tagName;
    this.model.tagPattern = this.scoreTagForm.value.tagPattern;

      this._scoreService.save(this.model).subscribe(data=>{
        console.log(data);
        this.shared.ShowAlert("Saved", "Save Successfully", "success");
        this.reset();
        this.router.navigate(['/score/score-list']);
      })
      
    this.changeDetectorRef.detectChanges();
    this.getScoreTag();
  }

  reset(){
    this.model.tagName = '';
    this.model.tagPattern = '';
  }

  buildForm() {
    this.scoreTagForm = this.formBuilder.group({
      id:[0],
      tagName: ['', Validators.required],
      tagPattern: ['', Validators.required]
    });
  } 

  getScoreTag() {
    this.activeRoute.paramMap.subscribe(params => {
      const SID = +params.get('id');
      //this.id = +params.get('id');
      if (SID != null) {
        this.isEdit = true;
        this._scoreService.GetScoreTagById(SID).subscribe(
          (res: any) => {            
            this.scoreTagForm.patchValue({
              id: res.id,
              tagName: res.tagName,
              tagPattern: res.tagPattern
            })
            
          },

          (error: any) => {
            this.shared.ShowAlert('Error', 'Failed to retrieve.', 'error');
          }
        );
      }
    });
  }

}
