<div class="card">
    <div class="card-body">
        <div class="container">
            <div class="card" style="width: 100%;">
                <div class="" style="margin-top: 3%;">
                    <button type="button" data-toggle="tooltip" class="btn btn-secondary" routerLink="/score/score-list"
                        style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">List View</button>
                </div>
                <div class="card-body">
                    <form [formGroup]="scoreTagForm">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4">Tag Name</label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" name="tagName"
                                            [(ngModel)]="model.tagName" placeholder="Tag Name" value="{{tagName}}">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="" class="col-sm-4">Tag Pattern</label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" name="tagPattern"
                                            [(ngModel)]="model.tagPattern" placeholder="Tag Pattern" value="{{tagPattern}}">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button data-toggle="tooltip" [disabled]="!scoreTagForm.valid" (click)="onsubmit()" title="Save"
                            type="button" class="btn btn-success" style="float:right; margin-top:5px;">Save </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>