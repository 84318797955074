import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

const apiUrl = environment.baseUrl;
@Injectable({
  providedIn: 'root'
})
export class NavbarsService {

  constructor(private http: HttpClient) { }


  getList(): Observable<any> {
    
    return this.http.get(apiUrl + '/api/User/GetNavbarListInfo');
   }
   getAllUserType(): Observable<any> {
    
    return this.http.get(apiUrl + '/api/User/GetAllUserType');
   }
   save(model: any){
     return this.http.post(apiUrl +'/api/User/SaveUpdateNavbar', model);
  }

   
   delete(id: any){
     return this.http.delete(apiUrl + '/api/User/DeleteNavbar/'+ id );
  }

  getParentListInfo(): Observable<any> {
    return this.http.get(apiUrl + '/api/User/GetNaverParentList');
   }

   //RoleAssign
   getRoleListInfo(): Observable<any> {
    
    return this.http.get(apiUrl + '/api/User/GetAllUserRole');
   }

   getUserMenuAccess(userRoleId:any): Observable<any> {
  
    //return this.http.get(apiUrl + '/api/User/GetUserPageAssignList/\'' + userRoleId + '\'');
    return this.http.get(apiUrl + '/api/User/GetUserPageAssignList/' + userRoleId );
   }

   getAllUserMenu(): Observable<any> {
  
    return this.http.get(apiUrl + '/api/User/getAllUserMenu' );
   }

/////////
  //  getNavbars(): Observable<any>{
  //   return this.http.get(apiUrl + '/api/User/GetNavbar');
  // }

  
  GetUserActiveNavbarList(userRoleId:string,userId: string): Observable<any> {
  
    // return this.http.get(apiUrl + '/api/User/GetUserActiveNavbarList/' + userRoleId );
    return this.http.get(apiUrl + '/api/User/GetUserActiveNavbarList/' + userRoleId+'/'+ userId);
   }

   GetUserInActiveNavbarList(userRoleId:any,userId: string): Observable<any> {
  
    return this.http.get(apiUrl + '/api/User/GetUserInActiveNavbarList/' + userRoleId +'/'+ userId );
   }


  saveUserAssign(userTypeId: string, selectedMenuIds: number[]): Observable<any> {
    const data = {
      UserTypeIds: userTypeId,
      AllMenuIds: selectedMenuIds
    };

  
    return this.http.post(apiUrl + '/api/User/SaveUserPageAssign', data);
  }

  CreateRoleWithUserAssign(userTypeId: string, selectedMenuIds: number[], roleName:string): Observable<any> {
    const data = {
      UserTypeIds: userTypeId,
      AllMenuIds: selectedMenuIds,
      RoleName: roleName
    };

  
    return this.http.post(apiUrl + '/api/User/CreateRoleWithUserPageAssign', data);
  }


  saveUserExceptionLog(userId: string, userTypeId: string, selectedMenuIds: number[]): Observable<any> {
    const data = {
      userIds: userId,
      UserTypeIds: userTypeId,
      AllMenuIds: selectedMenuIds
    };

    return this.http.post(apiUrl + '/api/User/SaveExciseDutyDetails', data);
  }


  saveInactiveUserExceptionLog(userId: string, userTypeId: string, selectedMenuIds: number[]): Observable<any> {
    const data = {
      userIds: userId,
      UserTypeIds: userTypeId,
      AllMenuIds: selectedMenuIds
    };

    return this.http.post(apiUrl + '/api/User/SaveInactiveExciseDutyDetails', data);
  }



//#region 
getUserListInfo(): Observable<any> {
    
  return this.http.get(apiUrl + '/api/User/GetAllActiveUser');
 }

 getUserRoalByUserId(userId:any): Observable<any> {
  
    return this.http.get(apiUrl + '/api/User/GetAllUserRoleByUserId/' + userId );
 }
 

//#endregion

//#region 

GetLoanMenuSetup():Observable<any>{
  return this.http.get(apiUrl+ '/api/LoanMenuSetup/GetLoanMenuSetup');
}
GetLoanType():Observable<any>{
  return this.http.get(apiUrl+ '/api/LoanMenuSetup/GetLoanType');
}
GetUserAllRolesByUserName():Observable<any>{
  return this.http.get(apiUrl+ '/api/LoanMenuSetup/GetUserAllRolesByUserName');
}
SaveLoanMenuSetup(model:any){
  //alert(0)
  return this.http.post(apiUrl + '/api/LoanMenuSetup/SaveLoanMenuSetup', model);
}
DeleteLoanMenuSetup(id:any){
  //alert(55);
  return this.http.get(apiUrl + '/api/LoanMenuSetup/DeleteLoanMenuSetup/' +id);
}
//#endregion

}
