import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NavbarsComponent } from './navbars/navbars.component';
import { UserroleassignComponent } from './userroleassign/userroleassign.component';
import { UserExceptionLogComponent } from './user-exception-log/user-exception-log.component';
import { LoanMenuSetupComponent } from './loan-menu-setup/loan-menu-setup.component';
import { AuthGuard } from 'app/shared/auth/auth-guard.service';


const routes: Routes = [

  {
    path: "navbars",
    component: NavbarsComponent,
    data: {
      title: "Navbars",
    },canActivate:[AuthGuard]
  },

  {
    path: "userAssign",
    component:UserroleassignComponent ,
    data: {
      title: "User Assign",
    },canActivate:[AuthGuard]
  },
  {
    path: "userExceptionLog",
    component:UserExceptionLogComponent ,
    data: {
      title: "User Exception Log",
    },canActivate:[AuthGuard]
  },
  {
    path: "loan-menu-setup",
    component:LoanMenuSetupComponent ,
    data: {
      title: "loan menu setup",
    },canActivate:[AuthGuard]
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NavbarsRoutingModule { }
