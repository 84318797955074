<div class="row">
    <hr>
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-10">
                        <h5 class="card-title" style="color: #888888;font-family: Arial;font-size: 20px !important;">Day Book</h5>
                    </div>
                
                    <div class="col-2">
                            <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
                        
                                <i class="icon-arrow-left" style="font-size: 17px;"> </i>
                            </button>
                   </div>
                </div>
<hr>
            </div>
      
            <div class="card-body">
                <div class="col-md-12">
                   
                    <div class="form-group row" id="DateRangeSelect">
                        <label for="fromDate" class="col-sm-3 col-form-label text-left">From Date <span class="redStar">*</span></label>
                        <div class="col-sm-3 input-group">
                           
                                <input type="date" class="form-control" [(ngModel)]="fromDate" placeholder="Select From Date">
                        </div>
                        <label for="toDate" class="col-sm-2 col-form-label text-left">To Date <span class="redStar">*</span></label>
                        <div class="col-sm-4 input-group">
                            <input type="date" class="form-control" [(ngModel)]="toDate" placeholder="Select From Date">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Voucher Type</label>
                        <div class="col-sm-9">
                            <select type="text" class="form-control"  [(ngModel)]="reportBy">
                                <option value="0">All</option>
                                <option value="1">Received</option>
                                <option value="2">Payment</option>
                                <option value="3">Journal</option>
                                <option value="4">Contra</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group row" style="margin-top:10px;">
                        <label class="col-sm-8"></label>
                        <div class="col-sm-4">
                            <!-- <button id="SubmitButton" style="float:right" class="btn btn-success btn-xs" title="Preview">Print</button> -->
                            <button  class="btn btn-success" style="margin-left: 5px; float: right;" (click)="OpenPDF()">Print</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<br />