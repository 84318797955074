import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChartsRoutingModule } from './charts-routing.module';
//mport { ChartjsComponent } from './chartjs/chartjs.component';


@NgModule({
  declarations: [
   // ChartjsComponent
  ],
  imports: [
    CommonModule,
    ChartsRoutingModule
  ]
})
export class ChartsModule { }
