
<div class="card" style="width: 100%;">
    <div class="card-body">
      <div class="container">
        <div class="card" style="width: 100%;">
          <div class="" style="margin-top: 3%;">
            <h3 class="card-title" style="color: black;text-align: center;">Provide Your Info</h3>
          </div>
          <div class="card-body">
            <form>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group row">
                    <label for="" class="col-sm-4 ">Age</label>
                    <div class="col-sm-8">
                        <select class="form-control" name="CountrysId" placeholder="Do You have any loan now?" >
                            <option value="">Select</option>
                            <option value="">18-28</option>
                            <option value="">29-38</option>
                            <ng-container>
                              <option></option>
                            </ng-container>
                          </select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Credit Cards Holder</label>
                    <div class="col-sm-8">
                      <select class="form-control" name="CountrysId" placeholder="" >

                            <option value="">Do You have any loan now?</option>
                            <option value="">Yes</option>
                            <option value="">No</option>
                            <ng-container>
                              <option></option>
                            </ng-container>
                          </select>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label"> Current Loan Status</label>
                    <div class="col-sm-8">
                     
                        <select class="form-control" name="CountrysId" placeholder="Do You have any loan now?" >
                            <option value="">Do You have any credit cards?</option>
                            <option value="">Yes</option>
                            <option value="">No</option>
                           
                            <ng-container>
                              <option></option>
                            </ng-container>
                          </select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Gender</label>
                    <div class="col-sm-8">
                        <select class="form-control" name="CountrysId" placeholder="Do You have any loan now?" >
                            <option value="">Select</option>
                            <option value="">Male</option>
                            <option value="">Female</option>
                            <option value="">Other</option>
                            <ng-container>
                              <option></option>
                            </ng-container>
                          </select>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="" class="col-sm-4 col-form-label">Marital Status</label>
                        <div class="col-sm-8">
                            <select class="form-control" name="CountrysId" placeholder="Do You have any loan now?" >
                                <option value="">Select</option>
                                <option value="">Married</option>
                                <option value="">Unmarried</option>
                                <option value="">Divorced</option>
                                <option value="">Widowed</option>
                                <ng-container>
                                  <option></option>
                                </ng-container>
                              </select>
                        </div>
                      </div>
                    <div class="form-group row">
                      <label for="" class="col-sm-4 col-form-label">Residence Location</label>
                      <div class="col-sm-8">
                          <select class="form-control" name="CountrysId" placeholder="Do You have any loan now?" >
                              <option value="">Select</option>
                              <ng-container>
                                <option></option>
                              </ng-container>
                            </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group row">
                        <label for="" class="col-sm-4 col-form-label"> NUMBER OF FAMILY MEMBERS</label>
                        <div class="col-sm-8">
                            <select class="form-control" name="CountrysId" placeholder="Do You have any loan now?" >
                                <option value="">Select</option>
                                <ng-container>
                                  <option></option>
                                </ng-container>
                              </select>
                        </div>
                      </div>
                    <div class="form-group row">
                      <label for="" class="col-sm-4 col-form-label">Residence Ownership</label>
                      <div class="col-sm-8">
                          <select class="form-control" name="CountrysId" placeholder="Do You have any loan now?" >
                              <option value="">Select</option>
                              <ng-container>
                                <option></option>
                              </ng-container>
                            </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group row">
                        <label for="" class="col-sm-4 col-form-label">Duration of Residency(years)</label>
                        <div class="col-sm-8">
                            <select class="form-control" name="CountrysId" placeholder="Do You have any loan now?" >
                                <option value="">Select</option>
                               
                                <ng-container>
                                  <option></option>
                                </ng-container>
                              </select>
                        </div>
                      </div>
                    <div class="form-group row">
                      <label for="" class="col-sm-4 col-form-label">WALLET ACCOUNT(E.G.BKASH/NAGAD/ROCKET ETC)</label>
                      <div class="col-sm-8">
                          <select class="form-control" name="CountrysId" placeholder="Do You have any loan now?" >
                              <option value="">Select</option>
                              <option value="">Yes</option>
                              <option value="">No</option>
                              <ng-container>
                                <option></option>
                              </ng-container>
                            </select>
                      </div>
                    </div>
                    <div class="form-group row">
                        <label for="" class="col-sm-4 col-form-label">Profession</label>
                        <div class="col-sm-8">
                            <select class="form-control" name="CountrysId" placeholder="Do You have any loan now?" >
                                <option value="">Select</option>
                                <option value="">Yes</option>
                                <option value="">No</option>
                                <ng-container>
                                  <option></option>
                                </ng-container>
                              </select>
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group row">
                        <label for="" class="col-sm-4 col-form-label">BANK ACCOUNT</label>
                        <div class="col-sm-8">
                            <select class="form-control" name="CountrysId" placeholder="Do You have any loan now?" >
                                <option value="">Select</option>
                                <option value="">Yes</option>
                                <option value="">No</option>
                                <ng-container>
                                  <option></option>
                                </ng-container>
                              </select>
                        </div>
                      </div>
                    <div class="form-group row">
                      <label for="" class="col-sm-4 col-form-label">EDUCATION LEVEL</label>
                      <div class="col-sm-8">
                          <select class="form-control" name="CountrysId" placeholder="Do You have any loan now?" >
                              <option value="">Select</option>
                              <ng-container>
                                <option></option>
                              </ng-container>
                            </select>
                      </div>
                    </div>
                    
              </div>
              <div class="col-md-6"> 
          </div>
           </div>
              <button data-toggle="tooltip" (click)="onsubmit()" title="Save" type="button" class="btn btn-success"
                style="float:right; margin-top:5px;">Save </button>
  
        </form>
          </div>
  
  
        </div>
        </div>
        </div>
</div>
