import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

const apiUrl=environment.baseUrl + '/api/Accured/'

@Injectable({
  providedIn: 'root'
})
export class AccuredService {

  constructor(private http:HttpClient) { }

  getAccuredList():Observable<any>{
    return this.http.get(apiUrl+ 'GetAllAccuredList')
  }

  getAccuredById(id:number){
    return this.http.get(apiUrl + 'GetAccuredById/' + id)
  }

  saveAccured(model:any){
    return this.http.post(apiUrl + 'SaveAccured',model)
  }

  deleteAccured(id:any){
    return this.http.delete(apiUrl + 'DeleteAccured/' + id)
  }

}
