
<div class="card" style="width: 100%;">
    <div class="card-header py-3 d-flex flex-row align-items-center">
      <h6 class="m-0 font-weight-bold text-primary">Applied Loan List</h6>
     

    </div>
    
<div class="card-body">
            <div class="container">
             
              <table id="example" class="table table-striped table-bordered" style="width:100%">
                <thead>
                    <tr>
                      <th>SL No.</th>
                      <th>Lead Code</th>
                        <th>Customer</th>
                      
                        <!-- <th>Product</th>
                        <th>Loan Amount</th> -->
                       <!--  <th>From</th>
                        
                        <th>Applied On</th> -->
                        <th>Status</th>
                        <!-- <th style="width: 15%;text-align: center;">Action</th> -->
                    </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of LeadLists; let i = index;">
                    <td>{{i + 1}}</td>
                    <td>{{item.leadCode}}</td>
                 <td><span>Name:{{item.clientInfo?.nameEnglish}}</span> <br>
                  <span>Mobile No:{{item.clientInfo?.mobileHome}} </span><br>
                  <!-- <span>NID No:{{item.clientInfo?.NIDNo}}</span> <br> -->
                  <!-- <span>Date Of Birth:{{formatDate(item.clientInfo?.dateOfBirth)}}</span></td> -->
               
                    <!-- <td>{{item.loanProduct?.productName}}</td>
                    <td>{{item.loanRegisterMaster?.loanAmount}}</td>  -->
                
                    <td>
                      <div class="badge badge-pill"
                      [ngClass]="{'bg-light-primary': item.isComplete == 0,
                       'bg-light-success':item.isComplete == 1 && item.status != 2, 
                       'bg-light-danger':item.isComplete == 1 && item.status == 2 , 
                       'bg-light-warning':item.isComplete == 4 , 
                       'bg-light-info':item.isComplete == 2}">

                      {{ item.isComplete == 0 ? "Ongoing"  : 
                      (item.isComplete == 1 && item.status != 2 ? "Send For Aproval" : 
                      (item.isComplete == 1 && item.status == 2? "Pendding For Aproval" :
                      (item.isComplete == 3 ? item.remarks: 
                       item.isComplete == 2 ? "Approved" : 
                       "Pendding For Forward"))) }}
                      </div>
                    </td>
                    <!-- <td style="width: 16%;">

                      <button class="btn btn-sm btn-secondary" (click)="printPDF(item.id)" type="button" title="Print Preview" style="background-color: #6f7270;">Print</button>
                    
                   
                    </td> -->
                  </tr>
                 </tbody>
            </table>
            
            </div>  
         
 </div>  
    </div>





   