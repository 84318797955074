import { Component, OnInit,ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormBuilder, NgForm,FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { Upazila } from '../../basic-model/upazila';
import { UpazilaService } from '../../basic-service/upazila.service';
import { SharedService } from 'app/services/shared.service';

import { NgSelectModule } from '@ng-select/ng-select';
@Component({
  selector: 'app-upazila',
  templateUrl: './upazila.component.html',
  styleUrls: ['./upazila.component.scss']
})
export class UpazilaComponent implements OnInit {

  isEdit: boolean = false;
  districtForm: FormGroup;
  list:Upazila[]=[];
  model = new Upazila();
  districtList: Observable<any[]>;

  currentPage = 1;
  itemsPerPage = 10;
  totalItems = 0;
  currentItems=0;
  searchControl="";
  showSaveForm:string='';
  constructor(
    private _service:UpazilaService,
    private changeDetectorRef: ChangeDetectorRef,
    private router:Router,
    private formBuilder: FormBuilder,
    private shared: SharedService,
  )
   {
    this.getUpazilaList()
    this.buildForm();
    this.loadDistrictList();
    }

//#region For pagination
onChangeForShow(value: any) {
  this.currentPage = 1;
  this.itemsPerPage=value;
  this.getUpazilaList();
}

onPageChange(pageNumber: number) {
  this.currentPage = pageNumber;
  this.getUpazilaList();
}

onSearch() {
  this.currentPage = 1; // Reset the current page to 1 when a new search is performed
  this.getUpazilaList();
}

onClickShowSaveForm(value:string){
  this.showSaveForm=value;
  this.refresh();
}

getUpazilaList() {
  this._service.getListWithSerchAndPagination(this.currentPage, this.itemsPerPage, this.searchControl).subscribe((res : any) => {
    this.list = res.data;
    this.totalItems = res.totalItems;
    this.currentItems=res.currentItems;
  })
}

//#endregion
  ngOnInit(): void {
    this.getUpazilaList();
    this.loadDistrictList();
    this.showSaveForm='saveList';
  }
 
  loadDistrictList() {
    this._service.GetDistrtList().subscribe(res => {
      this.districtList = of(res);
    });
  }

  buildForm() {
    this.districtForm = this.formBuilder.group({
      UpazilaCode: ['', Validators.required],
      UpazilaName: ['', Validators.required]
    });
  }

  onsubmit() {
    if(this.model.id===null){
      this.model.id=0;
    }
    this._service.save(this.model).subscribe(res => {
    if (this.isEdit){
      this.shared.ShowAlert("Success", "Updated Successfully", "success");
    }
    else{
      this.shared.ShowAlert("Success", "Save Successfully", "success");
    }
      this.isEdit = false;
      this.showSaveForm = 'saveList';
      this.getUpazilaList();
    })
   }

   delete(id:any){
    this._service.delete(id).subscribe(res=>{
      this.shared.ShowAlert('Alert','Data deleted successfully.','warning');
      this.getUpazilaList();
      this.showSaveForm='saveList';
    })
  }

  edit(item: any) {
    this.isEdit = true;
    this.showSaveForm = 'saveForm';
    this.model = {
      id: item.id,
      UpazilaCode: item.upazilaCode,
      UpazilaName: item.upazilaName,
      DistrictsId:item.districtsId
      
      
    };
  }

  refresh() {
    this.isEdit = false;
    this.model={
      id: null,
      UpazilaCode: null,
      UpazilaName: null,
      DistrictsId:null
    }
  }
}
