export class ProductGLList{
    constructor(
        public eventId: number = 0,
        public accountCode: string = '',
        public accountName: string = '',
        public eventCode: string = '',
        public eventName: string = '',
        public ledgerRelationId: number = 0,
        public chargeAmount: number = 0,
        public chargeTypeName: string = '',
        public chargeTypeId: number = 0,
        public isVat: number = 0,
        public vatAmount: number = 0,
        public vatGLId: number = 0
      ) {}
    // eventId:number;
    // accountCode:string;
    // accountName:string;
    // eventCode:string;
    // eventName:string;
    // ledgerRelationId:number;
    // chargeAmount:number;
    // chargeTypeName:number;
    // isVat:number;
    // vatAmount:number;
    // vatGLId:number;
    // constructor(eventId:number=0,accountCode:string='',accountName:string='',eventCode:string='',eventName:string=''
    //     ,ledgerRelationId:number=0,chargeAmount:number=0,chargeTypeName:number=0,isVat:number=0,vatAmount:number=0,vatGLId:number=0){
    //     this.eventId=eventId;
    //     this.accountCode=accountCode;
    //     this.accountName=accountName;
    //     this.eventCode=eventCode;
    //     this.eventName=eventName;
    //     this.ledgerRelationId=ledgerRelationId;
    //     this.chargeAmount=chargeAmount;
    //     this.chargeTypeName=chargeTypeName;
    //     this.isVat=isVat;
    //     this.vatAmount=vatAmount;
    //     this.vatGLId=vatGLId;
    // }
}