<section id="navs">
    <div class="row">
        <div class="col-md-12">
            <h3 class="card-title" style="color: black;">FDR Account Create</h3>
        </div>
    </div>
    <hr style="height: 10%;">
    <div class="card">
        <div class="card-body">
            <form [formGroup]="frmGroup">
                <ul ngbNav #basicnav="ngbNav" [(activeId)]="active" (navChange)="onNavChange($event,0)"
                    class="nav-tabs mr-3">
            
                    <li [ngbNavItem]="1">
                        <a ngbNavLink>Account Info</a>
                        <ng-template ngbNavContent>
                   
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Account Title <span class="redColor">
                                            *</span></label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" formControlName="AccountTilte"
                                            placeholder="Account Title ....">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12" style="display: none;">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Account Title (Bangla) <span
                                            class="redColor"> *</span></label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" formControlName="AccountTilteBn"
                                            placeholder="Account Title Bn....">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="name" class="col-sm-4 col-form-label">Tenure <span class="redColor">*</span></label>
                                    <div class="col-sm-8">
                                        <select class="form-control" [(ngModel)]="selectedProductId" formControlName="ProductBuilderId" readonly>
                                            <option *ngFor="let item of productlist" [value]="item.id">{{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 d-none">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">GL Code</label>
                                    <div class="col-sm-8">
                                      
                                        <input type="text" readonly class="form-control"
                                            formControlName="ledgerRelationName" placeholder="GL">
                                        <input type="hidden" readonly class="form-control" name="ledgerRelationId"
                                            formControlName="ledgerRelationId">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 d-none">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Account No</label>
                                    <div class="col-sm-8">
                                        <input type="text" readonly class="form-control"
                                            formControlName="customerAccountNo" placeholder="Account No">

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Currency</label>
                                    <div class="col-sm-8">
                                        <input type="text" readonly class="form-control"
                                            formControlName="accountCurrency" placeholder="Currency ....">
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Interest Rate<span class="redColor"> *</span></label>
                                    <div class="col-sm-8">
                                        <input type="number" class="form-control" formControlName="InterestRate" readonly>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Open Date<span class="redColor"> *</span></label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" formControlName="OpenDate" value="{{OpenDate  | date:'yyyy-MM-dd'}}"
                                            readonly>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Maturity Date<span class="redColor"> *</span></label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" formControlName="MaturityDate"
                                            value="{{MaturityDate | date:'yyyy-MM-dd'}}" readonly>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Deposit Amount<span class="redColor"> *</span></label>
                                    <div class="col-sm-8">
                                        <input type="number" class="form-control" formControlName="OpeningBalance" style="text-align: right;"
                                            (keyup)="calculateInterestAmount()" placeholder="deposit amount">
                                             <!-- <input type="number" style="text-align: right;" class="form-control" formControlName="OpeningBalance"
                                            (keyup)="calculateInterestAmount()"  (input)="frmGroup.get('OpeningBalance').setValue(formatNumber($event.target.value))" placeholder="deposit amount" > -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Interest Amount<span class="redColor"> *</span></label>
                                    <div class="col-sm-8">
                                        <input type="number" style="text-align: right;" class="form-control" formControlName="InterestAmount" readonly>
                                        <!-- <input type="number" style="text-align: right;" class="form-control" formControlName="InterestAmount"
                                        (input)="frmGroup.get('InterestAmount').setValue(formatNumber($event.target.value))" readonly> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Maturity Amount</label>
                                    <div class="col-sm-8">
                                        <input type="number" style="text-align: right;" class="form-control" formControlName="maturityAmount"
                                            readonly>
                                     
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Security<span class="redColor"> *</span></label>
                                    <div class="col-sm-8">
                                        <!-- <input type="number" class="form-control" formControlName="Security" > -->
                            
                                        <select class="form-control" formControlName="Security">
                                            <option value="0">No</option>
                                            <option value="1">Yes</option>
                                        </select>
                            
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Is Renew ?<span class="redColor"> *</span></label>
                                    <div class="col-sm-8">
                                        <!-- <input type="number" class="form-control" formControlName="Security" > -->
                            
                                        <select class="form-control" formControlName="checkRenew">
                                            <option value="0">No</option>
                                            <option value="1">Yes</option>
                                        </select>
                            
                                    </div>
                                </div>
                            </div>
                      
                            <div class="col-md-12" >
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Renewal Date<span class="redColor"> *</span></label>
                                    <div class="col-sm-8">
                                        <input type="date" class="form-control" formControlName="renewalDate">
                                    </div>
                                </div>
                            </div>

                       
                           
                            <div class="col-md-12">
                                <div class="form-group row pull-right">
                                    <button class="btn btn-sm btn-info" (click)="onNavChange($event,3)">Next</button>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="4">
                        <a ngbNavLink>Nominee Info</a>
                        <ng-template ngbNavContent>
                            <div class="col-md-12">
                                <div class="form-group row">
                                   
                                    <form [formGroup]="nomineeGroup">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group row">
                                                    <label for="" class="col-sm-4 col-form-label">Nominee's Name <span
                                                            style="color: red;"> *</span></label>
                                                    <div class="col-sm-8">
                                                        <input type="text" class="form-control"
                                                            formControlName="nomineeName"
                                                            placeholder="Nominee Name....">
                                                        <input type="hidden" formControlName="nomineeNameSl" value="0">
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="" class="col-sm-4 col-form-label">Nominee's DOB <span
                                                            style="color: red;"> *</span></label>
                                                    <div class="col-sm-8">
                                                        <input type="date" class="form-control"
                                                            formControlName="nomineeDOB"
                                                            placeholder="Nominee Date Of Birth....">
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="" class="col-sm-4 col-form-label">Nominee's Present
                                                        Address <span style="color: red;"> *</span></label>
                                                    <div class="col-sm-8">
                                                        <input type="text" class="form-control"
                                                            formControlName="nomineePresentAddress"
                                                            placeholder="Nominee Present Address....">
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label for="" class="col-sm-4 col-form-label">NID Picture <span
                                                            style="color: red;"> *</span></label>
                                                    <div class="col-sm-8">
                                                        <input type="file" accept="image/*" id="image"
                                                            (change)="selectNidBackPic($event)" formControlName="photo"
                                                            style="display: none;" #fileInputBack />

                                                        <div style="height: 125px; width: 150px; cursor: pointer;"
                                                            (click)="fileInputBack.click()">
                                                            <img src="{{ backPreview ? backPreview : 'assets/img/gallery/upload_image.png' }}"
                                                                id="image" style="width: 100%; height: 100%;"
                                                                class="proPreview" title="Click here to upload photo" />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group row">
                                                    <label for="" class="col-sm-4 col-form-label">Relation<span
                                                            style="color: red;"> *</span></label>
                                                    <div class="col-sm-8">
                                                        <select class="form-control" formControlName="relationId"
                                                            (change)="getRelation($event.target.value)">
                                                           


                                                            <option value="">Select</option>
                                                            <option *ngFor="let item of relationList" [value]="item.id">
                                                                {{item.name}}</option>


                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="" class="col-sm-4 col-form-label">Nominee's Share <span
                                                            style="color: red;"> *</span></label>
                                                    <div class="col-sm-8">
                                                        <input type="number" class="form-control" min="1" max="100"
                                                            formControlName="nomineeShare"
                                                            placeholder="Nominee's Share....">
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="" class="col-sm-4 col-form-label">Nominee's Identifier
                                                        <span style="color: red;"> *</span></label>
                                                    <div class="col-sm-8">
                                                        <div class="form-group row">
                                                            <label for="" class="col-sm-4 col-form-label">Nominee's
                                                                NID</label>
                                                            <div class="col-sm-8">
                                                                <input type="text" class="form-control"
                                                                    (input)="onNumberInput($event)"
                                                                    formControlName="nomineeNID"
                                                                    placeholder="Nominee NID....">
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label for="" class="col-sm-4 col-form-label">Nominee's
                                                                Passport</label>
                                                            <div class="col-sm-8">
                                                                <input type="text" class="form-control"
                                                                    formControlName="nomineePassport"
                                                                    placeholder="Nominee Passport.....">
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label for="" class="col-sm-4 col-form-label">Nominee's
                                                                Birth Identity No</label>
                                                            <div class="col-sm-8">
                                                                <input type="text" class="form-control"
                                                                    formControlName="nomineeBirthIdentity"
                                                                    placeholder="Nominee Birth Identity No.....">
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <button type="button" class="btn btn-success"
                                            (click)="addNominee()">Add</button>
                                       
                                    </form>

                                </div>
                                <div class="form-group row">
                                    <label for="" class="col-sm-2 col-form-label"></label>
                                    <div class="col-sm-10">
                                        <table class="table table-bordered">
                                            <tbody>
                                                <tr *ngFor="let item of nomineeList ;let i = index">
                                                    <td style="width: 10%;">{{i+1}}</td>
                                                    <td>
                                                        <ul>
                                                            <li><span style="font-weight: bold;">Nominee Name </span>:
                                                                {{item.nomineeName}}</li>
                                                           
                                                            <li><span style="font-weight: bold;">Nominee DOB</span> :
                                                                {{item.nomineeDOB}}</li>
                                                            <li><span style="font-weight: bold;">Present Address </span>
                                                                : {{item.nomineePresentAddress}}</li>
                                                         
                                                            <li><span style="font-weight: bold;">Occupation</span> :
                                                                {{item.nomineeOccupation}}</li>
                                                            <li><span style="font-weight: bold;">NID</span> :
                                                                {{item.nomineeNID}}</li>
                                                            <li><span style="font-weight: bold;">Passport</span> :
                                                                {{item.nomineePassport}}</li>
                                                            <li><span style="font-weight: bold;">Birth Identity No
                                                                </span> : {{item.nomineeBirthIdentity}}</li>
                                                         
                                                            <li><span style="font-weight: bold;">Share</span> :
                                                                {{item.nomineeShare}}</li>
                                                            <li>
                                                                <span style="font-weight: bold;">Relation</span> : {{
                                                                nomineeGroup.value.relationName }}
                                                                <span style="display: none;"> {{item.relationId}}
                                                                </span>


                                                            </li>
                                                            <li>
                                                                <span style="font-weight: bold;">NID Picture</span>:
                                                            
                                                                <img class="proPreview" src="{{backPreview}}"
                                                                    alt="proPreview" height="35" width="35" />
                                                            </li>
                                                        </ul>
                                                    </td>
                                                    <td>
                                                        <a class="btn btn-sm btn-info mr-2"
                                                            (click)="editNominee(item)"><i class="fa fa-edit"></i></a>
                                                        <a class="btn btn-sm btn-danger"
                                                            (click)="deleteNominee(item)"><i
                                                                class="fa fa-trash"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>


                            <!-- <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label" style=" font-weight: bold;">What kind
                                        of profession are you engaged in? <span style="color:red">*</span></label>
                                    <div class="col-sm-8">
                                        <select class="form-control" formControlName="riskGrading1">
                                            <option value="">Select</option>
                                            <option *ngFor="let item of riskGradingList" value="{{item.id}}">
                                                {{item.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div> -->
                            <button [disabled]="!frmGroup.valid" data-toggle="tooltip" title="Save" type="submit" (click)="onsubmit()"
                                value="Submit" class="btn btn-success" style="float:right; margin-top:5px;">
                                <span>{{ isEdit ? 'Update' : 'Save' }}</span>
                            </button>
                            <button type="button" data-toggle="tooltip" title="Refresh" (click)="refresh()" class="btn btn-secondary"
                                style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>
                            <!-- <div class="col-md-12">
                                <div class="form-group row pull-right">
                                    <button class="btn btn-sm btn-info" (click)="onNavChange($event,4)">Next</button>
                                </div>
                            </div> -->
                        </ng-template>
                    </li>
                    <!-- <li [ngbNavItem]="5">
                        <a ngbNavLink>Declearation</a>
                        <ng-template ngbNavContent>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Monthly Income</label>
                                    <div class="col-sm-8">
                                        <input type="number" class="form-control" formControlName="monthlyIncome"
                                            placeholder="Monthly Income ....">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Deposit Declearation</label>
                                    <div class="col-sm-8">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <td class="pl-1 p-0 m-0">Method</td>
                                                    <td class="pl-1 p-0 m-0">Monthly potential total deposit number</td>
                                                    <td class="pl-1 p-0 m-0">Monthly potential total deposit amount</td>
                                                    <td class="pl-1 p-0 m-0">Single maximum deposit amount</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of declearationList ;let i = index">
                                                    <td class="pl-1 p-0 m-0">
                                                        {{item.methodName}}
                                                        <input type="hidden" class="form-control" value="{{item.id}}">
                                                    </td>
                                                    <td class="p-1 m-0">
                                                        <input type="number" class="form-control"
                                                            (keyup)="updateDeclearationList(item.id,1,$event)"
                                                            placeholder="Number ....">
                                                    </td>
                                                    <td class="p-1 m-0">
                                                        <input type="number" class="form-control"
                                                            (keyup)="updateDeclearationList(item.id,2,$event)"
                                                            placeholder="Amount ....">
                                                    </td>
                                                    <td class="p-1 m-0">
                                                        <input type="number" class="form-control"
                                                            (keyup)="updateDeclearationList(item.id,3,$event)"
                                                            placeholder="Maximum ....">
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label">Withdrow Declearation</label>
                                    <div class="col-sm-8">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <td class="pl-1 p-0 m-0 ">Method</td>
                                                    <td class="pl-1 p-0 m-0 ">Monthly potential total deposit number
                                                    </td>
                                                    <td class="pl-1 p-0 m-0 ">Monthly potential total deposit amount
                                                    </td>
                                                    <td class="pl-1 p-0 m-0 ">Single maximum deposit amount</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of declearationList ;let i = index">
                                                    <td class="pl-1 p-0 m-0">
                                                        {{item.methodName}}
                                                        <input type="hidden" class="form-control" value="{{item.id}}">
                                                    </td>
                                                    <td class="p-1 m-0">
                                                        <input type="number"
                                                            (keyup)="updateDeclearationList1(item.id,1,$event)"
                                                            class="form-control" placeholder="Number ....">
                                                    </td>
                                                    <td class="p-1 m-0">
                                                        <input type="number"
                                                            (keyup)="updateDeclearationList1(item.id,2,$event)"
                                                            class="form-control" placeholder="Amount ....">
                                                    </td>
                                                    <td class="p-1 m-0">
                                                        <input type="number"
                                                            (keyup)="updateDeclearationList1(item.id,3,$event)"
                                                            class="form-control" placeholder="Maximum ....">
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="" class="col-sm-4 col-form-label" style=" font-weight: bold;">What kind
                                        of profession are you engaged in? <span style="color:red">*</span></label>
                                    <div class="col-sm-8">
                                        <select class="form-control" formControlName="riskGrading1">
                                            <option value="">Select</option>
                                            <option *ngFor="let item of riskGradingList" value="{{item.id}}">
                                                {{item.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <button [disabled]="!frmGroup.valid" data-toggle="tooltip" title="Save" type="submit"
                                (click)="onsubmit()" value="Submit" class="btn btn-success"
                                style="float:right; margin-top:5px;">
                                <span>{{ isEdit ? 'Update' : 'Save' }}</span>
                            </button>
                            <button type="button" data-toggle="tooltip" title="Refresh" (click)="refresh()"
                                class="btn btn-secondary"
                                style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>
                        </ng-template>
                    </li> -->
                </ul>
                <div [ngbNavOutlet]="basicnav" class="mt-2"></div>
              
            </form>
        </div>
    </div>
</section>
