import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CashPostingService } from '../service/cash-posting.service';
import { LedgerService } from '../service/ledger.service';
import { CountryInfoService } from 'app/master-data/basic/basic-service/country-info.service';
import { CustomerService } from 'app/customer/customer.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SharedService } from 'app/services/shared.service';
import { Observable, of } from 'rxjs';
import { CashPosting } from '../model/cash-posting';
import swal from 'sweetalert';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-cash-received',
  templateUrl: './cash-received.component.html',
  styleUrls: ['./cash-received.component.scss']
})
export class CashReceivedComponent implements OnInit {
  customerAccountId: number;
  selectedOption: string;
  currentBalance: number;
  isvalid: boolean = true;
  isBtnValid: boolean = false;

  accountNo: string = '';
  accountNoRecieve: string = '';
  customerInfo_cashReceive: any = '';

  isEdit: boolean = false;
  frmGroup: FormGroup;

   list: CashPosting[] = [];
   model = new CashPosting();
   formControl = new FormControl();
   suggestions: any[] = [];
   form: FormGroup;
   //frmGroup: FormGroup;
   hideShow: string = 'Show';
  searchType: string = '';
   
  constructor(
    private router: Router,
    private _service: CashPostingService,
    private _LedgerService: LedgerService,
    private _CountryInfoService: CountryInfoService,
    private _CustomerService: CustomerService,
    private changeDetectorRef: ChangeDetectorRef,

    private formBuilder: FormBuilder,
    private shared: SharedService,
  ) 
  {
    {
      this.frmGroup = new FormGroup({
        id: new FormControl(),
        customerCode: new FormControl(),
        transactionType: new FormControl(),
        customerId: new FormControl(),
        voucherNo: new FormControl(),
        postingType: new FormControl(),
        remarks: new FormControl(),
        currencyId: new FormControl(),
        voucherAmount: new FormControl(),


      });
    }


  }

  ngOnInit(): void {
    this.loadLedgerList();
    this.loadCountryList();
    this.formControl.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(value => this._LedgerService.getAutocompleteSuggestions(value))
    ).subscribe(data => {
      this.suggestions = data;
      console.log(this.suggestions)
    });
    this.hideShow = 'Show';
  }
  selectedSuggestion: any = null;

  selectSuggestion(item: any) {
    this.formControl.setValue(item.bdGenerateCode); // Set the selected value in the input field
    //this.suggestions = []; // Clear the suggestions list
    this.selectedSuggestion = item;
    this.hideShow = 'Hide';
  }
  hideAfterSelectValue(value: any) {
    //alert(value);
    this.hideShow = value;
  }
  selectSearchType(type: string) {
    this.searchType = type;
  }

  LedgerList: Observable<any[]>;
  loadLedgerList() {
    this._LedgerService.getLedgerRelationlist().subscribe(res => {
            this.LedgerList = of(res);
    });
  }

  countryList: Observable<any[]>;
  loadCountryList() {
    this._CountryInfoService.getlist().subscribe(res => {
      console.log(res)
      this.countryList = of(res);
    });
  }

  onsubmit() {
    if (this.isEdit) {
      this.model.id = this.frmGroup.value.id;
    }
    else {
      this.model.id = 0;
    }
    this.model.transactionType = 2;
    this.model.customerCode = this.frmGroup.value.customerCode;
    this.model.customerAccountId = this.customerAccountId;
    this.model.voucherNo = this.frmGroup.value.voucherNo;
    this.model.postingType = this.frmGroup.value.postingType;
    this.model.remarks = this.frmGroup.value.remarks;
    this.model.currencyId = this.frmGroup.value.currencyId;
    this.model.voucherAmount = this.frmGroup.value.voucherAmount;
    if (this.model.voucherAmount > 0 && this.model.currencyId > 0) {
      if (this.model.id == 0) {

        this._service.save(this.model).subscribe((res: number) => {
          if (res > 0) {
            this.ResetForm();

            this.shared.ShowAlert("Success", "Save Successfully", "success");
            this.isEdit = false;
          }
          else {
            this.shared.ShowAlert("Warning", "Failed to Save", "warning");
            this.isEdit = false;
          }

        })
      }
      else {
        this._service.save(this.model).subscribe(res => {
          this.ResetForm();

          this.shared.ShowAlert("Success", "Save Successfully", "success");
          //this.getLedgerList();
          this.isEdit = false;
          //this.showSaveForm='saveList';
        })
      }
    }
  }

  loadUserDataByAccountNoCashReceive() {
    this._CustomerService.getCustomerByAccountNo(this.accountNoRecieve).subscribe(res => {
      if (res != null) {
        this.customerInfo_cashReceive = res.customerNameEng + '; ' + res.mobileNo + '; ' + res.nidNo;
        //console.log(this.customerInfo_cashReceive);
        this.customerAccountId = res.id;
        this.isBtnValid = true;
      }
      else {
        this.refreshAc();
        swal('Warning', 'Invalid Account No', 'warning');
      }
    })
  }

  refreshAc() {
    this.currentBalance = 0;
    this.isBtnValid = false;
    this.customerAccountId = 0;
  }
  CheckBalance() {
    var currentBalance = this.currentBalance;
    var inputValue = this.frmGroup.value.voucherAmount;
    if (currentBalance < inputValue) {
      this.isvalid = false;
    }
    else {
      this.isvalid = true;
    }

  }

  ResetForm() {
    this.frmGroup.reset();
    this.customerInfo_cashReceive = '';
  }
}
