<!--Statistics cards Starts-->

<div class="mt-3" style="padding: 10px; border-radius: 4px;">
    <h4 style="color: #868585">Profile Update: {{ profileUpdate }}% Complete</h4>
</div>
<div class="row" (resized)="onResized($event)">
    <div class="col-xl-3 col-lg-6 col-md-6 col-12">
        <div class="card  TopCard" (click)="getDashboardListByType(1)" style="cursor: pointer;">
            <div class="card-content">
                <div class="card-body py-0">
                    <div class="media pb-1">
                        <div class="black customIcon">
                            <i class="ft-package"></i>
                        </div>
                        <div class="black">
                            <span>Incomplete</span>
                            <h4 class="font-large-0 black">{{underProcerssCount}}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="col-xl-3 col-lg-6 col-md-6 col-12">
        <div class="card white" (click)="getDashboardListByType(2)" style="cursor: pointer;">
            <div class="card-content">
                <div class="card-body py-0">
                    <div class="media pb-1">
                        <div class="black customIcon">
                            <i class="fa fa-list"></i>
                        </div>
                        <div class="black ml-2">
                            <span>Applied Loan</span>
                            <h4 class="font-large-0 black">{{appliedLoanCount}}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="col-xl-3 col-lg-6 col-md-6 col-12">
        <div class="card TopCard" (click)="getDashboardListByType(3)" style="cursor: pointer;">
            <div class="card-content">
                <div class="card-body py-0">
                    <div class="media pb-1">
                        <div class="black customIcon">
                            <i class="ft-trending-up"></i>
                        </div>
                        <div class="black ml-2">
                            <span>Under Process</span>
                            <h4 class="font-large-0 black">{{approvedLoanCount}}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-lg-6 col-md-6 col-12">
        <div class="card TopCard" (click)="getEMIPayBillList()" style="cursor: pointer;">
            <div class="card-content">
                <div class="card-body py-0">
                    <div class="media pb-1">
                        <!-- style="height: 80px;"    -->
                        <div class="black customIcon">
                            <i class="ft-activity"></i>
                        </div>
                        <div class="black ml-2">
                            <span>Loan Pay</span>
                            <h4 class="font-large-0 black">{{finalApproveCount}}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-lg-6 col-md-6 col-12">
        <a href="/dps-account/dps-collection">
            <div class="card TopCard"  style="cursor: pointer;">
                <div class="card-content">
                    <div class="card-body py-0">
                        <div class="media pb-1">
                           
                            <div class="black customIcon">
                                <i class="ft-activity"></i>
                            </div>
                            <div class="black ml-2">
                                <span>DPS Pay</span>
                               <h4 class="font-large-0 black">{{dpsPay}}</h4> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </a>

    </div>
</div>
<!--Statistics cards Ends-->


<div class="row match-height">
    <div class="col-xl-4 col-lg-12 col-12 d-none">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Statistics</h4>
            </div>
            <div class="card-content">
                <div class="card-body">
                    <p class="font-medium-2 text-center my-2">Loan Information</p>
                    <!-- <div id="Stack-bar-chart" class="Stackbarchart mb-2">
                        <x-chartist class="height-300" [data]="Stackbarchart.data" [type]="Stackbarchart.type"
                            [options]="Stackbarchart.options" [responsiveOptions]="Stackbarchart.responsiveOptions"
                            [events]="Stackbarchart.events">
                        </x-chartist>
                    </div>  -->
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-12 col-lg-12 col-md-12  col-12" style="height: 100%;">
        <div class="card">
            <div class="card-header pb-2">
                <h4 class="card-title mb-0">Account Details</h4>
            </div>
            <div *ngIf="noDataAvailable">

                <p style="text-align: center;font-size: 25px;font-weight: 700;">No data available</p>
            </div>
            <div *ngIf="!noDataAvailable">
                <table class="table text-center m-0 table-borderless" style="color: #868585; font-size: 14px;">
                    <thead style="background-color: #f8f9fb;">
                        <tr>
                            <th>Action</th>
                            <th>Account Type</th>
                            <th>Account Name</th>
                            <th>Account No</th>
                            <th>Account Create Date</th>
                            <th>Amount</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of customerList">
                            <td>
                                <button class="btn btn-primary"
                                    style="font-size: 14px; width: 120px !important; height: 35px;background-color: lightblue;color: white;"
                                    (click)="PrintKyc(item.accountId)" title="Click here for download pdf">View
                                    PDF</button>
                                <button class="btn btn-info mt-1"
                                    style="font-size: 14px; width: 120px !important; height: 35px;"
                                    (click)="DPSSchedulePDF(item.accountId)"
                                    title="Click here for download pdf">Schedule</button>
                            </td>
                            <td>{{item.accountType}}</td>
                            <td>{{item.title}}</td>
                            <td>{{item.accNo}}</td>
                            <td>{{item.createdDate | date: 'yyyy-MM-dd'}}</td>
                            <td>{{item.balance}}</td>

                        </tr>
                    </tbody>
                </table>
            </div>



            <!-- <div class="card-content">
                <div class="card-body">
                    
                </div>
            </div> -->
        </div>
    </div>
</div>

<!--Line with Area Chart 1 Starts-->
<div class="row card">
    <div class="col-xl-11 col-lg-11 col-md-11 col-1" style="height: 100%;">

        <div class="card-header">
            <h4 class="card-title mb-0">Loan Details</h4>
        </div>

        <table class="table text-center m-0 table-borderless"
            style="color: #868585; font-size: 14px;width: 100%!important;">
            <thead style="background-color: #f8f9fb;">
                <tr>
                    <th>Action</th>
                    <th>Loan Type</th>
                    <th>Account Name</th>
                    <th>Account No</th>
                    <th>Loan Apply Date</th>
                    <th>Loan Amount</th>
                    <!-- <th>Monthly EMI</th> -->
                    <th>Status</th>

                </tr>
            </thead>
            <tbody style="width: 100%!important;">
                <tr *ngFor="let item of customerLoan">
                    <td>
                        <button class="btn btn-primary" (click)="CollentionPDF(item.leadGenerationId)" type="button"
                            title="Loan Collection" style="float:right; margin-top:5px;background-color: lightblue;color: white;"> Payment </button>

                        <button class="btn btn-info ml-2" (click)="SchedulePDF(item.leadGenerationId)" type="button"
                            title="Loan Schedule" style="float:right; margin-top:5px; width: 110px;"> Schedule </button>
                    </td>
                    <td>{{item.productName}} - {{item.loanType}}</td>
                    <td>{{item.accountName}}</td>
                    <td>{{item.accountNo}}</td>
                    <td>{{item.createdAt | date: 'yyyy-MM-dd'}}</td>
                    <td>{{item.loanAmount}}</td>
                    <!-- <td>{{item.installmentSize}}</td> -->
                    <td>{{item?.statusName}}</td>


                </tr>
            </tbody>
        </table>
    </div>
</div>
<!--Line with Area Chart 1 Ends-->
<!-- <div class="col-xl-3 col-lg-6 col-md-6 col-12"> -->
<!-- <div class="row match-height">
    <div class="col-12">
        <div class="card" style="height: 50px;">
            <div class="card-header pb-2">
                <h4 class="card-title mb-0">Product List</h4>
            </div>
        </div>
    </div>
</div> -->
<!-- <div class="row match-height">
    <div class="col-12" style="height: 100%;">
        <div class="card">
            <div class="card-header pb-2">
                <h4 class="card-title mb-0">Loan Type Details</h4>
            </div>
            <div class="card-content">
                <div class="card-body">
                    <div class="row" style="width: 125%; margin-left: 3px; color: #868585">
                        <div class="card box1 col-xl-3 col-lg-3 col-md-3 mr-2 mt-2 ml-2 mb-2"
                            *ngFor="let item of productLoanList" style="background-color: #f8f9fb;  font-size: 14px;">
                            <div style="color: #868585; margin: auto;
                            background-color: rgb(223, 221, 221);
                            border-radius: 50%;
                            height: 45px;
                            width: 45px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-top: 15px;
                            margin-bottom: 10px;">
                                <i class="fa fa-list font-medium-2" ></i>
                            </div>
                            <p style="font-weight: bold; margin-top: 10px;">Loan Name : {{item.name}}</p>
                            <p>Interest Calculation : {{item.interestCalculationFrequency?.name}}</p>
                            <p>Interest Rate : {{item.interestRate}}</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</div>

<div class="row match-height">
    <div class="col-12" style="height: 100%;">
        <div class="card">
            <div class="card-header pb-2">
                <h4 class="card-title mb-0">Saving Product</h4>
            </div>
            <div class="card-content">
                <div class="card-body">
                    <div class="row" style="width: 125%; margin-left: 3px; color: #868585">
                        <div class="card box1 col-xl-3 col-lg-3 col-md-3 mr-2 mt-2 ml-2 mb-2"
                            *ngFor="let item of productList" style="background-color: #f8f9fb;  font-size: 14px;">
                            <div style="color: #868585; margin: auto;
                            background-color: rgb(223, 221, 221);
                            border-radius: 50%;
                            height: 45px;
                            width: 45px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-top: 15px;
                            margin-bottom: 10px;">
                                <i class="fa fa-list font-medium-2" ></i>
                            </div>
                            <p style="font-weight: bold; margin-top: 10px;">Product Name : {{item.name}}</p>
                            <p>Interest Calculation : {{item.interestCalculationFrequency?.name}}</p>
                            <p>Interest Rate : {{item.interestRate}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="row">
    <div class="col-xl-12 col-lg-12  col-12" style="height: 100%;">
        <div class="card">
            <div class="card-header pb-2">
                <h4 class="card-title mb-0">Loan Collection List</h4>
            </div>
            <div class="card-content">
                <div class="card-body">
                    <table class="table text-center m-0 table-borderless" style="color: #868585; font-size: 14px;">
                        <thead style="background-color: #f8f9fb;">
                            <tr>
                                <th>Installment Amount</th>
                                <th>Collection Amount</th>
                                <th>Collection Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of loanCollectionList">
                                <td>{{item.installmentAmount}}</td>
                                <td>{{item.collectionAmount}}</td>
                                <td>{{item.collectionDate | date: 'yyyy-MM-dd'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div> -->