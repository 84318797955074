import { NgModule } from '@angular/core';
import { CommonModule , DatePipe} from '@angular/common';
import { BasicRoutingModule } from '../basic-routing/basic-routing.module';

import { OccupationComponent } from '../basic-component/occupation/occupation/occupation.component';
import { IncomesourceComponent } from '../basic-component/incomesource/incomesource.component';
import { CountryInfoComponent } from '../basic-component/country-info/country-info.component';

import { DivisionComponent } from '../basic-component/division/division.component';
import { DistrictComponent } from '../basic-component/district/district.component';
import { UpazilaComponent } from '../basic-component/upazila/upazila.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FiscalyearComponent } from '../basic-component/fiscalyear/fiscalyear.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CountryListComponent } from '../basic-component/country-list/country-list.component';
import { RelationComponent } from '../basic-component/relation/relation.component';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
  declarations: [CountryInfoComponent,
    OccupationComponent,
    IncomesourceComponent,
    DistrictComponent,
    DivisionComponent,
    FiscalyearComponent,
    UpazilaComponent,
    CountryListComponent,
    RelationComponent
  ],

  imports: [
    CommonModule,FormsModule,BasicRoutingModule,ReactiveFormsModule,
    NgxPaginationModule,NgSelectModule
   
  ],
  providers: [
    DatePipe
  ]
})
export class BasicModuleModule { }
