import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DashboardCustomService } from '../dashboard-custom.service';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { Location } from "@angular/common";
@Component({
  selector: "app-today-settlement-list",
  templateUrl: "./today-settlement-list.component.html",
  styleUrls: ["./today-settlement-list.component.scss"],
})
export class TodaySettlementListComponent implements OnInit {
  dataList: any[] = [];

  constructor(
    private _serviceDashboard: DashboardCustomService,
    private changeDetectorRef: ChangeDetectorRef,
    private location: Location,
    private pdfService: PdfService
  ) {}

  ngOnInit(): void {
    this.GetTodayReceivedList();
  }

  goBack() {
    this.location.back();
  }
  GetTodayReceivedList() {
    this._serviceDashboard.TodaySettlementList().subscribe((res: any[]) => {
      this.dataList = res;
      console.log(res);
      this.changeDetectorRef.detectChanges();
    });
  }

  OpenPDF(leadId) {
    this.pdfService.OpenCollectionPdf(leadId).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }
}
