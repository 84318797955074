import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormBuilder, NgForm, FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { Division } from '../../basic-model/division';
import { DivisionService } from '../../basic-service/division.service';
import { SharedService } from 'app/services/shared.service';
@Component({
  selector: 'app-division',
  templateUrl: './division.component.html',
  styleUrls: ['./division.component.scss']
})


export class DivisionComponent implements OnInit {

  isEdit: boolean = false;
  districtForm: FormGroup;
  list: Division[] = [];
  CountryList: Observable<any[]>;
  model = new Division();

  currentPage = 1;
  itemsPerPage = 10;
  totalItems = 0;
  currentItems = 0;
  searchControl = "";
  showSaveForm: string = "saveList";

  constructor(
    private _service: DivisionService,
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private shared: SharedService,
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.getDivisionList();
    this.loadCountryList();
  }

  //#region For pagination
  onChangeForShow(value: any) {
    this.currentPage = 1;
    this.itemsPerPage = value;
    this.getDivisionList();
  }

  onPageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.getDivisionList();
  }

  onSearch() {
    this.currentPage = 1; // Reset the current page to 1 when a new search is performed
    this.getDivisionList();
  }
  onClickShowSaveForm(value: string) {
    this.showSaveForm = value;
    this.refresh();
  }

  getDivisionList() {
    this._service.getListWithSerchAndPagination(this.currentPage, this.itemsPerPage, this.searchControl).subscribe((res: any) => {
      this.list = res.data;
      this.totalItems = res.totalItems;
      this.currentItems = res.currentItems;
    })
  }
  //#endregion
  loadCountryList() {
    this._service.GetAllCountry().subscribe(res => {
      this.CountryList = of(res);
    });
  }

  buildForm() {
    this.districtForm = this.formBuilder.group({
      DistrictCode: ['', Validators.required],
      DistrictName: ['', Validators.required]
    });
  }

  onsubmit() {
    if (this.model.id === null) {
      this.model.id = 0
    }
    this._service.save(this.model).subscribe(res => {
      if (res != '') {
        if (this.isEdit) {
          this.shared.ShowAlert('Saved', 'Updated successfully.', 'success');
        }
        else {
          this.shared.ShowAlert('Saved', 'Saved successfully.', 'success');
        }
        this.getDivisionList();
        this.showSaveForm = 'saveList';
      }
    })
    this.changeDetectorRef.detectChanges();
  }

  delete(id: any) {
    this._service.delete(id).subscribe(res => {
      this.shared.ShowAlert('Alert', 'Data deleted successfully.', 'warning');
      this.getDivisionList();
      this.showSaveForm = 'saveList';
    })
  }

  refresh() {
    this.isEdit = false;
    this.model = {
      id: null,
      DivisionCode: null,
      DivisionName: null,
      CountrysId: null,
      ShortName: null
    }
  }

  edit(item: any) {
    this.isEdit = true;
    this.showSaveForm = 'saveForm';
    this.model = {
      id: item.id,
      DivisionCode: item.divisionCode,
      DivisionName: item.divisionName,
      CountrysId: item.countrysId,
      ShortName: item.shortName
    };
  }
}
