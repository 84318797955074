



<br>
<h4 class="card-title" style="color: black">Quarterly Interest Process</h4>
<hr>
<div class="card">
    <div class="card-body">
        <div class="form-group row">
            <label for="dateInput" class="col-md-4">Business Date:</label>
            <div class="col-md-4 input-group">
                <input type="date" readonly [(ngModel)]="businessDate" class="form-control">  
            </div>
            
           
         
        </div>
        <div class="form-group row">
            <label for="dateInput" class="col-md-4">Quarter:</label>
            <div class="col-md-4 input-group">
                    <select class="form-control" formControlName="quarter" placeholder="quarter" (change)="onquarterStatusChange($event.target.value)">
                      <option value="select">Select</option>
                      <option value="Q1">Q1</option>
                      <option value="Q2">Q2</option>
                      <option value="Q3">Q3</option>
                      <option value="Q4">Q4</option>
                    </select>
                 
            </div>
            
           
         
        </div>

        <div class="col-4">
            <button type="button" (click)="InterestProcess()" class="btn btn-primary" id="processButton">Process</button>
        </div>

    </div>
</div>