
<div class="card" style="width: 100%;" >
    <div class="card-body">
        <div class="container">
          <div class="row pb-1">
            <div class="col-10"></div>
            <div class="col-2">
              <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
                <i class="icon-arrow-left" style="font-size: 17px;"> </i>
              </button>
            </div>
          </div>
            <div class="row">
                <div class="col">
                  <h5 class="card-title" style="color: black">Opening Account</h5>
                </div>
                <div class="col" style="text-align: right;">
                  <button class="btn btn-primary" (click)="openingCreate()">Create</button>
                </div>
            </div>    
                     
          <table id="example" class="table table-striped table-bordered mt-3" style="width:100%">
            <thead>
                <tr>
                  <th>SL No.</th>
                    <th>Customer Name</th>
                    <th>Product Name</th>
                    <th>Account Title</th>
                    <th>Account No</th>
                    <th>Opening Balance</th>
                    <th style="width: 20%;">Action</th>
                </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of customerAccountlist; let i = index;">
                <td>{{i + 1}}</td>
                 <td>{{item.customers.customerNameEng}}</td>
                <td>{{item.productBuilder.name}}</td>
                <td>{{item.accountTilte}}</td>
                <td>{{item.customerAccountNo}}</td>
                <td>{{item.openingBalance}}</td>
               
                <td style="width: 20%;">
                    <!-- <button class="btn btn-sm btn-primary" (click)="editAccount(item)"><i class="fa fa-edit 3x"></i></button> -->
                    <button class="btn btn-sm btn-secondary ml-2" (click)="PrintKyc(item.id)"><i class="fa fa-file 3x"></i></button>
                    <!-- <button [disabled]="isEdit" class="btn btn-danger" style="margin-left: 5px;" (click)="delete(item.id)"><i class="fa fa-trash-o 3x"></i></button> -->
                </td>
              </tr>
             </tbody>
        </table>
        </div>  
      </div>
  </div> 
