import { Component, OnInit } from '@angular/core';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { Location } from "@angular/common";
import { LayoutService } from 'app/shared/services/layout.service';

@Component({
  selector: 'app-interest-report',
  templateUrl: './interest-report.component.html',
  styleUrls: ['./interest-report.component.scss']
})
export class InterestReportComponent implements OnInit {

  businessdate: any;
  businessdatevalue: any;
  constructor(private pdfService: PdfService,
    private layoutService: LayoutService,
    private location: Location) {}

  ngOnInit(): void {
    this.getCurrentBusinessDate();
  }
  goBack() {
    this.location.back();
  }

  getCurrentBusinessDate() {
    this.layoutService.getCurrentBusinessDate().subscribe((res: any) => {
      this.businessdatevalue = res.business_Date;
    })
  }
   OpenPDF() {
    this.pdfService
      .DpsinterestReportPdf(this.businessdatevalue)
      .subscribe((res) => {
        let blob: Blob = res.body as Blob;
        let url = window.URL.createObjectURL(blob);

        window.open(url);
      });
  } 

}
