<section>
    <div class="card" style="width: 100%;" *ngIf="showDiv === 2">
        <div class="card-body">
            <div class="container">
                <div class="row">
                    <div class="col">
                      <h5 class="card-title" style="color: black">Navbar </h5>
                    </div>
                    <div class="col" style="text-align: right;">
                        <button type="button" data-toggle="tooltip" (click)="showView(1)" class="btn btn-info" style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Go to List</button>
                    </div>
                  </div>
                <hr>
                <form [formGroup]="frmGroup">
                    <div asp-validation-summary="All" class="text-danger"></div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="name" class="col-sm-4 col-form-label">Name</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" formControlName="name" placeholder="Name">
                                </div>
                            </div>

                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="nameBn" class="col-sm-4 col-form-label">Name (bangla)</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" formControlName="nameBn" placeholder="Name Bangla">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="title" class="col-sm-4 col-form-label">Title</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" formControlName="title"
                                        placeholder="Title">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="route" class="col-sm-4 col-form-label">Route</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" formControlName="route"
                                        placeholder="url">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="icon" class="col-sm-4 col-form-label">Icon</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" formControlName="icon" placeholder="Icon">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="isActive" class="col-sm-4 col-form-label">Active
                                    </label>
                                <div class="col-sm-8">
                                    <select type="number" class="form-control"  formControlName="isActive">
                                        <option value="">--Select--</option>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="displayOrder" class="col-sm-4 col-form-label">Display Order</label>
                                <div class="col-sm-8">
                                    <input type="number" class="form-control" formControlName="displayOrder"
                                        placeholder="Display Order">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="" class="col-sm-4 col-form-label">Remarks</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" formControlName="remarks" placeholder="Remarks">
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="row">
                       
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="sortorder" class="col-sm-4 col-form-label">User Type </label>
                                <div class="col-sm-8">
                                    <select class="form-control" formControlName="userTypeId">
                                        <option value="0">All</option>
                                        <option *ngFor="let item of userTypelist" [value]="item.id">{{item.typeName}}</option>
                                    </select>                                   
                                </div>                                
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="sortorder" class="col-sm-4 col-form-label">Has parent ? </label>
                                <div class="col-sm-8">
                                    <select type="number" class="form-control" formControlName="isParent" (change)="onTypeChange()">
                                       
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </select>                                    
                                </div>                                
                            </div>
                        </div>

 
                    </div>


                    <div class="row">
                       
                        <div class="col-md-6" *ngIf="menuDiv">
                            <div class="form-group row">
                                <label for="sortorder" class="col-sm-4 col-form-label">Menu </label>
                                <div class="col-sm-8">
                                    <select class="form-control" formControlName="parentId">
                                        <option value="">--Select--</option>
                                        <option *ngFor="let item of ParentList" [value]="item.id">{{item.name}}</option>
                                    </select>
                                </div>
                                
                            </div>
                        </div>
                    </div>


                    
                    <button  data-toggle="tooltip" title="Save" type="submit" (click)="onsubmit()" value="Submit" class="btn btn-success" style="float:right; margin-top:5px;">
                    <span>{{ isEdit ? 'Update' : 'Save' }}</span>
                    </button>
                    <!-- <button type="button" data-toggle="tooltip" title="Refresh"  (click)="reset()"  class="btn btn-secondary" style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button> -->
                </form>
            </div>
        </div>
    </div>

          
      <div class="card" style="width: 100%;" *ngIf="showDiv === 1">
        <div class="card-body">
            <div class="container">
                <div class="row">
                    <div class="col">
                      <h5 class="card-title" style="color: black">Navbar List</h5>
                    </div>
                    <div class="col" style="text-align: right;">
                        <button type="button" data-toggle="tooltip" (click)="showView(2)" class="btn btn-info" style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Add Navbar</button>
                    </div>
                  </div>
                <hr>
              <table id="example" class="table table-striped table-bordered" style="width:100%">
                <thead>
                    <tr>
                      <th>SL No.</th>
                        <th>Name</th>
                        <th>Title</th>
                        <th>Route</th>
                     
                        <th>Is Parent</th>
                        <th  style="width:25%!important">Action</th>
                    </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of list; let i = index;">
                    <td>{{i + 1}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.title}}</td>
                    <td>{{item.route}}</td>
                    <td>{{item.isParent === "Menu" ? 'Active' : "Sub-Menu"}}</td>
                    <td style="width: 25%!important;">
                        <button class="btn btn-sm btn-primary mr-2" (click)="edit(item)"><i class="fa fa-edit 3x"></i></button>
                        <button [disabled]="isEdit" class="btn btn-sm btn-danger" style="margin-left: 5px;" (click)="delete(item.id)"><i class="fa fa-trash-o 3x"></i></button>
                    </td>
                  </tr>
                 </tbody>
            </table>
            </div>  
          </div>
      </div>   
</section>



