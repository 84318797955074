import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/shared/auth/auth.service';
import { environment } from 'environments/environment';
import { Observable, fromEvent, timer } from 'rxjs';
import { takeWhile } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private apiUrl: string = environment.baseUrl;

  private readonly INACTIVE_TIMEOUT = environment.sessionTimeout;
  private timer: any;
  private userSessionId: any;

  constructor(private router: Router, private auth: AuthService, private http: HttpClient) { }

  initSessionTimer(): void {
    let lastActivityTime = Date.now();

    const activity$ = fromEvent(document, 'mousemove').pipe(
      takeWhile(() => true)
    );

    activity$.subscribe(() => {
      lastActivityTime = Date.now();
      clearTimeout(this.timer);
      this.startTimer(lastActivityTime);
    });

    this.startTimer(lastActivityTime);
  }

  private startTimer(lastActivityTime: number): void {
    const timeSinceLastActivity = Date.now() - lastActivityTime;
    const timeToLogout = this.INACTIVE_TIMEOUT - timeSinceLastActivity;

    this.timer = setTimeout(() => {
      console.log('timeSinceLastActivity>', timeSinceLastActivity);
      this.logout();
    }, timeToLogout);
  }

  private logout(): void {
    sessionStorage.clear();
    this.auth.clearAuthToken();
    this.router.navigate(['/pages/login']);
  }

  saveUserLastSession(username: string, sessionId: string): Observable<any> {
    const requestBody = { username, sessionId };
    return this.http.post<number>(`${this.apiUrl}/api/SaveUserLastSessionId`, requestBody);
  }

  
  GetActiveSessionByUserName(username: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/api/GetActiveSessionByUserName?userName=${username}`);
  }

  fetchAndSetUserSession(username: string) {
    this.GetActiveSessionByUserName(username).subscribe(
      (response: any) => {
        this.userSessionId = response.sessionId;

        // sessionStorage.setItem('userSessionId', this.userSessionId);

        if(this.userSessionId != sessionStorage.getItem('sessionToken')){
          //logged out if multiple session found
          // this.logout();
        }
      },
      (error: any) => {
        console.error('Error occurred:', error);
      }
    );
  }

  validateUserSession(){
    console.log(this.userSessionId);
    // if(this.userSessionId != sessionStorage.getItem('sessionToken')){
    //   this.logout();
    // }
  }

}
