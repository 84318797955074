<section>
    <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveForm'">
  
      <div class="card-body">
        <div class="container">
          <div class="row">
            <div class="col">
              <h5 class="card-title" style="color: black">Transaction Event</h5>
              <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary"
   (click)="onClickShowSaveForm('saveList')"
   style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">List View</button>
            </div>
          </div>
          <hr>
          <form [formGroup]="frmGroup">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Transaction Name</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" name="transactionName" formControlName="transactionName"
                        placeholder="Transaction Name">
                    </div>
                  </div>
                <div class="form-group row">
                  <label for="" class="col-sm-4 col-form-label">Transaction Code</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" name="transactionCode" formControlName="transactionCode"
                      placeholder="Transaction Code">
                  </div>
                </div>
  
                <div class="form-group row">
                  <label for="" class="col-sm-4 ">Description</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" name="description" formControlName="description"
                      placeholder="Description">
                  </div>
                </div>
                <div class="form-group row">
                    <label for="" class="col-sm-4 ">Sort Order</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" name="sortOrder" formControlName="sortOrder"
                        placeholder="Sort Order">
                    </div>
                  </div>
               
              </div>
              <div class="col-md-6">
              </div>
            </div>
            <button data-toggle="tooltip" (click)="onsubmit()" title="Save" type="button" class="btn btn-success"
              style="float:right; margin-top:5px;">Save </button>
  
          </form>
        </div>
  
      </div>
  
  
    </div>
    <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveList'">
        <div class="card-body">
          <div class="container">
            <div class="row">
              <div class="col">
                <h5 class="card-title" style="color: black">Transaction Event List</h5>
                <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary"
                  (click)="onClickShowSaveForm('saveForm')"
                  style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">Add New</button>
              </div>
    
            </div>
            <hr>
            <div class="row">
              <div class="col-md-6" style="text-align: left;">
                <select type="number" class="form-control" style="width: 16%;" (change)="onChangeForShow($event.target.value)">
                  <option value="5">5</option>
                  <option value="10" selected>10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div class="col-md-6 form-group" style="text-align: right;">
                <input type="text" class="form-control" placeholder="Search" [(ngModel)]="searchControl" (keyup)="onSearch()">
              </div>
            </div>
            <table id="example" class="table table-bordered  ">
              <thead>
                <tr>
                  <th>SL No.</th>
                  <th>Transaction Name</th>
                  <th>Transaction Code</th>
                  <th>Description</th>
                  <th>Sort Order</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of glList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }; let i = index;">
                  <td>{{ (currentPage - 1) * itemsPerPage + i + 1 }}</td>
                  <td>{{item.transactionName}}</td>
                  <td>{{item.transactionCode}}</td>
                  <td>{{item.description}}</td>
                  <td>{{item.sortOrder}}</td>
                    <td style="width: 15%!important;">
                    <button class="btn btn-primary btn-sm" (click)="edit(item)"><i class="fa fa-edit"></i></button>
                    <button [disabled]="isEdit" class="btn  btn-danger btn-sm" style="margin-left: 2px;margin-right:6px"
                      (click)="delete(item.id)"><i class="fa fa-trash-o"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="row">
              <div class="col-md-6" style="text-align: left">
                <span>Showing {{currentItems}} of {{totalItems}} entries</span>
              </div>
              <div class="col-md-6" style="text-align: right">
                <pagination-controls (pageChange)="onPageChange($event)"></pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
  </section>
