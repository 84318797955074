import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SharedService } from 'app/services/shared.service';
import { SubGroupService } from '../service/sub-group.service';
import { GroupService } from '../service/group.service';
import { Observable } from 'rxjs';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'app-sub-group',
  templateUrl: './sub-group.component.html',
  styleUrls: ['./sub-group.component.scss']
})
export class SubGroupComponent implements OnInit {
  list: any[] = [];
  GroupNatureList: any[] = [];
  natureList: any[] = [];
  AccountGroupList: any[] = [];
  showSaveForm: string = '';
  groupListByNature: any[] = [];
  isEdit: boolean = false;
  frmGroup: FormGroup;
  model: any = {};
  accountSubGroupList : Observable<any[]>;
  currentPage = 1;
  itemsPerPage = 10;
  totalItems = 0;
  currentItems = 0;
  searchControl = "";


  constructor(private formBuilder: FormBuilder,
    private shared: SharedService,
    private _subGroupService: SubGroupService,
    private _groupService: GroupService,
    private changeDetectorRef: ChangeDetectorRef,

  ) 
  { 


  }

  ngOnInit(): void {
    this.populateForm();
    this.reset();
    this.GetAccountSubGroupList();
   // this.GetAccountGroupList();
    this.GetGroupNatureList();
    this.GetSubGroupList();
    
    this.showSaveForm = 'saveList';


  }

  onClickShowSaveForm(value: string) {
    this.showSaveForm = value;

    this.reset();
  }

  populateForm() {
    this.frmGroup = this.formBuilder.group({
      id: [null],
      GroupCode: ['',],
      GroupName: ['',],
      //NatureId: ['',],
      sortOrder: [''],
      ParentGroupId: [''],
      natureId: [0],
    })
  }
  
  GetAccountSubGroupList() {
    this._subGroupService.GetAccountGroupList().subscribe(res => {
      this.list = res;
    })
  }

  GetSubGroupList(){
    this._subGroupService.GetAccountSubGroupList(this.currentPage, this.itemsPerPage, this.searchControl).subscribe((res=>{

      this.accountSubGroupList = res.data;
      this.totalItems = res.totalItems;
      this.currentItems = res.currentItems;
    }))
  }

  GetGroupNatureList() {
    this._groupService.GetGroupNatureList().subscribe(res => {
      //this.GroupNatureList = res;
      this.natureList = res;
    })
  }

  GetAccountGroupList() {
    this._groupService.GetAccountGroupList().subscribe(res => {
      this.AccountGroupList = res;
    })
  }

  onsubmit() {
    if (this.isEdit) {
      this.model.id = this.frmGroup.value.id;
    }
    else{
      this.model.id =0;
    }
    this.model.GroupCode = this.frmGroup.value.GroupCode;
    this.model.GroupName = this.frmGroup.value.GroupName;
    //this.model.NatureId = this.frmGroup.value.NatureId;
    this.model.sortOrder = (this.frmGroup.value.sortOrder===null || this.frmGroup.value.sortOrder==='')?0:this.frmGroup.value.sortOrder;
    this.model.ParentGroupId = this.frmGroup.value.ParentGroupId;
    this._subGroupService.SaveAccountGroup(this.model).subscribe(res => {
      if (this.isEdit){
        this.shared.ShowAlert('Thank you...', 'You updated succesfully!', 'success');
      }
      else{
        this.shared.ShowAlert('Thank you...', 'You save succesfully!', 'success');
      }
      
      this.GetSubGroupList();
      this.GetAccountSubGroupList();
      this.isEdit = false;
      this.showSaveForm = 'saveList';
    })
  }

  reset() {
    this.isEdit = false;
    this.populateForm();
    this.model = {};
  }

  edit(item: any) {
    
    this.isEdit = true;
    this.showSaveForm = 'saveForm';
    this.getGroupByNatureId(item.natureId);
    this.frmGroup.patchValue({
      id: item.id,
      GroupCode: item.groupCode,
      GroupName: item.groupName,
      natureId: item.natureId,
      sortOrder: item.sortOrder,
      ParentGroupId: item.parentGroupId,
    });
  }

  DeleteaccountGroupById(id: any) {
    this._subGroupService.DeleteaccountGroupById(id).subscribe(res => {
      this.shared.ShowAlert('Thank you...', 'Delete succesfully!', 'success');
      this.GetAccountGroupList();
      this.GetSubGroupList();
    })
  }

  GetSubGroupCode(gId: any): void {
    this._groupService.GetSubGroupCode(gId).subscribe((res: any) => {
      this.frmGroup.patchValue({
        GroupCode: res.code,
      })
    });
  }
  
  getGroupByNatureId(natureId: number) {
    //debugger
    this._groupService.GetAccountGroupListByNature(natureId).subscribe(res => {
      this.AccountGroupList = res;
    })
  }

  onNatureGroupChange(nId: number) {
    //debugger
    //alert(nId);
    if (nId != 0 || nId != null) {
       this.getGroupByNatureId(nId);
    }

  }

  onChangeForShow(value:any){
    this.currentPage = 1;
    this.itemsPerPage = value;
    this.GetSubGroupList();
  }

  onSearch(){
    this.currentPage = 1;
    this.GetSubGroupList();
  }

  onPageChange(pageNumber : number){
    this.currentPage = pageNumber;
    this.GetSubGroupList();
  }
}
