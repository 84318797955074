import { Component, OnInit } from '@angular/core';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { Location } from "@angular/common";

@Component({
  selector: 'app-upcoming-matured-report',
  templateUrl: './upcoming-matured-report.component.html',
  styleUrls: ['./upcoming-matured-report.component.scss']
})
export class UpcomingMaturedReportComponent implements OnInit {

  days: number;
  constructor(private pdfService: PdfService, private location: Location) {}

  ngOnInit(): void {}
  goBack() {
    this.location.back();
  }

  OpenPDF() {
    this.pdfService
      .UpcomingMaturedDPS(this.days)
      .subscribe((res) => {
        let blob: Blob = res.body as Blob;
        let url = window.URL.createObjectURL(blob);

        window.open(url);
      });
  }

}
