import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { id } from '@swimlane/ngx-datatable';

const apiUrl=environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class OccupationService {

  constructor(private http: HttpClient) { }

  getlist():Observable<any>
   {
        return this.http.get(apiUrl + '/api/Occupation/GetAllOccupation');
   }
    getListWithSerchAndPagination(currentPage: any, itemsPerPage: any, searchValue: string) {
    return this.http.get(apiUrl + '/api/Occupation/GetAllOccupationWithPaginationSearch?page='+ currentPage + '&pageSize=' +itemsPerPage + '&search=' + searchValue);
  }
  

   save(model: any){
        return this.http.post(apiUrl + '/api/Occupation/ekyc/InsertOccupation',model);
      }

      delete(Id:any){
   
        return this.http.delete(apiUrl +'/api/Occupation/DeleteOccupationbyId?Id=' +Id);
      }
}
