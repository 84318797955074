import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'; // Import from the correct module
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

//const apiurl = environment.baseUrl;

const apiurl = environment.baseUrl+'/api/LoanApplication';
const apiurlDis = environment.baseUrl+'/api/LoanDisbursement';

@Injectable({
  providedIn: "root",
})
export class LeadGenerationServiceService {
  constructor(private http: HttpClient) {}

  searchLeadGeneration(searchValue: any) {
    return this.http.get(apiurl + "/GetLeadGenerationByLGCode/" + searchValue);
  }

  searchLeadInfoByLGCode(searchValue: any) {
    return this.http.get(apiurl + "/GetLeadInfoByLGCode/" + searchValue);
  }
  GetLoanScheduleCode(searchValue: any) {
    return this.http.get(apiurl + "/GetLoanScheduleCode/" + searchValue);
  }

  GetExistingLeadGeneration(): Observable<any> {
    return this.http.get(apiurl + "/GetEsxistingLeadGenerationByCustomer/");
  }

  // getSaveLeadGeneration() {
  //   return this.http.post(apiurl + '/saveLeadGeneration', {});
  // }

  getSaveLeadGeneration(
    customerId: string,
    loanProductId: string
  ): Observable<any> {
    // Include customerId and productId in the request body
    const requestBody = {
      customerId: customerId,
      loanProductId: loanProductId,
    };

    return this.http.post(apiurl + "/saveLeadGeneration", requestBody);
  }

  getSaveLeadGenerationNew(
    customerId: string,
    loanProductId: string,
    tenureId: string
  ): Observable<any> {
    // Include customerId and productId in the request body
    const requestBody = {
      customerId: customerId,
      loanProductId: loanProductId,
      tenureId: tenureId,
    };

    return this.http.post(apiurl + "/saveLeadGeneration", requestBody);
  }

  searchLeadCodebyId(leadId: any) {
    return this.http.get(apiurl + "/GetLeadGenerationByLGId/" + leadId);
  }

  getMoneyLaunderingCustomerByCustomerId(customerId: any) {
    return this.http.get(
      apiurl +
        "/CheckMoneyLaunderingCustomerFromOAMLlist?customerId=" +
        customerId
    );
  }

  //loan register
  GetLoanRegistersById(id: any): Observable<any> {
    return this.http.get(apiurl + "/GetLoanRegistersById/" + id);
  }

  saveLoanRegister(model: any) {
    return this.http.post(apiurl + "/SaveLoanRegister", model);
  }

  GetLoanRegistersAll(): Observable<any> {
    return this.http.get(apiurl + "/GetLoanRegisters");
  }

  GetLoanSchedulePreview(
    id: number,
    amount: number,
    tenureId: number,
    date: any
  ): Observable<any> {
    console.log(date);
    return this.http.get(
      apiurlDis +
        "/GetInstallmantAmountFeesInfoPreview?productId=" +
        id +
        "&amount=" +
        amount +
        "&tenureId=" +
        tenureId +
        "&firstInstallmentDate=" +
        date
    );
  }
}
