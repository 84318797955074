import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LeadInfoService } from '../lead-info.service';

@Component({
  selector: 'app-customerlead-applied',
  templateUrl: './customerlead-applied.component.html',
  styleUrls: ['./customerlead-applied.component.scss']
})
export class CustomerleadAppliedComponent implements OnInit {
  LeadLists:any []=[];

  constructor(
     private _leadService:LeadInfoService,
    private changeDetectorRef: ChangeDetectorRef,) { }

  ngOnInit(): void {
    this.getLeadList();
  }
  getLeadList(){
   
    this._leadService.getLeadList().subscribe((res: any[]) =>{

      this.LeadLists =res.filter(item => item.isComplete == 1 && item.status ===0);
      this.changeDetectorRef.detectChanges();
    })
  }
}
