<section>
  <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveForm'">
    <div class="card-body">
      <div class="container">
        <h5 class="card-title" style="color: black">Sub Group Creation</h5>
        <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary" (click)="onClickShowSaveForm('saveList')" style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">List View</button>
        <hr>
        <form [formGroup]="frmGroup">
          <div asp-validation-summary="All" class="text-danger"></div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label for="NatureId" class="col-sm-4 col-form-label">GL Type<span
                    style="color:red; font-size: 15px; ">*</span></label>
                    <div class="col-sm-8">
                      <select (change)="onNatureGroupChange($event.target.value)" class="form-control" name="natureId" formControlName="natureId">
                        <option value="0">Select</option>
                        <ng-container *ngFor="let item of natureList">
                          <option [value]="item.id">{{item.natureName}}</option>
                        </ng-container>
                      </select>
                    </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group row">
                <label for="NatureId" class="col-sm-4 col-form-label">Account Group<span
                    style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <select class="form-control" (change)="GetSubGroupCode($event.target.value)"
                    formControlName="ParentGroupId" name="ParentGroupId">
                    <option value="">Select</option>
                    <option *ngFor="let item of AccountGroupList" [value]="item.id">{{item.groupName}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group row">
                <label for="GroupCode" class="col-sm-4 col-form-label">Sub-Group Code<span style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="text" readonly class="form-control" formControlName="GroupCode" placeholder="Group Code">
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group row">
                <label for="GroupName" class="col-sm-4 col-form-label">Sub-Group Name<span style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="GroupName" placeholder="Group Name">
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group row">
                <label for="sortOrder" class="col-sm-4 col-form-label">Sort Order</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="sortOrder" placeholder="Sort Order">
                </div>
              </div>
            </div>
            <!-- <div class="col-md-6">
              <div class="form-group row">
                <label for="sortOrder" class="col-sm-4 col-form-label">Sort Order</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="sortOrder" placeholder="Sort Order">
                </div>
              </div>
            </div> -->
          </div>
          <div class="form-group">
            <button [disabled]="!frmGroup.valid" data-toggle="tooltip" title="Save" (click)="onsubmit()" type="submit" value="Submit" class="btn btn-success" style="float:right; margin-top:5px;">
              <span>{{ isEdit ? 'Update' : 'Save' }}</span>
            </button>
            <button type="button" data-toggle="tooltip" title="Refresh" (click)="reset()" class="btn btn-secondary" style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveList'">
    <div class="card-body">
      <div class="container">
        <h5 class="card-title" style="color: black">Sub Group Creation List</h5>
        <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary" (click)="onClickShowSaveForm('saveForm')" style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">Add New</button>
        <hr>
        <div class="row">
          <div class="col-md-6" style="text-align: left;">
            <select type="number" class="form-control" style="width: 16%;" (change)="onChangeForShow($event.target.value)">
              <option value="5">5</option>
              <option value="10" selected>10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div class="form-group col-md-6" style="text-align: right;">
            <input type="text" class="form-control"  [(ngModel)]="searchControl" placeholder="Search" (keyup)="onSearch()">
        </div>
        </div>
        <table id="example" class="table table-striped table-bordered" style="width:100%">
          <thead>
            <tr>
              <th>SL No.</th>
              <th>Group Nature</th>
              <th>Group Code</th>
              <th>Group Creation</th>
              
              <th>Group Name</th>
              <th>Sort Order</th>

              <th style="width: 20%;">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of accountSubGroupList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }; let i = index;">
              <td>{{ (currentPage - 1) * itemsPerPage + i + 1 }}</td>
              <td>{{item.nature?.natureName}}</td>
              <td>{{item.groupCode}}</td>
              <td>{{item.parentGroup?.groupName}}</td>
             
              <td>{{item.groupName}}</td>
              <td>{{item.sortOrder}}</td>
              <td style="width: 20%;">
                <button class="btn btn-primary" (click)="edit(item)"><i class="fa fa-edit 3x"></i></button>
                <button [disabled]="isEdit" class="btn btn-danger" style="margin-left: 5px;" (click)="DeleteaccountGroupById(item.id)"><i class="fa fa-trash-o 3x"></i></button>

              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-md-6" style="text-align: left">
            <span>Showing {{currentItems}} of {{totalItems}} entries</span>
          </div>
          <div class="col-md-6" style="text-align: right">
            <pagination-controls (pageChange)="onPageChange($event)"></pagination-controls>
        </div>
        </div>
      </div>
    </div>
  </div>


</section>
