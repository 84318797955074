<div class="row" (resized)="onResized($event)">
    <div class="card" style="width: 100%;">
        <div class="card-header py-3 d-flex flex-row align-items-center">
            <h6 class="m-0 font-weight-bold text-primary">Scheduled List</h6>
        </div>
        <div class="card-body">
            <div class="container">
                <table id="example" class="table table-striped table-bordered" style="width:100%">
                    <thead>
                        <tr>
                            <th style="width: 5%!important;">SL No.</th>
                            <th style="width: 8%!important;">Loan Account</th>
                            <th style="width: 12%!important;">Customer</th>
                            <th style="width: 8%!important;">Disburse Date</th>
                            <th style="width: 8%!important;">Loan Amount</th>
                            <th style="width: 5%!important;">Status</th>
                           
                            <th style="width: 25%!important;">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of LeadList; let i = index;">
                            <td style="width: 5%!important;">{{i + 1}}</td>
                            <td  style="width: 8%!important;">{{item?.customerAccountNo}}</td>
                           
                            <td style="width: 12%!important;">
                                <span>Name:{{item.customers?.customerNameEng }}</span> <br>
                                <span>Mobile No:{{item.customers?.mobileNo}} </span><br>
                             </td>

                           <td style="width: 8%!important;">{{item?.leadGeneration?.updatedAt | date:'dd-MM-yyyy'}}</td>
                           <td style="width: 8%!important;">{{item?.leadGeneration?.loanAmount}}</td>
                            <td style="width: 5%!important;">
                                <!-- [ngClass]="{'bg-light-info':item.isComplete == 1 && item.status == 2 || item.status == 3}" -->
                                <div class="badge badge-pill">
                                    Scheduled
                                </div>
                            </td>
                            
                            <td style="width: 25%!important;">
                                <button class="btn btn-sm btn-info mb-2" (click)="OpenPDF(item?.leadGeneration?.id)" type="button" title="Disburse Loan"> View Pdf</button>
                                <button class="btn btn-sm btn-success" (click)="checkPayment(item.leadGeneration?.id,item?.customerAccountNo,item?.leadGeneration?.loanAmount)" type="button" title="Disburse Loan"> Disburse</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>