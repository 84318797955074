import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
const apiUrl=environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class CashApproveService {

  constructor(private http: HttpClient) { }
    getAlllist():Observable<any>
    {
        return this.http.get(apiUrl + '/api/CashTransaction/GetVoucherMasterListByApprover');
    }
}
