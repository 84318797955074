
<div class="card">

    <div class="row card-header  d-flex flex-row align-items-center">
        <div class="col-6">
        <h5 class="card-title" style="color:black">Loan Settlement</h5>
        </div>
        <div class="col-6">
            <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
                <!-- <i class="ft-chevrons-left"> Back</i> -->
                <i class="icon-arrow-left" style="font-size: 17px;"> </i>
            </button>
        </div>
    </div>
    
    <hr style="height: 10%;">
<div class="card-body">


<form [formGroup]="frmGroup">
<div asp-validation-summary="All" class="text-align"></div>
<div class="row">
<div class="col-md-6">
    <div class="form-group row">
        <label class="col-sm-4 col-form-label">Loan Account</label>
        <div class="col-sm-8">
            <select class="form-control" (change)="getCustomerLeadList($event.target.value)">
                <option value="">Select</option>
                <option *ngFor="let item of List" [value]="item.leadGenerationId">
                    {{item.customerAccountNo}}-{{item.accountType}}
                </option>
            </select>
        </div>
    </div>
</div>
<div class="col-md-6">
    <div class="form-group row">
        <label class="col-sm-4 col-form-label">OutStanding</label>
        <div class="col-sm-8">
            <input type="hidden" formControlName="leadId"/>
            <input type="hidden" formControlName="customername"/>
            <input type="hidden" formControlName="outstanding"/>
            <!-- <input type="hidden"  formControlName="collectionAmount"/> -->
            <input type="text" class="form-control" readonly style="text-align: right;" [value]="frmGroup.get('outstanding').value | number:'1.0-0'"/>
        </div>
    </div>
</div>

<div class="col-md-6">
    <div class="form-group row">
        <label class="col-sm-4 col-form-label">Settlement Fee</label>
        <div class="col-sm-8">
            <input type="text" class="form-control"  readonly
                style="text-align: right;" [value]="frmGroup.get('settelementFeeAmount').value | number:'1.0-0'" />

            <!-- <div *ngIf="frmGroup.get('collectionAmount').hasError('pattern')" class="text-danger">
                Only numeric values are allowed.
            </div> -->


        </div>
    </div>
</div>
<div class="col-md-6">
    <div class="form-group row">
        <label class="col-sm-4 col-form-label">Settlement Amount</label>
        <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="collectionAmount"   readonly style="text-align: right;" [value]="frmGroup.get('collectionAmount').value | number:'1.0-0'"/>
            
            <!-- <div *ngIf="frmGroup.get('collectionAmount').hasError('pattern')" class="text-danger">
                Only numeric values are allowed.
            </div> -->

            
        </div>
    </div>
</div>
</div>

<button class="btn btn-success ml-2" (click)="CollentionPDF()" type="button" title="Disburse Loan"
    style="float:right; margin-top:5px;"> Payment PDF </button>

<button class="btn btn-info ml-2" (click)="SchedulePDF()" type="button" title="Disburse Loan"
    style="float:right; margin-top:5px;"> Schedule PDF</button>

<button [disabled]="!frmGroup.valid" data-toggle="tooltip" title="Save" type="submit" (click)="SaveLoanSettlement()"
    value="Submit" class="btn btn-primary" style="float:right; margin-top:5px;">
    <span>Loan Payment</span>
</button>

</form>
</div>
</div>