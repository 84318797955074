<div class="" style="width: 100%;">
  <div class="">
    <div class="container">
      <div class="" style="margin-top: 3%;">
        <h3 class="card-title" style="color: black;text-align: center;"> eKYC - Customer Registration</h3>
      </div>

      <hr style="height: 10%;">


      <div class="card"> 
        <div class="card-body">
          <form #customerForm="ngForm">
            <div>
              <div class="row">
                <div class="col-md-6">

                  <div class="form-group row">
                    <label for="name" class="col-sm-4 col-form-label">Customer Name</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" name="CustomerNameEng" placeholder="Name English"
                        [(ngModel)]="model.CustomerNameEng" #CustomerNameEng="ngModel" readonly>

                    </div>
                  </div>


                  <!-- <div class="form-group row" style="display: none;">
                    <label for="name" class="col-sm-4 ">Customer Name (Bangla)</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" name="CustomerNameBan" placeholder="Name bangla"
                        [(ngModel)]="model.CustomerNameBan" #CustomerNameBan="ngModel" readonly>
                     
                    </div>
                  </div> -->

                  <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Father Name</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" name="FatherNameEng" placeholder=""
                        [(ngModel)]="model.FatherNameEng" readonly>

                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Mother Name</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" name="MotherNameEng" placeholder=""
                        [(ngModel)]="model.MotherNameEng" readonly>

                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">NID</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" name="NidNo" placeholder="NID" [(ngModel)]="model.NidNo"
                        readonly>
                    </div>
                  </div>

                  <!-- <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Father Name (Bangla)</label>
                    <div class="col-sm-8">
                     
                      <input type="text" class="form-control" name="FatherNameBan" [(ngModel)]="model.FatherNameBan" readonly>
                    </div>
                  </div>                   -->

                </div>

                <div class="col-md-6">

                  <div class="form-group row">
                    <label for="image" class="col-sm-4 col-form-label">Profile PICTURE <span
                        style="color:red">*</span></label>
                    <div class="col-sm-8">
                      <input type="file" accept="image/*" id="image" (change)="selectProfilePicture($event)"
                        style="display: none;" #fileInput />
                      <div style="height: 180px; width: 180px; cursor: pointer;" (click)="fileInput.click()">

                        <img src="{{ proPreview ? proPreview : 'assets/img/gallery/1Icon.png' }}" id="imageProPreview"
                          style="width: 100%; height: 100%;" class="proPreview" title="Click here to upload photo" />
                        <!-- <img *ngIf="!proPreview" src="assets/img/gallery/1Icon.png" id="imageProPreview"
                          style="width: 100%; height: 100%;" class="proPreview" title="Click here to upload photo" />
                          <img *ngIf="proPreview" src="{{model.profileImage}}" id="imageProPreview"
                          style="width: 100%; height: 100%;" class="proPreview" title="Click here to upload photo" /> -->


                      </div>
                    </div>
                  </div>

                </div>

              </div>

              <div class="row mt-2">


                <!-- <div class="col-md-6">
                  <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Mother Name (Bangla)</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" name="MotherNameBan" placeholder=""
                        [(ngModel)]="model.MotherNameBan" readonly>
                      
                    </div>
                  </div>
                </div> -->
              </div>

              <div class="row mt-2">
                <div class="col-md-6">
                  <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Date Of Birth</label>
                    <div class="col-sm-8">
                      <input class="form-control" type="date" placeholder="yyyy-mm-dd" name="dp" name="DateOfBirth"
                        [(ngModel)]="model.DateOfBirth" readonly>
                    </div>
                  </div>

                </div>

                <div class="col-md-6">
                  <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Mobile No</label>
                    <div class="col-sm-8">
                      <input class="form-control" type="text" placeholder="" name="MobileNo" name="MobileNo"
                        [(ngModel)]="model.MobileNo" readonly>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2">

                <div class="col-md-6">
                  <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Gender<span style="color: red;"> *</span></label>
                    <div class="col-sm-8">
                      <select class="form-control" name="GenderId" placeholder="" [(ngModel)]="model.GenderId">
                        <option value="">Select</option>
                        <option *ngFor="let item of genderList | async" [value]="item.id">{{item.name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Email<span style="color: red;"> *</span></label>
                    <div class="col-sm-8">

                      <input class="form-control" type="text" placeholder="" name="Email" name="Email"
                        [(ngModel)]="model.Email" required>

                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="row mt-2">

                <div class="col-md-6">
                  <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Marital Status</label>
                    <div class="col-sm-8">
                      <select class="form-control" name="MaritalStatus" id="MaritalStatus"
                        [(ngModel)]="model.MaritalStatus">
                        <option selected value="">Select</option>

                        <option value="Married">Married</option>
                        <option value="Unmaried">Unmaried</option>

                      </select>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Spouse Name</label>
                    <div class="col-sm-8">
                      <input class="form-control" type="text" placeholder="" name="SpouseNameEng"
                        [(ngModel)]="model.SpouseNameEng" readonly>

                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group row">
                    <label for="SpouseMobileNo" class="col-sm-4 col-form-label">Spouse Mobile</label>
                    <div class="col-sm-8">
                      <input class="form-control" type="text" placeholder="" name="SpouseMobileNo"
                        name="SpouseMobileNo">
                    </div>
                  </div>

                </div>


                 <div class="col-md-6">
                      <div class="form-group row">
                         <label for="" class="col-sm-4 col-form-label">Spouse Name (Bangla)</label>
                         <div class="col-sm-8">
                            <input class="form-control" type="text" placeholder="" name="SpouseNameBan" name="SpouseNameEng">
                         </div>
                      </div>
                    </div>  

              </div> -->

              <!-- <div class="row mt-2">
                <div class="col-md-6 ">
              <!-- <div class="row mt-2">
                <div class="col-md-6 ">
                  <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">District</label>
                    <div class="col-sm-8">
                      <select (change)="onDistrictChange($event.target.value)" class="form-control" name="DistrictId"
                        placeholder="" [(ngModel)]="model.DistrictId">
                        <option value="null">Select</option>
                        <option value="null">Select</option>
                        <ng-container *ngFor="let item of districtList | async">
                          <option [value]="item.id">{{item.districtName}}</option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 ">
                  <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Upazila</label>
                    <div class="col-sm-8">

                      <select class="form-control" name="UpazilaId" placeholder="" [(ngModel)]="model.UpazilaId">
                        <option value="null">Select</option>
                        <option value="null">Select</option>
                        <option *ngFor="let item of upazilaList | async" [value]="item.id">{{item.upazilaName}}</option>
                      </select>
                    </div>
                  </div>
                </div> 
              </div> -->
              <!-- <div class="row mt-2">
                <div class="col-md-6">
                  <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Present Address</label>
                    <div class="col-sm-8">
                       
                       <textarea class="form-control" type="text" placeholder="" [(ngModel)]="model.presentAddressEN" readonly>  </textarea>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Permanent Address</label>
                    <div class="col-sm-8">
                      <textarea class="form-control" type="text" placeholder="" [(ngModel)]="model.presentAddressEN" readonly> </textarea>
                       
                    </div>
                  </div>
                </div>
              </div> -->

              <button data-toggle="tooltip" title="Save" type="button" (click)="onsubmit(customerForm)"
                class="btn btn-success" style="float:right; margin-top:5px;"> <span>{{ isEdit ? 'Update' : 'Save'
                  }}</span></button>



              <button type="button" data-toggle="tooltip" title="Refresh" (click)="resetCust()"
                class="btn btn-secondary"
                style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>


            </div>



          </form>
        </div>
      </div>





    </div>
  </div>
</div>