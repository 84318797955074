import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SharedService } from 'app/services/shared.service';
import { NavbarsService } from '../navbars.service';
import { LoanProductService } from 'app/product/service/loan-product.service';

@Component({
  selector: 'app-loan-menu-setup',
  templateUrl: './loan-menu-setup.component.html',
  styleUrls: ['./loan-menu-setup.component.scss']
})
export class LoanMenuSetupComponent implements OnInit {
  List:any []=[];
  ParentList:any []=[];
  isEdit:boolean=false;
  loanFromGrp: FormGroup;
  userTypelist: any[] = [];
  loanModel:any={};
  productList:any[]=[];
  showSaveForm:string='';

  parentNameId:any;

  constructor(private formBuilder: FormBuilder,
    private shared:SharedService,
    private _service:NavbarsService,
    private _loanProductService:LoanProductService,
    private changeDetectorRef: ChangeDetectorRef,) { }

  ngOnInit(): void {
    this.loanForm();
    this.getLoanProductBuilderInfoList();
    this.GetUserAllRolesByUserName();
    this.GetLoanMenuSetup();
    this.showSaveForm='saveForm';
  }
  loanForm(){
    this.loanFromGrp=this.formBuilder.group({
      id:[0],     
      name:[''],
      nameBn:[''],
      position:[''],
      parentId:[0],
      LoanProductId:[0],
      applicationRoleId:[''],     
      status:[''],
      url:[''],
      sortOrder:[0],
      
    })
  }

  refresh() {
    this.isEdit = false;
    this.loanForm();
    this.loanModel = {};
  }

  onClickShowSaveForm(value:string){
    this.showSaveForm=value;
    this.refresh();
  }

  onParentChange(value:any){
    if(value != 0){
    this.parentNameId=value;
    }
  }

  GetLoanMenuSetup(){
    this._service.GetLoanMenuSetup().subscribe(res=>{
      
      this.ParentList=res;
      console.log('ParentList');
      console.log(this.ParentList);
    })
  }
  getLoanProductBuilderInfoList() {
    this._loanProductService.getLoanProductList().subscribe(res => {
      this.productList= res;
      //this.selectedProductId = '';
      this.changeDetectorRef.detectChanges();
    })
  }
  GetLoanType(){
    this._service.GetLoanType().subscribe(res=>{
      
      this.List=res;
    })
  }
  GetUserAllRolesByUserName() { 
    this._service.GetUserAllRolesByUserName().subscribe(res => {    
      this.userTypelist = res;
    })
  }
  onSubmit() {
    if (this.isEdit) {
      this.loanModel.id = this.loanFromGrp.value.id;
    }
 
    this.loanModel.name = this.loanFromGrp.value.name;
    this.loanModel.nameBn = this.loanFromGrp.value.nameBn;
    this.loanModel.position = this.loanFromGrp.value.position;
    this.loanModel.parentId = this.loanFromGrp.value.parentId;
    this.loanModel.LoanProductId = this.loanFromGrp.value.LoanProductId;
    this.loanModel.applicationRoleId = this.loanFromGrp.value.applicationRoleId;

    this.loanModel.status = this.loanFromGrp.value.status;
    this.loanModel.url = this.loanFromGrp.value.url;
    this.loanModel.sortOrder = this.loanFromGrp.value.sortOrder;
    console.log(222);
  console.log(this.loanModel);
    this._service.SaveLoanMenuSetup(this.loanModel).subscribe(res => {

      this.shared.ShowAlert("Success","Save Successfully","success");
      this.getLoanProductBuilderInfoList();      
      this.GetUserAllRolesByUserName();      
      this.GetLoanMenuSetup();      
      // this.showSaveForm='ListView';
      this.showSaveForm='saveForm';
      this.refresh();
    })
  }

  edit(item:any){

    this.isEdit = true;
    this.showSaveForm = 'saveForm';
    console.log("item")
    console.log(item)
    this.loanFromGrp.patchValue({
      
      id: item.id,
      name: item.name,
      nameBn: item.nameBn,
      position: item.position,
      parentId: item.parentId,
      LoanProductId: item.loanProduct?.id,
      applicationRoleId: item.applicationRoleId,
      status: item.status,
      url: item.url,
      sortOrder: item.sortOrder,
  
    })
   
  }
  DeleteLoanMenuSetup(id:any){
    this._service.DeleteLoanMenuSetup(id).subscribe(data=>{
      this.shared.ShowAlert("Alert", "Data Deleted Successfully", "warning");
      this.GetLoanMenuSetup();
      this.showSaveForm='saveList';
    })
  }
}
