import { Injectable } from "@angular/core";
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CibreportsService {
  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {}

  GenerateCIBSubject(generateDate: any) {
    return this.http.get(
      this.baseUrl + "/GenerateCIBSubjectReport?generateDate=" + generateDate
    );
  }

  GetCIBSubjectPerson(generateMonth: any, generateyear: any) {
    return this.http.get(
      this.baseUrl +
        "/CIBSubjectReportPreView?generateMonth=" +
        generateMonth +
        "&generateyear=" +
        generateyear
    );
  }

  OpenCibSubjectReport(reportDate: any) {
    return this.http.get(
      this.baseUrl + "/GetCIBSubjectReport?reportDate=" + reportDate,
      {
        observe: "response",
        responseType: "blob",
      }
    );
  }
  OpenCibSubjectReportText() {
    return this.http.get(this.baseUrl + "/DownloadCIBSubjectText", {
      observe: "response",
      responseType: "blob",
    });
  }

  GenerateCIBSubjectPersonTextWithDate(generateMonth: any, generateyear: any) {
    return this.http.get(
      this.baseUrl +
        "/DownloadCIBSubjectTextByGenerateDate?generateMonth=" +
        generateMonth +
        "&generateyear=" +
        generateyear,
      {
        observe: "response",
        responseType: "blob",
      }
    );
  }

  //contract
  GenerateCIBContract(generateDate: any) {
    return this.http.get(
      this.baseUrl + "/GenerateCIBContract?generateDate=" + generateDate
    );
  }

  GetCIBContractData(generateMonth: any, generateyear: any) {
    return this.http.get(
      this.baseUrl +
        "/CIBContractDataReportPreView?generateMonth=" +
        generateMonth +
        "&generateyear=" +
        generateyear
    );
  }

  GenerateCIBCOntractDataTextWithMonth(generateMonth: any, generateyear: any) {
    return this.http.get(
      this.baseUrl +
        "/DownloadCIBContractDataTextByGenerateDate?generateMonth=" +
        generateMonth +
        "&generateyear=" +
        generateyear,
      {
        observe: "response",
        responseType: "blob",
      }
    );
  }
}
