import { Component, OnInit } from '@angular/core';
import { AccountBalanceService } from '../service/account-balance.service';
import {  Location } from "@angular/common";

@Component({
  selector: "app-account-balance",
  templateUrl: "./account-balance.component.html",
  styleUrls: ["./account-balance.component.scss"],
})
export class AccountBalanceComponent implements OnInit {
  list: any[] = [];

  constructor(
    private _service: AccountBalanceService,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.getbalanceList();
  }

  getbalanceList() {
    this._service.getlist().subscribe((res) => {
      this.list = res;
    });
  }
  formatNumber(value: string): string {
    const formattedValue = value
      .replace(/,/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedValue;
  }
  goBack() {
    this.location.back();
  }
}
