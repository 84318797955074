
<h4>Angular PrimeNG TreeTable Page</h4> 
<!-- tree-table.component.html -->
<div>
    <span>Test tree</span>
    <p-treeTable [value]="data">
        <ng-template pTemplate="header">
            <tr>
                <th>Name</th>
                <th>Code</th>
                <th>Name</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
            <tr [ttRow]="rowNode">
                <td>
                    <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                    {{ rowData.name }}
                </td>
                <td>{{ rowData.code }}</td>
                <td>{{ rowData.id }}</td>
            </tr>
           
        </ng-template>
        <!-- <ng-template pTemplate="body" let-node>
          <tr>
            <td>{{ node.data.id }}</td>
            <td>{{ node.data.code }}</td>
            <td>{{ node.data.name }}</td>
          </tr>
          <tr *ngIf="node.children?.length">
            <td [attr.colspan]="columns.length">
              <app-tree-table [data]="node.children"></app-tree-table>
            </td>
          </tr>
        </ng-template> -->
      </p-treeTable>
</div>

  
  <!-- <div>
    <p-treeTable 
    #myTab  
    [columns]="cols"  
    [value]="tableData"  
    [paginator]="true"  
    [rows]="5"> 
    <ng-template pTemplate="colgroup" let-columns> 
        <colgroup> 
            <col *ngFor="let col of columns" /> 
        </colgroup> 
    </ng-template> 
  
    <ng-template pTemplate="header" let-columns> 
        <tr> 
            <th *ngFor="let col of columns"> 
                {{ col.header }} 
            </th> 
        </tr> 
    </ng-template> 
  
    <ng-template 
        pTemplate="body" 
        let-rowNode  
        let-rowData="rowData" 
        let-columns="columns"> 
        <tr> 
            <td *ngFor="let col of columns; let i = index"> 
                <p-treeTableToggler 
                    [rowNode]="rowNode"  
                    *ngIf="i == 0"> 
                </p-treeTableToggler> 
                {{ rowData[col.field] }} 
            </td> 
        </tr> 
    </ng-template> 
</p-treeTable>
  </div> -->
