import { Routes, RouterModule } from "@angular/router";

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: "user",
    loadChildren: () =>
      import("../../user/user-routing.module").then((m) => m.UserRoutingModule),
  },
  {
    path: "customer",
    loadChildren: () =>
      import("../../customer/customer-routing.module").then(
        (m) => m.CustomerRoutingModule
      ),
  },

  {
    path: "cashtransaction-list",
    loadChildren: () =>
      import("../../customer/customer-routing.module").then(
        (m) => m.CustomerRoutingModule
      ),
  },

  {
    path: "customeraccount",
    loadChildren: () =>
      import("../../customer-account/customer-account-routing.module").then(
        (m) => m.CustomerAccountRoutingModule
      ),
  },

  {
    path: "occupation",
    loadChildren: () =>
      import("../../master-data/basic/basic-routing/basic-routing.module").then(
        (m) => m.BasicRoutingModule
      ),
  },

  {
    path: "country",
    loadChildren: () =>
      import("../../master-data/basic/basic-routing/basic-routing.module").then(
        (m) => m.BasicRoutingModule
      ),
  },

  {
    path: "district",
    loadChildren: () =>
      import("../../master-data/basic/basic-routing/basic-routing.module").then(
        (m) => m.BasicRoutingModule
      ),
  },
  {
    path: "division",
    loadChildren: () =>
      import("../../master-data/basic/basic-routing/basic-routing.module").then(
        (m) => m.BasicRoutingModule
      ),
  },
  {
    path: "upazila",
    loadChildren: () =>
      import("../../master-data/basic/basic-routing/basic-routing.module").then(
        (m) => m.BasicRoutingModule
      ),
  },
  {
    path: "incomesource",
    loadChildren: () =>
      import("../../master-data/basic/basic-routing/basic-routing.module").then(
        (m) => m.BasicRoutingModule
      ),
  },
  {
    path: "fiscalyear",
    loadChildren: () =>
      import("../../master-data/basic/basic-routing/basic-routing.module").then(
        (m) => m.BasicRoutingModule
      ),
  },

  {
    path: "role",
    loadChildren: () =>
      import("../../user/user-routing.module").then((m) => m.UserRoutingModule),
  },
  {
    path: "userrole",
    loadChildren: () =>
      import("../../user/user-routing.module").then((m) => m.UserRoutingModule),
  },
  {
    path: "product",
    loadChildren: () =>
      import("../../product/product-routing.module").then(
        (m) => m.ProductRoutingModule
      ),
  },

  {
    path: "products",
    loadChildren: () =>
      import("../../master-data/products/products-routing.module").then(
        (m) => m.ProductsRoutingModule
      ),
  },

  {
    path: "balancetype",
    loadChildren: () =>
      import("../../master-data/products/products-routing.module").then(
        (m) => m.ProductsRoutingModule
      ),
  },
  {
    path: "accured",
    loadChildren: () =>
      import("../../master-data/products/products-routing.module").then(
        (m) => m.ProductsRoutingModule
      ),
  },
  {
    path: "day-size",
    loadChildren: () =>
      import("../../master-data/products/products-routing.module").then(
        (m) => m.ProductsRoutingModule
      ),
  },
  {
    path: "commission",
    loadChildren: () =>
      import("../../master-data/products/products-routing.module").then(
        (m) => m.ProductsRoutingModule
      ),
  },
  {
    path: "charge",
    loadChildren: () =>
      import("../../master-data/products/products-routing.module").then(
        (m) => m.ProductsRoutingModule
      ),
  },
  {
    path: "capitalization",
    loadChildren: () =>
      import("../../master-data/products/products-routing.module").then(
        (m) => m.ProductsRoutingModule
      ),
  },
  {
    path: "slab",
    loadChildren: () =>
      import("../../master-data/products/products-routing.module").then(
        (m) => m.ProductsRoutingModule
      ),
  },
  {
    path: "transection-type",
    loadChildren: () =>
      import("../../master-data/products/products-routing.module").then(
        (m) => m.ProductsRoutingModule
      ),
  },
  {
    path: "interest-calculation-balance",
    loadChildren: () =>
      import("../../master-data/products/products-routing.module").then(
        (m) => m.ProductsRoutingModule
      ),
  },
  {
    path: "capitalize-frequency",
    loadChildren: () =>
      import("../../master-data/products/products-routing.module").then(
        (m) => m.ProductsRoutingModule
      ),
  },

  //bank-empRegister
  {
    path: "employeeregister",
    loadChildren: () =>
      import("../../employee/employee-routing/employee-routing.module").then(
        (m) => m.EmployeeRoutingModule
      ),
  },

  {
    path: "navbars",
    loadChildren: () =>
      import("../../navbars/navbars-routing.module").then(
        (m) => m.NavbarsRoutingModule
      ),
  },

  {
    path: "userAssign",
    loadChildren: () =>
      import("../../navbars/navbars-routing.module").then(
        (m) => m.NavbarsRoutingModule
      ),
  },

  {
    path: "accounting",
    loadChildren: () =>
      import("../../accounting/account-routing.module").then(
        (m) => m.AccountRoutingModule
      ),
  },
  {
    path: "voucher-info",
    loadChildren: () =>
      import("../../accounting/account-routing.module").then(
        (m) => m.AccountRoutingModule
      ),
  },
  {
    path: "transection-limit",
    loadChildren: () =>
      import("../../accounting/account-routing.module").then(
        (m) => m.AccountRoutingModule
      ),
  },
  {
    path: "other-account",
    loadChildren: () =>
      import("../../accounting/account-routing.module").then(
        (m) => m.AccountRoutingModule
      ),
  },
  {
    path: "beftn",
    loadChildren: () =>
      import("../../accounting/account-routing.module").then(
        (m) => m.AccountRoutingModule
      ),
  },
  {
    path: "pdf",
    loadChildren: () =>
      import("../../pdf-reporting/pdf-reporting-routing.module").then(
        (m) => m.PdfReportingRoutingModule
      ),
  },
  {
    path: "cash-approval",
    loadChildren: () =>
      import("../../accounting/account-routing.module").then(
        (m) => m.AccountRoutingModule
      ),
  },
  {
    path: "cash-approve",
    loadChildren: () =>
      import("../../accounting/account-routing.module").then(
        (m) => m.AccountRoutingModule
      ),
  },
  {
    path: "payment",
    loadChildren: () =>
      import("../../payment/payment-routing.module").then(
        (m) => m.PaymentRoutingModule
      ),
  },
  {
    path: "cob",
    loadChildren: () =>
      import("../../cobprocess/cobprocess-routing.module").then(
        (m) => m.COBProcessRoutingModule
      ),
  },
  {
    path: "loan-application",
    loadChildren: () =>
      import("../../loan-application/loan-application.module").then(
        (m) => m.LoanApplicationModule
      ),
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("../../dashboard/dashboard-custom-routing.module").then(
        (m) => m.DashboardCustomRoutingModule
      ),
  },

  {
    path: "loanapplication-approval",
    loadChildren: () =>
      import(
        "../../loanapplication-approval/loanapplication-approval.module"
      ).then((m) => m.LoanapplicationApprovalModule),
  },

  {
    path: "accounting-reports",
    loadChildren: () =>
      import("../../accounting-reports/accounting-reports-routing.module").then(
        (m) => m.AccountingReportsRoutingModule
      ),
  },
  {
    path: "dayclose-process",
    loadChildren: () =>
      import("../../dayclose-process/dayclose-process-routing.module").then(
        (m) => m.DaycloseProcessRoutingModule
      ),
  },
  {
    path: "fdr-select",
    loadChildren: () =>
      import("../../fdr-account/fdr-account-routing.module").then(
        (m) => m.FdrAccountRoutingModule
      ),
  },
  {
    path: "score",
    loadChildren: () =>
      import("../../Score/score-routing.module").then(
        (m) => m.ScoreRoutingModule
      ),
  },
  {
    path: "bank-list",
    loadChildren: () =>
      import("../../Score/score-routing.module").then(
        (m) => m.ScoreRoutingModule
      ),
  },
  {
    path: "dps-account",
    loadChildren: () =>
      import("../../dps-account/dps-account-routing.module").then(
        (m) => m.DpsAccountRoutingModule
      ),
  },
  {
    path: "api",
    loadChildren: () =>
      import("../../api/api-routing.module").then((m) => m.ApiRoutingModule),
  },

  {
    path: "nid-verification",
    loadChildren: () =>
      import("../../nidverification/nidverification-routing.module").then(
        (m) => m.NidverificationRoutingModule
      ),
  },

  {
    path: "cib-reports",
    loadChildren: () => import("../../cib-reports/cib-reports-routing.module").then((m) => m.CibReportsRoutingModule),
  },
];
