import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LedgerService } from '../service/ledger.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SharedService } from 'app/services/shared.service';
import { Ledger } from '../model/ledger';
import { DivisionService } from 'app/master-data/basic/basic-service/division.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-gl-generate',
  templateUrl: './gl-generate.component.html',
  styleUrls: ['./gl-generate.component.scss']
})
export class GlGenerateComponent implements OnInit {
  isEdit: boolean = false;
  frmGroup: FormGroup;
  list: any[] = [];
  //list: Ledger[] = [];
  subGroupList: any[] = [];
  model = new Ledger();
  CountryList: Observable<any[]>;
  Clist: any[] = [];
  LedgerList: any[] = [];
  // LedgerList: any[] = [];

  glList: Observable<any[]>;
  currentPage = 1;
  itemsPerPage = 10;
  totalItems = 0;
  currentItems = 0;
  searchControl = "";

  countryVal: any;
  ledgerVal: any;
  bdtVal: any;


  constructor(
    private _service: DivisionService,
    private _LedgerService: LedgerService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private formBuilder: FormBuilder,
    private shared: SharedService,

  ) { }

  ngOnInit(): void {
    this.showSaveForm = 'saveList';
    this.loadCountryList();
    //this.getLedgerList();
    this.GetAllLedger();
    this.LedgerForm();
    this.GetGLGenerate();
    this.GetGlGenerateList();

  }
  showSaveForm: string = '';
  currency: any;
  ledgerCode: any;
  getCurrency(currencyId: any) {
    const selectedCurrency = this.Clist.find(item => item.id == currencyId);

    if (selectedCurrency) {
      this.currency = selectedCurrency.currency;
      if(this.currency && this.ledgerCode){
        this.frmGroup.patchValue({
          BDGenerateCode: this.currency + '' + this.ledgerCode
        });
      }
    }
  }

  getLedgerCode(ledgerId: any) {
    const selectedLedger = this.LedgerList.find(item => item.id == ledgerId);
    if (selectedLedger) {
      this.ledgerCode = selectedLedger.accountCode;
      if(this.currency && this.ledgerCode){
        this.frmGroup.patchValue({
          BDGenerateCode: this.currency + '' + this.ledgerCode
        });
      }
      
    }
  }

  LedgerForm() {
    this.frmGroup = this.formBuilder.group({
      id: [0],
      BDGenerateCode: [''],
      ledgerId: [0],
      countryId: [0]
    });
  }


  onClickShowSaveForm(value: string) {
    this.showSaveForm = value;
    this.refresh();
  }
  GetGLGenerate() {
    this._LedgerService.GetGLGenerate().subscribe(res => {
      this.list = res;
    })
  }

  GetGlGenerateList(){
  
    this._LedgerService.GetGlGenerateWithSearchAndPagination(this.currentPage, this.itemsPerPage, this.searchControl).subscribe((res:any)=>{
      this.glList = res.data;
      this.currentItems = res.currentItems;
      this.totalItems = res.totalItems;
    })
  }

  onsubmit() {
    // debugger
    if (this.isEdit) {
      this.model.id = this.frmGroup.value.id;
    }
    this.model.ledgerId = this.frmGroup.value.ledgerId;
    this.model.countryId = this.frmGroup.value.countryId;
    this.model.BDGenerateCode = this.frmGroup.value.BDGenerateCode;


    if (this.model.id) {
      this._LedgerService.PostGLGenerate(this.model).subscribe(res => {
        this.shared.ShowAlert("Success", "Save Successfully", "success");
        this.isEdit = false;
        this.showSaveForm = 'saveList';
        this.GetGLGenerate();
        this.GetGlGenerateList();
        this.refresh();

      })
    }
    else {
      this._LedgerService.PostGLGenerate(this.model).subscribe(res => {
        this.shared.ShowAlert("Success", "Save Successfully", "success");
         this.GetGLGenerate();
         this.GetGlGenerateList();
        this.isEdit = false;
        this.showSaveForm = 'saveList';
        this.refresh();
      })
    }
  }

  edit(item: any) {
    this.isEdit = true;
    this.showSaveForm = 'saveForm';
    this.frmGroup.patchValue({
      id: item.id,
      BDGenerateCode: item.bdGenerateCode,
      ledgerId: item.ledgerId,
  
      countryId: item.countryId,

    })

  }


  delete(Id: any) {

    this._LedgerService.DeleteGLGenerateById(Id).subscribe(res => {
           this.shared.ShowAlert("Success", "Data deleted successfully", "success");
      
    })
  }

  refresh() {
    this.model = new Ledger;
    this.LedgerForm();
  }

  loadCountryList() {
    this._service.GetAllCountry().subscribe(res => {
      
      this.Clist = res;
    });
  }


  GetAllLedger() {
    this._LedgerService.GetLedgerListWithOutGL().subscribe(res => {
      this.LedgerList = res;

    })
  }


  getCurrency1(curr: any) {
    alert(curr)
  }

  onChangeForShow(value: any){
    this.currentPage = 1;
    this.itemsPerPage = value;
    this.GetGlGenerateList();
  }

  onSearch(){
    this.currentPage = 1;
    this.GetGlGenerateList();
  }

  onPageChange(pageNumber : number){
    this.currentPage = pageNumber;
    this.GetGlGenerateList();
  }

}
