<!--Statistics cards Starts-->
<div class="row" (resized)="onResized($event)">
   
    <div class="col-lg-6 col-md-6 col-12 col-sm-12" >
      <div class="card gradient-purple-love" (click)="getDashboardListByType(1)" style="cursor: pointer;background-color: #565d61;">
        <div class="card-content">
          <div class="card-body py-0">
            <div class="media pb-4">
              <div class="media-body white text-left mt-2 pl-4">
                <span style="font-size: 20px;">Under process </span>
                <h3 class="font-large-1 white mt-2">{{underProcerssCount}}</h3>
                
              </div>
              <div class="media-right white text-right mt-4 pr-4">
                <!-- <i class="ft-activity font-large-1"></i> -->
                <i class="fa fa-gears font-large-2"></i>
                
              </div>
              
            </div>
          </div>
         <!--  <div id="Widget-line-chart" class="WidgetlineChart WidgetlineChartshadow mb-2">
                      <x-chartist class="height-75" [data]="WidgetlineChart1.data" [type]="WidgetlineChart1.type" [options]="WidgetlineChart1.options" [responsiveOptions]="WidgetlineChart1.responsiveOptions"
                       [events]="WidgetlineChart1.events">
                      </x-chartist>
                  </div> -->
        </div>
      </div>
    </div>
    <!-- gradient-mint -->
    <div class="col-lg-6 col-md-6 col-12 col-sm-12">
      <div class="card " (click)="getDashboardListByType(2)" style="cursor: pointer;background-color:#526BA0 ;">
        <div class="card-content">
          <div class="card-body py-0">
            <div class="media pb-4">
              <div class="media-body white text-left  mt-2 pl-4">
                <span style="font-size: 20px;">Applied Loan</span>
                <h3 class="font-large-1 white mt-2">{{appliedLoanCount}}</h3>
              </div>
              <div class="media-right white text-right mt-4 pr-4">
                <i class="fa fa-list font-large-2"></i>
              </div>
            </div>
          </div>
       <!--   <div id="Widget-line-chart" class=" WidgetlineChart WidgetlineChartshadow mb-2">
                      <x-chartist class="height-75" [data]="WidgetlineChart.data" [type]="WidgetlineChart.type" [options]="WidgetlineChart.options" [responsiveOptions]="WidgetlineChart.responsiveOptions"
                       [events]="WidgetlineChart.events">
                      </x-chartist>
                  </div>  -->
        </div>
      </div>
    </div>
  
    <div class="col-lg-6 col-md-6 col-12 col-sm-12">
      <div class="card" (click)="getDashboardListByType(3)" style="cursor: pointer;background-color: #4EAC96;">
        <div class="card-content">
          <div class="card-body py-0">
            <div class="media pb-4">
              <div class="media-body white text-left mt-2 pl-4">
               
                <span  style="font-size: 20px;"> Approved Loan</span>
                <h3 class="font-large-1 white mt-2">{{approvedLoanCount}}</h3>
              </div>
              <div class="media-right white text-right  mt-4 pr-4">
                <i class="ft-trending-up font-large-2"></i>
               
              </div>
            </div>
          </div>
         <!--  <div id="Widget-line-chart" class="WidgetlineChart WidgetlineChartshadow mb-2">
                      <x-chartist class="height-75" [data]="WidgetlineChart2.data" [type]="WidgetlineChart2.type" [options]="WidgetlineChart2.options" [responsiveOptions]="WidgetlineChart2.responsiveOptions"
                       [events]="WidgetlineChart2.events">
                      </x-chartist>
                  </div> -->
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-12 col-sm-12">
      <div class="card " (click)="getDashboardListByType(4)" style="cursor: pointer;background-color: #6E3B44;">
        <div class="card-content">
          <div class="card-body py-0">
            <div class="media pb-4">
              <div class="media-body white text-left mt-2 pl-4">
                <span style="font-size: 20px;">Rejected  Loan</span>
                <h3 class="font-large-1 white mt-2">{{rejectedLoanCount}}</h3>
                
              </div>
              <div class="media-right white text-right mt-4 pr-4">
                <i class="ft-credit-card font-large-2"></i>
              </div>
            </div>
          </div>
         <!--  <div id="Widget-line-chart" class="WidgetlineChart WidgetlineChartshadow mb-2">
                      <x-chartist class="height-75" [data]="WidgetlineChart3.data" [type]="WidgetlineChart3.type" [options]="WidgetlineChart3.options" [responsiveOptions]="WidgetlineChart3.responsiveOptions"
                       [events]="WidgetlineChart3.events">
                      </x-chartist>
                  </div> -->
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-12 col-sm-12">
      <div class="card" (click)="getEMIPayBillList()" style="cursor: pointer;background-color: #0c484e;">
        <div class="card-content">
          <div class="card-body py-0">
            <div class="media pb-4">
              <div class="media-body white text-left mt-2 pl-4">
               
                <span  style="font-size: 20px;"> Loan Payment</span>
                <h3 class="font-large-1 white mt-2">{{finalApproveCount}}</h3>
              </div>
              <div class="media-right white text-right  mt-4 pr-4">
                <i class="ft-trending-up font-large-2"></i>
               
              </div>
            </div>
          </div>
         <!--  <div id="Widget-line-chart" class="WidgetlineChart WidgetlineChartshadow mb-2">
                      <x-chartist class="height-75" [data]="WidgetlineChart2.data" [type]="WidgetlineChart2.type" [options]="WidgetlineChart2.options" [responsiveOptions]="WidgetlineChart2.responsiveOptions"
                       [events]="WidgetlineChart2.events">
                      </x-chartist>
                  </div> -->
        </div>
      </div>
    </div>
    
  </div>

  
  
  