<div class="card" style="width: 100%;">
    <div class="row card-header  d-flex flex-row align-items-center">
        <div class="col-9">
            <h5 class="card-title" style="color: black">Bank Beneficiary List</h5>
        </div>
        <div class="col-2">
            <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary"
                routerLink="/score/bank-beneficiar-create"
                style="float:right;margin-right:5px;margin-left: 8px;">Add New</button>
        </div>
        <div class="col-1">
            <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
        
                <i class="icon-arrow-left" style="font-size: 17px;"> </i>
            </button>
        </div>
    </div>
    
    <hr style="height: 10%;">
    
            <div class="card" style="width: 100%;" >
                <div class="card-body">
                    <div class="container">
                       
                     

                        <table id="example" class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>SL No.</th>
                                    <th>Bank Name</th>
                                    <th>Branch Name</th>
                                    <th>Account Name</th>
                                    <th>Account Number</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let item of beneficiaryList; let i = index">
                                <tr>
                                    <td>{{i + 1}}</td>
                                    <td>{{item?.masterBank?.bankName}}</td>
                                    <td>{{item?.branch?.branchNameEn}}</td>
                                    <td>{{item?.accountName}}</td>
                                    <td>{{item?.accountNumber}}</td>
                                    <td>{{ item?.status == 0 ? 'InActive': 'Active'  }}</td>
                                  
                                    <td>
                                        <button class="btn btn-success btn-sm" (click)="edit(item.id)"><i class="fa fa-edit 3x"></i></button>
                                        <button class="btn btn-danger  btn-sm mt-1"  (click)="delete(item.id)"><i class="fa fa-trash-o 3x"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
           
    </div>
</div>