 
    <div class="col-md-12">
      <div class="card" style="width: 100%;">
        <div class="card-header">
          <h5 class="card-title" style="color: black;font-family: Arial;font-size: 20px !important;">Bangla QR</h5>         
        </div>
        
          <div class="card-body" style="margin-top: 30px;" >            
              <div class="row">
                <div class="col-md-12">
                    <h3 style="text-align: center;">This Page Under Development</h3>
                </div>
                  
              </div>
              
              
              
          </div>
      </div>
  </div>
   