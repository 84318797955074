import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
const apiUrl=environment.baseUrl + '/api/InterestCalculation/'
@Injectable({
  providedIn: 'root'
})
export class CapitalizeFrequencyService {

  constructor(private http:HttpClient) { }
  //#region

getCapitalizeFrequencyList():Observable<any>{
  return this.http.get(apiUrl+ 'GetAllCapitalizeFrequencyList')
}

getCapFreqListByProBuildeType(id:number):Observable<any>{
  return this.http.get(apiUrl+ 'GetAllCapFreqListByProBuilderType?id='+id)
}

getCapitalizeFrequencyById(id:number){
  return this.http.get(apiUrl + 'GetCapitalizeFrequencyById')
}

saveCapitalizeFrequency(model:any){
  return this.http.post(apiUrl + 'SaveCapitalizeFrequency',model)
}

deleteCapitalizeFrequency(id:any){
  return this.http.delete(apiUrl + 'DeleteCapitalizeFrequency/' + id)
}
getListWithSerchAndPagination(currentPage: any, itemsPerPage: any, searchValue: string) {
  return this.http.get(apiUrl + 'GetCapitalizationFrequencyWithSerchAndPagination?page='+ currentPage + '&pageSize=' +itemsPerPage + '&search=' + searchValue);
}
//#endregion
}
