export class BusinessDate {
    id:any;
    BusinessDate:any;
    BusinessClosingDate:number;
    statusId:string;
    ActiveStatus:string;
    Remarks:any;
    isMonthCOB:any;
    isCOB:any;
   
}

