<div class="card" style="width: 100%;">

    <div class="card-header py-3 d-flex flex-row align-items-center">
        <h6 class="m-0 font-weight-bold text-primary">Approved Application List</h6>
    </div>
    <div class="card-body">


        <table id="example" class="table table-striped table-bordered" style="width:100%">
            <thead>
                <tr>
                    <th>SL No.</th>
                    <th>Lead Code </th>
                    <th>Customer Info </th>
                    <th>Loan Type </th>
                    <th>Status</th>
                    <!-- <th>Action</th> -->
                  
                </tr>
            </thead>

            <tbody>

                <tr *ngFor="let item of dataList; let i = index">
                    <td>{{i+1}}</td>
                    <td>{{item.leadCode}}</td>
                    <td>
                        <span>Name: {{item?.customer?.customerNameEng}}</span><br>
                        <span>Mobile No.: {{item?.customer?.mobileNo}}</span>
                    </td>
                    <td>{{item.loanProduct?.loanType?.typeName}}</td>
                    <td>
                        <!-- <div class="badge badge-pill"
                            [ngClass]="{'bg-light-primary': item.status ===3 }">
                            Approved
                        </div>
                        <div class="badge badge-pill"
                            [ngClass]="{'bg-light-primary':  item.status ===4}">
                            Disbursed
                        </div> -->

                        <div class="badge badge-pill"
                        [ngClass]="{'bg-light-primary': item.statusInfoId == 3}">
                         Approved
                        </div>
          


                    </td>
                    <!-- <td>
                        
                        <button class="btn btn-sm btn-info" type="button" title="Click here to Approve" >
              
                            <a [routerLink]="'/loanapplication-approval/loanapplication-approval/' + item.leadCode + '/' + 3" style="color:white;font-weight: 700;"> Approval</a>
                          </button>
                       

                       
                    </td> -->
                </tr>
            </tbody>
        </table>

    </div>
</div>