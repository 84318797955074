export class ApplicationUser {
    
        EmailAddress:string;
        Mobile:string;
        Name:string;
        NID:string;
        genderId:number;
    
    
}
