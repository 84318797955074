import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LeadInfoService } from '../lead-info.service';

@Component({
  selector: 'app-customerlead-approved',
  templateUrl: './customerlead-approved.component.html',
  styleUrls: ['./customerlead-approved.component.scss']
})
export class CustomerleadApprovedComponent implements OnInit {
  LeadList:any []=[];
  constructor(
    private _leadService:LeadInfoService,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.getLeadList()
  }
  getLeadList(){
    this._leadService.getLeadList().subscribe((res: any[]) =>{
      this.LeadList =res.filter(item => item.isComplete === 1 && item.status===2);
      this.changeDetectorRef.detectChanges();
    })
  }
}
