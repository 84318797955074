import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

const apiUrl=environment.baseUrl + '/api/ScoreQuestion/'

@Injectable({
  providedIn: 'root'
})
export class ScoreQueAnsService {

  constructor(private http:HttpClient) { }

  getAllQuestionList():Observable<any>{
    return this.http.get(apiUrl+'GetAllQuestion');
  }

  saveQuestion(model:any){
    return this.http.post(apiUrl + 'SaveQuestion', model);
  }



  //#region Answer

  saveAnswer(model:any){
    return this.http.post(apiUrl + 'SaveAnswer', model);
  }

  //#endregion

  
}
