export class PasswordPolicy {
    Id: number = 0;
    isLowerCase: boolean = false;
    isUpperCase: boolean = false;
    isNumber: boolean = false;
    isSymbol: boolean = true;
    maxLength?: number = 6;
    minLength?: number = 6;
    specialCharacterMin?: number;
    passwordHistoryPeriod?: number;
    passwordReuseMax?: number;
    failedLoginAttemptsMax?: number;
    expirationDays?: number;
    expieryPriorAlert?: number;
    capitalLetterMin?: number;
    smallLetterMin?: number;
    numericCharacterMin?: number;
    consequtiveUsePass?: number;
    samePassRepeat?: number;
    changePassAfterExpiry?: boolean;
    isTwoFactorEnabled?: boolean;
}
