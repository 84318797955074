import { DatePipe, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PdfService } from 'app/pdf-reporting/pdf.service';

@Component({
  selector: 'app-loan-settlement-report',
  templateUrl: './loan-settlement-report.component.html',
  styleUrls: ['./loan-settlement-report.component.scss']
})
export class LoanSettlementReportComponent implements OnInit {

  fromDate:Date=new Date();
  toDate:Date=new Date();
  settlementDataList:any[];

  constructor(private pdfService:PdfService,private location:Location,private datePipe:DatePipe) { }


  ngOnInit(): void {
  }

  OpenPdf(){
    this.pdfService.LoanSettlementReportPdf(this.fromDate,this.toDate).subscribe(x=>{
      let blob:Blob=x.body as Blob;
      let url =window.URL.createObjectURL(blob);

      window.open(url);
    })
  }

  OpenExcel(){
    this.pdfService.LoanSettlementReportExcel(this.fromDate,this.toDate).subscribe(x=>{
      let blob:Blob=x.body as Blob;
      let url=window.URL.createObjectURL(blob);

      window.open(url)
    })
  }

  goBack(){
    this.location.back()
  }

  GetLoanCollection(){
    this.pdfService.LoanSettlementReportData(this.fromDate,this.toDate).subscribe(res=>{
      this.settlementDataList=res.body
    })
  }

  formatDateString(dateString: string): string {
    const formattedDate = this.datePipe.transform(dateString, 'dd-MM-yyyy'); // Adjust the format as needed
    return formattedDate || dateString;
  }

}
