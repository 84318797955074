import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NavbarsService } from '../navbars.service';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'app/services/shared.service';


@Component({
  selector: 'app-userroleassign',
  templateUrl: './userroleassign.component.html',
  styleUrls: ['./userroleassign.component.scss']
})
export class UserroleassignComponent implements OnInit {
  checkedUserMenu: any[] = [];
  userTypeId: string;
  userRoles: any[];
  userMenu: any[];
  constructor(
     private http: HttpClient,
     private _service:NavbarsService,
     private formBuilder: FormBuilder,
     private router: Router, 
     private activeRoute: ActivatedRoute,
     private shared: SharedService
    ) { }

  ngOnInit(): void {
    this.getUserRole();
    this.getUserMenuAccess();

  }



  getUserRole() {
    this._service.getRoleListInfo().subscribe((res) => {
      this.userRoles = res;
    });
  }


  getUserMenuAccess() {
    if (!this.userTypeId) {
      this.userMenu = [];
      return;
    }
  
    this._service.getUserMenuAccess(this.userTypeId)
      .subscribe((data) => {
        this.userMenu = data;
        data.forEach(menu => {
          if(menu.isActive==1){
            this.checkedUserMenu.push(menu);
          }
            menu.submenu.forEach(submenu => {
              if(submenu.isActive==1){
                this.checkedUserMenu.push(submenu);
              }
                submenu.submenu.forEach(child => {
                  if(child.isActive==1){
                    this.checkedUserMenu.push(child);
                  }
                });
            });
        });
    });

  }
  

  saveUserMenuAccess() {
    if (!this.userTypeId) {
      return;
    }
  
    if (this.checkedUserMenu.length === 0) {
      alert('Please select at least one menu item.');
      return;
    }
  
    const selectedMenuIds = this.checkedUserMenu.map((menu) => menu.id);
    this._service.saveUserAssign(this.userTypeId, selectedMenuIds).subscribe(
      () => {
        this.shared.ShowAlert('Success', 'Save Successfully', 'success');
      },
      (error) => {
        this.shared.ShowAlert('Warning', 'Unable to Save. Please Try Again', 'warning');
      }
    );
  }
  
  
  

  onCheckboxChange(event: Event, id) {
    const checkedMenu = event.target as HTMLInputElement;
    const menuItem = this.findMenuItemById(this.userMenu, id);
  
    if (checkedMenu.checked) {
      if (menuItem && !this.checkedUserMenu.some((checkedItem) => checkedItem.id === id)) {
        this.checkedUserMenu.push(menuItem);
      }
    } 
    else {
      const index = this.checkedUserMenu.findIndex((checkedItem) => checkedItem.id === id);
        if (index !== -1) {
          this.checkedUserMenu.splice(index, 1);
        }
    }
  }
     
  findMenuItemById(menuItems, itemId) {
    for (const menuItem of menuItems) {
      if (menuItem.id === itemId) {
        return menuItem;
      }
      if (menuItem.submenu && menuItem.submenu.length > 0) {
        const foundItem = this.findMenuItemById(menuItem.submenu, itemId);
        if (foundItem) {
          return foundItem;
        }
      }
    }
    return null;
  }
   
}
