import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TransactionLog } from '../models/transaction-log';
import { PaymentService } from '../payment.service';
import { DatePipe, Location } from "@angular/common";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: 'app-transaction-log-list',
  templateUrl: './transaction-log-list.component.html',
  styleUrls: ['./transaction-log-list.component.scss']
})
export class TransactionLogListComponent implements OnInit {
  transactionLogs: TransactionLog[] = [];
  startDate: Date | null = null;
  EndDate: Date | null = null;
  formattedStartDate: string| null = null;
  formattedEndDate: string| null = null;
  closeResult: string;
  transactionDetails: any = [];
  constructor(private paymentService: PaymentService,
    private changeDef: ChangeDetectorRef,
    private location: Location,
    private datePipe: DatePipe,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.getTransactionLogs();
  }


  getTransactionLogs() {
    this.paymentService.getTransactionLogs().subscribe(
      (logs: any[]) => {
        this.transactionLogs = logs;
        this.changeDef.detectChanges();
      },
      (error) => {
        console.error("Error fetching transaction logs", error);
      }
    );
  }


  GetClCalculationListByDate() {
    const defaultDate = "0001-01-01";
    //this.startDate ?? defaultDate
    this.transactionLogs = [];
    const formattedStartDate = this.startDate ? this.datePipe.transform(this.startDate,"yyyy-MM-dd") : "";
    const formattedEndDate = this.EndDate ? this.datePipe.transform(this.EndDate, "yyyy-MM-dd") : "";

    this.paymentService.getTransactionLogsByDate(formattedStartDate, formattedEndDate).subscribe((res) => {
      this.transactionLogs = res;
      this.changeDef.detectChanges();
    });
  }



  TransactionDetails(content) {
    let options: any = {
     
      centered: false,
    };
    this.modalService.open(content, options).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed`;
      }
    );
  }

  getTransactionById(id: any) {
   
    this.paymentService.getTransactionLogsById(id).subscribe((res) => {
      
      this.transactionDetails = res;
    });
  }

  formatDates() {
    this.formattedStartDate = this.datePipe.transform(this.startDate, "dd-MM-yyyy");
    this.formattedEndDate = this.datePipe.transform(this.EndDate, "dd-MM-yyyy");
  }
  onDateChange() {
    this.formatDates();
  }
  goBack() {
    this.location.back();
  }


}
