import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

const apiUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class InterestProcessService {

  constructor(private http:HttpClient) { }


  //InterestProcessDateRange(string type, DateTime? fromDate, DateTime? toDate

  // processInterest(businessDate:Date){
  //   return this.http.get(apiUrl +'/api/COBProcess/DailyInterstProcess?fromDate=' + businessDate);
  // }
  processInterest(type: string, fromDate: Date, toDate:Date){
    return this.http.get(apiUrl + '/api/COBProcess/InterestProcessDateRange?type=' + type + '&fromDate=' + fromDate + '&toDate=' + toDate);
  }


  GetBatchProcessMonitorSummary(fromDate: Date, toDate:Date){
    return this.http.get(apiUrl + '/api/GetProcessMonitorSumamry?fromDate=' + fromDate + '&toDate=' + toDate);
  }


  GetPRList(fromDate: string, toDate:string){
    return this.http.get(apiUrl + '/api/GetPRList?fromDate=' + fromDate + '&toDate=' + toDate);
  }


  GetAllBatchDetail(){
    return this.http.get(apiUrl + '/api/GetBatchDetails');
  }

  ProcessAll(type: string, fromDate: Date, toDate:Date){
    return this.http.get(apiUrl + '/api/COBProcess/InterestProcessDateRange?type=' + type + '&fromDate=' + fromDate + '&toDate=' + toDate);
  }

  ProcessBalance(type:string, fromDate: Date, toDate:Date){
    if(type == 'FDR'){
      return this.http.get(apiUrl + '/api/ProcessBalance?type=' + type + '&fromDate=' + fromDate + '&toDate=' + toDate);
    }
    else if(type == 'DPS'){
      return this.http.get(apiUrl + '/api/ProcessBalance?type=' + type + '&fromDate=' + fromDate + '&toDate=' + toDate);
    }
    else if(type == 'Deposit'){
      return this.http.get(apiUrl + '/api/ProcessBalance?type=' + type + '&fromDate=' + fromDate + '&toDate=' + toDate);
    }
    else if(type == 'Loan'){
      return this.http.get(apiUrl + '/api/ProcessBalance?type=' + type + '&fromDate=' + fromDate + '&toDate=' + toDate);
    }
    else{

    }
  }


  ProcessProvision(type:string, fromDate: Date, toDate:Date){
    if(type == 'FDR'){
      return this.http.get(apiUrl + '/api/ProcessProvision?type=' + type + '&fromDate=' + fromDate + '&toDate=' + toDate);
    }
    else if(type == 'DPS'){
      return this.http.get(apiUrl + '/api/ProcessProvision?type=' + type + '&fromDate=' + fromDate + '&toDate=' + toDate);
    }
    else if(type == 'Deposit'){
      return this.http.get(apiUrl + '/api/ProcessProvision?type=' + type + '&fromDate=' + fromDate + '&toDate=' + toDate);
    }
    else if(type == 'Loan'){
      return this.http.get(apiUrl + '/api/ProcessProvision?type=' + type + '&fromDate=' + fromDate + '&toDate=' + toDate);
    }
    else{

    }
  }



  QuarterlyProcessInterest(businessDate:Date,quarter:any){
    return this.http.get( apiUrl+'/api/COBProcess/QuarterlyProcessInterest?businessDate=' + businessDate +'&quarter='+quarter);
  }
  MonthlyProcessInterest(year:any,month:any){
    alert(22)
    return this.http.get( apiUrl+'/api/COBProcess/MonthlyProcessInterest?year=' + year +'&month='+month);
  }



  GetDailyInterestProcessData(fromDate: string, toDate:string){
    return this.http.get(apiUrl + '/api/GetDailyInterestProcessData?fromDate=' + fromDate + '&toDate=' + toDate);
  }


  GetMonthlyProvisionList(fromDate: string, toDate:string){
    return this.http.get(apiUrl + '/api/GetMonthlyProvisionList?fromDate=' + fromDate + '&toDate=' + toDate);
  }


}
