<!-- <p>nid-verify works!</p> -->

<div class="container">
    <!-- <h3 class="card-title" style="margin-top: 3%;color: black;text-align: center;"> eKyc - Customer Registration</h3> -->
    <div class="card">
        <div class="card-body">
            <form #customerForm="ngForm">

                <div class="row">

                    <div class="col-md-3 col-sm-12 mt-2" style="display: none;">
                        
                        <div class="col-md-12 col-sm-12 mt-2">
                            <img src="{{frontPreview}}" id="imageFrontPreview" class="frontPreview" />
                        </div>
                        <div class="col-md-12 col-sm-12 mt-4">

                            <img src="{{backPreview}}" id="imageBackPreview" class="backPreview" />
                        </div>

                    </div>

                    <div class="col-md-2 col-sm-12"></div>
                    <div class=" col-sm-12 ">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group row">
                                    <label for="nidNo" class="col-sm-4 col-form-label">NID No</label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control"   name="NidNo" id="NidNo" placeholder="Type your NID number" [(ngModel)]="model.NidNo">
                                    </div>
                                  </div>

                              
                               
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group row">
                                    <label for="nidNo" class="col-sm-5 col-form-label">Date of Birth</label>
                                    <div class="col-sm-7">
                                        <input  type="date" class="form-control"  id="dob" placeholder="yyyy-mm-dd" name="DateOfBirth" [(ngModel)]="model.DateOfBirth">
                                    </div>
                                  </div>

                               
                               
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12">
                       
                        <div class="mb-4 form-group">
                            
                        </div>

                        <div class="mb-4 form-group">
                            <div class="row" *ngIf="isNIDImageShow">
                                <div class="col-md-6" style="padding-right: 0px;">

                                    <label for="image">NID Front PICTURE</label>

                                    <input type="file" accept="image/*" id="image" (change)="selectNidFrontPic($event)"
                                        style="display: none;" #fileInputFront />

                                    <div style="height: 125px; width: 150px; cursor: pointer;"
                                        (click)="fileInputFront.click()">
                                        <img src="{{ frontPreview ? frontPreview : 'assets/img/gallery/upload_image.png' }}"
                                            id="image" style="width: 100%; height: 100%;" class="proPreview"
                                            title="Click here to upload photo" />
                                    </div>
                                </div>
                                <div class="col-md-6" style="padding-right: 0px;">

                                    <label for="image">NID Back PICTURE</label>

                                    <input type="file" accept="image/*" id="image" (change)="selectNidBackPic($event)"
                                        style="display: none;" #fileInputBack />

                                    <div style="height: 125px; width: 150px; cursor: pointer;"
                                        (click)="fileInputBack.click()">
                                        <img src="{{ backPreview ? backPreview : 'assets/img/gallery/upload_image.png' }}"
                                            id="image" style="width: 100%; height: 100%;" class="proPreview"
                                            title="Click here to upload photo" />
                                    </div>


                                </div>

                            </div>

                        </div>

                        <div class="mb-4 form-group">
                            <button class="btn btn-primary pull-left" (click)="onNidSubmit(customerForm)" type="button">NID Verify</button>

                            <div *ngIf="isNIDOkShow">
                                <button class="btn btn-primary pull-right" (click)="onNidOK()" type="button">Next</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12" style="margin-left: 100px;margin-top:30px" *ngIf="isNIDData">
                        <div class="form-group">
                            <p>Name : {{ nid.fullNameEN }}</p>
                            <p>Date Of Birth : {{ nid.dateOfBirth }}</p>
                        </div>
                        <div class="form-group">
                            <p>Father Name : {{ nid.fathersNameEN }}</p>
                            <p>Mother Name : {{ nid.mothersNameEN }}</p>
                        </div>
                        <div class="form-group">
                            <p>Present Address : {{ nid.presentAddressEN}} </p>
                            <p>Permanent Address : {{ nid.presentAddressEN}} </p>
                            <!-- <p>Present Address : {{ nid.presentAddress.homeOrHoldingNo }},
                                {{nid.presentAddress.additionalVillageOrRoad}}, 
                                {{ nid.presentAddress.postOffice }}, {{ nid.presentAddress.unionOrWard }},
                                {{nid.presentAddress.upozila }}{{ nid.presentAddress.district }}, 
                                {{ nid.presentAddress.division }}, {{ nid.presentAddress.postalCode }}</p> -->
                             
                            <!-- <p>Permanent Address : {{ nid.permanentAddress.homeOrHoldingNo }}, 
                                {{ nid.permanentAddress.additionalVillageOrRoad }}, 
                                {{ nid.permanentAddress.postOffice }}, {{ nid.permanentAddress.unionOrWard }}, 
                                {{ nid.permanentAddress.upozila }} {{ nid.permanentAddress.district }}, 
                                {{ nid.permanentAddress.division }}, {{ nid.permanentAddress.postalCode }}
                            </p> -->
                            <div style="height: 140px; width: 200px; cursor: pointer;">
                                <img src="{{nid.photoUrl}}" id="image" style="width: 100%; height: 100%;"
                                    class="proPreview" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
