<br />
<h4 class="card-title" style="color: black">Payment Receive List</h4>
<hr />
<div class="card">
  <div class="card-body">
    <div class="form-group row">
      <!-- <label for="type" class="col-md-1">Type:</label>
           <div class="col-2">
               <select type="text" class="form-control"  [(ngModel)]="types">
                    <option value="">Select</option>
                    <option value="Deposit">Deposit</option>
                    <option value="FDR">FDR</option>
                    <option value="DPS">DPS</option>
                    <option value="Loan">Loan</option>
               </select>
           </div> -->

      <label for="dateInput" class="col-md-2">From Date:</label>
      <div class="col-md-2 input-group">
        <input
          type="text"
          class="form-control"
          [(ngModel)]="fromDate"
          placeholder="Select From Date"
          [minDate]="{
            year: businessYear,
            month: businessMonth,
            day: businessDay
          }"
          (click)="d3.toggle()"
          ngbDatepicker
          #d3="ngbDatepicker"
          readonly
        />
        <div class="input-group-append">
          <span class="input-group-text">
            <i class="fa fa-calendar" (click)="d3.toggle()"></i>
          </span>
        </div>
      </div>

      <label for="dateInput" class="col-md-2">To Date:</label>
      <div class="col-md-2 input-group">
        <input
          type="text"
          class="form-control"
          [(ngModel)]="toDate"
          placeholder="Select To Date"
          [minDate]="{
            year: businessYear,
            month: businessMonth,
            day: businessDay
          }"
          (click)="d4.toggle()"
          ngbDatepicker
          #d4="ngbDatepicker"
          readonly
        />
        <div class="input-group-append">
          <span class="input-group-text">
            <i class="fa fa-calendar" (click)="d3.toggle()"></i>
          </span>
        </div>
      </div>

      <div class="col-2">
        <button
          type="button"
          class="btn btn-primary"
          (click)="GetPRList()"
          id="processButton"
        >
          Find
        </button>
      </div>
    </div>
    <hr />
    <div class="row" *ngIf="isLoad">
      <table style="width: 100%" class="table table-bordered table-striped">
        <thead>
          <tr style="background-color: rgb(180, 179, 179)">
            <th>Date</th>
            <th>Type</th>
            <th>Account Code</th>
            <th>Amount</th>
            <th>Particular</th>
            <th>Amount</th>
            <th>CBS GL Code</th>
            <th>Posting Status</th>
          </tr>
        </thead>
        <tbody style="background-color: white">
          <tr *ngFor="let item of paymentReceiveList">
            <td>{{ item.effectiveDate | date }}</td>
            <td>{{ item.type }}</td>
            <td>{{ item.accountCode }}</td>
            <td>{{ item.amount }}</td>
            <td>{{ item.particular }}</td>
            <td style="text-align: right;">{{ item.amount }}</td>
            <td>{{ item.bdGenerateCode }}</td>
            <td>
              <span
                *ngIf="item.postingStatus == 'Posted'"
                class="badge badge-success"
                >{{ item.postingStatus }}</span
              >
              <span
                *ngIf="item.postingStatus == 'Not Posted'"
                class="badge badge-warning"
                >{{ item.postingStatus }}</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
