<div class="row">
    <hr>
    <div class="col-md-12">
        <div class="card">
            <div class="card-header row">
                <div class="col-md-9">
                    <h3 class="card-title" style="color: #888888;font-family: Arial;font-size: 20px !important;"><b> Chart of Account </b></h3>
                </div>
                <div class="col-md-1">
                    <button class="btn btn-success" style="margin-left: 5px; float: right;" (click)="OpenPDF()">Print</button>
                  
                </div>
                <div class="col-md-1">
                    
                    <button class="btn btn-info" style="margin-left: 5px; float: right;" (click)="OpenExcel()">Excel</button>
                </div>
                <div class="col-md-1">
                   
                        <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
                    
                            <i class="icon-arrow-left" style="font-size: 17px;"> </i>
                        </button>
                    
                </div>
            </div>
            <hr>

            <div class="card-body" style="padding-top:0px">
                <div class="tree-view" style="padding-left: 10%;padding-top:0px">
                    <dl>
                        <dl *ngFor="let nature of accountNatureViewModels">
                            <span class="node" style="font-size:20px;cursor:pointer;"  title="Nature" (click)="toggleCollapse(nature)">
                                <span [innerHTML]="nature.collapsed ? '&#11167;' : '&#11166;'"></span>
                                <b>  {{nature.natureName}} - {{nature.natureCode}}</b>
                               
                            </span>
                            
                            
                            <!-- <span class="node" style="font-size:20px;" (click)="toggleCollapse(nature)"><b>{{nature.natureName}} - {{nature.natureCode}}</b> </span> -->
                            <ul *ngIf="nature.collapsed" >
                                <dl *ngFor="let group of filteredAccountsGroupByNId(nature.natureId)">
                                    <span class="node" style="font-size:15px;cursor: pointer;"  title="Group" (click)="toggleCollapse(group)">
                                        <span [innerHTML]="group.collapsed ? '&#11167;' : '&#11166;'"></span>
                                        <b>  {{group.accountGroupName}} - {{group.groupCode}}</b>
                                       
                                    </span>
                                    <!-- <span class="node" style="font-size:15px;" (click)="toggleCollapse(group)"><b>{{group.accountGroupName}} - {{group.groupCode}}</b> </span> -->
                                    <ul *ngIf="group.collapsed">
                                        <dl *ngFor="let subgroup of filteredSubAccountsGroupByPGId(group.accountGroupId)">
                                            <span class="node" style="font-size:15px;cursor: pointer;"  title="Sub-Group" (click)="toggleCollapse(subgroup)">
                                                <span [innerHTML]="subgroup.collapsed ? '&#11167;' : '&#11166;'"></span>
                                                <b>  {{subgroup.accountGroupName}} - {{subgroup.groupCode}}</b>
                                               
                                            </span>
                                            <!-- <span class="node" style="font-size:15px;" (click)="toggleCollapse(subgroup)"><b>{{subgroup.accountGroupName}} - {{subgroup.groupCode}}</b> </span> -->
                                            <ul *ngIf="subgroup.collapsed">
                                                <dl *ngFor="let ledger of filteredLedgerByGId(subgroup.accountGroupId)">
                                                    <span class="leaf-node" title="Ledger" >&#11166; {{ledger.ledgerName}} - {{ledger.ledgerCode}}</span>
                                                </dl>
                                            </ul>
                                        </dl>
                                    </ul>
                                </dl>
                            </ul>
                        </dl>
                    </dl>
                </div>
            </div>
        </div>
    </div>
</div>

