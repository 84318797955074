<div class="row" (resized)="onResized($event)">
    <div class="card" style="width: 100%;">
        <div class="row card-header py-3 d-flex flex-row align-items-center">
            
            <div class="col-6">
                <h6 class="m-0 font-weight-bold text-primary">Applied Loan List</h6>
            </div>
            <div class="col-6">
                <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
                  
                    <i class="icon-arrow-left" style="font-size: 17px;"> </i>
                </button>
            </div>
        </div>
        <hr>
        <div class="row" style="margin-left: 2%;">
        
            <div class="col-4">
                <label>From Date</label>
                <div class="col-12" style="margin-left: -16px;">
        
                    <div class="input-group">
                        <input type="date" class="form-control" [(ngModel)]="startDate" (ngModelChange)="onDateChange()"
                            placeholder="Select From Date">
        
                    </div>
                </div>
            </div>
        
            <div class="col-4">
                <label>To Date</label>
                <div class="col-12" style="margin-left: -16px;">
        
                    <div class="input-group">
                        <input type="date" class="form-control" [(ngModel)]="EndDate" (ngModelChange)="onDateChange()"
                            placeholder="Select To Date">
                    </div>
        
                </div>
            </div>
        
            <div class="col-4">
                <label></label>
        
                <div class="col-12 mt-1">
                    <button class="btn btn-primary" style="background-color: #1fca74;color: white;" (click)="getLeadList()">Search</button>
                </div>
            </div>
        
        </div>
        <div class="card-body" style="overflow: auto;max-height: 600px;">
            <div class="container">
                <table id="example" class="table table-striped table-bordered scrollable-table" style="width:100%">
                    <!-- <thead>
                        <tr>
                            <th>SL No.</th>
                            <th>Loan Account</th>
                            <th>Customer</th>
                            <th>Create at</th>
                            <th>Loan Amount</th>
                            <th>Status</th>
                           
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of LeadList; let i = index;">
                            <td>{{i + 1}}</td>
                            <td>{{item?.customerAccountNo}}</td>
                           
                            <td>
                                <span>Name:{{item.customers?.customerNameEng }}</span> <br>
                                <span>Mobile No:{{item.customers?.mobileNo}} </span><br>
                             </td>
                             
                           <td>{{item?.createdAt | date:'dd-MM-yyyy'}}</td>
                           <td>{{item?.leadGeneration?.loanAmount}}</td>
                            <td>
                               
                                <div class="badge badge-pill">
                                    Approved
                                </div>
                            </td> -->

                            <thead style="position: sticky;top:0;z-index: 1;background-color: #f8f9fa;">
                                <tr style="width: 100%;">
                                    <th>SL No.</th>
                                    <th>Action </th>
                                    <th style="width: 10%!important;">Loan Type </th>
                                    <th style="width: 10%!important;">Loan Code </th>
                                    <th style="width: 15%!important;">Apply Date </th>
                                    <th>Customer Info </th>
                                    <th style="width: 10%!important;">Loan Tenure </th>
                                    <th style="width: 10%!important;">Loan Amount</th>
                                    <th>Status</th>
                                   
                                </tr>
                            </thead>
                            
                            <tbody>
                            
                                <tr *ngFor="let item of LeadList; let i = index">
                                    <td style="width: 2%!;">{{i+1}} .</td>
                                    <td>
                                        <!-- <button class="btn btn-sm btn-secondary" (click)="loanDisburse(item?.leadGeneration?.id)" type="button" title="Disburse Loan" style="background-color: #6f7270;">Disburse</button> -->
                                        <!-- <button class="btn btn-sm btn-secondary" (click)="goDisburse(item?.leadGeneration?.id,item?.leadGeneration?.customerId,item?.leadGeneration?.loanProductId)" type="button" title="Disburse Loan" style="background-color: #6f7270;">Details</button> -->
                                        <button *ngIf="!userRoles.includes('UnderWriting') && item.statusInfoId == 3" class="btn btn-sm"
                                            (click)="goDisburse(item?.id,item?.customerId,item?.loanProductId)"
                                            style="background-color: #dd802a;color:white" type="button" title="Disburse Loan">Disburse</button>
                                        <button *ngIf="item.statusInfoId == 10 && userRoles.includes('UnderWriting')" class="btn btn-sm btn-success"
                                            (click)="UnderwritingApprove(item?.id,item?.customerId,item?.loanProductId)" type="button"
                                            title="Approve">Approve</button>
                                        <!-- <button class="btn btn-sm btn-info " (click)="goDetailsView(item?.id)" type="button" title="Details">Details</button> -->
                                    </td>
                                    <td style="width: 10%!important;">{{item.loanProduct?.loanType?.typeName}}</td>

                                    <td style="width: 10%!important;">{{item.leadCode}}</td>
                                    <td style="width: 15%!important;">{{item.updatedAt | date:'dd-MM-yyyy'}}</td>
                                    <td>
                                        <span style="font-weight: 600;">Name:</span><span>{{item?.customer?.customerNameEng}}</span><br>
                                        <span style="font-weight: 600;">Mobile No.:</span><span>{{item?.customer?.mobileNo}}</span>
                                    </td>
                                    <td>{{item.tenure?.monthName}}</td>
                                    <td>{{item?.loanAmount}}</td>
                            <td>
                                <!-- [ngClass]="{'bg-light-primary':  item.statusInfoId == 2}" -->
                                <div>
                                    Applied
                                </div>
                            </td>
                           
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>