
  <div class="card" style="width: 100%;">
    <div class="card-body">
        <div class="container">
          <h5 class="card-title" style="color: black"> Document List</h5>
          <hr>
          <table id="example" class="table table-striped table-bordered" style="width:100%">
            <thead>
              <tr>
                <th  style="width: 20%;">Customer Name</th>
                <th  style="width: 20%;">Customer Code</th>
              
                <th  style="width: 20%;">Image/File</th>
                <th style="width: 20%;">Document Type </th>
                <th style="width: 20%;">File Name</th>
                <th  style="width: 20%; text-align: center;">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let document of documentList">
                <td style="width: 20%;">{{ document.customer?.customerNameEng }}</td>
                <td style="width: 20%;">{{ document.customer?.customerRCode }}</td>
                <td style="width: 20%;">
                  <img *ngIf="document.fileName.split('.')[1] === 'jpeg' || document.fileName.split('.')[1] === 'png'"
                  [src]="getBase64ImageSrc(document.base64String,document.fileName.split('.')[1])" style="width: 40%; height: 30%;" alt="Preview" />
                    <img [src]="defaultDocxImage" style="width: 40%; height: 30%;" alt="Default Docx"
                         *ngIf="document.fileName && document.fileName.split('.')[1] === 'docx'" />
                    <img [src]="defaultPdfImage" style="width: 40%; height: 30%;" alt="Default PDF"
                         *ngIf="document.fileName && document.fileName.split('.')[1] === 'pdf'" />
                    <img [src]="defaultExcelImage" style="width: 40%; height: 30%;" alt="Default Excel"
                         *ngIf="document.fileName && document.fileName.split('.')[1] === 'xlsx'" />
                   
                  </td>
                  
                <td style="width: 20%;">{{ document.documentsType?.typeName }}</td>
                <td style="width: 20%;">{{ document.fileName }}</td>
                <td style="width: 20%; text-align: center;">
                  <a [href]="getDownloadUrl2(document.base64String, document.fileName.split('.')[1])" download="{{ document.fileName }}"
                  style="background-color: #B583FF; padding: 5px 10px; border-radius: 5px; color: white; text-decoration: none;"
                  class="btn">
                 Download
               </a>                  <br>
                      <button class="btn mt-1" *ngIf="document.status == 0"
                      style="font-size: 14px; width: 120px !important; height: 35px;"
                      (click)="Approve(document.id)"
                      style="background-color: #B5C3DB; padding: 5px 10px; border-radius: 5px; color: white; text-decoration: none;">Approve</button>
                </td>
              </tr>
            </tbody>
            
        
        </table>
        </div>  
      </div>
  </div>  
  