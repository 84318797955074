import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

const apiUrl = environment.baseUrl + '/api/LoanRegisters/';
const documentUrl=environment.baseUrl+'/api/LoanApplication/';
@Injectable({
  providedIn: 'root'
})
export class ProfessionalDetailService {

  constructor(private http: HttpClient) { }

  getselfprofList() {
    return this.http.get(apiUrl + 'GetSelfProfinfo');
  }

  getList() {
    return this.http.get(apiUrl + 'GetProfessionalinfo');
  }

  GetprofesionalinfoByClientId(id: any) {
    return this.http.get(apiUrl + 'GetprofesionalinfoByClientId/' + id);
  }

  GetprofesionalinfoByClientandTypeId(id: number, professionaltypeId): Observable<any> {
    return this.http.get(apiUrl + 'GetprofesionalinfoByClientandtypeId/' + id + '/' + professionaltypeId);
  }

  save(model: any) {
   
    return this.http.post(apiUrl + 'SaveServiceHolder', model);
  }
  saveform2(model: any) {
   
    return this.http.post(apiUrl + 'SaveSelfEmployee', model);
  }
  saveform3(model: any) {
   
    return this.http.post(apiUrl + 'SaveBusiness', model);
  }
  saveUpdate(model: any) {
 
    return this.http.post(apiUrl + 'UpdateProfessionalInfo', model);
  }
  saveDocument(model: any) {
 
    return this.http.post(documentUrl + 'UploadDocument', model);
  }
  delete(id: any) {
    return this.http.delete(apiUrl + 'DeleteProfessionalinfo/' + id);
  }
//#regio Address Details

getAllAddress():Observable<any>{
  return this.http.get(apiUrl + 'GetAllAddressDetails');
}

getAddressById(Id: number){
  return this.http.get(apiUrl + 'GetAddressDetailsById/' + Id);
}

getAllAddressByClientId(clientId: number):Observable<any>{
  return this.http.get(apiUrl + 'GetAllAddressDetailsByClientId/' + clientId);
}

getAddressByClientId(clientId: number){
  return this.http.get(apiUrl + 'GetAddressDetailsByClientId/' + clientId);
}

saveAddress(model:any){
 
  return this.http.post(apiUrl + 'SaveAddress', model);
}

deleteAddress(Id: number){
  return this.http.delete(apiUrl + 'DeleteAddress/' + Id);
}
GetLeadGenerationByLeadNoforAddress(customersId:number): Observable<any> {
  return this.http.get(apiUrl+ 'GetLeadGenerationByLeadNoforAddress?customersId=' + customersId );
 }

 GetPresentAndPermanentAddressByCustomerid(customerId:number):Observable<any>{
  return this.http.get(apiUrl+'GetPresentPermanentAddressByCustomerId?customersId='+customerId)
 }

 GetLeadGenerationLeadNoforAddress(customersId:number): Observable<any> {
  return this.http.get(apiUrl+ 'GetLeadGenerationLeadNoforAddress?customersId=' + customersId );
 }


 GetLeadGenerationByLeadNoforProfessional(customersId:number): Observable<any> {
  return this.http.get(apiUrl + 'GetLeadGenerationByLeadNoforProfessional?customersId=' + customersId );
 }
 
//endregion

}
