<section>
  <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveForm'">
    <div class="card-body">
      <div class="container">
        <div class="row">
          <div class="col">
            <h5 class="card-title" style="color: black">Sub-Ledger Entry</h5>
            <button type="button" data-toggle="tooltip" (click)="onClickShowSaveForm('saveList')" title="Go to list"
              class="btn btn-secondary" style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">List
              View</button>
          </div>
        </div>
        <hr>

        <form [formGroup]="frmGroup">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label for="" class="col-sm-4 col-form-label">Sub Ledger Name <span style="color: red;">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" name="accountNames" formControlName="accountNames"
                    placeholder="Account Name">

                    <input type="hidden" class="form-control" name="id" formControlName="id" >
                </div>
              </div>
              <div class="form-group row">
                <label for="" class="col-sm-4 ">Sub Ledger Account Code</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" name="accountCodes" formControlName="accountCodes" readonly
                    [value]="accountCodes" placeholder="Account Code">
                </div>
              </div>
              <form [formGroup]="subLedger">
                <div class="form-group row">
                  <label for="SubLedgerof" class="col-sm-4 col-form-label">Sub-Ledger of</label>
                  <div class="col-sm-8">
                    <select type="number" class="form-control" name="ledgerId" formControlName="ledgerId" id="ledgerId">
                      <option value="0" selected>Select</option>
                      <option *ngFor="let item of ledgerlist" [attr.particularName]="item.accountName"
                        [value]="item.id">
                        {{item?.accountName}} - {{item?.accountCode}}
                      </option>
                    </select>
                  </div>
                  <div class="col-sm-12">
                    <button type="button" (click)="AddParticular()" style="margin-left: 35%;
                           margin-top: 2%;" class="btn btn-sm btn-success" title="Add Ledger">Add Ledger</button>
                  </div>
                </div>
              </form>

              <div class="form-group row">
                <div class="col-sm-4"></div>
                <div id="SubLedgerList" class="col-sm-8 row" style="margin-left: 0%;">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Ledger Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of MainLedgerList; let i = index;">
                        <td>{{i + 1}}.</td>
                        <td>{{item.accountName}}</td>
                        <td>
                          <button *ngIf="isEdit" class="btn btn-danger btn-sm" (click)="handleButtonClick(item, i)">Delete</button>
                          <button *ngIf="!isEdit" class="btn btn-danger btn-sm" (click)="removeParticular(i)">Remove</button>


                          <!-- <button class="btn btn-danger btn-sm" (click)="handleButtonClick(item, i)">Remove</button> -->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <hr>

              <button data-toggle="tooltip" (click)="onsubmit()" title="Save" type="button" class="btn btn-success"
                style="float:right; margin-top:5px;">Save</button>

            </div>
          </div>
        </form>
      </div>
    </div>
  </div>


  <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveList'">
    <div class="card-body">
      <div class="container">
        <h5 class="card-title" style="color: black">Sub-ledger List</h5>
        <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary"
          (click)="onClickShowSaveForm('saveForm')"
          style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">Add New</button>

        <hr>

        <table id="example" class="table table-striped table-bordered" style="width:100%">
          <thead>
            <tr>
              <th>Sl.</th>
              <th>Account Name</th>
              <th>Account Code</th>
              <th style="width: 20%;">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let itemss of subLedgerlist; let i = index;">

              <td>{{i+1}}</td>
              <td>{{itemss.accountCode}}</td>
              <td>{{itemss.accountName}}</td>
              <td>
                <button class="btn btn-primary btn-sm" (click)="edit(itemss)"><i class="fa fa-edit 3x"></i></button>
                <button [disabled]="isEdit" class="btn btn-danger btn-sm" style="margin-left: 5px;" (click)="deleteSubLedger(itemss.id)"><i
                    class="fa fa-trash-o 3x"></i></button>

              </td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>
  </div>
</section>