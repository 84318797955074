import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FdrAccountRoutingModule } from './fdr-account-routing.module';
import { FdrSelectComponent } from './fdr-select/fdr-select.component';
import { CreateFdrAccountComponent } from './create-fdr-account/create-fdr-account.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { FdrListComponent } from './fdr-list/fdr-list.component';
import { CustomerFdrComponent } from './customer-fdr/customer-fdr.component';


@NgModule({
  declarations: [
    FdrSelectComponent,
    CreateFdrAccountComponent,
    FdrListComponent,
    CustomerFdrComponent
  ],
  imports: [
    NgbModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FdrAccountRoutingModule
  ],
  providers: []
})
export class FdrAccountModule { }
