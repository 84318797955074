import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardCustomService } from '../dashboard-custom.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {
  activeTab:string = '0-0';

  constructor(private router: Router,
    private _serviceDashboard: DashboardCustomService,
    private changeDetectorRef: ChangeDetectorRef) { 

    }

    waitingApplication: number;
    forwardedApplication: number;
    approvedLoan: number;
    rejectedLoan: number;
    waitingForDisburse: number;
    disbursmentList: number;
    finalDisbursList : number;
  ngOnInit(): void {
    this.getLoanLeadInfoForAdmin();
  }
  onResized(event: any) {
    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }
  fireRefreshEventOnWindow() {
    throw new Error('Method not implemented.');
  }
  onUnderProClicked(type:number) {
    this.router.navigate(['/dashboard-custom/cutomer-lead']);
    this.changeDetectorRef.detectChanges();
  }
onWaiting(st:number){  
this.router.navigate(['/dashboard/waiting-application']);
}
onForwarded(st:number){
  this.router.navigate(['/dashboard/forward-loan-app']);
}
onApproved(st:number){
  this.router.navigate(['/dashboard/approved-loan-app']);
}
onRejected(st:number){
  this.router.navigate(['/dashboard/rejected-loan-app']);
}
onDisburse(st:number){  
  this.router.navigate(['/dashboard/application-disburse-list']);
  }
ApprovedDisburse(st:number){  
  this.router.navigate(['/dashboard/app-approve-disburse']);
  }
  FinalDisburse(st:number){
    this.router.navigate(['/dashboard/final-disburse-list']);
  }

  getLoanLeadInfoForAdmin() {
    this._serviceDashboard.GetLoanInfoForAdminDeshBoard().subscribe({
      next: (res: any) => {
      
      this.waitingApplication = res.waitingApplication; 
      this.forwardedApplication = res.forwardedApplication; 
      this.approvedLoan = res.approvedLoan; 
      this.rejectedLoan = res.rejectedLoan; 
      this.waitingForDisburse = res.waitingForDisburse; 
      this.disbursmentList = res.disbursmentList; 
      this.finalDisbursList = res.finalDisbursmentList;
      this.changeDetectorRef.detectChanges();
      },
      error: (e) => {
        console.log(e);
      },
      complete: () => {
   
      }
    });

  }

}
