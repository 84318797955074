import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardCustomService } from 'app/dashboard/dashboard-custom.service';

@Component({
  selector: 'app-approved-loan-app',
  templateUrl: './approved-loan-app.component.html',
  styleUrls: ['./approved-loan-app.component.scss']
})
export class ApprovedLoanAppComponent implements OnInit {
  dataList:any []=[];
  constructor(private router: Router,
    private _serviceDashboard: DashboardCustomService,
    private changeDetectorRef: ChangeDetectorRef) 
    {  
    }


  ngOnInit(): void {
    this.getApprovedApplication();
  }
  getApprovedApplication(){
    this._serviceDashboard.getLoanLeadInfoForAdmin().subscribe((res) =>{
      this.dataList =res.filter(item => item.statusInfoId ===3);
      this.changeDetectorRef.detectChanges();

    })
  }

  // ApplicationApproval(leadCode:string,type:number){
  //   this.router.navigate(['/loanapplication-approval/loanapplication-approval/'+ leadCode +'/'+ type]);
  // }
}
