<div class="card" style="width: 100%;">
  <div class="card-body">
    
    <div class="container">
      <div style="display: flex; justify-content: space-between; align-items: center;">
        <h3 class="card-title" style="color: black; text-align: center;">{{'emp_reg.Bank User Registration' |
          translate}}</h3>
        <div>
          <button class="btn " style="background-color: #975AFF;"> <a routerLink="/employeeregister/employeeregister"
              style="color: white;font-weight: 700;">{{ 'global.CREATE' | translate }}</a></button>
        </div>
      </div>

      <hr style="height: 10%;">

      <div class="row">
        <div class="col-md-6" style="text-align: left;">
          <select type="number" class="form-control" style="width: 16%;" (change)="onChangeForShow($event.target.value)">
            <option value="5">5</option>
            <option value="10" selected>10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <div class="col-md-6" style="text-align: right;">
          <input type="text" class="form-control" placeholder="Search" [(ngModel)]="searchControl" (keyup)="onSearch()">
        </div>
      </div>


      <table id="example" class="table table-striped table-bordered" style="width:100%">
        <thead>
          <tr>
            <th>SL No.</th>
            <th>Full Name</th>
            <th>User Name</th>
            <th>Mobile No</th>
            <th>Email Address</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of list | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }; let i = index">
            <td>{{ (currentPage - 1) * itemsPerPage + i + 1 }}</td>
            <td>{{item.fullName }}</td>
            <td>{{item.userName}}</td>
            <td>{{item.phoneNumber}}</td>
            <td>{{item.email}}</td>
            <td>
              <button class="btn btn-sm btn-dark" title="Reset Password" type="button"
                (click)="openReset(resetcontent,item)"><i class="fa fa-key "></i></button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col-md-6" style="text-align: left">
          <span>Showing {{currentItems}} of {{totalItems}} entries</span>
        </div>
        <div class="col-md-6" style="text-align: right">
          <pagination-controls (pageChange)="onPageChange($event)"></pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>







<ng-template #resetcontent let-c="close" let-d="dismiss">
  <div class="modal-dialog modal-lg" id="myModal">
    <div class="modal-content">
      <form [formGroup]="formGroupReset">
        <div class="modal-header">
          <h4 class="modal-title">Reset Password</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label for="desicode" class="col-sm-4 col-form-label">User Name</label>
                <div class="col-sm-8">
                  <!-- <input type="text" class="form-control" readonly formControlName="userName"> -->
                  <input type="text" class="form-control" readonly formControlName="userName">
                  <input type="hidden" class="form-control" formControlName="userName">
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group row">
                <label for="name" class="col-sm-4 col-form-label">New Password</label>
                <div class="col-sm-8">
                  <input type="password" class="form-control" formControlName="password">
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group row">
                <label for="confirmPassword" class="col-sm-4 col-form-label">Confirm Password</label>
                <div class="col-sm-8">
                  <input type="password" class="form-control" formControlName="confirmPassword" id="confirmPassword">
                  <div *ngIf="errorMessage" class="text-danger">{{ errorMessage }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary" (click)="ResetSubmit()">Submit</button>

          <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
        </div>
      </form>

    </div>
  </div>
</ng-template>