<h4>FDR Account</h4>
<hr>
<div class="row">
    <div class="col-lg-3 col-md-3 col-12 col-sm-12" *ngFor="let item of productlist"   (click)="goAccountForm(item.id)" >
        <div class="card" style="cursor: pointer; background-color: white; margin-right: 10px;">
            <div class="card-content">
                <div class="card-body py-0">
                    <div class="media pb-4">
                        <div class="media-right text-right mt-4 pr-4">
                            <div>
                                <h5>
                                    <p style="color: #888888 !important;">{{ item.name }}</p>
                                </h5>
                                <p style="color: #888888 !important;">{{ item.description }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>