import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CustommeraccountService } from '../custommeraccount.service';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'app/services/shared.service';
import { Observable } from 'rxjs/internal/Observable';


@Component({
  selector: 'app-saving-account-details',
  templateUrl: './saving-account-details.component.html',
  styleUrls: ['./saving-account-details.component.scss']
})
export class SavingAccountDetailsComponent implements OnInit {
  accId:any;
  accountDatails:any=[];
  accountDatails$: Observable<any>;
  constructor(
    private _service: CustommeraccountService,
    private pdfService: PdfService,
    private activeRoute: ActivatedRoute,
    private shared: SharedService,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.activeRoute.paramMap.subscribe(params => {
      this.accId = parseInt(params.get('accId'), 10);
      //this.GetDetailsByAccountId(this.accId);
      this.accountDatails$ = this._service.GetCustomerAccountDetalis(this.accId);
    });
    
  }

  GetDetailsByAccountId(accId:number) {
    this._service.GetCustomerAccountDetalis(accId).subscribe(res => {
      this.accountDatails=res;
    })
  }

  PrintKyc() {
    this.pdfService.OpenKycPdf(this.accId).subscribe(res => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    })
  }

}
