import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormBuilder, NgForm, FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { CountryInfo } from '../../basic-model/country-info';
import { CountryInfoService } from '../../basic-service/country-info.service';
import { SharedService } from 'app/services/shared.service';

@Component({
  selector: 'app-country-info',
  templateUrl: './country-info.component.html',
  styleUrls: ['./country-info.component.scss']
})
export class CountryInfoComponent implements OnInit {

  isEdit: boolean = false;
  countryForm: FormGroup;
  list: CountryInfo[] = [];
  model = new CountryInfo();
  frmGroup: FormGroup;
  frmmodel: CountryInfo = new CountryInfo();

  currentPage = 1;
  itemsPerPage = 10;
  totalItems = 0;
  currentItems = 0;
  searchControl = "";

  showSaveForm: string = '';

  constructor(
    private _service: CountryInfoService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private formBuilder: FormBuilder,
    private shared: SharedService
  ) {

    this.getCountryList()
    this.buildForm();
  }

  ngOnInit(): void {
    this.showSaveForm = 'saveList';
  }

  onChangeForShow(value: any) {
    this.currentPage = 1;
    this.itemsPerPage = value;
    this.getCountryList();
  }

  onPageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.getCountryList();
  }

  onSearch() {
    this.currentPage = 1; // Reset the current page to 1 when a new search is performed
    this.getCountryList();
  }

  getCountryList() {
    this._service.getAllCountryWithPaginationSearch(this.currentPage, this.itemsPerPage, this.searchControl).subscribe((res: any) => {
      this.list = res.data;
      this.totalItems = res.totalItems;
      this.currentItems = res.currentItems;
    })
  }

  onClickShowSaveForm(value: string) {
    this.showSaveForm = value;
    this.refresh();
  }

  buildForm() {
    this.countryForm = this.formBuilder.group({
      COUNTRY_CODE: ['', Validators.required],
      COUNTRY_Name: ['', Validators.required]
    });
  }

  onsubmit() {
    if (this.model.Id === null) {
      this.model.Id = 0
    }
    this._service.save(this.model).subscribe(res => {
      if (this.isEdit) {
        this.shared.ShowAlert("Success", "Update Successfully", "success");
      }
      else {
        this.shared.ShowAlert("Success", "Save Successfully", "success");
      }
      this.isEdit = false;
      this.showSaveForm = 'saveList';
      this.getCountryList();
    })
  }

  edit(item: any) {
    this.isEdit = true;
    this.showSaveForm = 'saveForm';
    this.model = {
      Id: item.id,
      CountryCode: item.countryCode,
      CountryName: item.countryName,
      Currency: item.currency
    };
  }

  delete(id: any) {
    this._service.delete(id).subscribe(res => {
      this.shared.ShowAlert('Alert', 'Data deleted successfully.', 'warning');
      this.getCountryList();
      this.showSaveForm = 'saveList';
    })
  }

  refresh() {
    this.isEdit = false;
    this.model = {
      Id: null,
      CountryCode: null,
      CountryName: null,
      Currency: null,
    }
  }
}

