import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { AccountingreportService } from '../accountingreport.service';
import { CustomerService } from 'app/customer/service/customer.service';

@Component({
  selector: 'app-statement-report',
  templateUrl: './statement-report.component.html',
  styleUrls: ['./statement-report.component.scss']
})
export class StatementReportComponent implements OnInit {

  fromDate:Date =new Date();
  toDate:Date = new Date();
  reportBy:number;

 
  loanAccountId:any=[];
  customerId:any=[];
  customerlist:any=[];
  loanAccountList: any[] = [];

  constructor(
    private pdfService:PdfService,
    private accountingreportService:AccountingreportService,
    private changeDetectorRef: ChangeDetectorRef,
    private  customerService:CustomerService
  ) { }

  ngOnInit(): void {
    this.GetCustomerlist(); 
  }

  OpenPDF(){
    this.pdfService.CustomerstatementReportViewpdf(this.customerId,this.loanAccountId,this.fromDate,this.toDate,1,2).subscribe(res => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);
      window.open(url);
      this.changeDetectorRef.detectChanges();
    })
  }

  
 

  GetCustomerlist(){
    this.customerService.getlist().subscribe(res=>
    {
        this.customerlist=res;
        this.changeDetectorRef.detectChanges();
    })
  }




  onCustomerChange(Id: any): void {

    if (Id > 0 || Id != null || Id != "") {
      this.customerId=Id;
      this.GetLoanAccountList(Id);
    }
  }

  GetLoanAccountList(Id){
    this.customerService.GetCustomerLoanAccountList(Id).subscribe(res=>{
      this.loanAccountList=res;
      //this.changeDetectorRef.detectChanges();
    })
  }

}
