import { DatePipe, Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardCustomService } from 'app/dashboard/dashboard-custom.service';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { LayoutService } from 'app/shared/services/layout.service';

@Component({
  selector: "app-receivable-list",
  templateUrl: "./receivable-list.component.html",
  styleUrls: ["./receivable-list.component.scss"],
})
export class ReceivableListComponent implements OnInit {
  dataList: any = [];
  status: number;
  startDate: any;
  EndDate: Date ;
  leadNo: any = null;
  mfsType: number = 0;
  asOnDate: any;
  formattedStartDate: string;
  formattedEndDate: string;
  businessDate: any;
  constructor(
    private router: Router,
    private _serviceDashboard: DashboardCustomService,
    private pdfService: PdfService,
    private changeDetectorRef: ChangeDetectorRef,
    private location: Location,
    private datePipe: DatePipe,
    private layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.getCurrentBusinessDate();
    this.startDate = this.startDate || new Date();
    this.EndDate = this.EndDate || new Date();
    //this.GetLoanOutstandingData();
   // this.GetLoanOutstandingDataByDateRange();
  }

  getCurrentBusinessDate() {
    this.layoutService.getCurrentBusinessDate().subscribe((res: any) => {
      this.businessDate = res.business_Date;
       this.startDate = new Date("1970-01-01");
       this.EndDate = new Date(this.businessDate);
       this.GetLoanOutstandingDataByDateRange();
    });
  }

  formatDates() {
    this.formattedStartDate = this.datePipe.transform(
      this.startDate,
      "dd-MM-yyyy"
    );
    this.formattedEndDate = this.datePipe.transform(this.EndDate, "dd-MM-yyyy");
  }
  onDateChange() {
    this.formatDates();
  }
  // getFinalDisburse() {
  //   this.status = 7;
  //   this._serviceDashboard
  //     .getFinalDisbursLoan(this.status)
  //     .subscribe((res: any[]) => {
  //       this.dataList = res;
  //       this.changeDetectorRef.detectChanges();
  //     });
  // }

  OpenPDF(leadId) {
    this.pdfService.OpenSchedulePdf(leadId).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    });
  }

  // GetLoanOutstandingData() {
  //   const formattedAsNoDate = this.datePipe.transform(
  //     this.startDate,
  //     "yyyy-MM-dd"
  //   );
  //   this.pdfService
  //     .LoanOutstandingData(this.leadNo, formattedAsNoDate, this.mfsType)
  //     .subscribe((res) => {
  //       alert(45);
  //       console.log("ooooooooooooooo");
  //       console.log(res);
  //       this.dataList = res;
  //       console.log(this.dataList);
  //       this.changeDetectorRef.detectChanges();
  //     });
  // }

  // GetLoanOutstandingDatasss() {
  //   this.pdfService
  //     .LoanOutstandingData(this.leadNo, this.asOnDate, this.mfsType)
  //     .subscribe({
  //       next: (res: any) => {
  //         console.log("ooooooooooooooo");
  //         console.log(res);
  //         this.dataList = res;

  //         this.changeDetectorRef.detectChanges();
  //       },
  //       error: (e) => {
  //         console.log(e);
  //       },
  //       complete: () => {},
  //     });
  // }

  GetLoanOutstandingDataByDateRange() {
    const formattedStartDate = this.datePipe.transform(
      this.startDate,
      "yyyy-MM-dd"
    );
    const formattedEndDate = this.datePipe.transform(
      this.EndDate,
      "yyyy-MM-dd"
    );
   
    this.pdfService
      .LoanOutstandingDataByDateRange(formattedStartDate, formattedEndDate)
      .subscribe((res) => {
        this.dataList = res;
        this.changeDetectorRef.detectChanges();
      });
  }

  goBack() {
    this.location.back();
  }
  // ApplicationApproval(leadCode:string,type:number){
  //   this.router.navigate(['/loanapplication-approval/loanapplication-approval/'+ leadCode +'/'+ type]);
  // }
}
