<br />
<h4 class="card-title" style="color: black">Daily Interestmonthly-provision</h4>
<hr />
<div class="card">
  <div class="card-body">
    <div class="form-group row">
      <label for="dateInput" class="col-md-2">From Date:</label>
      <div class="col-md-2 input-group">
        <input
          type="text"
          class="form-control"
          [(ngModel)]="fromDate"
          placeholder="Select From Date"
          [minDate]="{
            year: businessYear,
            month: businessMonth,
            day: businessDay
          }"
          (click)="d3.toggle()"
          ngbDatepicker
          #d3="ngbDatepicker"
          readonly
        />
        <div class="input-group-append">
          <span class="input-group-text">
            <i class="fa fa-calendar" (click)="d3.toggle()"></i>
          </span>
        </div>
      </div>

      <label for="dateInput" class="col-md-2">To Date:</label>
      <div class="col-md-2 input-group">
        <input
          type="text"
          class="form-control"
          [(ngModel)]="toDate"
          placeholder="Select To Date"
          [minDate]="{
            year: businessYear,
            month: businessMonth,
            day: businessDay
          }"
          (click)="d4.toggle()"
          ngbDatepicker
          #d4="ngbDatepicker"
          readonly
        />
        <div class="input-group-append">
          <span class="input-group-text">
            <i class="fa fa-calendar" (click)="d3.toggle()"></i>
          </span>
        </div>
      </div>

      <div class="col-2">
        <button
          type="button"
          class="btn btn-primary"
          (click)="LoadData()"
          id="processButton"
        >
          Find
        </button>
      </div>
    </div>
    <hr />
    <div class="row" *ngIf="isLoad">
      <table style="width: 100%" class="table table-bordered table-striped">
        <thead>
          <tr style="background-color: rgb(180, 179, 179)">
            <th>Process Date</th>
            <th>Customer Name</th>
            <th>Customer Account</th>
            <th>Interest Charged</th>
          </tr>
        </thead>
        <tbody style="background-color: white">
          <tr *ngFor="let item of dailyInterests">
            <td>{{ item.processDate | date }}</td>
            <td>{{ item.customerAccount?.accountTilte }}</td>
            <td>{{ item.customerAccount?.customerAccountNo }}</td>
            <td style="text-align: right;">{{ item.interestCharged }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
