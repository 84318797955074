<div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Job List</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <table class="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>API Name</th>
              <th>Process Type</th>
              <th>Process Time</th>
              <th>Is Auto Process</th>
              <th>Status</th>
              <th>
                <button type="button" class="btn btn-success btn-sm" (click)="AddItem()">New Job</button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of data">
              <td>{{item.name}}</td>
              <td>{{item.apiName}}</td>
              <td>{{item.cobJobProcessType}}</td>
              <td>{{item.processTime}}</td>
              <td>{{item.isAutoProcess == 0 ? 'No' : 'Yes'}}</td>
              <td>{{item.readyState == true ? 'Active' : 'Inactive'}}</td>
              <td>
                <button type="button" class="btn btn-danger btn-sm" (click)="removeItem(item)">Remove</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
      </div>
    </div>
  </div>
