import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appDecimalOnly]'
})
export class DecimalOnlyDirective {

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: any) {
    const initialValue = this.el.nativeElement.value;
    const sanitizedValue = initialValue.replace(/[^0-9.]/g, '');
    this.el.nativeElement.value = sanitizedValue;
    if (initialValue !== sanitizedValue) {
      event.stopPropagation();
    }
  }

}
