<div class="card" style="width: 100%;">
    <div class="card-body">
        <div class="container">
            <div class="row">
                <div class="col-8">
                    <h5 class="card-title" style="color: black">eKYC Verifications</h5>
                </div>
                
            </div>
            <hr>
            <table id="example" class="table table-striped table-bordered" style="width:100%">
                <thead>
                    <tr>
                        <th>SL No.</th>
                        <th>NID No</th>
                        <th>Process Date</th>
                        <th>Status</th>
                        <th>Matched Percent</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of nidList; let i = index;">
                        <td>{{i + 1}}</td>
                        <td>{{item.idNo}}</td>
                        <td>{{item.date | date:'yyyy-MM-dd'}}</td>
                        <td style="width: 13%;">{{item.isPassed === true|| item.isPassed===null? 'Fail' : "Passed"}}</td>
                        <td>{{item.avgMatched}}</td>
                        
                        <td style="width: 23%;">
                            <button class="btn btn-info mb-1" style="margin-left: 5px;" data-bs-toggle="modal" data-bs-target="#newModal"
                            (click)="fnDetails(item.idNo)"><i
                                class="fa fa-list"></i></button>
                           
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>


<form #newForm="ngForm" >
    <div class="modal" role="dialog" id="newModal">
        <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">NID Information</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
                <div class="modal-body">
  
                    <div class="form-group row">
                        <label for="txtName" class="col-sm-4">Name</label>
                        <div class="col-sm-8">
                            <input type="hidden" id="txtNewCatalogId"  name="id" />
                            <input  id="txtName" name="name" type="text" class="form-control" placeholder="Name"
                            required="required" />
                        </div>
                    </div>
                    <div style="height: 10px;"></div>
                    <div class="form-group row">
                        <label for="txtDescription" class="col-sm-4">Description</label>
                        <div class="col-sm-8">
                            <input  id="txtDescription" name="description" type="text" class="form-control" placeholder="Description" minlength="3" maxlength="30"
                            required="required"/>
                        </div>
                    </div>
                    <div style="height: 10px;"></div>
                    <div class="form-group row">
                      <label for="txtPrice" class="col-sm-4">Price</label>
                      <div class="col-sm-8">
                          <input  id="txtPrice" name="price" type="text" class="form-control" placeholder="Price"
                          required="required"/>
                      </div>
                  </div>
                  <div style="height: 10px;"></div>
  
                </div>
                <div class="modal-footer">
                    <button class="btn btn-warning" data-dismiss="modal" id="newFormCancel">Cancel</button>
                </div>
            </div>
        </div>
    </div>
  </form>
