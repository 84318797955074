import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CibsubjectGenerateComponent } from './cibsubject-generate/cibsubject-generate.component';
import { AuthGuard } from 'app/shared/auth/auth-guard.service';
import { CibsubjectPdfComponent } from './cibsubject-pdf/cibsubject-pdf.component';
import { CibcontractGenerateComponent } from './cibcontract-generate/cibcontract-generate.component';
import { CibcontractReportComponent } from './cibcontract-report/cibcontract-report.component';

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "cibsubject-generate",
        component: CibsubjectGenerateComponent,
        data: {
          title: "cibsubject-generate",
        },
        //canActivate: [AuthGuard],
      },
      {
        path: "cibsubject-pdf",
        component: CibsubjectPdfComponent,
        data: {
          title: "cibsubject-pdf",
        },
        //canActivate: [AuthGuard],
      },

      {
        path: "cibcontract-generate",
        component: CibcontractGenerateComponent,
        data: {
          title: "cibcontract-generate",
        },
        //canActivate: [AuthGuard],
      },

      {
        path: "cibcontract-report",
        component: CibcontractReportComponent,
        data: {
          title: "cibcontract-report",
        },
        //canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CibReportsRoutingModule { }
