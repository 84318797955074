<div class="card" style="width: 100%;">

    

    <div class="row card-header  d-flex flex-row align-items-center">
        <div class="col-6">
            <h6 class="m-0 font-weight-bold text-primary">Faild / Unsuccessfull Loan List</h6>
        </div>
        <div class="col-6">
            <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
                <!-- <i class="ft-chevrons-left"> Back</i> -->
                <i class="icon-arrow-left" style="font-size: 17px;"> </i>
            </button>
        </div>
    </div>
    <hr>
    <div *ngIf="isLoading" class="cssload-container">
        <div class="cssload-speeding-wheel"></div>
    </div>
    <div class="card-body">


        <table id="example" class="table table-striped table-bordered" style="width:100%">
            <thead>
                <tr>
                    <th>SL No.</th>
                    <th>Loan Account</th>
                    <th>Customer</th>
                    <th>Disburse Date</th>
                    <th>Loan Amount</th>
                    <!-- <th>Status</th> -->
                    <!-- <th>Action</th> -->

                </tr>
            </thead>

            <tbody>

                <tr *ngFor="let item of dataList; let i = index">
                    <td>{{i+1}}</td>
                    <td>{{item?.customerAccountNo}}</td>
                    <td>
                        <span>Name:{{item.customers?.customerNameEng }}</span> <br>
                        <span>Mobile No:{{item.customers?.mobileNo}} </span><br>
                    </td>

                    <td>{{item?.leadGeneration?.updatedAt | date:'dd-MM-yyyy'}}</td>
                    <td>{{item?.leadGeneration?.loanAmount}}</td>
                    <!-- <td>


                        <div class="" [ngClass]="{'bg-light-primary': item.statusInfoId == 7}">
                            Reconciliation
                        </div>



                    </td> -->
                    <!-- <td style="width: 18%!important;">
                        <button class="btn btn-sm btn-success "
                            (click)="checkPayment(item?.leadGeneration?.id,item?.customerAccountNo,item?.leadGeneration?.loanAmount)"
                            type="button" title="Disburse Loan">
                            Loan Sanction</button>
                            
                       
                  

                    </td> -->

                </tr>
            </tbody>
        </table>

    </div>
</div>