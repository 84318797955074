import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { LoginService } from 'app/pages/content-pages/login/login.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private logService: LoginService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const username = localStorage.getItem('userName');
      const routePath = state.url;


      return this.logService.checkUserAccess(username, routePath).toPromise()
        .then(canAccess => {
          if (canAccess) {
            return true;
          } else {
            this.router.navigate(['/unauthorized']);
            return false;
          }
        })
        .catch(error => {
          console.error('Error checking user access:', error);
          this.router.navigate(['/error']);
          return false;
        });

    // If not authenticated, redirect to the login page
    this.router.navigate(['/pages/login']);
    return false;
  }
}
