import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LedgerService } from '../service/ledger.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { SharedService } from 'app/services/shared.service';

@Component({
  selector: 'app-ledger-list',
  templateUrl: './ledger-list.component.html',
  styleUrls: ['./ledger-list.component.scss']
})
export class LedgerListComponent implements OnInit {
  isEdit: boolean = false;
  frmGroup: FormGroup;
  groupLedgerList:any []=[];
  subGroupLedgerList:any []=[];
 ledgerId :any=null;

 groupList1:any []=[];
subGroupList : any [] =[];
currentPage = 1;
itemsPerPage = 10;
currentItems = 0;
totalItems = 0;
searchControl = "";
subGroupTotalItems = 0;
subGroupCurrentItems = 0;

  constructor(private _ledgerService: LedgerService,
              private changeDetectorRef: ChangeDetectorRef,
              private router: Router,
              private shared: SharedService,
              private route: ActivatedRoute,) { }

  ngOnInit(): void {
    //this.showList='btnDiv';
    this.showList='subGroupList';
    this.getGrpLedgerList();
    this.GetGroupList();
    this.getSubGrpLedgerList();
    this.GetSubGroupList();
    this.route.params.subscribe(params=>{
      this.ledgerId=params['parameter'];
      
    })
  }

  showSaveForm: string = '';
  showList:string='';



  onClickShowSaveForm(value: string) {
    this.showSaveForm = value
  }

  showGrpSubGrpList(value: string){
    this.showList=value;
  }

  goSaveForm(){
    // this.router.navigate(['/e-kyc/nid-verify']);
    this.router.navigate(['/accounting/ledger']);
  }

  getGrpLedgerList(){
    this._ledgerService.getGrpLedgerList().subscribe(res =>{
      //console.log(res);
      this.groupLedgerList=res;

      this.changeDetectorRef.detectChanges();
    });
  }

  GetGroupList(){
    this._ledgerService.getGroupListWithSearchAndPagination(this.currentPage, this.itemsPerPage, this.searchControl).subscribe((res:any)=>{
      // console.log(3333);
      // console.log(res.data);
      this.groupList1= res.data;
      this.totalItems = res.totalItems;
      this.currentItems = res.currentItems;
    })
  }

  getSubGrpLedgerList(){
    this._ledgerService.getSubGrpLedgerList().subscribe(res =>{
      this.subGroupLedgerList=res;

      this.changeDetectorRef.detectChanges();
    });
    
  }

  GetSubGroupList(){
    this._ledgerService.getSubGroupListWithSearchAndPagination(this.currentPage, this.itemsPerPage, this.searchControl).subscribe((res:any)=>{
    
      this.subGroupList = res.data;
      // this.currentItems = res.currentItems;
      // this.totalItems = res.totalItems;
      this.subGroupTotalItems = res.subGroupTotalItems;
      this.subGroupCurrentItems = res.subGroupCurrentItems;
    })
  }

  edit(id: any){
   
    this.router.navigate(['/accounting/ledger-with-id', id]);
  
  }

  onChangeForShow(value:any){
    this.currentPage = 1;
    this.itemsPerPage = value;
    this.GetGroupList();
    this.GetSubGroupList();
  }

  onPageChange(pageNumber : number){
    this.currentPage = pageNumber;
    this.GetGroupList();
    this.GetSubGroupList();
  }

  


  onSearch(){
    this.currentPage = 1;
    this.GetGroupList();
    this.GetSubGroupList();
  }
  delete(Id: any) {

    this._ledgerService.delete(Id).subscribe(res => {

      this.shared.ShowAlert("Success", "Data deleted successfully", "success");
      //this.getLedgerList();
    })
  }

}
