import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
const apiUrl = environment.baseUrl + '/api/AccountGroup/';
const apiUrl2 = environment.baseUrl + '/api/GroupNature/';
@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor(private http: HttpClient) { }

  GetAccountGroupList(): Observable<any> {
    return this.http.get(apiUrl + 'GetAccountGroupList')

  }

  GetAllAccountGroupSubGroupList(): Observable<any> {
    return this.http.get(apiUrl + 'GetAllAccountGroupSubGroupList')

  }


  AllAccountGroupSubGroupList(): Observable<any> {
    return this.http.get(apiUrl + 'AllAccountGroupSubGroupList')

  }

  getGroupListWithPaginationAndSearch(currentPage: any, itemsPerPage : any, searchValue : string){
    return this.http.get(apiUrl + 'GetAccountGroupWithPaginationAndSearch?page=' + currentPage + '&pageSize=' + itemsPerPage + '&search=' + searchValue);
  }

  GetAccountGroupSubGroup(): Observable<any> {
    return this.http.get(apiUrl + 'GetAllAccountGroupSubGroupList')

  }

  getSubGrpByGrpId(grpId:number):Observable<any>{
    return this.http.get(apiUrl + 'GetAccountSubGroupByGroupId?parentId='+grpId)
  }

  GetAccountGroupListByNature(id:number): Observable<any> {
    return this.http.get(apiUrl + 'GetAccountGroupByNatureId?nId='+id)

  }

  GetAccountMainGroupByNatureId(id:number): Observable<any> {
    return this.http.get(apiUrl + 'GetAccountMainGroupByNatureId?nId='+id)

  }
  SaveAccountGroup(model: any) {

    return this.http.post(apiUrl + 'SaveAccountGroup', model)
  }

  DeleteaccountGroupById(id: any) {
    return this.http.delete(apiUrl + 'DeleteaccountGroupById?Id=' + id)
  }


  GetGroupNatureList(): Observable<any> {
    return this.http.get(apiUrl2 + 'GetGroupNatureList')

  }

  GetGroupCode(nId: number) {

    return this.http.get(apiUrl + 'GetGroupCodeByNatureId?nId=' + nId);
  }
  GetSubGroupCode(nId: number) {

    return this.http.get(apiUrl + 'GetSubGroupCodeByNatureId?nId=' + nId);
  }


  ChildAccountGroupByNature(id: number): Observable<any> {
    return this.http.get(apiUrl + 'ChildAccountGroupByNatureId?nId=' + id)

  }
  GetIncomeExpenseAccountGroup(): Observable<any> {
    return this.http.get(apiUrl + 'GetIncomeExpenseAccountGroup')

  }

}
