import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustommeraccountService } from 'app/customer-account/custommeraccount.service';
import { PaymentService } from 'app/payment/payment.service';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { ProductBuilderService } from 'app/product/service/product-builder.service';
import { SharedService } from 'app/services/shared.service';
import { LayoutService } from 'app/shared/services/layout.service';
import { TransectionService } from '../service/transection.service';

@Component({
  selector: 'app-transection-limit',
  templateUrl: './transection-limit.component.html',
  styleUrls: ['./transection-limit.component.scss']
})
export class TransectionLimitComponent implements OnInit {
  model: any = {};
  isEdit: boolean = false;
  frmGroup: FormGroup;
  declearationList: any[] = [];
  transactionList: any[] = [];
  list: any[] = [];

  decSelectList1: any[] = [];
  decSelectList2: any[] = [];

  constructor(
    private _service: TransectionService,
    private _productService: ProductBuilderService,
    private router: Router,
    private modalService: NgbModal,
    private changeDetectorRef: ChangeDetectorRef,
    private shared: SharedService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private paymentService: PaymentService,
    private pdfService: PdfService,
    private layoutService: LayoutService
  ) { }

  ngOnInit(): void {
    this.populateForm();
    this.getMoneyTransactionList();
    this.getTransectionLimitList();
  }

  populateForm() {
    this.frmGroup = this.formBuilder.group({
      id: [0],
      CustomersInfoId: [null],
      CustomersId: [null],

    })
  }

  // Delearation  

  getMoneyTransactionListKy() {
    
    this._service.GetAllTransactionDeclearationList().subscribe(res => {
      res.forEach(element => {
        this.transactionList.push(element);
      });

      
      res.forEach(element1 => {
        element1.monthlyTRCount = 0;
        element1.monthlyTRTotalAmount = 0;
        element1.monthlyTRMaxAmount = 0;
        this.decSelectList1.push(element1);

      });




      res.forEach(element2 => {
        element2.monthlyTRCount = 0;
        element2.monthlyTRTotalAmount = 0;
        element2.monthlyTRMaxAmount = 0;
        this.decSelectList2.push(element2);

      });
    });
   
  }

  getTransectionLimitList(){
    this._service.GetTransectionLimit().subscribe(res=>{
      console.log("res");
      console.log(res);
      this.list = res;
    })
  }

  getMoneyTransactionList() {
    this._service.GetAllTransactionDeclearationList().subscribe(res => {
      
      res.forEach(element => {
        this.transactionList.push(element);

        if (element.type === 1) {
          this.decSelectList1.push(element);
        }
       
      });

      res.forEach(element2 => {
        if (element2.type === 2) {
          this.decSelectList2.push(element2);
        }
      });
    });
  }


  updateDeclearationList(id: number, p: number, event: any) {

    let index = this.decSelectList1.findIndex((obj) => obj.methodTypeId === id);
  
    const updatedValue = event.target.value;
    const fullObject = this.decSelectList1[index];
   
    if (p === 1) {
      fullObject.monthlyTRCount = updatedValue;
      fullObject.monthlyTRTotalAmount = (fullObject.monthlyTRTotalAmount == undefined) ? 0 : fullObject.monthlyTRTotalAmount;
      fullObject.monthlyTRMaxAmount = (fullObject.monthlyTRMaxAmount == undefined) ? 0 : fullObject.monthlyTRMaxAmount;
    }
    else if (p === 2) {
      fullObject.monthlyTRTotalAmount = updatedValue;
      fullObject.monthlyTRCount = (fullObject.monthlyTRCount == undefined) ? 0 : fullObject.monthlyTRCount;
      fullObject.monthlyTRMaxAmount = (fullObject.monthlyTRMaxAmount == undefined) ? 0 : fullObject.monthlyTRMaxAmount;
    }
    else if (p === 3) {
      fullObject.monthlyTRMaxAmount = updatedValue;
      fullObject.monthlyTRTotalAmount = (fullObject.monthlyTRTotalAmount == undefined) ? 0 : fullObject.monthlyTRTotalAmount;
      fullObject.monthlyTRCount = (fullObject.monthlyTRCount == undefined) ? 0 : fullObject.monthlyTRCount;
    }
    this.decSelectList1.splice(index, 1);
    this.decSelectList1.push(fullObject);
  }



  updateDeclearationList1(id: number, p: number, event: any) {
    let index = this.decSelectList2.findIndex((obj) => obj.methodTypeId === id);
    const updatedValue = event.target.value;
    const fullObject = this.decSelectList2[index];
    if (p === 1) {
      fullObject.monthlyTRCount = updatedValue;
      fullObject.monthlyTRTotalAmount = (fullObject.monthlyTRTotalAmount == undefined) ? 0 : fullObject.monthlyTRTotalAmount;
      fullObject.monthlyTRMaxAmount = (fullObject.monthlyTRMaxAmount == undefined) ? 0 : fullObject.monthlyTRMaxAmount;
    }
    else if (p === 2) {
      fullObject.monthlyTRTotalAmount = updatedValue;
      fullObject.monthlyTRCount = (fullObject.monthlyTRCount == undefined) ? 0 : fullObject.monthlyTRCount;
      fullObject.monthlyTRMaxAmount = (fullObject.monthlyTRMaxAmount == undefined) ? 0 : fullObject.monthlyTRMaxAmount;
    }
    else if (p === 3) {
      fullObject.monthlyTRMaxAmount = updatedValue;
      fullObject.monthlyTRTotalAmount = (fullObject.monthlyTRTotalAmount == undefined) ? 0 : fullObject.monthlyTRTotalAmount;
      fullObject.monthlyTRCount = (fullObject.monthlyTRCount == undefined) ? 0 : fullObject.monthlyTRCount;
    }
    this.decSelectList2.splice(index, 1);
    this.decSelectList2.push(fullObject);
  }


  showDetails(id: number) {
    window.open('/customeraccount/accountDetail/' + id, '_blank');
  }
  // Delearation  


  onsubmit() {

    if (this.isEdit) {
      this.model.Id = this.frmGroup.value.id;
    }
   
    this.model.EditDepositDeclearation = this.decSelectList1;
    this.model.EditWithdrowDeclearation = this.decSelectList2;

    this._service.SaveDeclearation(this.model).subscribe(res => {
      this.shared.ShowAlert("Success", "Save Successful", "success");
    })
    this.changeDetectorRef.detectChanges();
  } 
  
  refresh() {
    this.isEdit = false;
    this.populateForm();
    this.model = {};
  }

}
